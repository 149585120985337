/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { PagedRequest, SortOn, WebError } from '@dpa/ui-common';
import { createAction, props } from '@ngrx/store';
import { TimeoutError } from 'rxjs';

import {
  ActionData,
  AggregationWidget,
  Automation,
  AutomationAction,
  AutomationConnectionTest,
  AutomationQuota,
  AutomationSearchRequest,
  AutomationSearchResponse,
  AutomationSourceObject,
  AutomationTestActionResult,
  AutomationTestConnectionResult,
  AutomationType,
  Connector,
  ConnectorAction,
  ConnectorsAllActionsRequest,
  ConnectorsAllActionsResponse,
  CronExpressionData,
  CustomReport,
  CustomReportPreviewSearchResponse,
  DashboardView,
  FilterRule,
  JsonSchemaAutomationActionFieldLookupRequestPayload,
  NameValue,
  QueryBuilder,
  SourceObjectType,
  Trend,
  TrendDefinition,
  WidgetDetailDefinition,
  WidgetDetailPageSkinType,
  WorkflowConditionBranch,
  WorkflowTaskType,
} from '@ws1c/intelligence-models';

/**
 * AutomationCommonActions
 *
 * @export
 * @class AutomationCommonActions
 */
@Injectable()
export class AutomationCommonActions {
  public static readonly CATEGORY = '[AUTOMATION_COMMON]';

  private static type = (actionName: string): string => `${AutomationCommonActions.CATEGORY} ${actionName}`;

  /**
   * loadAutomation
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static loadAutomation = createAction(AutomationCommonActions.type('LOAD_AUTOMATION'), props<{ automation: Automation }>());

  /**
   * loadAutomation
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static loadAutomationSuccess = createAction(
    AutomationCommonActions.type('LOAD_AUTOMATION_SUCCESS'),
    props<{ automation: Automation }>(),
  );

  /**
   * setAutomation
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static setAutomation = createAction(AutomationCommonActions.type('SET_AUTOMATION'), props<{ automation: Automation }>());

  /**
   * goToAutomationDetailsPage
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static goToAutomationDetailsPage = createAction(
    AutomationCommonActions.type('GO_TO_AUTOMATION_DETAILS_PAGE'),
    props<{ automationId: string; automationType?: AutomationType }>(),
  );

  /**
   * automateFromDataExplorer
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static automateFromDataExplorer = createAction(
    AutomationCommonActions.type('AUTOMATE_FROM_DATA_EXPLORER'),
    props<{ customReport: CustomReport }>(),
  );

  /**
   * automateFromReportList
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static automateFromReportList = createAction(
    AutomationCommonActions.type('AUTOMATE_FROM_REPORT_LIST'),
    props<{ customReport: CustomReport }>(),
  );

  /**
   * automateFromSourceObject
   * @static
   * @memberof AutomationCommonActions
   */
  public static automateFromSourceObject = createAction(
    AutomationCommonActions.type('AUTOMATE_FROM_SOURCE_OBJECT'),
    props<AutomationSourceObject>(),
  );

  /**
   * automateFromReportDetails
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static automateFromReportDetails = createAction(
    AutomationCommonActions.type('AUTOMATE_FROM_REPORT_DETAILS'),
    props<{ customReport: CustomReport }>(),
  );

  /**
   * automateFromDashboard
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static automateFromDashboard = createAction(
    AutomationCommonActions.type('AUTOMATE_FROM_DASHBOARD'),
    props<{
      widget: AggregationWidget;
      activeDashboard: DashboardView;
    }>(),
  );

  /**
   * automateFromStandardDashboard
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static automateFromStandardDashboard = createAction(
    AutomationCommonActions.type('AUTOMATE_FROM_STANDARD_DASHBOARD'),
    props<{
      dashboardName: string;
      widgetName: string;
      trendDefinition: TrendDefinition;
      returnUrl: string;
      detailsSkinType: WidgetDetailPageSkinType;
    }>(),
  );

  /**
   * automateFromWidgetDetails
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static automateFromWidgetDetails = createAction(
    AutomationCommonActions.type('AUTOMATE_FROM_WIDGET_DETAILS'),
    props<{
      detail: WidgetDetailDefinition;
      widget: AggregationWidget;
      trend: Trend;
      returnPath: string;
      skinType: WidgetDetailPageSkinType;
    }>(),
  );

  /**
   * getAutomationQuotas
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static getAutomationQuotas = createAction(AutomationCommonActions.type('GET_AUTOMATION_QUOTAS'));

  /**
   * getAutomationQuotasSuccess
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static getAutomationQuotasSuccess = createAction(
    AutomationCommonActions.type('GET_AUTOMATION_QUOTAS_SUCCESS'),
    props<{ automationQuotas: AutomationQuota[] }>(),
  );

  /**
   * getAutomationQuotasFailure
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static getAutomationQuotasFailure = createAction(
    AutomationCommonActions.type('GET_AUTOMATION_QUOTAS_FAILURE'),
    props<{ error: WebError }>(),
  );

  /**
   * requestCreateAutomation
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static requestCreateAutomation = createAction(AutomationCommonActions.type('REQUEST_CREATE_AUTOMATION'));

  /**
   * requestCreateAutomation
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static requestEditAutomation = createAction(
    AutomationCommonActions.type('REQUEST_EDIT_AUTOMATION'),
    props<{ automationId: string }>(),
  );

  /**
   * editFromOtherSource
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static editFromOtherSource = createAction(
    AutomationCommonActions.type('EDIT_FROM_OTHER_SOURCE'),
    props<{
      automationId: string;
      cancelReturnPath?: string;
      successReturnPath?: string;
    }>(),
  );

  /**
   * clearAutomationWizard
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static clearAutomationWizard = createAction(AutomationCommonActions.type('CLEAR_AUTOMATION_WIZARD'));

  /**
   * clearAutomationStateParams
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static clearAutomationStateParams = createAction(AutomationCommonActions.type('CLEAR_AUTOMATION_STATE_PARAMS'));

  /**
   * clearAutomationPreviewData
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static clearAutomationPreviewData = createAction(AutomationCommonActions.type('CLEAR_AUTOMATION_PREVIEW_DATA'));

  /**
   * setAutomationPreviewData
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static setAutomationPreviewData = createAction(
    AutomationCommonActions.type('SET_AUTOMATION_PREVIEW_DATA'),
    props<{ previewData: CustomReportPreviewSearchResponse }>(),
  );

  /**
   * setSourceObject
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static setSourceObject = createAction(AutomationCommonActions.type('SET_SOURCE_OBJECT'), props<AutomationSourceObject>());

  /**
   * clearSourceObject
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static clearSourceObject = createAction(AutomationCommonActions.type('CLEAR_SOURCE_OBJECT'));

  /**
   * requestCopyAutomation
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static requestCopyAutomation = createAction(
    AutomationCommonActions.type('REQUEST_COPY_AUTOMATION'),
    props<{ automation: Automation }>(),
  );

  /**
   * confirmRenameAutomation
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static confirmCopyAutomation = createAction(
    AutomationCommonActions.type('CONFIRM_COPY_AUTOMATION'),
    props<{
      newAutomationName: string;
      fromDetailView: boolean;
    }>(),
  );

  /**
   * confirmRunAutomation
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static confirmRunAutomation = createAction(
    AutomationCommonActions.type('CONFIRM_RUN_AUTOMATION'),
    props<{ automation: Automation }>(),
  );

  /**
   * automationSaveAndEnableConfirm
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static automationSaveAndEnableConfirm = createAction(
    AutomationCommonActions.type('AUTOMATION_SAVE_AND_ENABLE_CONFIRM'),
    props<{
      isOneTimeManualRunEnabled: boolean;
      fromDetailView: boolean;
    }>(),
  );

  /**
   * requestRenameAutomation
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static requestRenameAutomation = createAction(
    AutomationCommonActions.type('REQUEST_RENAME_AUTOMATION'),
    props<{ automation: Automation }>(),
  );

  /**
   * confirmRenameAutomation
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static confirmRenameAutomation = createAction(
    AutomationCommonActions.type('CONFIRM_RENAME_AUTOMATION'),
    props<{
      newAutomationName: string;
      fromDetailView: boolean;
    }>(),
  );

  /**
   * requestDeleteAutomation
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static requestDeleteAutomation = createAction(
    AutomationCommonActions.type('REQUEST_DELETE_AUTOMATION'),
    props<{ automation: Automation }>(),
  );

  /**
   * confirmDeleteAutomation
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static confirmDeleteAutomation = createAction(
    AutomationCommonActions.type('CONFIRM_DELETE_AUTOMATION'),
    props<{ fromDetailView: boolean }>(),
  );

  /**
   * confirmDeleteAutomationSuccess
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static confirmDeleteAutomationSuccess = createAction(
    AutomationCommonActions.type('CONFIRM_DELETE_AUTOMATION_SUCCESS'),
    props<{ automation: Automation }>(),
  );

  /**
   * requestChangeAutomationStatus
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static requestChangeAutomationStatus = createAction(
    AutomationCommonActions.type('REQUEST_CHANGE_AUTOMATION_STATUS'),
    props<{ automation: Automation }>(),
  );

  /**
   * requestRunAutomation
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static requestRunAutomation = createAction(
    AutomationCommonActions.type('REQUEST_RUN_AUTOMATION'),
    props<{ automation: Automation }>(),
  );

  /**
   * confirmTemplateUpdate
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static confirmTemplateUpdate = createAction(AutomationCommonActions.type('CONFIRM_TEMPLATE_UPDATE'));

  /**
   * fetchAutomationAndRunAction
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static fetchAutomationAndRunAction = createAction(
    AutomationCommonActions.type('FETCH_AUTOMATION_AND_RUN_ACTION'),
    props<{ id: string; nextAction: any }>(),
  );

  /**
   * goToAutomationPage
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static goToAutomationPage = createAction(
    AutomationCommonActions.type('GO_TO_AUTOMATION_PAGE'),
    props<{ automationType?: AutomationType }>(),
  );

  /**
   * navigateToSuccessReturnPath
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static navigateToSuccessReturnPath = createAction(
    AutomationCommonActions.type('NAVIGATE_TO_SUCCESS_RETURN_PATH'),
    props<{ automation: Automation }>(),
  );

  /**
   * goToCreateAutomationPage
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static goToCreateAutomationPage = createAction(
    AutomationCommonActions.type('GO_TO_CREATE_AUTOMATION_PAGE'),
    props<{ automationType?: AutomationType }>(),
  );

  /**
   * goToEditAutomationPage
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static goToEditAutomationPage = createAction(
    AutomationCommonActions.type('GO_TO_EDIT_AUTOMATION_PAGE'),
    props<{ automationId: string; automationType?: AutomationType }>(),
  );

  /**
   * requestPreviewAutomation
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static requestPreviewAutomation = createAction(AutomationCommonActions.type('REQUEST_PREVIEW_AUTOMATION'));

  /**
   * closeAutomationDialog
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static closeAutomationDialog = createAction(AutomationCommonActions.type('CLOSE_AUTOMATION_DIALOG'));

  /**
   * updateAutomationSuccess
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static updateAutomationSuccess = createAction(
    AutomationCommonActions.type('UPDATE_AUTOMATION_SUCCESS'),
    props<{ automation: Automation }>(),
  );

  /**
   * updateAutomationFailure
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static updateAutomationFailure = createAction(AutomationCommonActions.type('UPDATE_AUTOMATION_FAILURE'));

  /**
   * setAutomationDetails
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static setAutomationDetails = createAction(
    AutomationCommonActions.type('SET_AUTOMATION_DETAILS'),
    props<{ automation: Automation }>(),
  );

  /**
   * refreshAutomations
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static refreshAutomations = createAction(AutomationCommonActions.type('REFRESH_AUTOMATION_LIST'));

  /**
   * changePaginationAutomations
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static changePagination = createAction(
    AutomationCommonActions.type('CHANGE_PAGINATION_AUTOMATIONS'),
    props<{ pagedRequest: AutomationSearchRequest }>(),
  );

  /**
   * sortAutomationList
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static sortAutomations = createAction(AutomationCommonActions.type('SORT_AUTOMATION_LIST'), props<{ sortOns: SortOn[] }>());

  /**
   * loadAutomationList
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static loadAutomationList = createAction(
    AutomationCommonActions.type('LOAD_AUTOMATION_LIST'),
    props<{ request: AutomationSearchRequest }>(),
  );

  /**
   * loadAutomationListSuccess
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static loadAutomationListSuccess = createAction(
    AutomationCommonActions.type('LOAD_AUTOMATION_LIST_SUCCESS'),
    props<{ response: AutomationSearchResponse }>(),
  );

  /**
   * loadAutomationListFailure
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static loadAutomationListFailure = createAction(
    AutomationCommonActions.type('LOAD_AUTOMATION_LIST_FAILURE'),
    props<{ error: WebError }>(),
  );

  /**
   * loadAutomationPreviewResults
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static loadAutomationPreviewResults = createAction(AutomationCommonActions.type('LOAD_AUTOMATION_PREVIEW_RESULTS'));

  /**
   * filterAutomations
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static filterAutomations = createAction(AutomationCommonActions.type('FILTER_AUTOMATIONS'), props<{ text: string }>());

  /**
   * loadMoreAutomations
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static loadMoreAutomations = createAction(
    AutomationCommonActions.type('LOAD_MORE_AUTOMATIONS'),
    props<{ automationSearchRequest: AutomationSearchRequest }>(),
  );

  /**
   * loadMoreAutomationsSuccess
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static loadMoreAutomationsSuccess = createAction(
    AutomationCommonActions.type('LOAD_MORE_AUTOMATIONS_SUCCESS'),
    props<{ response: AutomationSearchResponse }>(),
  );

  /**
   * setCancelReturnPath
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static setCancelReturnPath = createAction(
    AutomationCommonActions.type('SET_CANCEL_RETURN_PATH'),
    props<{ cancelWizardReturnPath: string }>(),
  );

  /**
   * setSuccessReturnPath
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static setSuccessReturnPath = createAction(
    AutomationCommonActions.type('SET_SUCCESS_RETURN_PATH'),
    props<{ successWizardReturnPath: string }>(),
  );

  /**
   * changeAutomationStatus
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static changeAutomationStatus = createAction(
    AutomationCommonActions.type('CHANGE_AUTOMATION_STATUS'),
    props<{
      fromDetailView: boolean;
      isOneTimeManualRunEnabled: boolean;
    }>(),
  );

  /**
   * showSaveAndEnableDialog
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static showSaveAndEnableDialog = createAction(AutomationCommonActions.type('SHOW_SAVE_AND_ENABLE_DIALOG'));

  /**
   * showSaveAndEnableDialogSuccess
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static showSaveAndEnableDialogSuccess = createAction(AutomationCommonActions.type('SHOW_SAVE_AND_ENABLE_DIALOG_SUCCESS'));

  /**
   * setTestAttributes
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static setTestAttributes = createAction(
    AutomationCommonActions.type('SET_TEST_ATTRIBUTES'),
    props<{ testAttributes: Set<string> }>(),
  );

  /**
   * loadTestDynamicValuesTable
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static loadTestDynamicValuesTable = createAction(
    AutomationCommonActions.type('LOAD_TEST_DYNAMIC_VALUES_TABLE'),
    props<{
      pagedRequest: PagedRequest;
      filterRule?: FilterRule;
    }>(),
  );

  /**
   * loadTestDynamicValuesSuccess
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static loadTestDynamicValuesSuccess = createAction(
    AutomationCommonActions.type('LOAD_TEST_DYNAMIC_VALUES_SUCCESS'),
    props<{ testDynamicValues: CustomReportPreviewSearchResponse }>(),
  );

  /**
   * loadTestDynamicValuesFailure
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static loadTestDynamicValuesFailure = createAction(
    AutomationCommonActions.type('LOAD_TEST_DYNAMIC_VALUES_FAILURE'),
    props<{ error: WebError }>(),
  );

  /**
   * confirmConnectorActionTest
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static confirmConnectorActionTest = createAction(
    AutomationCommonActions.type('CONFIRM_CONNECTOR_ACTION_TEST'),
    props<{ automationAction: AutomationAction; connector: Connector; connectorAction: ConnectorAction }>(),
  );

  /**
   * loadAllActions
   * @static
   * @memberof AutomationCommonActions
   */
  public static loadAllActions = createAction(
    AutomationCommonActions.type('LOAD_ALL_ACTIONS'),
    props<{ request?: ConnectorsAllActionsRequest; sourceObjectType?: SourceObjectType }>(),
  );

  /**
   * loadAllActionsSuccess
   * @static
   * @memberof AutomationCommonActions
   */
  public static loadAllActionsSuccess = createAction(
    AutomationCommonActions.type('LOAD_ALL_ACTIONS_SUCCESS'),
    props<{
      connectorsActionsResponse: ConnectorsAllActionsResponse;
    }>(),
  );

  /**
   * loadAllActionsFailure
   * @static
   * @memberof AutomationCommonActions
   */
  public static loadAllActionsFailure = createAction(AutomationCommonActions.type('LOAD_ALL_ACTIONS_FAILURE'));

  /**
   * testConnectorAction
   * @static
   * @memberof AutomationCommonActions
   */
  public static testConnectorAction = createAction(
    AutomationCommonActions.type('TEST_CONNECTOR_ACTION'),
    props<{ actionTest: AutomationConnectionTest }>(),
  );

  /**
   * testConnectorActionSuccess
   * @static
   * @memberof AutomationCommonActions
   */
  public static testConnectorActionSuccess = createAction(
    AutomationCommonActions.type('TEST_CONNECTOR_ACTION_SUCCESS'),
    props<{ actionTestResult: AutomationTestConnectionResult | AutomationTestActionResult }>(),
  );

  /**
   * testConnectorActionFailure
   * @static
   * @memberof AutomationCommonActions
   */
  public static testConnectorActionFailure = createAction(
    AutomationCommonActions.type('TEST_CONNECTOR_ACTION_FAILURE'),
    props<{ actionTestResult: AutomationTestConnectionResult | AutomationTestActionResult }>(),
  );

  /**
   * selectAutomationRepeatTrigger
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static selectAutomationRepeatTrigger = createAction(
    AutomationCommonActions.type('SELECT_AUTOMATION_REPEAT_TRIGGER'),
    props<{ repeatTrigger: boolean }>(),
  );

  /**
   * selectAutomationEvaluationType
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static selectAutomationEvaluationType = createAction(
    AutomationCommonActions.type('SELECT_AUTOMATION_EVALUATION_TYPE'),
    props<{ evaluationType: string }>(),
  );

  /**
   * setCronExpressionData
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static setCronExpressionData = createAction(
    AutomationCommonActions.type('SET_CRON_EXPRESSION_DATA'),
    props<{ cronExpressionDetail: CronExpressionData }>(),
  );

  /**
   * clearCronExpressionData
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static clearCronExpressionData = createAction(AutomationCommonActions.type('CLEAR_CRON_EXPRESSION_DATA'));

  /**
   * updateWizardModel
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static updateWizardModel = createAction(
    AutomationCommonActions.type('UPDATE_WIZARD_MODEL'),
    props<{ automation: Partial<Automation> }>(),
  );

  /**
   * addTriggerToWizardModel
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static addTriggerToWizardModel = createAction(
    AutomationCommonActions.type('ADD_TRIGGER_TO_WIZARD_MODEL'),
    props<{ queryBuilder: QueryBuilder }>(),
  );

  /**
   * refreshAutomationPreviewData
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static refreshAutomationPreviewData = createAction(AutomationCommonActions.type('REFRESH_AUTOMATION_PREVIEW_DATA'));

  /**
   * getIrrecoverableActions
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static getIrrecoverableActions = createAction(AutomationCommonActions.type('GET_IRRECOVERABLE_ACTIONS'));

  /**
   * loadAutomationTemplateDetailsSuccess
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static loadAutomationTemplateDetailsSuccess = createAction(
    AutomationCommonActions.type('LOAD_AUTOMATION_TEMPLATE_DETAILS_SUCCESS'),
    props<{ automation: Automation; updatePreviewModelOnly?: boolean }>(),
  );

  /**
   * applyPreviewModel
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static applyPreviewModel = createAction(AutomationCommonActions.type('APPLY_PREVIEW_MODEL'));

  /**
   * resetPreviewModel
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static resetPreviewModel = createAction(AutomationCommonActions.type('RESET_PREVIEW_MODEL'));

  /**
   * loadAutomationTemplateDetailsFailure
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static loadAutomationTemplateDetailsFailure = createAction(
    AutomationCommonActions.type('LOAD_AUTOMATION_TEMPLATE_DETAILS_FAILURE'),
    props<{ error: WebError }>(),
  );

  /**
   * loadAutomationsBySourceObjectId
   * @static
   * @memberof AutomationCommonActions
   */
  public static loadAutomationsBySourceObjectId = createAction(
    AutomationCommonActions.type('LOAD_AUTOMATIONS_BY_SOURCE_OBJECT_ID'),
    props<{
      sourceObjectId: string;
      pagedRequest?: AutomationSearchRequest;
    }>(),
  );

  /**
   * loadAutomationsBySourceObjectIdSuccess
   * @static
   * @memberof AutomationCommonActions
   */
  public static loadAutomationsBySourceObjectIdSuccess = createAction(
    AutomationCommonActions.type('LOAD_AUTOMATIONS_BY_SOURCE_OBJECT_ID_SUCCESS'),
    props<{
      request: AutomationSearchRequest;
      response: AutomationSearchResponse;
    }>(),
  );

  /**
   * loadAutomationsBySourceObjectIdFailure
   * @static
   * @memberof AutomationCommonActions
   */
  public static loadAutomationsBySourceObjectIdFailure = createAction(
    AutomationCommonActions.type('LOAD_AUTOMATIONS_BY_SOURCE_OBJECT_ID_FAILURE'),
    props<{ error: WebError }>(),
  );

  /**
   * confirmCreateAutomation
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static confirmCreateAutomation = createAction(
    AutomationCommonActions.type('CONFIRM_CREATE_AUTOMATION'),
    props<{ isOneTimeManualRunEnabled?: boolean }>(),
  );

  /**
   * confirmCreateAutomationSuccess
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static confirmCreateAutomationSuccess = createAction(AutomationCommonActions.type('CONFIRM_CREATE_AUTOMATION_SUCCESS'));

  /**
   * confirmCreateAutomationFailure
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static confirmCreateAutomationFailure = createAction(AutomationCommonActions.type('CONFIRM_CREATE_AUTOMATION_FAILURE'));

  /**
   * confirmEditAutomation
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static confirmEditAutomation = createAction(
    AutomationCommonActions.type('CONFIRM_EDIT_AUTOMATION'),
    props<{ isOneTimeManualRunEnabled?: boolean }>(),
  );

  /**
   * setAutomationWizardModel
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static setAutomationWizardModel = createAction(
    AutomationCommonActions.type('SET_AUTOMATION_WIZARD_MODEL'),
    props<{ automation: Automation }>(),
  );

  /**
   * loadAutomationEditor
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static loadAutomationEditor = createAction(AutomationCommonActions.type('LOAD_AUTOMATION_EDITOR'));

  /**
   * loadAutomationPreviewTable
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static loadAutomationPreviewTable = createAction(
    AutomationCommonActions.type('LOAD_AUTOMATION_PREVIEW_TABLE'),
    props<{
      automation: Automation;
      pagedRequest?: PagedRequest;
    }>(),
  );

  /**
   * loadAutomationPreviewDataSuccess
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static loadAutomationPreviewDataSuccess = createAction(
    AutomationCommonActions.type('LOAD_AUTOMATION_PREVIEW_DATA_SUCCESS'),
    props<{ response: CustomReportPreviewSearchResponse }>(),
  );

  /**
   * loadAutomationPreviewDataFailure
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static loadAutomationPreviewDataFailure = createAction(
    AutomationCommonActions.type('LOAD_AUTOMATION_PREVIEW_DATA_FAILURE'),
    props<{ error: WebError | TimeoutError }>(),
  );

  /**
   * setSourceObjectBreadCrumbs
   * @static
   * @memberof AutomationCommonActions
   */
  public static setSourceObjectBreadCrumbs = createAction(
    AutomationCommonActions.type('SET_SOURCE_OBJECT_BREADCRUMBS'),
    props<AutomationSourceObject>(),
  );

  /**
   * setIncidentAutomationBreadCrumbs
   * @static
   * @memberof AutomationCommonActions
   */
  public static setIncidentAutomationBreadCrumbs = createAction(
    AutomationCommonActions.type('SET_INCIDENT_AUTOMATION_BREAD_CRUMBS'),
    props<{ incidentId: string }>(),
  );

  /**
   * addWorkflowTask
   * @static
   * @memberof AutomationCommonActions
   */
  public static addWorkflowTask = createAction(
    AutomationCommonActions.type('ADD_WORKFLOW_TASK'),
    props<{
      workflowTaskType: WorkflowTaskType;
      addToWorkflowTask: AutomationAction;
      addToWorkflowConditionBranch: WorkflowConditionBranch;
      insertAtIndex?: number;
    }>(),
  );

  /**
   * addActionsToWorkflowTask
   * @static
   * @memberof AutomationCommonActions
   */
  public static addActionsToWorkflowTask = createAction(
    AutomationCommonActions.type('ADD_ACTIONS_TO_WORKFLOW_TASK'),
    props<{ workflowTask: AutomationAction; payload: Array<Partial<ActionData>> }>(),
  );

  /**
   * updateActionOfWorkflowTask
   * @static
   * @memberof AutomationCommonActions
   */
  public static updateActionOfWorkflowTask = createAction(
    AutomationCommonActions.type('UPDATE_ACTION_OF_WORKFLOW_TASK'),
    props<{ workflowTask: AutomationAction; payload: Partial<ActionData> }>(),
  );

  /**
   * deleteWorkflowTask
   * @static
   * @memberof AutomationCommonActions
   */
  public static deleteWorkflowTask = createAction(
    AutomationCommonActions.type('DELETE_WORKFLOW_TASK'),
    props<{ taskToDelete: AutomationAction }>(),
  );

  /**
   * updateWorkflowConditionTaskFilter
   * @static
   * @memberof AutomationCommonActions
   */
  public static updateWorkflowConditionTaskFilter = createAction(
    AutomationCommonActions.type('UPDATE_WORKFLOW_CONDITION_TASK_FILTER'),
    props<{ workflowTask: AutomationAction; queryBuilder: QueryBuilder }>(),
  );

  /**
   * reArrangeWorkflowTasks
   * @static
   * @memberof AutomationCommonActions
   */
  public static reArrangeWorkflowTasks = createAction(
    AutomationCommonActions.type('REARRANGE_WORKFLOW_TASKS'),
    props<{
      parentWorkflowTask: AutomationAction;
      parentWorkflowConditionBranch: WorkflowConditionBranch;
      previousIndex: number;
      currentIndex: number;
    }>(),
  );

  public static requestFieldLookup = createAction(
    AutomationCommonActions.type('REQUEST_FIELD_LOOKUP'),
    props<{ payload: JsonSchemaAutomationActionFieldLookupRequestPayload }>(),
  );

  public static loadLookupValues = createAction(
    AutomationCommonActions.type('LOAD_LOOKUP_VALUES'),
    props<{ lookupMap: Record<string, NameValue[]> }>(),
  );

  public static loadLookupValuesFailure = createAction(
    AutomationCommonActions.type('LOAD_LOOKUP_VALUES_FAILURE'),
    props<{ error: WebError | TimeoutError }>(),
  );

  public static setCurrentActionConnectorId = createAction(
    AutomationCommonActions.type('SET_CURRENT_ACTION_CONNECTOR_ID'),
    props<{ connectorId: string }>(),
  );

  /**
   * saveAutomation
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static saveAutomation = createAction(
    AutomationCommonActions.type('SAVE_AUTOMATION'),
    props<{ automation: Partial<Automation> }>(),
  );

  /**
   * showSaveDraftDialog
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static showSaveDraftDialog = createAction(AutomationCommonActions.type('SHOW_SAVE_DRAFT_DIALOG'));

  /**
   * showEditEnabledAutomationModal
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static showEditEnabledAutomationModal = createAction(
    AutomationCommonActions.type('SHOW_EDIT_ENABLED_AUTOMATION_MODAL'),
    props<{ automation: Automation }>(),
  );

  /**
   * setActiveAutomationType
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static setActiveAutomationType = createAction(
    AutomationCommonActions.type('SET_ACTIVE_AUTOMATION_TYPE'),
    props<{ automationType: AutomationType }>(),
  );

  /**
   * showTriggerRules
   *
   * @static
   * @memberof AutomationCommonActions
   */
  public static setShowTriggerRules = createAction(
    AutomationCommonActions.type('SET_SHOW_TRIGGER_RULES'),
    props<{ showTriggerRules: boolean }>(),
  );
}
