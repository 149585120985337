/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

/**
 * DeemVirtualGPOEventName
 * @export
 * @enum {string}
 */
export enum DeemVirtualGPOEventName {
  USER_GPO_CSE_DETAIL = 'user_gpo_cse_details',
  USER_GPO_DETAIL = 'user_gpo_details',
  USER_GPO_SUMMAY = 'user_gpo_summary',
}
