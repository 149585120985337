/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnChanges,
  Output,
  PLATFORM_ID,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { BaseChartComponent, Color, ColorProfiles, DataItem, TreeMapComponent } from '@dpa/ui-common';

import { ChartDrilldownEvent, NgxChart, NgxSingleData } from '@ws1c/intelligence-models';

/**
 * TreeMapChartComponent
 * @export
 * @class TreeMapChartComponent
 * @extends {BaseChartComponent}
 * @implements {OnChanges}
 */
@Component({
  selector: 'dpa-tree-map-chart',
  templateUrl: 'tree-map-chart.component.html',
  styleUrls: ['tree-map-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreeMapChartComponent extends BaseChartComponent implements OnChanges {
  @Input() public ngxChart: NgxChart;
  @Input() public showDetailsLink?: boolean = false;
  @Input() public stackedTooltip?: TemplateRef<any>;
  @Input() public labelFormatting?: any;
  @Input() public valueFormatting?: any;
  @Input() public showSameSizeCell?: boolean = false;
  @Input() public noDataMessage?: string;
  @Input() public noDataTooltip?: string;
  @Output() public onViewDetails = new EventEmitter<ChartDrilldownEvent>();

  @ViewChild(TreeMapComponent) public chart?: TreeMapComponent;

  public readonly colorScheme: Color = ColorProfiles.CATEGORICAL;

  /**
   * Creates an instance of TreeMapChartComponent.
   * @param {ElementRef} chartElement
   * @param {NgZone} zone
   * @param {ChangeDetectorRef} cd
   * @param {*} platformId
   * @memberof TreeMapChartComponent
   */
  constructor(
    chartElement: ElementRef,
    zone: NgZone,
    cd: ChangeDetectorRef,
    @Inject(PLATFORM_ID) public platformId: any,
  ) {
    // To fix https://github.com/karma-runner/karma-coverage/issues/460
    super(chartElement, zone, cd, platformId);
  }

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes
   * @memberof TreeMapChartComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (this.showSameSizeCell && changes.ngxChart.currentValue) {
      const data: NgxSingleData[] = changes.ngxChart.currentValue.chartData;
      this.ngxChart.chartData = data.map((dataItem) => ({
        ...dataItem,
        extra: {
          rawName: dataItem.name,
        },
        name: `${dataItem.name} (${dataItem.value})`,
        value: 1,
      }));
      this.valueFormatting = () => '';
    }
  }

  /**
   * onResize
   * @memberof TreeMapChartComponent
   */
  public onResize() {
    if (!this.chart) {
      return;
    }
    this.chart.update();
  }

  /**
   * onSelect
   * @param {DataItem} ngxSelectEvent
   * @memberof TreeMapChartComponent
   */
  public onSelect(ngxSelectEvent: DataItem) {
    if (!this.showDetailsLink) {
      return;
    }
    const dataItemName: string = this.showSameSizeCell ? ngxSelectEvent.extra?.rawName : ngxSelectEvent.name;
    const drilldownEvent: ChartDrilldownEvent = {
      ...this.ngxChart.drilldownEventBuilder.getEvent(ngxSelectEvent, false),
      selectedCounter: this.ngxChart.usingFakeGroupBy ? dataItemName : undefined,
    };
    this.onViewDetails.emit(drilldownEvent);
  }
}
