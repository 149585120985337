/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { BreadCrumb, GenericObject, PagedRequest, SortOn, WebError } from '@dpa/ui-common';
import { GridsterItem } from 'angular-gridster2';

import {
  AggregationWidget,
  Category,
  ChartDrilldownEvent,
  Column,
  CustomReportPreviewSearchResponse,
  Dashboard,
  DashboardSearchRequest,
  DashboardSearchResponse,
  DashboardTagFilter,
  DashboardView,
  DrilldownTrail,
  IncrementalLoadingResponseTrendStatus,
  IncrementalLoadingWidgetDetails,
  LayoutType,
  LOAD_STATE,
  LocalDataGridSettings,
  OperatingSystem,
  ROUTE_NAMES,
  RuleGroup,
  StandardDashboardRequest,
  TransferOwnershipActionOrigin,
  Trend,
  TrendDateRange,
  TrendDefinition,
  UserAdminAccount,
  WidgetDataRequest,
  WidgetDetailDefinition,
  WidgetDetailPageSkinType,
  WidgetPreference,
  WidgetTemplate,
  WidgetUpdateDataRequest,
  WidgetWizardDialogMode,
} from '@ws1c/intelligence-models';

/**
 * Map of all widget data
 *
 * @export
 * @interface WidgetData
 */
export interface WidgetData {
  [id: string]: WidgetDataRequest;
}

/**
 * ActiveWidgetDialogMode
 * @export
 * @enum {number}
 */
export enum ActiveWidgetDialogMode {
  CLOSE,
  COPY,
  DELETE,
  DUPLICATE,
  INFO,
  RENAME,
  EDIT_THEME,
}

/**
 * DashboardDialogMode
 * @export
 * @enum {number}
 */
export enum DashboardDialogMode {
  ADD_WIDGET,
  CLOSE,
  DELETE,
  DUPLICATE,
  EXPORT_SUCCESS,
  IMPORT,
  RENAME,
  SHARE,
  TRANSFER_OWNERSHIP,
}

/**
 * DashboardActionOrigin
 * @export
 * @enum {number}
 */
export enum DashboardActionOrigin {
  LIST,
  DETAILS,
}

export interface CopyToWidget {
  dashboard: Dashboard;
  widget: AggregationWidget;
}

/**
 * Defines UI State for Dashboard Feature
 *
 * @export
 * @interface DashboardState
 */

export interface DashboardState {
  drilldownEventsById: Record<string, ChartDrilldownEvent[]>;
  widgetDetailDefinition: WidgetDetailDefinition;
  widgetDetailDrilldownEvents: ChartDrilldownEvent[];
  widgetDetailWidgetId: string;
  widgetDetailPageSkinType: WidgetDetailPageSkinType;
  widgetDetailUrl: string;
  widgetDetailTableSelectedColumnNames: string[];
  widgetDetailIsEditRangeDialogOpen: boolean;
  widgetDetailDrilldownEventIndex: number;
  widgetDetailPagedRequest: PagedRequest;
  widgetPreviewPagedRequest: PagedRequest;
  widgetDetailLoadingTrend: boolean;
  widgetDetailTrend: Trend;
  widgetDetailTrendError: WebError;
  widgetDetailLoadingTablePreview: boolean;
  widgetDetailTablePreviewError: WebError;
  widgetDetailTablePreview: CustomReportPreviewSearchResponse;
  widgetTableSortOns: SortOn[];
  isOverlayDetailTableLoading: boolean;
  isOverlayDetailTrendLoading: boolean;
  overlayDetailTablePreview: CustomReportPreviewSearchResponse;
  widgetDetailOverlayTrend: Trend;
  overlayTableSortOns: SortOn[];
  overlayTablePagedRequest: PagedRequest;
  widgetPreviewTableSortOns: SortOn[];
  widgetDetailCompositeTrendData: Map<string, Trend>;
  widgetDetailSelectedCompositeTableSubtype: string;
  widgetEditRangeWidgetId: string;
  widgetEditRangeGroupByLabel: string;
  widgetAttributePreferencesById: Record<string, WidgetPreference[]>;

  allDashboards: DashboardView[];
  selectedDashboards: Dashboard[];
  activeWidget: AggregationWidget;
  activeWidgetDialogMode: ActiveWidgetDialogMode;
  activeWidgetTemplate: WidgetTemplate;
  activeWidgetTheme: GenericObject;
  activeDashboard: DashboardView;
  lastRefreshTime: number;
  previewRefreshedAt: number;
  widgetList: GridsterItem[];
  widgetData: WidgetData;
  widgetsById: Record<string, AggregationWidget>;
  webErrorsByWidgetId: Record<string, WebError>;
  widgetIdToDashboardIdMap: Record<string, string>;
  widgetTemplates: WidgetTemplate[];
  widgetTemplatesCategory: Category;
  widgetTemplatesByCategory: WidgetTemplate[];
  widgetWizardDialogMode: WidgetWizardDialogMode;
  widgetUpdateData: WidgetUpdateDataRequest;
  overlayUpdateData: WidgetUpdateDataRequest;
  isLoadingWidgetPreviewData: boolean;
  isLoadingOverlayPreviewData: boolean;
  widgetDataPreview: WidgetDataRequest;
  overlayDataPreview: WidgetDataRequest;
  widgetPreviewTablePreview: CustomReportPreviewSearchResponse;
  widgetPreviewColumnSelection: Column[];
  returnCrumbs: BreadCrumb[];

  trendDefinitionsByStandardWidgetSubtype: any;
  standardWidgetColumnsMapping: Record<string, string[]>;

  visibleWidgetCountBySubtypes: any;

  standardDashboardRequest: StandardDashboardRequest;
  standardDashboardData: Map<string, Trend>;

  myDashboardViewFilterRuleGroup: RuleGroup;
  myDashboardViewTrendDateRange: TrendDateRange;
  userFlowsSubFilterRuleGroup: RuleGroup;
  userFlowsCrumbListsIndex: any;

  deviceDashboardTrendDateRange: TrendDateRange;
  operatingSystems: OperatingSystem[];
  scrollToBottom: boolean;
  addedOrDuplicated: boolean;
  layoutEditConfirm: boolean;

  actionOrigin: DashboardActionOrigin;
  dashboardDialogMode: DashboardDialogMode;
  dashboardDialogModel: Dashboard | DashboardView;
  dashboardsDialogModel: Dashboard[];
  isDashboardLoading: boolean;
  isDashboardListloading: boolean;
  isDashboardImportInProgress: boolean;

  dashboardSearchRequest: DashboardSearchRequest;
  dashboardSearchResponse: DashboardSearchResponse;
  dashboardSearchType: string;
  dashboardSearchError: WebError;
  isDashboardSearchInProgress: boolean;
  globalSearchResults: Dashboard[];
  widgetListFilter: any;
  dashboardListFilter: DashboardTagFilter;
  dashboardRecipients: UserAdminAccount[];
  isDashboardRecipientsLoading: boolean;

  localDataGridSettingsById: Record<string, LocalDataGridSettings>;

  pendingStandardDashboardRequests: number;
  pendingPreviewDashboardRequests: number;

  pendingPreviewWidgetSubtypes: string[];

  tableTrendDefinitionsById: Record<string, TrendDefinition>;
  tableTrendsById: Record<string, Trend>;

  deviceHealth: string;
  deviceId: string;

  rootUrl: string;
  incident: {
    customDashboard: Dashboard;
    incidentId: string;
  };
  widgetDrilldownTrails: DrilldownTrail[];
  dashboardLastUpdated: number;

  dapInfo: {
    selectedDashboardOwnersDAPIds: Set<string>;
    transferToOwnerDAPId: string;
  };

  riskIndicatorDetailsByTimestampByGuid: GenericObject;

  activeCategoryId: string;
  isCrossCategoryActive: boolean;
  defaultColumns: Record<string, string[]>;
  defaultNonJoinColumns: Record<string, string[]>;
  isLoadingDefaultColumns: boolean;
  currentDashboard: string;

  transferOwnershipActionOrigin: TransferOwnershipActionOrigin;

  selectedViewLayout: LayoutType;
  unMergeWidgetSubTypeList: string[];

  dashboardTemplateImportLoadStatus: LOAD_STATE;

  // incremental loading
  queryIdVsTrackingIdMap: Map<string, string[]>;
  trackingIdDetailsMap: Map<string, IncrementalLoadingWidgetDetails>;
  loadingStatusByTrackingIdMap: Map<string, IncrementalLoadingResponseTrendStatus>;
}

/**
 * initialDashboardState
 */
export const initialDashboardState: DashboardState = {
  drilldownEventsById: {},
  widgetDetailDefinition: undefined,
  widgetDetailDrilldownEvents: undefined,
  widgetDetailWidgetId: undefined,
  widgetDetailPageSkinType: undefined,
  widgetDetailUrl: undefined,
  widgetDetailTableSelectedColumnNames: undefined,
  widgetDetailIsEditRangeDialogOpen: false,
  widgetDetailDrilldownEventIndex: 0,
  widgetDetailPagedRequest: undefined,
  widgetPreviewPagedRequest: undefined,
  widgetDetailLoadingTrend: undefined,
  widgetDetailTrend: undefined,
  widgetDetailTrendError: undefined,
  widgetDetailLoadingTablePreview: undefined,
  widgetDetailTablePreview: undefined,
  widgetDetailTablePreviewError: undefined,
  widgetDrilldownTrails: [],
  widgetTableSortOns: undefined,
  widgetDetailOverlayTrend: undefined,
  isOverlayDetailTableLoading: false,
  isOverlayDetailTrendLoading: false,
  overlayDetailTablePreview: undefined,
  overlayTableSortOns: undefined,
  overlayTablePagedRequest: undefined,
  widgetPreviewTableSortOns: undefined,
  widgetDetailCompositeTrendData: undefined,
  widgetDetailSelectedCompositeTableSubtype: undefined,
  widgetEditRangeWidgetId: undefined,
  widgetEditRangeGroupByLabel: undefined,
  widgetAttributePreferencesById: {},

  allDashboards: [],
  selectedDashboards: [],
  activeWidget: null,
  activeWidgetDialogMode: ActiveWidgetDialogMode.CLOSE,
  activeWidgetTemplate: null,
  activeWidgetTheme: null,
  activeDashboard: null,
  lastRefreshTime: null,
  previewRefreshedAt: null,
  widgetList: [],
  widgetData: {},
  widgetsById: {},
  webErrorsByWidgetId: {},
  widgetIdToDashboardIdMap: {},
  widgetTemplates: [],
  widgetTemplatesCategory: undefined,
  widgetTemplatesByCategory: [],
  widgetWizardDialogMode: WidgetWizardDialogMode.CLOSE,
  widgetUpdateData: null,
  overlayUpdateData: null,
  isLoadingWidgetPreviewData: undefined,
  isLoadingOverlayPreviewData: false,
  widgetDataPreview: null,
  overlayDataPreview: null,
  widgetPreviewTablePreview: undefined,
  widgetPreviewColumnSelection: undefined,
  returnCrumbs: null,

  trendDefinitionsByStandardWidgetSubtype: {},
  standardWidgetColumnsMapping: {},

  visibleWidgetCountBySubtypes: {},

  standardDashboardRequest: undefined,
  standardDashboardData: new Map<string, Trend>(),

  myDashboardViewFilterRuleGroup: undefined,
  myDashboardViewTrendDateRange: undefined,
  userFlowsSubFilterRuleGroup: undefined,
  userFlowsCrumbListsIndex: {},

  deviceDashboardTrendDateRange: undefined,
  operatingSystems: [],
  scrollToBottom: false,
  addedOrDuplicated: false,
  layoutEditConfirm: false,

  actionOrigin: DashboardActionOrigin.LIST,
  dashboardDialogMode: DashboardDialogMode.CLOSE,
  dashboardDialogModel: undefined,
  dashboardsDialogModel: undefined,
  isDashboardLoading: false,
  isDashboardListloading: false,
  isDashboardImportInProgress: false,

  dashboardSearchRequest: new DashboardSearchRequest(),
  dashboardSearchResponse: new DashboardSearchResponse(),
  dashboardSearchType: undefined,
  dashboardSearchError: new WebError(),
  isDashboardSearchInProgress: false,
  globalSearchResults: [],
  widgetListFilter: {},
  dashboardListFilter: {},
  dashboardRecipients: [],
  isDashboardRecipientsLoading: false,

  localDataGridSettingsById: {},

  pendingStandardDashboardRequests: 0,
  pendingPreviewDashboardRequests: 0,

  pendingPreviewWidgetSubtypes: [],

  tableTrendDefinitionsById: {},
  tableTrendsById: {},

  deviceHealth: undefined,
  deviceId: undefined,

  rootUrl: ROUTE_NAMES.WORKSPACE.HOME,
  incident: {
    customDashboard: undefined,
    incidentId: undefined,
  },

  dashboardLastUpdated: undefined,

  dapInfo: {
    selectedDashboardOwnersDAPIds: undefined,
    transferToOwnerDAPId: undefined,
  },

  riskIndicatorDetailsByTimestampByGuid: {},

  activeCategoryId: null,
  isCrossCategoryActive: false,
  defaultColumns: {},
  defaultNonJoinColumns: {},
  isLoadingDefaultColumns: false,
  currentDashboard: undefined,

  transferOwnershipActionOrigin: undefined,

  selectedViewLayout: undefined,
  unMergeWidgetSubTypeList: [],

  dashboardTemplateImportLoadStatus: LOAD_STATE.NONE,

  // incremental loading
  queryIdVsTrackingIdMap: new Map(),
  trackingIdDetailsMap: new Map(),
  loadingStatusByTrackingIdMap: new Map(),
};
