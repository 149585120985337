/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { ChronoUnit, DataUnit, TrendSpan } from '@dpa/ui-common';

import { Column, COLUMN_NAMES } from '@ws1c/intelligence-models';

/**
 * DeemVirtualGPOConfig
 * @export
 * @class DeemVirtualGPOConfig
 */
export class DeemVirtualGPOConfig {
  public static readonly timeSpan15MinInMillis: number = 15 * TrendSpan.timeUnitsByName[ChronoUnit[ChronoUnit.MINUTES]].value;
  public static readonly timeSpan5MinInMillis: number = 5 * TrendSpan.timeUnitsByName[ChronoUnit[ChronoUnit.MINUTES]].value;

  public static readonly gpoColumns: Column[] = [
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_horizon_user_actions_name,
      label: 'DEEM_VIRTUAL_SUMMARY.GPO',
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_horizon_user_actions_duration_millis,
      label: 'DEEM_COMMON_MESSAGES.DURATION',
      dataUnit: DataUnit.MILLISECONDS,
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_horizon_user_actions_cse_names,
      label: 'DEEM_VIRTUAL_SUMMARY.CLIENT_SIDE_EXTENSIONS',
    }),
  ];
  public static readonly cseColumns: Column[] = [
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_horizon_user_actions_name,
      label: 'DEEM_VIRTUAL_SUMMARY.CSE',
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_horizon_user_actions_duration_millis,
      label: 'DEEM_COMMON_MESSAGES.DURATION',
      dataUnit: DataUnit.MILLISECONDS,
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_horizon_user_actions_start_time,
      label: 'DEEM_COMMON_MESSAGES.START_TIME',
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_horizon_user_actions_end_time,
      label: 'DEEM_COMMON_MESSAGES.END_TIME',
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_horizon_user_actions_gpo_names,
      label: 'DEEM_VIRTUAL_SUMMARY.GPO',
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.employee_experience_horizon_user_actions_is_async_processing,
      label: 'DEEM_VIRTUAL_SUMMARY.ASYNC_PROCESSING',
    }),
  ];
}
