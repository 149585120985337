/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject } from '@dpa/ui-common';
import { createAction, props } from '@ngrx/store';

import {
  AggregationAttributesResponse,
  Category,
  CategoryColumns,
  Column,
  CustomAttributeIdentifier,
  CustomAttributeIdentifiersSearchRequest,
  CustomAttributeIdentifiersSearchResponse,
  FilterAttributeConfig,
  PrecomputedAggregation,
  SuggestionFilterBy,
  SuggestionSearch,
  SuggestionSearchItem,
  Tag,
} from '@ws1c/intelligence-models';

/**
 * IntegrationMetaActions
 * @export
 * @class IntegrationMetaActions
 */
export class IntegrationMetaActions {
  public static readonly CATEGORY = '[INTEGRATION_META]';

  private static type = (actionName: string): string => `${IntegrationMetaActions.CATEGORY} ${actionName}`;

  public static loadColumns = createAction(
    IntegrationMetaActions.type('LOAD_COLUMNS'),
    props<{
      categoryId: string;
      isCrossCategory?: boolean;
      setActiveCategory?: boolean;
    }>(),
  );

  public static loadColumnsSuccess = createAction(
    IntegrationMetaActions.type('LOAD_COLUMNS_SUCCESS'),
    props<{
      columns: Column[];
      category: Category;
    }>(),
  );

  public static loadColumnsForMultiCategory = createAction(
    IntegrationMetaActions.type('LOAD_COLUMNS_FOR_MULTI_CATEGORY'),
    props<{
      entitiesByIntegration: GenericObject;
      activeCategories: Category[];
    }>(),
  );

  public static loadColumnsForMultiCategorySuccess = createAction(
    IntegrationMetaActions.type('LOAD_COLUMNS_FOR_MULTI_CATEGORY_SUCCESS'),
    props<{
      categoryColumns: CategoryColumns;
    }>(),
  );

  public static loadColumnsQuietly = createAction(
    IntegrationMetaActions.type('LOAD_COLUMNS_QUIETLY'),
    props<{
      categoryId: string;
      isCrossCategory?: boolean;
    }>(),
  );

  public static searchFilterValuesSuccess = createAction(
    IntegrationMetaActions.type('SEARCH_FILTER_VALUES_SUCCESS'),
    props<{
      suggestionValues: SuggestionSearchItem[];
      suggestionSearch: SuggestionSearch;
    }>(),
  );

  public static searchFilterValuesFailure = createAction(
    IntegrationMetaActions.type('SEARCH_FILTER_VALUES_FAILURE'),
    props<{
      suggestionSearch: SuggestionSearch;
    }>(),
  );

  public static searchFilterValues = createAction(
    IntegrationMetaActions.type('SEARCH_FILTER_VALUES_V2'),
    props<{
      suggestionSearch: SuggestionSearch;
    }>(),
  );

  public static getAvailableFilterTags = createAction(
    IntegrationMetaActions.type('GET_AVAILABLE_FILTER_TAGS'),
    props<{
      attributes: FilterAttributeConfig[];
      suggestionFilterBys: SuggestionFilterBy[];
    }>(),
  );

  public static getAvailableFilterTagsSuccess = createAction(
    IntegrationMetaActions.type('GET_AVAILABLE_FILTER_TAGS_SUCCESS'),
    props<{
      filterTags: Tag[];
    }>(),
  );

  public static cleanAvailableFilterTags = createAction(IntegrationMetaActions.type('CLEAN_AVAILABLE_FILTER_TAGS'));

  public static searchAvailableColumns = createAction(
    IntegrationMetaActions.type('SEARCH_AVAILABLE_COLUMNS'),
    props<{
      query: string;
      selected: string;
    }>(),
  );

  public static resetColumnsByGroupSearchString = createAction(IntegrationMetaActions.type('RESET_COLUMNS_BY_GROUP_SEARCH_STRING'));

  public static toggleColumnFilter = createAction(
    IntegrationMetaActions.type('TOGGLE_COLUMN_FILTER'),
    props<{
      name: string;
    }>(),
  );

  public static resetColumnFilters = createAction(IntegrationMetaActions.type('RESET_COLUMN_FILTERS'));

  public static loadCategoriesSuccess = createAction(
    IntegrationMetaActions.type('LOAD_CATEGORIES_SUCCESS'),
    props<{
      categories: Category[];
    }>(),
  );

  public static loadCategoriesFailure = createAction(IntegrationMetaActions.type('LOAD_CATEGORIES_FAILURE'));

  public static getPrecomputedAggregations = createAction(
    IntegrationMetaActions.type('GET_PRECOMPUTED_AGGREGATIONS'),
    props<{
      categoryId: string;
      setActiveCategory?: boolean;
    }>(),
  );

  public static getPrecomputedAggregationsSuccess = createAction(
    IntegrationMetaActions.type('GET_PRECOMPUTED_AGGREGATIONS_SUCCESS'),
    props<{
      categoryId: string;
      precomputedAggregations: PrecomputedAggregation[];
    }>(),
  );

  public static getPrecomputedAggregationsFailure = createAction(IntegrationMetaActions.type('GET_PRECOMPUTED_AGGREGATIONS_FAILURE'));

  public static getAggregationAttributes = createAction(
    IntegrationMetaActions.type('GET_AGGREGATION_ATTRIBUTES'),
    props<{
      category?: Category;
      id: string;
    }>(),
  );

  public static getAggregationAttributesSuccess = createAction(
    IntegrationMetaActions.type('GET_AGGREGATION_ATTRIBUTES_SUCCESS'),
    props<{
      category?: Category;
      aggregationAttributesResponse: AggregationAttributesResponse;
    }>(),
  );

  public static getAggregationAttributesFailure = createAction(IntegrationMetaActions.type('GET_AGGREGATION_ATTRIBUTES_FAILURE'));

  public static clearAttributes = createAction(IntegrationMetaActions.type('CLEAR_ATTRIBUTES'));

  public static clearActiveCategories = createAction(IntegrationMetaActions.type('CLEAR_ACTIVE_CATEGORIES'));

  public static loadCustomAttributeIdentifiers = createAction(
    IntegrationMetaActions.type('LOAD_CUSTOM_ATTRIBUTE_IDENTIFIERS'),
    props<{
      customAttributeIdentifiersSearchRequest: CustomAttributeIdentifiersSearchRequest;
    }>(),
  );

  public static loadCustomAttributeIdentifiersSuccess = createAction(
    IntegrationMetaActions.type('LOAD_CUSTOM_ATTRIBUTE_IDENTIFIERS_SUCCESS'),
    props<{
      customAttribute: string;
      customAttributeIdentifiersSearchResponse: CustomAttributeIdentifiersSearchResponse;
    }>(),
  );

  public static loadCustomAttributeIdentifiersFailure = createAction(
    IntegrationMetaActions.type('LOAD_CUSTOM_ATTRIBUTE_IDENTIFIERS_FAILURE'),
  );

  public static loadCustomAttributesIdentifierAttributes = createAction(
    IntegrationMetaActions.type('LOAD_CUSTOM_ATTRIBUTES_IDENTIFIER_ATTRIBUTES'),
    props<{
      customAttributeIdentifiersByAttributeName: Map<string, CustomAttributeIdentifier[]>;
    }>(),
  );

  public static loadCustomAttributesIdentifierAttributesSuccess = createAction(
    IntegrationMetaActions.type('LOAD_CUSTOM_ATTRIBUTES_IDENTIFIER_ATTRIBUTES_SUCCESS'),
    props<{
      customAttributeIdentifierAttributesByKey: Map<string, Column[]>;
    }>(),
  );
}
