/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, Output, ViewEncapsulation } from '@angular/core';
import { BarChartType, Color, ColorHelper, getScaleType, getUniqueXDomainValues, id, Orientation, ScaleType, Series } from '@dpa/ui-common';
import { scaleBand, scaleLinear, scalePoint, scaleTime } from 'd3-scale';
import { curveLinear } from 'd3-shape';

import { BaseOverlayChartComponent } from '@ws1c/dashboard-common/chart/custom/base-overlay-chart.component';

/**
 * BarVerticalOverlayChartComponent
 * @export
 * @class BarVerticalOverlayChartComponent
 * @extends {BaseChartComponent}
 */
@Component({
  selector: 'dpa-bar-vertical-overlay-chart',
  templateUrl: 'bar-vertical-overlay-chart.component.html',
  styleUrls: ['bar-vertical-overlay-chart.component.scss'],
  animations: [
    trigger('animationState', [
      transition(':leave', [
        style({
          opacity: 1,
          transform: '*',
        }),
        animate(500, style({ opacity: 0, transform: 'scale(0)' })),
      ]),
    ]),
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BarVerticalOverlayChartComponent extends BaseOverlayChartComponent {
  @Input() public xAxis: boolean;
  @Input() public yAxis: boolean;
  @Input() public showYAxisLineLabel: boolean = true;
  @Input() public tooltipDisabled: boolean = false;
  @Input() public gradient: boolean;
  @Input() public showGridLines: boolean = true;
  @Input() public activeEntries: any[] = [];
  @Input() public trimXAxisTicks: boolean = true;
  @Input() public trimYAxisTicks: boolean = true;
  @Input() public rotateXAxisTicks: boolean = true;
  @Input() public maxXAxisTickLength: number = 16;
  @Input() public maxYAxisTickLength: number = 16;
  @Input() public xAxisTickFormatting: any;
  @Input() public yAxisTickFormatting: any;
  @Input() public xAxisTicks: any[];
  @Input() public yAxisTicks: any[];
  @Input() public barPadding = 8;
  @Input() public roundDomains: boolean = false;
  @Input() public roundEdges: boolean = true;
  @Input() public yScaleMax: number;
  @Input() public yScaleMin: number;
  @Input() public dataLabelFormatting: any;
  @Input() public noBarWhenZero: boolean = true;
  @Input() public wrapTicks = true;
  @Input() public customColorsForLine = [];
  @Input() public isStacked: boolean;
  @Input() public schemeForLine: Color;

  @Output() public activate: EventEmitter<any> = new EventEmitter();
  @Output() public deactivate: EventEmitter<any> = new EventEmitter();

  public groupDomain: string[];
  public innerDomain: string[];
  public valueDomain: [number, number];
  public xScale: any;
  public yScale: any;
  public xDomain: any;
  public yDomain: any;
  public combinedColors: ColorHelper;
  public tickFormatting: (label: string) => string;
  public margin: number[] = [10, 20, 10, 20];
  public readonly BAR_CHART_TYPE = BarChartType;
  public combinedSeries: any;
  public readonly ORIENTATION = Orientation;

  // Line
  public clipPath: string;
  public clipPathId: string;
  public scaleType: ScaleType;
  public curve: any = curveLinear;
  public rangeFillOpacity: number;
  public xScaleLine: any;
  public yScaleLine: any;
  public xScaleLineMin;
  public xScaleLineMax;
  public yScaleLineMin;
  public yScaleLineMax;
  public xDomainLine;
  public yDomainLine;
  public xLineSet;
  public hasRange;
  public autoScale;
  public isSSR = false;
  public yOrientRight = Orientation.Right;
  public hoveredVertical: any;
  public seriesDomain: any[] = [];

  private currentWidth: number = 0;

  /**
   * update
   * @memberof BarVerticalOverlayChartComponent
   */
  public update() {
    super.update();

    // Not support Quantile yet for LINE chart
    if (this.schemeForLine.group === ScaleType.Quantile) {
      this.schemeForLine.group = ScaleType.Ordinal;
    }

    if (this.isStacked) {
      this.formatDates();
      this.groupDomain = this.getGroupDomain();
      this.innerDomain = this.getInnerDomain();
      this.valueDomain = this.getValueDomain();
    }
    this.xScale = this.isStacked ? this.getXScaleGroup() : this.getXScale();
    this.yScale = this.getYScale();

    this.setColors(this.isStacked ? this.innerDomain : this.xDomain, this.isStacked ? this.valueDomain : this.yDomain);

    // Line
    this.seriesDomain = this.getSeriesDomain();
    this.xDomainLine = this.getXDomainLine();
    this.xScaleLine = this.getXScaleLine(this.xDomainLine, this.dims.width);
    this.yScaleLine = this.getYScaleLine();
    this.setColorsForLine();
    this.clipPathId = 'clip' + id().toString();
    this.clipPath = `url(#${this.clipPathId})`;
    // Transform
    // Check to avoid flickering issue
    if (this.currentWidth === this.width) {
      // return;
    }
    this.currentWidth = this.width;
    this.transform = `translate(${Math.max(60, this.dims.xOffset / 2)} , ${this.margin[0]})`;
  }

  /**
   * onClick
   * @param {(DataItem | string)} data
   * @param {Series} [group]
   * @memberof BarVerticalOverlayChartComponent
   */
  public onClick(data, group?: Series) {
    if (group) {
      data.series = group.name;
    }
    this.select.emit(data);
  }

  /**
   * updateYAxisWidth
   * @param { width: number } { width }
   * @memberof BarVerticalOverlayChartComponent
   */
  public updateYAxisWidth({ width }: { width: number }): void {
    this.yAxisWidth = Math.max(width, this.yAxisWidth);
    this.update();
  }

  /**
   * updateXAxisHeight
   * @param { height } { height }
   * @memberof BarVerticalOverlayChartComponent
   */
  public updateXAxisHeight({ height }) {
    this.xAxisHeight = height;
    this.update();
  }

  /**
   * onDataLabelMaxHeightChanged
   * @param {*} event
   * @param {number} [groupIndex]
   * @memberof BarVerticalOverlayChartComponent
   */
  public onDataLabelMaxHeightChanged(event, groupIndex?: number) {
    if (event.size.negative) {
      this.dataLabelMaxHeight.negative = Math.max(this.dataLabelMaxHeight.negative, event.size.height);
    } else {
      this.dataLabelMaxHeight.positive = Math.max(this.dataLabelMaxHeight.positive, event.size.height);
    }
    if ((groupIndex ?? event?.index) === this.results.length - 1) {
      setTimeout(() => this.update());
    }
  }

  /**
   * groupTransform
   * @param {Series} group
   * @returns {string}
   * @memberof BarVerticalOverlayChartComponent
   */
  public groupTransform(group: Series): string {
    return `translate(${this.xScale(group.name) || 0}, 0)`;
  }

  /**
   * onActivate
   * @param {*} event
   * @param {*} group
   * @param {boolean} [fromLegend=false]
   * @memberof BarVerticalOverlayChartComponent
   */
  public onActivate(event, group?, fromLegend: boolean = false): void {
    let item = Object.assign({}, event);
    if (!this.isStacked) {
      item = this.overlayResults.find((d) => {
        if (fromLegend) {
          return d.label === item.name;
        } else {
          return d.name === item.name;
        }
      });
      const idx = this.activeEntries.findIndex((d) => {
        return d?.name === item?.name && d.value === item.value && d.series === item.series;
      });
      if (idx > -1) {
        return;
      }
      this.activeEntries = item ? [item, ...this.activeEntries] : this.activeEntries;
      this.activate.emit({ value: item, entries: this.activeEntries });
      return;
    }
    if (group) {
      item.series = group.name;
    }
    const items = this.results
      .map((g) => g.series)
      .flat()
      .filter((i) => {
        if (fromLegend) {
          return i.label === item.name;
        } else {
          return i.name === item.name && i.series === item.series;
        }
      });
    this.activeEntries = [...items];
    this.activate.emit({ value: item, entries: this.activeEntries });
  }

  /**
   * onDeactivate
   * @param {any} event
   * @param {Series} [group]
   * @param {boolean} [fromLegend=false]
   * @memberof BarVerticalOverlayChartComponent
   */
  public onDeactivate(event: any, group?: Series, fromLegend: boolean = false) {
    let item = Object.assign({}, event);
    if (!this.isStacked) {
      item = this.results.find((d) => {
        if (fromLegend) {
          return d.label === item.name;
        } else {
          return d.name === item.name;
        }
      });
      const idx = this.activeEntries.findIndex((d) => {
        return d?.name === item?.name && d.value === item.value && d.series === item.series;
      });
      this.activeEntries.splice(idx, 1);
      this.activeEntries = [...this.activeEntries];
      this.deactivate.emit({ value: item, entries: this.activeEntries });
      return;
    }
    if (group) {
      item.series = group.name;
    }
    this.activeEntries = this.activeEntries.filter((i) => {
      if (fromLegend) {
        return i.label !== item.name;
      } else {
        return !(i.name === item.name && i.series === item.series);
      }
    });
    this.deactivate.emit({ value: item, entries: this.activeEntries });
  }

  /**
   * hideCircles
   * @memberof BarVerticalOverlayChartComponent
   */
  @HostListener('mouseleave')
  public hideCircles(): void {
    this.hoveredVertical = null;
    this.deactivateAll();
  }

  /**
   * deactivateAll
   * @memberof BarVerticalOverlayChartComponent
   */
  public deactivateAll(): void {
    this.activeEntries = [...this.activeEntries];
    for (const entry of this.activeEntries) {
      this.deactivate.emit({ value: entry, entries: [] });
    }
    this.activeEntries = [];
  }

  /**
   * getXScale
   * @returns {*}
   * @memberof BarVerticalOverlayChartComponent
   */
  private getXScale(): any {
    this.xDomain = this.getXDomain();
    const spacing = this.xDomain.length / (this.dims.width / this.barPadding + 1);
    return scaleBand().range([0, this.dims.width]).paddingInner(spacing).domain(this.xDomain);
  }

  /**
   * getXScaleLine
   * @param {*} domain
   * @param {number} width
   * @returns {*}  {*}
   * @memberof BarVerticalOverlayChartComponent
   */
  private getXScaleLine(domain: any, width: number): any {
    let scale;
    switch (this.scaleType) {
      case ScaleType.Time:
        scale = scaleTime().range([0, width]).domain(domain);
        break;
      case ScaleType.Ordinal:
        scale = scalePoint().range([0, width]).padding(0.1).domain(domain);
        break;
      case ScaleType.Linear:
        scale = scaleLinear().range([0, width]).domain(domain);
        if (this.roundDomains) {
          scale = scale.nice();
        }
        break;
    }
    return scale;
  }

  /**
   * getYScaleLine
   * @returns {*}
   * @memberof BarVerticalOverlayChartComponent
   */
  private getYScaleLine(): any {
    this.yDomainLine = this.getYDomainLine();
    const scale = scaleLinear().range([this.dims.height, 0]).domain(this.yDomainLine);
    return this.roundDomains ? scale.nice() : scale;
  }

  /**
   * getYScale
   * @returns {*}
   * @memberof BarVerticalOverlayChartComponent
   */
  private getYScale(): any {
    const domain = this.isStacked ? this.valueDomain : this.getYDomain();
    const scale = scaleLinear().range([this.dims.height, 0]).domain(domain);
    return this.roundDomains ? scale.nice() : scale;
  }

  /**
   * getXDomain
   * @returns {any[]}
   * @memberof BarVerticalOverlayChartComponent
   */
  private getXDomain(): any[] {
    return this.results.map((d) => d.label);
  }

  /**
   * getXDomainLine
   * @returns {*}  {any[]}
   * @memberof BarVerticalOverlayChartComponent
   */
  private getXDomainLine(): any[] {
    let values = getUniqueXDomainValues(this.overlayResults);
    this.scaleType = getScaleType(values);
    let domain = [];

    if (this.scaleType === ScaleType.Linear) {
      values = values.map((v) => Number(v));
    }

    let min;
    let max;
    if (this.scaleType === ScaleType.Time || this.scaleType === ScaleType.Linear) {
      min = this.xScaleLineMin ? this.xScaleLineMin : Math.min(...values);
      max = this.xScaleLineMax ? this.xScaleLineMax : Math.max(...values);
    }
    switch (this.scaleType) {
      case ScaleType.Time:
        domain = [new Date(min), new Date(max)];
        this.xLineSet = [...values].sort((a, b) => {
          const aDate = a.getTime();
          const bDate = b.getTime();
          if (aDate > bDate) return 1;
          if (bDate > aDate) return -1;
          return 0;
        });
        break;
      case ScaleType.Linear:
        domain = [min, max];
        // Use compare function to sort numbers numerically
        this.xLineSet = [...values].sort((a, b) => a - b);
        break;
      default:
        domain = values;
        this.xLineSet = values;
        break;
    }

    return domain;
  }

  /**
   * getYDomainLine
   * @returns {[number, number]}
   * @memberof BarVerticalOverlayChartComponent
   */
  private getYDomainLine(): [number, number] {
    const domain = [];
    for (const result of this.overlayResults) {
      for (const d of result.series) {
        if (domain.indexOf(d.value) < 0) {
          domain.push(d.value);
        }
        if (d.min !== undefined) {
          this.hasRange = true;
          if (domain.indexOf(d.min) < 0) {
            domain.push(d.min);
          }
        }
        if (d.max !== undefined) {
          this.hasRange = true;
          if (domain.indexOf(d.max) < 0) {
            domain.push(d.max);
          }
        }
      }
    }

    const values = [...domain];
    if (!this.autoScale) {
      values.push(0);
    }

    const min = this.yScaleLineMin ? this.yScaleLineMin : Math.min(...values);

    const max = this.yScaleLineMax ? this.yScaleLineMax : Math.max(...values);

    return [min, max];
  }

  /**
   * getYDomain
   * @returns {[number, number]}
   * @memberof BarVerticalOverlayChartComponent
   */
  private getYDomain(): [number, number] {
    const values = this.results.map((d) => d.value);

    let min = this.yScaleMin ? Math.min(this.yScaleMin, ...values) : Math.min(0, ...values);
    if (this.yAxisTicks && !this.yAxisTicks.some(isNaN)) {
      min = Math.min(min, ...this.yAxisTicks);
    }

    let max = this.yScaleMax ? Math.max(this.yScaleMax, ...values) : Math.max(0, ...values);
    if (this.yAxisTicks && !this.yAxisTicks.some(isNaN)) {
      max = Math.max(max, ...this.yAxisTicks);
    }
    return [min, max];
  }

  /**
   * getXScaleGroup
   * @returns {*}
   * @memberof BarVerticalOverlayChartComponent
   */
  private getXScaleGroup(): any {
    const spacing = this.groupDomain.length / (this.dims.width / this.barPadding + 1);
    return scaleBand().rangeRound([0, this.dims.width]).paddingInner(spacing).domain(this.groupDomain);
  }

  /**
   * setColorsForLine
   * @memberof BarVerticalOverlayChartComponent
   */
  private setColorsForLine() {
    let domain;
    if (this.schemeForLine.group === ScaleType.Ordinal) {
      domain = this.seriesDomain;
    } else {
      domain = this.yDomain;
    }
    this.colorsForOverlay = new ColorHelper(this.schemeForLine, domain, this.customColorsForLine);
  }

  /**
   * getSeriesDomain
   * @returns {string[]}
   * @memberof BarVerticalOverlayChartComponent
   */
  private getSeriesDomain(): string[] {
    this.combinedSeries = this.overlayResults.slice(0);
    this.combinedSeries.push({
      name: this.yAxisLabel,
      series: this.results,
    });
    return this.overlayResults.map((d) => d.name);
  }

  /**
   * getGroupDomain
   * @returns {string[]}
   * @memberof BarVerticalOverlayChartComponent
   */
  private getGroupDomain(): string[] {
    const domain = [];
    for (const group of this.results) {
      if (!domain.includes(group.label)) {
        domain.push(group.label);
      }
    }
    return domain;
  }

  /**
   * getInnerDomain
   * @returns {*}  {string[]}
   * @memberof BarVerticalOverlayChartComponent
   */
  private getInnerDomain(): string[] {
    const domain = [];
    for (const group of this.results) {
      for (const d of group.series) {
        if (!domain.includes(d.label)) {
          domain.push(d.label);
        }
      }
    }
    return domain;
  }

  /**
   * getValueDomain
   * @returns {[number, number]}
   * @memberof BarVerticalOverlayChartComponent
   */
  private getValueDomain(): [number, number] {
    const domain = [];
    let smallest = 0;
    let biggest = 0;
    for (const group of this.results) {
      let smallestSum = 0;
      let biggestSum = 0;
      for (const d of group.series) {
        if (d.value < 0) {
          smallestSum += d.value;
        } else {
          biggestSum += d.value;
        }
        smallest = d.value < smallest ? d.value : smallest;
        biggest = d.value > biggest ? d.value : biggest;
      }
      domain.push(smallestSum);
      domain.push(biggestSum);
    }
    domain.push(smallest);
    domain.push(biggest);
    const min = Math.min(0, ...domain);
    const max = this.yScaleMax ? Math.max(this.yScaleMax, ...domain) : Math.max(...domain);
    return [min, max];
  }
}
