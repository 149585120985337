/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * SidepanelTemplateId
 *
 * @export
 * @enum {string}
 */
export enum SidepanelTemplateId {
  'POTENTIAL_IMPACT' = 'potentialImpact',
  'CONNECTOR_ACTION_CONFIG' = 'connectorActionConfig',
}
