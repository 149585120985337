/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { DateTimeFormat, deserialize, GenericObject } from '@dpa/ui-common';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import * as cryptoJS from 'crypto-js';
import { groupBy, maxBy } from 'lodash-es';
import moment from 'moment';

import {
  getFirstActiveIntegrationInstance,
  getFirstIntegrationInstance,
} from '@dpa-shared-merlot/store/integration/integration-selector-helpers';
import { AppConfig, FeatureControl, hash } from '@ws1c/intelligence-common';
import { CoreAppState } from '@ws1c/intelligence-core/store/core-app-state';
import { RbacSelectors } from '@ws1c/intelligence-core/store/rbac';
import { UserAdminAccountSelector } from '@ws1c/intelligence-core/store/user-admin-account';
import {
  AccountFeature,
  AccountPermission,
  AccountRole,
  FeatureControls,
  FeaturePermission,
  FEATURES,
  getFeaturePermissionFromRoles,
  Integration,
  IntegrationDetails,
  IntegrationDetailsListByKey,
  IntegrationRegistrationSource,
  IntroType,
  LOAD_STATE,
  Org,
  PendoMetadata,
  RequestStatusType,
  SolutionPlatform,
  UI,
  UIPreference,
  UNKNOWN_ID,
  User,
  UserAccount,
  UserDetails,
  UserPreference,
} from '@ws1c/intelligence-models';
import { UserPreferenceCommonSelectors } from './user-preference-common.selectors';
import { UserPreferenceFeatureControlsSelectors } from './user-preference-feature-controls.selectors';
import { filterEnabledIntegrationTypes } from './user-preference-selector-helpers';
import { UserPreferenceState } from './user-preference.state';

/**
 * UserPreferencesSelectors
 *
 * @export
 * @class UserPreferenceSelectors
 */
export class UserPreferenceSelectors {
  /**
   * getUserAccount
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, UserAccount>}
   * @memberOf UserPreferenceSelectors
   */
  public static getUserAccountInfo: MemoizedSelector<CoreAppState, UserAccount> = createSelector(
    UserPreferenceCommonSelectors.getUserPreference,
    (userPreference: UserPreference) => userPreference.userAccount,
  );

  /**
   * getUserAccountId
   * @static
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberof UserPreferenceSelectors
   */
  public static getUserAccountId: MemoizedSelector<CoreAppState, string> = createSelector(
    UserPreferenceSelectors.getUserAccountInfo,
    (userAccount: UserAccount) => userAccount.id,
  );

  /**
   * getUserAccountRoles
   * @static
   * @type {MemoizedSelector<CoreAppState, AccountRole[]>}
   * @memberof UserPreferenceSelectors
   */
  public static getUserAccountRoles: MemoizedSelector<CoreAppState, AccountRole[]> = createSelector(
    UserPreferenceSelectors.getUserAccountInfo,
    (userAccount: UserAccount) => {
      return userAccount?.roles || [];
    },
  );

  /**
   * getUserAccountCspMergeStatus
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof UserPreferenceSelectors
   */
  public static getUserAccountCspMergeStatus: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceSelectors.getUserAccountInfo,
    (userAccount: UserAccount) => {
      return userAccount?.cspAccountMergeStatus;
    },
  );

  /**
   * getFeaturePermissions
   * @static
   * @type {MemoizedSelector<CoreAppState, FeaturePermission[]>}
   * @memberof UserPreferenceSelectors
   */
  public static getFeaturePermissions: MemoizedSelector<CoreAppState, FeaturePermission[]> = createSelector(
    UserPreferenceSelectors.getUserAccountInfo,
    (userAccount: UserAccount) => {
      return FEATURES.map((feature: AccountFeature) => {
        return {
          feature,
          perm: getFeaturePermissionFromRoles(feature, userAccount.roles),
        };
      }).filter((featurePerm: FeaturePermission) => featurePerm.perm !== AccountPermission.NONE);
    },
  );

  /**
   * isAccessRequested
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof UserPreferenceSelectors
   */
  public static isAccessRequested: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceSelectors.getUserAccountInfo,
    (userAccount: UserAccount) => userAccount.accessRequested,
  );

  /**
   * isNonAdminMode
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof UserPreferenceSelectors
   */
  public static isNonAdminMode: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceCommonSelectors.selectUserPreferenceState,
    (state: UserPreferenceState) => state.isNonAdminMode,
  );

  /**
   * isRemediationModes
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof UserPreferenceSelectors
   */
  public static isRemediationMode: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceCommonSelectors.selectUserPreferenceState,
    (state: UserPreferenceState) => state.isRemediationMode,
  );

  /**
   * isEulaShowing
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof UserPreferenceSelectors
   */
  public static isEulaShowing: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceCommonSelectors.selectUserPreferenceState,
    (state: UserPreferenceState) => state.isEulaShowing,
  );

  /**
   * isEulaAccepted
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof UserPreferenceSelectors
   */
  public static isEulaAccepted: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceCommonSelectors.selectUserPreferenceState,
    (state: UserPreferenceState) => state.eulaAccepted,
  );

  /**
   * isAcceptingEula
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof UserPreferenceSelectors
   */
  public static isAcceptingEula: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceCommonSelectors.selectUserPreferenceState,
    (state: UserPreferenceState) => state.isAcceptingEula,
  );

  /**
   * getUserPreferenceLoadState
   * @static
   * @type {MemoizedSelector<CoreAppState, LOAD_STATE>}
   * @memberof UserPreferenceSelectors
   */
  public static getUserPreferenceLoadState: MemoizedSelector<CoreAppState, LOAD_STATE> = createSelector(
    UserPreferenceCommonSelectors.selectUserPreferenceState,
    (state: UserPreferenceState) => state.loadState,
  );

  /**
   * isUpdatingUISettings
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf UserPreferenceSelectors
   */
  public static isUpdatingUISettings: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceCommonSelectors.selectUserPreferenceState,
    (state: UserPreferenceState) => state.isUpdatingUISettings,
  );

  /**
   * getActiveIntegrationInstances
   * @static
   * @type {MemoizedSelector<CoreAppState, GenericObject>}
   * @memberof UserPreferenceSelectors
   */
  public static getActiveIntegrationInstances: MemoizedSelector<CoreAppState, GenericObject> = createSelector(
    UserPreferenceCommonSelectors.selectUserPreferenceState,
    (state: UserPreferenceState) => state.integrationsDetails ?? {},
  );

  /**
   * isBrownfieldNotificationModalOpen
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof UserPreferenceSelectors
   */
  public static isBrownfieldNotificationModalOpen: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceCommonSelectors.selectUserPreferenceState,
    (state: UserPreferenceState) => state.isBrownfieldNotificationModalOpen,
  );

  /**
   * isRequestCloudAccountModalOpen
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof UserPreferenceSelectors
   */
  public static isRequestCloudAccountModalOpen: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceCommonSelectors.selectUserPreferenceState,
    (state: UserPreferenceState) => state.isRequestCloudAccountModalOpen,
  );

  /**
   * showRequestCloudAccountModal
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof UserPreferenceSelectors
   */
  public static showRequestCloudAccountModal: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceSelectors.isRequestCloudAccountModalOpen,
    UserAdminAccountSelector.getCspAccountRequestStatus,
    (isRequestCloudAccountModalOpen: boolean, cspAccountRequestStatus: RequestStatusType) => {
      return isRequestCloudAccountModalOpen && !!cspAccountRequestStatus;
    },
  );

  /**
   * getActiveIntegrationTypes
   * @static
   * @type {MemoizedSelector<CoreAppState, string[]>}
   * @memberof UserPreferenceSelectors
   */
  public static getActiveIntegrationTypes: MemoizedSelector<CoreAppState, string[]> = createSelector(
    UserPreferenceSelectors.getActiveIntegrationInstances,
    (activeIntegrationInstances: GenericObject) => Object.keys(activeIntegrationInstances),
  );

  /**
   * getActiveEnabledIntegrationTypes
   * @static
   * @type {MemoizedSelector<CoreAppState, string[]>}
   * @memberof UserPreferenceSelectors
   */
  public static getActiveEnabledIntegrationTypes: MemoizedSelector<CoreAppState, string[]> = createSelector(
    UserPreferenceCommonSelectors.getFeatureControls,
    UserPreferenceSelectors.getActiveIntegrationTypes,
    (featureControls: FeatureControls, integrations: string[]) => {
      return filterEnabledIntegrationTypes(featureControls, integrations);
    },
  );

  /**
   * getAvailableIntegrations
   * @static
   * @type {MemoizedSelector<CoreAppState, IntegrationDetails[]>}
   * @memberof UserPreferenceSelectors
   */
  public static getAvailableIntegrations: MemoizedSelector<CoreAppState, IntegrationDetails[]> = createSelector(
    UserPreferenceSelectors.getActiveIntegrationInstances,
    (integrationDetails: Record<string, GenericObject[]>) => {
      return Object.keys(integrationDetails)
        .filter((integrationType: string) => !!integrationDetails[integrationType][0])
        .reduce(
          (integrationInstances: GenericObject[], integrationType: string) => [
            ...integrationInstances,
            ...integrationDetails[integrationType],
          ],
          [],
        )
        .map((integrationInstance: GenericObject) => deserialize(IntegrationDetails, integrationInstance));
    },
  );

  /**
   * isAnyDashboardFeaturesEnabled
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf UserPreferenceSelectors
   */
  public static isAnyDashboardFeaturesEnabled: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceCommonSelectors.anyFeaturesEnabled([
      FeatureControl.DASHBOARDS_ENABLED, // For WS1 SKU Only
      FeatureControl.DASHBOARD_APP_ADOPTION_ENABLED,
      FeatureControl.DASHBOARD_DEVICES_ENABLED,
      FeatureControl.DASHBOARD_ENROLLMENT_ENABLED,
      FeatureControl.DASHBOARD_USER_RISK_ENABLED,
      FeatureControl.DASHBOARD_SECURITY_ENABLED,
      FeatureControl.DASHBOARD_HORIZON_ENABLED,
      FeatureControl.DASHBOARD_HORIZON_TITAN_ENABLED,
    ]),
    (isAnyDashboardFeaturesEnabled: boolean) => isAnyDashboardFeaturesEnabled,
  );

  /**
   * isLoadingUserPreference
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf UserPreferenceSelectors
   */
  public static isLoadingUserPreference: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceCommonSelectors.loadState,
    (loadState: LOAD_STATE) => loadState === LOAD_STATE.IN_FLIGHT,
  );

  /**
   * isCspLinkedOrg
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf UserPreferenceSelectors
   */
  public static isCspLinkedOrg: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceCommonSelectors.getOrgInfo,
    (org: Org) => org?.cspLinkedOrg,
  );

  /**
   * isUemIdentity
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof UserPreferenceSelectors
   */
  public static isUemIdentity: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceSelectors.getUserAccountInfo,
    (user: User) => user?.isUemIdentity,
  );

  /**
   * isCspLoggedInUser
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf UserPreferenceSelectors
   */
  public static isCspLoggedInUser: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceSelectors.isCspLinkedOrg,
    UserPreferenceSelectors.isUemIdentity,
    (isCspLinkedOrg: boolean, isUemIdentity: boolean) => isCspLinkedOrg && !isUemIdentity,
  );

  /**
   * isPendoAdminPreferencesEnabled
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf UserPreferenceSelectors
   */
  public static isPendoAdminPreferencesEnabled: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceSelectors.isCspLoggedInUser,
    UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.PENDO_ADMIN_PREFERENCES_ENABLED),
    (isCspLoggedInUser: boolean, isPendoAdminPreferencesEnabled: boolean) => isCspLoggedInUser && isPendoAdminPreferencesEnabled,
  );

  /**
   * isWSOneRbacEnabled
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf UserPreferenceSelectors
   */
  public static isWSOneRbacEnabled: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.WORKSPACE_ONE_CLOUD_ENABLED),
    UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.WORKSPACE_ONE_RBAC_ENABLED),
    (isWorkspaceOneCloudEnabled: boolean, isWorkspaceOneRbacEnabled: boolean) => isWorkspaceOneCloudEnabled && isWorkspaceOneRbacEnabled,
  );

  /**
   * getOrgBaseUrl
   * @static
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberOf UserPreferenceSelectors
   */
  public static getOrgBaseUrl: MemoizedSelector<CoreAppState, string> = createSelector(
    UserPreferenceSelectors.isCspLoggedInUser,
    UserPreferenceCommonSelectors.getOrgInfo,
    (isCspLoggedInUser: boolean, org: Org) => (isCspLoggedInUser ? org?.cspBaseUrl : org?.externalOrgUrl),
  );

  /**
   * getCspBaseUrl
   * @static
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberOf UserPreferenceSelectors
   */
  public static getCspBaseUrl: MemoizedSelector<CoreAppState, string> = createSelector(
    UserPreferenceCommonSelectors.getOrgInfo,
    (org: Org) => org.cspBaseUrl,
  );

  /**
   * getAddGroupsCspUrl
   * @static
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberOf UserPreferenceSelectors
   */
  public static getAddGroupsCspUrl: MemoizedSelector<CoreAppState, string> = createSelector(
    UserPreferenceCommonSelectors.getOrgInfo,
    (org: Org) => org.cspUserGroupsAddUrl,
  );

  /**
   * isPendoMetadataLoading
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf UserPreferenceSelectors
   */
  public static isPendoMetadataLoading: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceCommonSelectors.selectUserPreferenceState,
    (state: UserPreferenceState) => state.isPendoMetadataLoading,
  );

  /**
   * isRefreshingUserPreference
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf UserPreferenceSelectors
   */
  public static isRefreshingUserPreference: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceCommonSelectors.selectUserPreferenceState,
    (state: UserPreferenceState) => state.isRefreshingUserPreference,
  );

  /**
   * pendoMetadata
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, PendoMetadata>}
   * @memberOf UserPreferenceSelectors
   */
  public static pendoMetadata: MemoizedSelector<CoreAppState, PendoMetadata> = createSelector(
    UserPreferenceCommonSelectors.selectUserPreferenceState,
    (state: UserPreferenceState) => state.pendo,
  );

  /**
   * pendoAccountId
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberOf UserPreferenceSelectors
   */
  public static pendoAccountId: MemoizedSelector<CoreAppState, string> = createSelector(
    UserPreferenceSelectors.isPendoAdminPreferencesEnabled,
    UserPreferenceCommonSelectors.getOrgInfo,
    (isPendoAdminPreferencesEnabled: boolean, org: Org) => {
      const orgId: string = org?.orgId || UNKNOWN_ID;
      return (isPendoAdminPreferencesEnabled ? cryptoJS.SHA256(orgId) : hash(orgId)).toString();
    },
  );

  /**
   * pendoVisitorId
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberOf UserPreferenceSelectors
   */
  public static pendoVisitorId: MemoizedSelector<CoreAppState, string> = createSelector(
    UserPreferenceSelectors.isPendoAdminPreferencesEnabled,
    UserPreferenceSelectors.getUserAccountInfo,
    (isPendoAdminPreferencesEnabled: boolean, userAccount: UserAccount) => {
      return (
        isPendoAdminPreferencesEnabled
          ? cryptoJS.SHA256(userAccount?.directoryUser?.directoryUserId || UNKNOWN_ID)
          : hash(userAccount?.id || UNKNOWN_ID)
      ).toString();
    },
  );

  /**
   * getEnabledCveSolutionPlatforms
   * @static
   * @type {MemoizedSelector<CoreAppState, SolutionPlatform[]>}
   * @memberof UserPreferenceSelectors
   */
  public static getEnabledCveSolutionPlatforms: MemoizedSelector<CoreAppState, SolutionPlatform[]> = createSelector(
    UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.SOLUTIONS_CVE_IOS_ENABLED),
    UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.SOLUTIONS_CVE_MACOS_ENABLED),
    (isIosEnabled: boolean, isMacosEnabled: boolean) => [
      SolutionPlatform.WINDOWS,
      ...(isIosEnabled ? [SolutionPlatform.IOS] : []),
      ...(isMacosEnabled ? [SolutionPlatform.MACOS] : []),
    ],
  );

  /**
   * getInitialUrlQueryParams
   * @static
   * @type {MemoizedSelector<CoreAppState, GenericObject>}
   * @memberof UserPreferenceSelectors
   */
  public static getInitialUrlQueryParams: MemoizedSelector<CoreAppState, GenericObject> = createSelector(
    UserPreferenceCommonSelectors.selectUserPreferenceState,
    (state: UserPreferenceState) => {
      return [LOAD_STATE.SUCCESS, LOAD_STATE.FAILURE].includes(state.loadState) ? state.initialUrlQueryParams : null;
    },
  );

  /**
   * isFromRegistrationFlow
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof UserPreferenceSelectors
   */
  public static isFromRegistrationFlow: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceSelectors.getInitialUrlQueryParams,
    (initialUrlQueryParams: GenericObject) => {
      return (
        initialUrlQueryParams?.hasOwnProperty('idm_registration_success') ||
        initialUrlQueryParams?.hasOwnProperty('registration_success') ||
        initialUrlQueryParams?.hasOwnProperty('setup_success') ||
        initialUrlQueryParams?.hasOwnProperty('callback_success')
      );
    },
  );

  /**
   * getTokenEndPoint
   * @static
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberOf UserPreferenceSelectors
   */
  public static getTokenEndPoint: MemoizedSelector<CoreAppState, string> = createSelector(
    UserPreferenceCommonSelectors.getUiInfo,
    (ui: UI) => ui?.tokenEndpoint,
  );

  /**
   * isTrialQuestionnaireEnabled
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf UserPreferenceSelectors
   */
  public static isTrialQuestionnaireEnabled: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceCommonSelectors.getOrgInfo,
    (org: Org) => org?.isTrialQuestionnaireEnabled,
  );

  /**
   * isBrownfieldWorkspaceOneCloudCustomer
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf UserPreferenceSelectors
   */
  public static isBrownfieldWorkspaceOneCloudCustomer: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceCommonSelectors.getOrgInfo,
    (org: Org) => org?.isBrownfieldWorkspaceOneCloudCustomer,
  );

  /**
   * hasPremiumLicense
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf UserPreferenceSelectors
   */
  public static hasPremiumLicense: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceCommonSelectors.getOrgInfo,
    (org: Org) => org?.hasPremiumLicense,
  );

  /**
   * brownfieldCutoffDate
   * @static
   * @type {MemoizedSelector<CoreAppState, number>}
   * @memberOf UserPreferenceSelectors
   */
  public static brownfieldCutoffDate: MemoizedSelector<CoreAppState, number> = createSelector(
    UserPreferenceCommonSelectors.getOrgInfo,
    (org: Org) => org?.brownfieldCutoffDate,
  );

  /**
   * isDataAccessPolicyOptedIn
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof UserPreferenceSelectors
   */
  public static isDataAccessPolicyOptedIn: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceCommonSelectors.getOrgInfo,
    (org: Org) => org?.optInFeatures?.dataSegmentationEnabled,
  );

  /**
   * isDeemEnabled
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof UserPreferenceSelectors
   */
  public static isDeemEnabled: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceCommonSelectors.getOrgInfo,
    (org: Org) => org?.optInFeatures?.deemEnabled,
  );

  /**
   * isMobileTelemetryEnabled
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof UserPreferenceSelectors
   */
  public static isMobileTelemetryEnabled: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceCommonSelectors.getOrgInfo,
    (org: Org) => org?.optInFeatures?.mobileTelemetryEnabled,
  );

  /**
   * isIntegrationAutoOptinForCSPEnabled
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf UserPreferenceSelectors
   */
  public static isIntegrationAutoOptinForCSPEnabled: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceFeatureControlsSelectors.isIntegrationAutoOptinEnabled,
    UserPreferenceSelectors.isCspLoggedInUser,
    (isIntegrationAutoOptinEnabled: boolean, isCspLoggedInUser: boolean) => isIntegrationAutoOptinEnabled && isCspLoggedInUser,
  );

  /** INTEGRATION SELECTORS */
  /**
   * availableIntegrations
   * @static
   * @type {MemoizedSelector<CoreAppState, IntegrationDetails[]>}
   * @memberOf UserPreferenceSelectors
   */
  public static availableIntegrations: MemoizedSelector<CoreAppState, IntegrationDetails[]> = createSelector(
    UserPreferenceSelectors.getActiveIntegrationInstances,
    (integrationDetails: GenericObject) => {
      return Object.keys(integrationDetails ?? {})
        .filter((integrationType: string) => !!integrationDetails[integrationType][0])
        .reduce((integrationInstances: GenericObject[], integrationType: string) => {
          return [...integrationInstances, ...integrationDetails[integrationType]];
        }, [])
        .map((integrationInstance: GenericObject) => {
          return new IntegrationDetails({
            ...deserialize(IntegrationDetails, integrationInstance),
            createdByDetails: new UserDetails({
              ...deserialize(UserDetails, integrationInstance.created_by_details),
            }),
            modifiedByDetails: new UserDetails({
              ...deserialize(UserDetails, integrationInstance.modified_by_details),
            }),
          });
        });
    },
  );

  /**
   * integrationDetailsById
   * @static
   * @type {MemoizedSelector<CoreAppState, Record<string, IntegrationDetails>>}
   * @memberOf UserPreferenceSelectors
   */
  public static integrationDetailsById: MemoizedSelector<CoreAppState, Record<string, IntegrationDetails>> = createSelector(
    UserPreferenceSelectors.availableIntegrations,
    (integrationDetailsList: IntegrationDetails[]) => {
      const integrationDetailsById: Record<string, IntegrationDetails> = {};
      (integrationDetailsList ?? []).forEach((integrationDetails: IntegrationDetails) => {
        integrationDetailsById[integrationDetails.id] = integrationDetails;
      });
      return integrationDetailsById;
    },
  );

  /**
   * availableGemProvisionedIntegrationsSelector
   * @static
   * @type {MemoizedSelector<CoreAppState, IntegrationDetails[]>}
   * @memberOf UserPreferenceSelectors
   */
  public static availableGemProvisionedIntegrations: MemoizedSelector<CoreAppState, IntegrationDetails[]> = createSelector(
    UserPreferenceSelectors.availableIntegrations,
    (integrationsDetails: IntegrationDetails[]) => {
      return (integrationsDetails ?? []).filter((integrationDetails: IntegrationDetails) => {
        return integrationDetails.registrationSource === IntegrationRegistrationSource.GEM;
      });
    },
  );

  /**
   * gemIntegrationDetailsListByType
   * @static
   * @type {MemoizedSelector<CoreAppState, IntegrationDetailsListByKey>}
   * @memberOf UserPreferenceSelectors
   */
  public static gemIntegrationDetailsListByType: MemoizedSelector<CoreAppState, IntegrationDetailsListByKey> = createSelector(
    UserPreferenceSelectors.availableGemProvisionedIntegrations,
    (integrationsDetails: IntegrationDetails[]) => {
      return groupBy(integrationsDetails ?? [], (integrationDetails: IntegrationDetails) => integrationDetails.integration);
    },
  );

  /**
   * getAirwatchIntegrationDetails
   * @static
   * @type {MemoizedSelector<CoreAppState, IntegrationDetails>}
   * @memberof UserPreferenceSelectors
   */
  public static getAirwatchIntegrationDetails: MemoizedSelector<CoreAppState, IntegrationDetails> = createSelector(
    UserPreferenceSelectors.availableIntegrations,
    (integrationsDetails: IntegrationDetails[]) => {
      return integrationsDetails.find((integrationDetail: IntegrationDetails) => integrationDetail.integration === Integration.AIRWATCH);
    },
  );

  /**
   * availableAdminProvisionedIntegrations
   * @static
   * @type {MemoizedSelector<CoreAppState, IntegrationDetails[]>}
   * @memberOf UserPreferenceSelectors
   */
  public static availableAdminProvisionedIntegrations: MemoizedSelector<CoreAppState, IntegrationDetails[]> = createSelector(
    UserPreferenceSelectors.availableIntegrations,
    (integrationsDetails: IntegrationDetails[]) => {
      return (integrationsDetails ?? []).filter((integrationDetails: IntegrationDetails) => {
        return integrationDetails.registrationSource === IntegrationRegistrationSource.ADMIN;
      });
    },
  );

  /**
   * latestGemProvisionedAccessTenant
   * @static
   * @type {MemoizedSelector<CoreAppState, IntegrationDetails>}
   * @memberOf UserPreferenceSelectors
   */
  public static latestGemProvisionedAccessTenant: MemoizedSelector<CoreAppState, IntegrationDetails> = createSelector(
    UserPreferenceSelectors.availableGemProvisionedIntegrations,
    (integrationsDetails: IntegrationDetails[]) => {
      const accessIntegrationDetailsList: IntegrationDetails[] = integrationsDetails.filter((integrationDetails: IntegrationDetails) => {
        return integrationDetails.integration === Integration.IDM;
      });
      if (!accessIntegrationDetailsList.length) {
        return null;
      }
      return maxBy(accessIntegrationDetailsList, (integrationDetails: IntegrationDetails) => {
        return integrationDetails.createdAt;
      });
    },
  );

  /**
   * isHubSilentOptInAllowed
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof UserPreferenceSelectors
   */
  public static isHubSilentOptInAllowed: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceSelectors.isIntegrationAutoOptinForCSPEnabled,
    UserPreferenceFeatureControlsSelectors.isHubSilentOptInEnabled,
    (isIntegrationAutoOptinEnabled: boolean, isHubSilentOptInEnabled: boolean) => {
      return isIntegrationAutoOptinEnabled && isHubSilentOptInEnabled;
    },
  );

  /**
   * availableGemProvisionedIntegrationsForOptIn
   * @static
   * @type {MemoizedSelector<CoreAppState, IntegrationDetails[]>}
   * @memberOf UserPreferenceSelectors
   */
  public static availableGemProvisionedIntegrationsForOptIn: MemoizedSelector<CoreAppState, IntegrationDetails[]> = createSelector(
    UserPreferenceSelectors.availableGemProvisionedIntegrations,
    UserPreferenceSelectors.isHubSilentOptInAllowed,
    (integrationsDetails: IntegrationDetails[], isHubSilentOptInAllowed: boolean) => {
      return (integrationsDetails ?? []).filter((integrationDetails: IntegrationDetails) => {
        // Only UEM, WS1 Access and Hub integrations are available for region based opt in
        return (
          AppConfig.GEM_INTEGRATIONS_SUPPORTED_FOR_OPT_IN.includes(integrationDetails.integration) ||
          (isHubSilentOptInAllowed && integrationDetails.integration === Integration.HUB)
        );
      });
    },
  );

  /**
   * allOptedInGemProvisionedIntegrations
   * @static
   * @type {MemoizedSelector<CoreAppState, IntegrationDetails[]>}
   * @memberOf UserPreferenceSelectors
   */
  public static allOptedInGemProvisionedIntegrations: MemoizedSelector<CoreAppState, IntegrationDetails[]> = createSelector(
    UserPreferenceSelectors.availableGemProvisionedIntegrations,
    (availableIntegrations: IntegrationDetails[]) => {
      return availableIntegrations?.filter((availableIntegration: IntegrationDetails) => availableIntegration.active);
    },
  );

  /**
   * allNonOptedGemProvisionedIntegrations
   * @static
   * @type {MemoizedSelector<CoreAppState, IntegrationDetails[]>}
   * @memberOf UserPreferenceSelectors
   */
  public static allNonOptedGemProvisionedIntegrations: MemoizedSelector<CoreAppState, IntegrationDetails[]> = createSelector(
    UserPreferenceSelectors.availableGemProvisionedIntegrations,
    (availableIntegrations: IntegrationDetails[]) => {
      return availableIntegrations?.filter((availableIntegration: IntegrationDetails) => !availableIntegration.active);
    },
  );

  /**
   * optedInGemProvisionedIntegrations
   * @static
   * @type {MemoizedSelector<CoreAppState, IntegrationDetails[]>}
   * @memberOf UserPreferenceSelectors
   */
  public static optedInGemProvisionedIntegrations: MemoizedSelector<CoreAppState, IntegrationDetails[]> = createSelector(
    UserPreferenceSelectors.availableGemProvisionedIntegrationsForOptIn,
    (availableIntegrations: IntegrationDetails[]) => {
      return availableIntegrations?.filter((availableIntegration: IntegrationDetails) => availableIntegration.active);
    },
  );

  /**
   * nonOptedGemProvisionedIntegrations
   * @static
   * @type {MemoizedSelector<CoreAppState, IntegrationDetails[]>}
   * @memberOf UserPreferenceSelectors
   */
  public static nonOptedGemProvisionedIntegrations: MemoizedSelector<CoreAppState, IntegrationDetails[]> = createSelector(
    UserPreferenceSelectors.availableGemProvisionedIntegrationsForOptIn,
    (availableIntegrations: IntegrationDetails[]) => {
      return availableIntegrations?.filter((availableIntegration: IntegrationDetails) => !availableIntegration.active);
    },
  );

  /**
   * isSilentOptInAllowed
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof UserPreferenceSelectors
   */
  public static isSilentOptInAllowed: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceSelectors.isIntegrationAutoOptinForCSPEnabled,
    UserPreferenceFeatureControlsSelectors.isSilentOptInEnabled,
    (isIntegrationAutoOptinEnabled: boolean, isSilentOptInEnabled: boolean) => {
      return isIntegrationAutoOptinEnabled && isSilentOptInEnabled;
    },
  );

  /**
   * integrationsToOptInMapSelector
   * @static
   * @type {MemoizedSelector<CoreAppState, Map<string, IntegrationDetails[]>>}
   * @memberOf UserPreferenceSelectors
   */
  public static integrationsToOptInMap: MemoizedSelector<CoreAppState, Map<string, IntegrationDetails[]>> = createSelector(
    UserPreferenceFeatureControlsSelectors.isMultiSubscriptionsEnabled,
    UserPreferenceSelectors.allOptedInGemProvisionedIntegrations,
    UserPreferenceSelectors.allNonOptedGemProvisionedIntegrations,
    (
      isMultiSubscriptionsEnabled: boolean,
      allOptedInGemProvisionedIntegrations: IntegrationDetails[],
      allNonOptedGemProvisionedIntegrations: IntegrationDetails[],
    ) => {
      const integrationDetailsList: IntegrationDetails[] = allOptedInGemProvisionedIntegrations?.length
        ? allOptedInGemProvisionedIntegrations
        : allNonOptedGemProvisionedIntegrations;
      const integrationsToOptInMap: Map<string, IntegrationDetails[]> = new Map<string, IntegrationDetails[]>();
      integrationDetailsList?.forEach((integrationDetails: IntegrationDetails) => {
        const integrationType: string = integrationDetails.integration;
        if (!integrationsToOptInMap.has(integrationType)) {
          integrationsToOptInMap.set(integrationType, []);
        }
        if (isMultiSubscriptionsEnabled || !integrationsToOptInMap.get(integrationType).length) {
          integrationsToOptInMap.get(integrationType).push(integrationDetails);
        }
      });
      return integrationsToOptInMap;
    },
  );

  /**
   * integrationsToOptInList
   * @static
   * @type {MemoizedSelector<CoreAppState, IntegrationDetails[]>}
   * @memberOf UserPreferenceSelectors
   */
  public static integrationsToOptInList: MemoizedSelector<CoreAppState, IntegrationDetails[]> = createSelector(
    UserPreferenceSelectors.integrationsToOptInMap,
    UserPreferenceSelectors.isHubSilentOptInAllowed,
    (integrationsToOptInMap: Map<string, IntegrationDetails[]>, isHubSilentOptInAllowed: boolean) => {
      const integrationsToOptInMapData: Map<string, IntegrationDetails[]> = new Map(integrationsToOptInMap);
      if (integrationsToOptInMapData?.has(Integration.HORIZON)) {
        integrationsToOptInMapData?.delete(Integration.HORIZON);
      }
      if (integrationsToOptInMapData?.has(Integration.HUB) && !isHubSilentOptInAllowed) {
        integrationsToOptInMapData?.delete(Integration.HUB);
      }
      return Array.from(integrationsToOptInMapData?.values()).reduce(
        (integrationDetailsList: IntegrationDetails[], currentIntegrationsDetails: IntegrationDetails[]) => {
          return integrationDetailsList.concat(currentIntegrationsDetails);
        },
        [],
      );
    },
  );

  /**
   * getFirstActiveInstanceByIntegration
   * @static
   * @param {string} integration
   * @type {MemoizedSelector<CoreAppState, IntegrationDetails>}
   * @memberof UserPreferenceSelectors
   */
  public static getFirstActiveInstanceByIntegration = (integration: string): MemoizedSelector<CoreAppState, IntegrationDetails> =>
    createSelector(UserPreferenceSelectors.getAvailableIntegrations, (integrationDetailsList: IntegrationDetails[]) =>
      getFirstActiveIntegrationInstance(integrationDetailsList, integration),
    );

  /**
   * getFirstIntegrationInstanceByIntegrationType
   * @static
   * @param {string} integration
   * @type {MemoizedSelector<CoreAppState, IntegrationDetails>}
   * @memberof UserPreferenceSelectors
   */
  public static getFirstIntegrationInstanceByIntegrationType = (integration: string): MemoizedSelector<CoreAppState, IntegrationDetails> =>
    createSelector(UserPreferenceSelectors.getAvailableIntegrations, (integrationDetailsList: IntegrationDetails[]) =>
      getFirstIntegrationInstance(integrationDetailsList, integration),
    );

  /**
   * isIntegrationAuthorized
   * @static
   * @param {string} integration
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof UserPreferenceSelectors
   */
  public static isIntegrationAuthorized = (integration: string): MemoizedSelector<CoreAppState, boolean> =>
    createSelector(
      UserPreferenceSelectors.getFirstActiveInstanceByIntegration(integration),
      (activeIntegrationInstance: IntegrationDetails) => activeIntegrationInstance?.active,
    );

  /**
   * isIDMAuthorized
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof UserPreferenceSelectors
   */
  public static isIDMAuthorized: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceSelectors.isIntegrationAuthorized(Integration.IDM),
    (isIntegrationAuthorized: boolean) => isIntegrationAuthorized,
  );

  // Workspace ONE related selectors
  /**
   * showWorkspaceOneCloud
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof UserPreferenceSelectors
   */
  public static showWorkspaceOneCloud: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceFeatureControlsSelectors.isWorkspaceOneCloudEnabled,
    UserPreferenceSelectors.isCspLoggedInUser,
    (isWorkspaceOneCloudEnabled: boolean, isCspLoggedInUser: boolean) => isWorkspaceOneCloudEnabled && isCspLoggedInUser,
  );

  /**
   * applicationDescriptionKey
   * @static
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberof UserPreferenceSelectors
   */
  public static applicationDescriptionKey: MemoizedSelector<CoreAppState, string> = createSelector(
    UserPreferenceSelectors.showWorkspaceOneCloud,
    (showWorkspaceOneCloud: boolean) => {
      return showWorkspaceOneCloud
        ? 'COMMON_MESSAGES.ACCESS_YOUR_OMNISSA_EUC_PORTFOLIO'
        : 'COMMON_MESSAGES.WS_ONE_INTELLIGENCE_DESCRIPTION';
    },
  );
  /**
   * applicationNameKey
   * @static
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberof UserPreferenceSelectors
   */
  public static applicationNameKey: MemoizedSelector<CoreAppState, string> = createSelector(
    UserPreferenceSelectors.showWorkspaceOneCloud,
    (showWorkspaceOneCloud: boolean) => {
      return showWorkspaceOneCloud ? 'COMMON_MESSAGES.WORKSPACE_ONE' : 'COMMON_MESSAGES.WS1_INTELLIGENCE';
    },
  );
  /**
   * applicationTitleKey
   * @static
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberof UserPreferenceSelectors
   */
  public static applicationTitleKey: MemoizedSelector<CoreAppState, string> = createSelector(
    UserPreferenceSelectors.showWorkspaceOneCloud,
    (showWorkspaceOneCloud: boolean) => {
      return showWorkspaceOneCloud ? 'COMMON_MESSAGES.WS1_TAGLINE' : 'COMMON_MESSAGES.WS1_INTELLIGENCE_TITLE';
    },
  );

  /**
   * isAccessAndUemInstanceAvailable
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof UserPreferenceSelectors
   */
  public static isAccessAndUemInstanceAvailable: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceSelectors.getFirstIntegrationInstanceByIntegrationType(Integration.IDM),
    UserPreferenceSelectors.getFirstIntegrationInstanceByIntegrationType(Integration.AIRWATCH),
    (activeAccessIntegrationInstance: IntegrationDetails, activeUemIntegrationInstance: IntegrationDetails) =>
      !!(activeAccessIntegrationInstance && activeUemIntegrationInstance),
  );

  /**
   * isBrownfieldNotificationEnabled
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof UserPreferenceSelectors
   */
  public static isBrownfieldNotificationEnabled: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceFeatureControlsSelectors.isWorkspaceOneBrownfieldNotificationExperienceEnabled,
    UserPreferenceSelectors.isBrownfieldWorkspaceOneCloudCustomer,
    UserPreferenceSelectors.isCspLinkedOrg,
    UserPreferenceSelectors.isUemIdentity,
    (
      isWorkspaceOneBrownfieldNotificationExperienceEnabled: boolean,
      isBrownfieldWorkspaceOneCloudCustomer: boolean,
      isCspLinkedOrg: boolean,
      isUemIdentity: boolean,
    ) => {
      return (
        isWorkspaceOneBrownfieldNotificationExperienceEnabled && isBrownfieldWorkspaceOneCloudCustomer && isCspLinkedOrg && isUemIdentity
      );
    },
  );

  /**
   * isBrownfieldNotificationModalShown
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof UserPreferenceSelectors
   */
  public static isBrownfieldNotificationModalShown: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceSelectors.isBrownfieldNotificationEnabled,
    RbacSelectors.hasUserScopes,
    (isBrownfieldNotificationEnabled: boolean, hasUserScopes: boolean) => {
      return isBrownfieldNotificationEnabled && hasUserScopes;
    },
  );

  /**
   * getRemainingBrownfieldTransitionDays
   * @static
   * @type {MemoizedSelector<CoreAppState, number>}
   * @memberof UserPreferenceSelectors
   */
  public static getRemainingBrownfieldTransitionDays: MemoizedSelector<CoreAppState, number> = createSelector(
    UserPreferenceSelectors.brownfieldCutoffDate,
    (brownfieldCutoffDateMillis: number) => {
      return brownfieldCutoffDateMillis ? moment(brownfieldCutoffDateMillis).diff(moment(), DateTimeFormat.DAYS) : null;
    },
  );

  /**
   * getCurrentIntroPage
   * @static
   * @type {MemoizedSelector<CoreAppState, IntroType>}
   * @memberof UserPreferenceSelectors
   */
  public static getCurrentIntroPage: MemoizedSelector<CoreAppState, IntroType> = createSelector(
    UserPreferenceCommonSelectors.selectUserPreferenceState,
    (state: UserPreferenceState) => state.currentIntroPage,
  );

  /**
   * getGemProvisionedAirwatchIntegrationDetails
   * @static
   * @type {MemoizedSelector<CoreAppState, IntegrationDetails>}
   * @memberof UserPreferenceSelectors
   */
  public static getGemProvisionedAirwatchIntegrationDetails: MemoizedSelector<CoreAppState, IntegrationDetails> = createSelector(
    UserPreferenceSelectors.availableGemProvisionedIntegrations,
    (integrationsDetails: IntegrationDetails[]) => {
      return integrationsDetails?.find((integrationDetail: IntegrationDetails) => integrationDetail.integration === Integration.AIRWATCH);
    },
  );

  /**
   * isGemProvisionedAirwatchIntegrationPresent
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf UserPreferenceSelectors
   */
  public static isGemProvisionedAirwatchIntegrationPresent: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceSelectors.getGemProvisionedAirwatchIntegrationDetails,
    (airwatchIntegration: IntegrationDetails) => !!airwatchIntegration,
  );

  /**
   * isDeployDisabledForScriptAndSensor
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf UserPreferenceSelectors
   */
  public static isDeployDisabledForScriptAndSensor: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceSelectors.isUemIdentity,
    UserPreferenceSelectors.isGemProvisionedAirwatchIntegrationPresent,
    (isUemIdentity: boolean, isGemProvisionedAirwatchIntegrationPresent: boolean) => {
      return isUemIdentity || !isGemProvisionedAirwatchIntegrationPresent;
    },
  );

  /**
   * getCurrentIntroPageUIPreference
   * @static
   * @type {MemoizedSelector<CoreAppState, UIPreference>}
   * @memberof UserPreferenceSelectors
   */
  public static getCurrentIntroPageUIPreference: MemoizedSelector<CoreAppState, UIPreference> = createSelector(
    UserPreferenceSelectors.getCurrentIntroPage,
    (introType: IntroType) => {
      switch (introType) {
        case IntroType.AUTOMATION: {
          return UIPreference.IS_AUTOMATION_INTRO_PAGE_VISITED;
        }
        case IntroType.REPORT: {
          return UIPreference.IS_REPORT_INTRO_PAGE_VISITED;
        }
        case IntroType.DASHBOARD: {
          return UIPreference.IS_DASHBOARD_INTRO_PAGE_VISITED;
        }
      }
    },
  );

  /**
   * isNonAdminPage
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof UserPreferenceSelectors
   */
  public static isNonAdminPage: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceSelectors.getInitialUrlQueryParams,
    (initialUrlQueryParams: GenericObject) => !!initialUrlQueryParams?.share_type,
  );

  /**
   * isCustomRoleEnabled
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof UserPreferenceSelectors
   */
  public static isCustomRoleEnabled: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceSelectors.isCspLoggedInUser,
    UserPreferenceFeatureControlsSelectors.isWorkspaceOneRbacEnabled,
    UserPreferenceFeatureControlsSelectors.isCustomRoleEnabled,
    UserPreferenceFeatureControlsSelectors.isWorkspaceOneBrownfieldRbacEnabled,
    (
      isCspLoggedInUser: boolean,
      isWorkspaceOneRbacEnabled: boolean,
      isCustomRoleEnabled: boolean,
      isWorkspaceOneBrownfieldRbacEnabled: boolean,
    ) => {
      if (isWorkspaceOneBrownfieldRbacEnabled) {
        return false;
      }
      if (!isCspLoggedInUser) {
        return isCustomRoleEnabled;
      }
      return isWorkspaceOneRbacEnabled && isCustomRoleEnabled;
    },
  );
}
