/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { createSelector, MemoizedSelector } from '@ngrx/store';

import { Features } from '@ws1c/intelligence-common/constants';
import { CoreAppState } from '@ws1c/intelligence-core/store/core-app-state';
import { UserPreferenceCommonSelectors } from '@ws1c/intelligence-core/store/user-preference/user-preference-common.selectors';
import { Feature, FeatureControls } from '@ws1c/intelligence-models';
import { RBAC_HELPERS } from './rbac-selector-helper';
import { RbacSelectors } from './rbac.selectors';

/**
 * FeaturesSelector
 * @export
 * @class FeatureSelectors
 */
export class FeatureSelectors {
  /**
   * hasAnyFeature
   * @static
   * @param {Feature[]} features
   * @param {boolean} [tolerateUndefinedScope=false]
   * @returns {MemoizedSelector<CoreAppState, boolean>}
   * @memberof FeatureSelectors
   */
  public static hasAnyFeature = (features: Feature[], tolerateUndefinedScope = false): MemoizedSelector<CoreAppState, boolean> =>
    createSelector(
      UserPreferenceCommonSelectors.getFeatureControls,
      RbacSelectors.getUserScopes,
      (featureControls: FeatureControls, userScopes: string[]) =>
        RBAC_HELPERS.hasAnyScope(featureControls, userScopes, features, tolerateUndefinedScope),
    );

  /**
   * hasAllFeatures
   * @param {Feature[]} features
   * @returns {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf FeatureSelectors
   */
  public static hasAllFeatures = (features: Feature[]): MemoizedSelector<CoreAppState, boolean> =>
    createSelector(
      UserPreferenceCommonSelectors.getFeatureControls,
      RbacSelectors.getUserScopes,
      (featureControls: FeatureControls, userScopes: string[]) => RBAC_HELPERS.hasAllScope(featureControls, userScopes, features),
    );

  /**
   * hasFeature
   * @param {Feature} feature
   * @returns {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf FeatureSelectors
   */
  public static hasFeature = (feature: Feature): MemoizedSelector<CoreAppState, boolean> =>
    createSelector(
      UserPreferenceCommonSelectors.getFeatureControls,
      RbacSelectors.getUserScopes,
      (featureControls: FeatureControls, userScopes: string[]) => RBAC_HELPERS.hasScope(featureControls, userScopes, feature),
    );

  /**
   * hasAccountPerm
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf FeatureSelectors
   */
  public static hasAccountPerm: MemoizedSelector<CoreAppState, boolean> = FeatureSelectors.hasFeature(Features.ACCOUNT);

  /**
   * hasAccountReadOnlyPerm
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf FeatureSelectors
   */
  public static hasAccountReadOnlyPerm: MemoizedSelector<CoreAppState, boolean> = FeatureSelectors.hasFeature(Features.ACCOUNT_READONLY);

  /**
   * hasAccountGroupPerm
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf FeatureSelectors
   */
  public static hasAccountGroupPerm: MemoizedSelector<CoreAppState, boolean> = FeatureSelectors.hasFeature(Features.ACCOUNT_GROUP);

  /**
   * hasAccountGroupReadOnlyPerm
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf FeatureSelectors
   */
  public static hasAccountGroupReadOnlyPerm: MemoizedSelector<CoreAppState, boolean> = FeatureSelectors.hasFeature(
    Features.ACCOUNT_GROUP_READONLY,
  );

  /**
   * hasAdministratorsPerm
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf FeatureSelectors
   */
  public static hasAdministratorsPerm: MemoizedSelector<CoreAppState, boolean> = FeatureSelectors.hasAnyFeature(Features.ADMINISTRATORS);

  /**
   * hasAccountRolesPerm
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof FeatureSelectors
   */
  public static hasAccountRolesPerm: MemoizedSelector<CoreAppState, boolean> = FeatureSelectors.hasFeature(Features.ACCOUNT_ROLES);

  /**
   * hasAccountRolesReadOnlyPerm
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof FeatureSelectors
   */
  public static hasAccountRolesReadOnlyPerm: MemoizedSelector<CoreAppState, boolean> = FeatureSelectors.hasFeature(
    Features.ACCOUNT_ROLES_READONLY,
  );

  /**
   * hasApteligentPerm
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf FeatureSelectors
   */
  public static hasApteligentPerm: MemoizedSelector<CoreAppState, boolean> = FeatureSelectors.hasFeature(Features.APTELIGENT);

  /**
   * hasApteligentReadOnlyPerm
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf FeatureSelectors
   */
  public static hasApteligentReadOnlyPerm: MemoizedSelector<CoreAppState, boolean> = FeatureSelectors.hasFeature(
    Features.APTELIGENT_READONLY,
  );

  /**
   * hasAutomationPerm
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf FeatureSelectors
   */
  public static hasAutomationPerm: MemoizedSelector<CoreAppState, boolean> = FeatureSelectors.hasFeature(Features.AUTOMATION);

  /**
   * hasAutomationReadOnlyPerm
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf FeatureSelectors
   */
  public static hasAutomationReadOnlyPerm: MemoizedSelector<CoreAppState, boolean> = FeatureSelectors.hasFeature(
    Features.AUTOMATION_READONLY,
  );

  /**
   * hasContentSearchPerm
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf FeatureSelectors
   */
  public static hasContentSearchPerm: MemoizedSelector<CoreAppState, boolean> = FeatureSelectors.hasFeature(Features.CONTENT_SEARCH);

  /**
   * hasContentSearchReadOnlyPerm
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf FeatureSelectors
   */
  public static hasContentSearchReadOnlyPerm: MemoizedSelector<CoreAppState, boolean> = FeatureSelectors.hasFeature(
    Features.CONTENT_SEARCH_READONLY,
  );

  /**
   * hasDashboardPerm
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf FeatureSelectors
   */
  public static hasDashboardPerm: MemoizedSelector<CoreAppState, boolean> = FeatureSelectors.hasFeature(Features.DASHBOARD);

  /**
   * hasDashboardReadOnlyPerm
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf FeatureSelectors
   */
  public static hasDashboardReadOnlyPerm: MemoizedSelector<CoreAppState, boolean> = FeatureSelectors.hasFeature(
    Features.DASHBOARD_READONLY,
  );

  /**
   * hasDashboardSettingsPerm
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf FeatureSelectors
   */
  public static hasDashboardSettingsPerm: MemoizedSelector<CoreAppState, boolean> = FeatureSelectors.hasFeature(
    Features.DASHBOARD_SETTINGS,
  );

  /**
   * hasDashboardWidgetPerm
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf FeatureSelectors
   */
  public static hasDashboardWidgetPerm: MemoizedSelector<CoreAppState, boolean> = FeatureSelectors.hasFeature(Features.DASHBOARD_WIDGET);

  /**
   * hasDashboardWidgetReadOnlyPerm
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf FeatureSelectors
   */
  public static hasDashboardWidgetReadOnlyPerm: MemoizedSelector<CoreAppState, boolean> = FeatureSelectors.hasFeature(
    Features.DASHBOARD_WIDGET_READONLY,
  );

  /**
   * hasDataAccessPolicyPerm
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf FeatureSelectors
   */
  public static hasDataAccessPolicyPerm: MemoizedSelector<CoreAppState, boolean> = FeatureSelectors.hasFeature(Features.DATA_ACCESS_POLICY);

  /**
   * hasEventForwarderPerm
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf FeatureSelectors
   */
  public static hasEventForwarderPerm: MemoizedSelector<CoreAppState, boolean> = FeatureSelectors.hasFeature(Features.EVENT_FORWARDER);

  /**
   * hasEventForwarderReadOnlyPerm
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf FeatureSelectors
   */
  public static hasEventForwarderReadOnlyPerm: MemoizedSelector<CoreAppState, boolean> = FeatureSelectors.hasFeature(
    Features.EVENT_FORWARDER_READONLY,
  );

  /**
   * hasGroupsUsersPerm
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf FeatureSelectors
   */
  public static hasGroupsUsersPerm: MemoizedSelector<CoreAppState, boolean> = FeatureSelectors.hasAllFeatures(
    Features.ADMINISTRATOR_GROUPS_USERS,
  );

  /**
   * hasGroupsUsersPermBrownfieldRbac
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf FeatureSelectors
   */
  public static hasGroupsUsersPermBrownfieldRbac: MemoizedSelector<CoreAppState, boolean> = FeatureSelectors.hasAllFeatures(
    Features.ADMINISTRATOR_GROUPS_USERS_BROWNFIELD_RBAC,
  );

  /**
   * hasIamIntegrationPerm
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf FeatureSelectors
   */
  public static hasIamIntegrationPerm: MemoizedSelector<CoreAppState, boolean> = FeatureSelectors.hasFeature(Features.IAM_INTEGRATION);

  /**
   * hasIamIntegrationReadOnlyPerm
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf FeatureSelectors
   */
  public static hasIamIntegrationReadOnlyPerm: MemoizedSelector<CoreAppState, boolean> = FeatureSelectors.hasFeature(
    Features.IAM_INTEGRATION_READONLY,
  );

  /**
   * hasIntegrationPerm
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf FeatureSelectors
   */
  public static hasIntegrationPerm: MemoizedSelector<CoreAppState, boolean> = FeatureSelectors.hasFeature(Features.SETTINGS);

  /**
   * hasIntegrationReadOnlyPerm
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf FeatureSelectors
   */
  public static hasIntegrationReadOnlyPerm: MemoizedSelector<CoreAppState, boolean> = FeatureSelectors.hasFeature(
    Features.SETTINGS_READONLY,
  );

  /**
   * hasIntegrationHomePerm
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf FeatureSelectors
   */
  public static hasIntegrationHomePerm: MemoizedSelector<CoreAppState, boolean> = FeatureSelectors.hasAnyFeature(Features.INTEGRATION_HOME);

  /**
   * hasIntegrationRegistrationPerm
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf FeatureSelectors
   */
  public static hasIntegrationRegistrationPerm: MemoizedSelector<CoreAppState, boolean> = FeatureSelectors.hasFeature(
    Features.INTEGRATION_REGISTRATION,
  );

  /**
   * hasIntegrationRegistrationReadOnlyPerm
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf FeatureSelectors
   */
  public static hasIntegrationRegistrationReadOnlyPerm: MemoizedSelector<CoreAppState, boolean> = FeatureSelectors.hasFeature(
    Features.INTEGRATION_REGISTRATION_READONLY,
  );

  /**
   * hasOrgRegistrationPerm
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf FeatureSelectors
   */
  public static hasOrgRegistrationPerm: MemoizedSelector<CoreAppState, boolean> = FeatureSelectors.hasFeature(Features.ORG_REGISTRATION);

  /**
   * hasOrgRegistrationReadOnlyPerm
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf FeatureSelectors
   */
  public static hasOrgRegistrationReadOnlyPerm: MemoizedSelector<CoreAppState, boolean> = FeatureSelectors.hasFeature(
    Features.ORG_REGISTRATION_READONLY,
  );

  /**
   * hasPartnerPerm
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf FeatureSelectors
   */
  public static hasPartnerPerm: MemoizedSelector<CoreAppState, boolean> = FeatureSelectors.hasFeature(Features.PARTNER);

  /**
   * hasPartnerReadOnlyPerm
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf FeatureSelectors
   */
  public static hasPartnerReadOnlyPerm: MemoizedSelector<CoreAppState, boolean> = FeatureSelectors.hasFeature(Features.PARTNER_READONLY);

  /**
   * hasPlaybooksPerm
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf FeatureSelectors
   */
  public static hasPlaybooksPerm: MemoizedSelector<CoreAppState, boolean> = FeatureSelectors.hasFeature(Features.PLAYBOOKS);

  /**
   * hasReportPerm
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf FeatureSelectors
   */
  public static hasReportPerm: MemoizedSelector<CoreAppState, boolean> = FeatureSelectors.hasFeature(Features.REPORT);

  /**
   * hasReportReadOnlyPerm
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf FeatureSelectors
   */
  public static hasReportReadOnlyPerm: MemoizedSelector<CoreAppState, boolean> = FeatureSelectors.hasFeature(Features.REPORT_READONLY);

  /**
   * hasReportSchedulePerm
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf FeatureSelectors
   */
  public static hasReportSchedulePerm: MemoizedSelector<CoreAppState, boolean> = FeatureSelectors.hasFeature(Features.REPORT_SCHEDULE);

  /**
   * hasReportScheduleReadOnlyPerm
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf FeatureSelectors
   */
  public static hasReportScheduleReadOnlyPerm: MemoizedSelector<CoreAppState, boolean> = FeatureSelectors.hasFeature(
    Features.REPORT_SCHEDULE_READONLY,
  );

  /**
   * hasReportTemplatePerm
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf FeatureSelectors
   */
  public static hasReportTemplatePerm: MemoizedSelector<CoreAppState, boolean> = FeatureSelectors.hasFeature(Features.REPORT_TEMPLATE);

  /**
   * hasServiceAccountPerm
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf FeatureSelectors
   */
  public static hasServiceAccountPerm: MemoizedSelector<CoreAppState, boolean> = FeatureSelectors.hasFeature(Features.SERVICE_ACCOUNT);

  /**
   * hasServiceAccountReadOnlyPerm
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf FeatureSelectors
   */
  public static hasServiceAccountReadOnlyPerm: MemoizedSelector<CoreAppState, boolean> = FeatureSelectors.hasFeature(
    Features.SERVICE_ACCOUNT_READONLY,
  );

  /**
   * hasSolutionIncidentPerm
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf FeatureSelectors
   */
  public static hasSolutionIncidentPerm: MemoizedSelector<CoreAppState, boolean> = FeatureSelectors.hasFeature(Features.INCIDENT);

  /**
   * hasSolutionIncidentReadOnlyPerm
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof FeatureSelectors
   */
  public static hasSolutionIncidentReadOnlyPerm: MemoizedSelector<CoreAppState, boolean> = FeatureSelectors.hasFeature(
    Features.INCIDENT_READONLY,
  );

  /**
   * hasSolutionSettingsPerm
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf FeatureSelectors
   */
  public static hasSolutionSettingsPerm: MemoizedSelector<CoreAppState, boolean> = FeatureSelectors.hasAllFeatures(
    Features.SOLUTION_SETTINGS,
  );

  /**
   * hasSolutionSettingsMetricReadOnlyPerm
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf FeatureSelectors
   */
  public static hasSolutionSettingsMetricReadOnlyPerm: MemoizedSelector<CoreAppState, boolean> = FeatureSelectors.hasAllFeatures(
    Features.SOLUTION_SETTINGS_READONLY,
  );

  /**
   * hasSolutionSettingsStepsReadOnlyPerm
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof FeatureSelectors
   */
  public static hasSolutionSettingsStepsReadOnlyPerm: MemoizedSelector<CoreAppState, boolean> = FeatureSelectors.hasFeature(
    Features.SOLUTIONS_SETTING_STEPS_READONLY,
  );

  /**
   * hasWorkflowPerm
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf FeatureSelectors
   */
  public static hasWorkflowPerm: MemoizedSelector<CoreAppState, boolean> = FeatureSelectors.hasFeature(Features.WORKFLOW);

  /**
   * hasWorkflowReadOnlyPerm
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf FeatureSelectors
   */
  public static hasWorkflowReadOnlyPerm: MemoizedSelector<CoreAppState, boolean> = FeatureSelectors.hasFeature(Features.WORKFLOW_READONLY);

  /**
   * hasDashboardManagePerm
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof FeatureSelectors
   */
  public static hasDashboardManagePerm: MemoizedSelector<CoreAppState, boolean> = FeatureSelectors.hasFeature(Features.DASHBOARD_ADMIN);

  /**
   * hasReportManagePerm
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof FeatureSelectors
   */
  public static hasReportManagePerm: MemoizedSelector<CoreAppState, boolean> = FeatureSelectors.hasFeature(Features.REPORT_ADMIN);

  /**
   * hasWorkflowManagePerm
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof FeatureSelectors
   */
  public static hasWorkflowManagePerm: MemoizedSelector<CoreAppState, boolean> = FeatureSelectors.hasFeature(Features.WORKFLOW_ADMIN);

  /**
   * hasReportRetentionPerm
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof FeatureSelectors
   */
  public static hasReportRetentionPerm: MemoizedSelector<CoreAppState, boolean> = FeatureSelectors.hasFeature(
    Features.REPORT_RETENTION_SUPPORTED,
  );

  /**
   * canViewReports
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof FeatureSelectors
   */
  public static canViewReports: MemoizedSelector<CoreAppState, boolean> = createSelector(
    FeatureSelectors.hasReportReadOnlyPerm,
    FeatureSelectors.hasReportManagePerm,
    (hasReportReadOnlyPerm: boolean, hasReportManagePerm: boolean) => hasReportReadOnlyPerm || hasReportManagePerm,
  );

  /**
   * isSolutionsDeemEnabled
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof FeatureSelectors
   */
  public static isSolutionsDeemEnabled: MemoizedSelector<CoreAppState, boolean> = FeatureSelectors.hasAnyFeature(
    [Features.SOLUTION_DEEM_PHYSICAL, Features.SOLUTION_DEEM_VIRTUAL],
    true,
  );

  /**
   * hasScriptsReadOnlyPerm
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof FeatureSelectors
   */
  public static hasScriptsReadOnlyPerm: MemoizedSelector<CoreAppState, boolean> = FeatureSelectors.hasFeature(Features.SCRIPTS);

  /**
   * hasSensorsReadOnlyPerm
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof FeatureSelectors
   */
  public static hasSensorsReadOnlyPerm: MemoizedSelector<CoreAppState, boolean> = FeatureSelectors.hasFeature(Features.SENSORS);

  /**
   * hasUEMFreestyleWorkflowsReadOnlyPerm
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof FeatureSelectors
   */
  public static hasUEMFreestyleWorkflowsReadOnlyPerm: MemoizedSelector<CoreAppState, boolean> = FeatureSelectors.hasFeature(
    Features.UEM_FREESTYLE_WORKFLOWS,
  );

  /**
   * hasSyntheticUrlMonitoringPerm
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof FeatureSelectors
   */
  public static hasSyntheticUrlMonitoringPerm: MemoizedSelector<CoreAppState, boolean> = FeatureSelectors.hasFeature(
    Features.SOLUTION_DEEM_SYNTHETIC_URL_MONITORING,
  );

  /**
   * hasIncidentPlaybookPerm
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof FeatureSelectors
   */
  public static hasIncidentPlaybookPerm: MemoizedSelector<CoreAppState, boolean> = createSelector(
    FeatureSelectors.hasSolutionIncidentPerm,
    FeatureSelectors.hasPlaybooksPerm,
    (hasSolutionIncidentPerm: boolean, hasPlaybooksPerm: boolean) => hasSolutionIncidentPerm && hasPlaybooksPerm,
  );
}
