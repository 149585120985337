/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, Output, ViewEncapsulation } from '@angular/core';
import { BarChartType, Color, ColorHelper, getUniqueXDomainValues, id, Orientation, ScaleType, Series } from '@dpa/ui-common';
import { scaleLinear, scalePoint } from 'd3-scale';
import { curveLinear } from 'd3-shape';

import { BaseOverlayChartComponent } from '@ws1c/dashboard-common/chart/custom/base-overlay-chart.component';

/**
 * LineOverlayChartComponent
 * @export
 * @class LineOverlayChartComponent
 * @extends {BaseOverlayChartComponent}
 */
@Component({
  selector: 'dpa-line-overlay-chart',
  templateUrl: 'line-overlay-chart.component.html',
  styleUrls: ['line-overlay-chart.component.scss'],
  animations: [
    trigger('animationState', [
      transition(':leave', [
        style({
          opacity: 1,
          transform: '*',
        }),
        animate(500, style({ opacity: 0, transform: 'scale(0)' })),
      ]),
    ]),
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LineOverlayChartComponent extends BaseOverlayChartComponent {
  @Input() public xAxis: boolean;
  @Input() public yAxis: boolean;
  @Input() public showYAxisOverlayLabel: boolean = true;
  @Input() public tooltipDisabled: boolean = false;
  @Input() public gradient: boolean;
  @Input() public showGridLines: boolean = true;
  @Input() public activeEntries: any[] = [];
  @Input() public trimXAxisTicks: boolean = true;
  @Input() public trimYAxisTicks: boolean = true;
  @Input() public rotateXAxisTicks: boolean = true;
  @Input() public maxXAxisTickLength: number = 16;
  @Input() public maxYAxisTickLength: number = 16;
  @Input() public xAxisTickFormatting: any;
  @Input() public yAxisTickFormatting: any;
  @Input() public xAxisTicks: any[];
  @Input() public yAxisTicks: any[];
  @Input() public barPadding = 8;
  @Input() public roundDomains: boolean = false;
  @Input() public roundEdges: boolean = true;
  @Input() public yScaleMax: number;
  @Input() public yScaleMin: number;
  @Input() public dataLabelFormatting: any;
  @Input() public noBarWhenZero: boolean = true;
  @Input() public wrapTicks = false;
  @Input() public overlayResults = [];
  @Input() public customcolorsForOverlay = [];
  @Input() public schemeForOverlay: Color;

  @Output() public activate: EventEmitter<any> = new EventEmitter();
  @Output() public deactivate: EventEmitter<any> = new EventEmitter();

  public xScale: any;
  public yScale: any;
  public xDomain: any;
  public yDomain: any;
  public tickFormatting: (label: string) => string;
  public readonly BAR_CHART_TYPE = BarChartType;
  public xSet: string[];
  public combinedSeries: any;

  // Overlay
  public clipPath: string;
  public clipPathId: string;
  public scaleType: ScaleType = ScaleType.Ordinal;
  public curve: any = curveLinear;
  public rangeFillOpacity: number;
  public xScaleOverlay: any;
  public yScaleOverlay: any;
  public xScaleOverlayMin: number;
  public xScaleOverlayMax: number;
  public yScaleOverlayMin: number;
  public yScaleOverlayMax: number;
  public xDomainOverlay: string[];
  public yDomainOverlay: number[];
  public xOverlaySet: string[];
  public hasRange: boolean;
  public autoScale: boolean;
  public isSSR = false;
  public yOrientRight = Orientation.Right;
  public hoveredVertical: any;
  public seriesDomain: any[] = [];
  public overlaySeriesDomain: any[] = [];
  public widgetHoverInfo: any[];

  /**
   * update
   * @memberof LineOverlayChartComponent
   */
  public update() {
    super.update();

    // Not support Quantile yet for LINE chart
    if (this.schemeForOverlay.group === ScaleType.Quantile) {
      this.schemeForOverlay.group = ScaleType.Ordinal;
    }

    this.xDomain = this.getXDomain();
    this.yDomain = this.getYDomain(this.results, this.yScaleMin, this.yScaleMax);
    this.xScale = this.getXScale(this.xDomain, this.dims.width);
    this.yScale = this.getYScale();
    this.seriesDomain = this.getSeriesDomain();
    this.setColors(this.seriesDomain, this.yDomain);
    // Line
    this.overlaySeriesDomain = this.getOverlaySeriesDomain();
    this.xDomainOverlay = this.getXDomainOverlay();
    this.xScaleOverlay = this.getXScale(this.xDomainOverlay, this.dims.width);
    this.yScaleOverlay = this.getYScaleOverlay();
    this.setColorsForOverlay();
    this.clipPathId = 'clip' + id().toString();
    this.clipPath = `url(#${this.clipPathId})`;
  }

  /**
   * updateYAxisWidth
   * @param {{ width: number }} { width }
   * @memberof LineOverlayChartComponent
   */
  public updateYAxisWidth({ width }: { width: number }) {
    this.yAxisWidth = Math.max(width, this.yAxisWidth);
    this.update();
  }

  /**
   * updateXAxisHeight
   * @param { height: number } { height }
   * @memberof LineOverlayChartComponent
   */
  public updateXAxisHeight({ height }: { height: number }) {
    this.xAxisHeight = height;
    this.update();
  }

  /**
   * hideCircles
   * @memberof LineOverlayChartComponent
   */
  @HostListener('mouseleave')
  public hideCircles() {
    this.hoveredVertical = null;
  }

  /**
   * onClick
   * @param {any} data
   * @param {Series} group
   * @memberof LineOverlayChartComponent
   */
  public onClick(data: any, group?: Series) {
    if (group) {
      data.series = group.name;
    }
    this.select.emit(data);
  }

  /**
   * onActivate
   * @param {any} event
   * @param {Series} group
   * @memberof LineOverlayChartComponent
   */
  public onActivate(event: any, group?: Series): void {
    const item = Object.assign({}, event);
    if (group) {
      item.series = group.name;
    }
    const items = this.results
      .map((g) => g.series)
      .flat()
      .filter((i) => {
        return i.name === item.name && i.series === item.series;
      });
    this.activeEntries = [...items];
    this.activate.emit({ value: item, entries: this.activeEntries });
  }

  /**
   * onDeactivate
   * @param {any} event
   * @param {Series} [group]
   * @param {boolean} [fromLegend=false]
   * @memberof LineOverlayChartComponent
   */
  public onDeactivate(event: any, group?: Series, fromLegend: boolean = false) {
    const item = Object.assign({}, event);
    if (group) {
      item.series = group.name;
    }
    this.activeEntries = this.activeEntries.filter((i) => {
      if (fromLegend) {
        return i.label !== item.name;
      } else {
        return !(i.name === item.name && i.series === item.series);
      }
    });
    this.deactivate.emit({ value: item, entries: this.activeEntries });
  }

  /**
   * getXScale
   * @private
   * @param {any} domain
   * @param {number} width
   * @returns {Function}
   * @memberof LineOverlayChartComponent
   */
  private getXScale(domain: any, width: number): any {
    return scalePoint().range([0, width]).padding(0.1).domain(domain);
  }

  /**
   * getYScaleOverlay
   * @returns {Function}
   * @memberof LineOverlayChartComponent
   */
  private getYScaleOverlay(): any {
    this.yDomainOverlay = this.getYDomain(this.overlayResults, this.yScaleOverlayMin, this.yScaleOverlayMax);
    const scale = scaleLinear().range([this.dims.height, 0]).domain(this.yDomainOverlay);
    return this.roundDomains ? scale.nice() : scale;
  }

  /**
   * getYScale
   * @returns {Function}
   * @memberof LineOverlayChartComponent
   */
  private getYScale(): any {
    this.yDomain = this.getYDomain(this.results, this.yScaleMin, this.yScaleMax);
    const scale = scaleLinear().range([this.dims.height, 0]).domain(this.yDomain);
    return this.roundDomains ? scale.nice() : scale;
  }

  /**
   * getXDomain
   * @returns {any[]}
   * @memberof LineOverlayChartComponent
   */
  private getXDomain(): any[] {
    const values = getUniqueXDomainValues(this.results);
    this.xSet = values;
    return values;
  }

  /**
   * getXDomainOverlay
   * @returns {string[]}
   * @memberof LineOverlayChartComponent
   */
  private getXDomainOverlay(): any[] {
    const values = getUniqueXDomainValues(this.overlayResults);
    this.xOverlaySet = values;
    return values;
  }

  /**
   * getYDomain
   * @private
   * @param {any[]} results
   * @param {number} yScaleMin
   * @param {number} yScaleMax
   * @returns {[number, number]}
   * @memberof LineOverlayChartComponent
   */
  private getYDomain(results: any[], yScaleMin: number, yScaleMax: number): [number, number] {
    const domain: number[] = [];
    for (const result of results) {
      for (const seriesResult of result.series) {
        this.checkAndAddToArray(domain, seriesResult.value);
        if (seriesResult.min !== undefined) {
          this.hasRange = true;
          this.checkAndAddToArray(domain, seriesResult.min);
        }
        if (seriesResult.max !== undefined) {
          this.hasRange = true;
          this.checkAndAddToArray(domain, seriesResult.max);
        }
      }
    }
    const values = [...domain];
    if (!this.autoScale) {
      values.push(0);
    }
    const min = yScaleMin ?? Math.min(...values);
    const max = yScaleMax ?? Math.max(...values);
    return [min, max];
  }

  /**
   * checkAndAddToArray
   * @private
   * @param {number} domain
   * @param {number} value
   * @memberof LineOverlayChartComponent
   */
  private checkAndAddToArray(domain: number[], value: number) {
    if (domain.indexOf(value) < 0) {
      domain.push(value);
    }
  }

  /**
   * setColorsForOverlay
   * @memberof LineOverlayChartComponent
   */
  private setColorsForOverlay() {
    const domain = this.schemeForOverlay.group === ScaleType.Ordinal ? this.overlaySeriesDomain : this.yDomainOverlay;
    this.colorsForOverlay = new ColorHelper(this.schemeForOverlay, domain, this.customcolorsForOverlay);
  }

  /**
   * getSeriesDomain
   * @returns {string[]}
   * @memberof LineOverlayChartComponent
   */
  private getSeriesDomain(): string[] {
    this.combinedSeries = this.overlayResults.slice(0);
    this.combinedSeries = [...this.combinedSeries, ...this.results.slice(0)];
    return this.results.map((d: any) => d.name);
  }

  /**
   * getOverlaySeriesDomain
   * @returns {string[]}
   * @memberof LineOverlayChartComponent
   */
  private getOverlaySeriesDomain(): string[] {
    return this.overlayResults.map((d: any) => d.name);
  }
}
