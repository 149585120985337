/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject, TimeOfDay } from '@dpa/ui-common';
import { every, get, keyBy } from 'lodash-es';

import {
  Automation,
  AutomationAction,
  AutomationDialogMode,
  AutomationSchedule,
  Category,
  ChoiceValue,
  Column,
  ColumnIndex,
  ConnectorAction,
  ConnectorActionMetadata,
  ConnectorActionSection,
  convertCronExpressionDetail,
  CronExpressionData,
  DataGridColumn,
  EvaluationType,
  helpers,
  JsonSchemaAutomationActionFieldLookupRequestPayload,
  JsonSchemaMetadata,
  MetaForm,
  MetaFormField,
  MetaFormFieldPresentationType,
  NameValue,
  ReportColumnMapper,
  TestableDataList,
  TrendMode,
} from '@ws1c/intelligence-models';

export let automationCommonHelpers; // eslint-disable-line prefer-const

/**
 * getAnythingByKey
 * @param {any} val
 * @returns {any}
 * @export
 */
export const getAnythingByKey = (val) => keyBy(val, 'key');

/**
 * Get only categories that support Automation
 *
 * @export
 * @param {Category[]} categories
 * @returns {Category[]}
 */
export function getAvailableAutomationEntities(categories: Category[]): Category[] {
  return categories.filter((category: Category) => category.supportsAutomation);
}

/**
 * isAutomationDialogMode
 *
 * @param {...AutomationDialogMode} args
 * @returns {(mode: AutomationDialogMode) => boolean}
 */
export function isAutomationDialogMode(...args: AutomationDialogMode[]): (mode: AutomationDialogMode) => boolean {
  return (mode: AutomationDialogMode) => args.includes(mode);
}

/**
 * convertColumnsToDatagridColumns
 * @param {Column[]} columns
 * @returns {DataGridColumn[]}
 */
export function convertColumnsToDatagridColumns(columns: Column[]): DataGridColumn[] {
  return ReportColumnMapper.getDataGridColumns(columns);
}

/**
 * getDynamicValuesColumns
 *
 * @export
 * @param {ColumnIndex} availableColumns
 * @param {Set<string>} attributes
 * @returns {Column[]}
 */
export function getDynamicValuesColumnsHelper(availableColumns: ColumnIndex, attributes: Set<string> = new Set<string>()): Column[] {
  const columns: Column[] = [];
  for (const attribute of attributes) {
    if (availableColumns[attribute]) {
      columns.push(availableColumns[attribute]);
    }
  }
  return columns;
}

/**
 * getDynamicValuesColumnsByNameHelper
 *
 * @export
 * @param {ColumnIndex} availableColumns
 * @param {Set<string>} attributes
 * @returns {ColumnIndex}
 */
export function getDynamicValuesColumnsByNameHelper(
  availableColumns: ColumnIndex,
  attributes: Set<string> = new Set<string>(),
): ColumnIndex {
  const columns: ColumnIndex = {} as ColumnIndex;
  for (const attribute of attributes) {
    if (availableColumns[attribute]) {
      columns[attribute] = availableColumns[attribute];
    }
  }
  return columns;
}

/**
 * getFilteredTestAttributes
 *
 * @export
 * @param {ColumnIndex} allColumns
 * @param {Set<string>} attributes
 * @param {boolean} known
 * @returns {Set<string>}
 */
export function getFilteredTestAttributes(
  allColumns: ColumnIndex,
  attributes: Set<string> = new Set<string>(),
  known: boolean = true,
): Set<string> {
  return new Set([...attributes].filter((attribute: string) => (known ? allColumns[attribute] : !allColumns[attribute])));
}

/**
 * getDataListForTesting
 *
 * @param {AutomationAction} automationAction
 * @param {MetaForm} metaForm
 * @returns {TestableDataList}
 */
export function getDataListForTesting(automationAction: AutomationAction, metaForm: MetaForm): TestableDataList {
  if (!metaForm) {
    return {
      data: [],
      dataBySection: {},
    };
  }

  return {
    data: helpers.convertMetaFormFieldsForTesting(automationAction, get(metaForm, 'fields', [])),
    dataBySection: helpers.convertMetaFormSectionsForTesting(automationAction, get(metaForm, 'sections', [])),
  };
}

/**
 * getAutomationCategory
 *
 * @export
 * @param {Automation} automation
 * @param {Category[]} categories
 * @returns {Category}
 */
export function getAutomationCategory(automation: Automation, categories: Category[]): Category {
  if (!automation || !categories) {
    return null;
  }
  return categories.find((category: Category) => category.categoryId === automation.categoryId);
}

/**
 * isAutomationCategoryOnlySupportsIncomingData
 *
 * @export
 * @param {Category} [automationCategory]
 * @returns {boolean}
 */
export function isAutomationCategoryOnlySupportsIncomingData(automationCategory?: Category): boolean {
  if (!automationCategory) {
    return false;
  }
  return (
    automationCategory.supportedTrendModes.length === 1 && automationCategory.supportedTrendModes[0] === TrendMode[TrendMode.HISTORICAL]
  );
}

/**
 * getValueForDisplay
 *
 * @export
 * @param {MetaFormField} field
 * @returns {any}
 */
export function getValueForDisplay(field: MetaFormField): any {
  if (![MetaFormFieldPresentationType.LIST, MetaFormFieldPresentationType.RADIO].includes(field.presentationType)) {
    return field.value;
  }
  const selectedChoice = field.choiceValues.find((choice: ChoiceValue) => choice.value === field.value);
  return selectedChoice ? selectedChoice.label : field.value;
}

/**
 * convertMetaFormFieldsForDisplay
 *
 * @export
 * @param {AutomationAction} automationAction
 * @param {MetaForm} [metaForm=new MetaForm()]
 * @returns {MetaFormField[]}
 */
export function convertMetaFormFieldsForDisplay(automationAction: AutomationAction, metaForm: MetaForm = new MetaForm()): MetaFormField[] {
  let result = [];
  if (helpers.hasFields(metaForm)) {
    result = helpers
      .convertMetaFormFields(automationAction, metaForm.fields ?? [])
      .filter((field: MetaFormField) => field.presentationType !== MetaFormFieldPresentationType.HIDDEN)
      .map(
        (field: MetaFormField): MetaFormField => ({
          ...field,
          value: getValueForDisplay(field),
        }),
      );
  }
  if (helpers.hasSections(metaForm)) {
    const sections = helpers.convertMetaFormSections(automationAction, metaForm.sections || []);
    const sectionsResult = Object.keys(sections)
      .map((sectionKey: string): MetaFormField[] => sections[sectionKey] || [])
      .reduce((acc: MetaFormField[], fields: MetaFormField[]): MetaFormField[] => acc.concat(...fields), [])
      .filter((field: MetaFormField) => field.presentationType !== MetaFormFieldPresentationType.HIDDEN)
      .map(
        (field: MetaFormField): MetaFormField => ({
          ...field,
          value: getValueForDisplay(field),
        }),
      );
    result = result.concat(sectionsResult);
  }
  return result;
}

/**
 * cronExpressionDataFromScheduleOrDefault
 *
 * @export
 * @param {(Automation | undefined)} automation
 * @returns {CronExpressionData}
 */
export function cronExpressionDataFromScheduleOrDefault(automation: Automation | undefined): CronExpressionData {
  const schedule: AutomationSchedule | undefined = get(automation, 'schedule');
  if (!schedule) {
    return {
      frequency: 1,
      startTimeOfDay: new TimeOfDay({
        hour: 0,
        minute: 0,
        isAm: true,
      }),
      end: null,
      requiredEnDate: false,
      hourly: {
        interval: 4,
      },
      weekly: {
        sun: true,
        mon: false,
        tue: false,
        wed: false,
        thu: false,
        fri: false,
        sat: false,
      },
      monthly: {
        dayOfMonth: 1,
      },
    };
  }

  return convertCronExpressionDetail(schedule.cronExpressionDetail, schedule.start, schedule.end);
}

/**
 * getIrrecoverableActionsFromAutomation
 *
 * Fetches irrecoverable actions from an automation and returns an array of irrecoverable actions with messages.
 * @export
 * @param {Automation} automationDetails
 * @param {Record<string, ConnectorAction>} connectorActionsById
 * @param {Automation} automationDialogModel
 * @returns {ConnectorAction[]}
 */
export function getIrrecoverableConnectorActionsFromAutomation(
  automationDetails: Automation,
  connectorActionsById: Record<string, ConnectorAction>,
  automationDialogModel: Automation,
): ConnectorAction[] {
  if (!automationDetails && !automationDialogModel) {
    return [];
  }
  const automation = automationDetails ?? automationDialogModel;
  return (automation.actions ?? []).reduce((irrecoverableActions: ConnectorAction[], action: AutomationAction) => {
    const { actionTemplateId } = action.actionData;
    const actionDetails: ConnectorAction = connectorActionsById[actionTemplateId];
    if (actionDetails && !actionDetails.metadata.isRecoverable) {
      irrecoverableActions.push(
        new ConnectorAction({
          id: actionTemplateId,
          name: actionDetails.name,
          metadata: new ConnectorActionMetadata({
            unrecoverableMessage: actionDetails.metadata.unrecoverableMessage,
          }),
        }),
      );
    }
    return irrecoverableActions;
  }, []);
}

/**
 * isAutomationConnectorActionValid
 *
 * @export
 * @param {AutomationAction} automationAction
 * @param {Record<string, ConnectorActionSection[]>} sections
 * @returns {boolean}
 */
export function isAutomationConnectorActionValid(
  automationAction: AutomationAction,
  sections: Record<string, ConnectorActionSection[]>,
): boolean {
  if (!sections) {
    return false;
  }
  const flattenSections = helpers.getFlattenConnectorActionSections(sections);
  return every(flattenSections, (section: ConnectorActionSection) => {
    return every(Object.keys(section.schema?.properties ?? {}), (fieldKey: string) => {
      const currentField = section.schema?.properties[fieldKey];
      const defaultValue = currentField.default;
      const finalValue = automationAction?.actionData?.settingsBySection?.[section.sectionType]?.[fieldKey] ?? defaultValue;
      return helpers.isSchemaValueAllowed(section, currentField, fieldKey, finalValue);
    });
  });
}

/**
 * areAutomationConnectorActionsValid
 *
 * @export
 * @param {Automation} automation
 * @param {ConnectorAction[]} actions
 * @returns {boolean}
 */
export function areAutomationConnectorActionsValid(automation: Automation, actions: ConnectorAction[]): boolean {
  if (!automation) {
    return false;
  }

  const validityFn = (automationAction: AutomationAction) => {
    return automationCommonHelpers.isAutomationConnectorActionValid(
      automationAction,
      actions?.find((action: ConnectorAction) => action.id === automationAction?.actionData?.actionTemplateId)?.sections,
    );
  };
  return automation.actions.every((automationAction: AutomationAction) => {
    return automationAction.isValid(validityFn);
  });
}

/**
 * getAutomationValid
 *
 * @export
 * @param {boolean} automationActionsValidity
 * @param {Automation} automationWizardModel
 * @param {boolean} isAutomationValidForGlobalOrchestrator
 * @returns  {boolean}
 */
export function getAutomationValid(
  automationActionsValidity: boolean,
  automationWizardModel: Automation,
  isAutomationValidForGlobalOrchestrator: boolean,
): boolean {
  let isValidName: boolean = false;
  let isValidFilter: boolean = false;
  if (automationWizardModel) {
    isValidName = !!automationWizardModel.name;
    isValidFilter =
      (!!automationWizardModel.condition && !!automationWizardModel.condition.filter) ||
      automationWizardModel.evaluationType === EvaluationType.CUSTOM_TARGET;
  }
  return isAutomationValidForGlobalOrchestrator && automationActionsValidity && isValidName && isValidFilter;
}

/**
 * getLookupFieldNameValue
 * @export
 * @param {JsonSchemaMetadata} field
 * @param {JsonSchemaAutomationActionFieldLookupRequestPayload} payload
 * @param {GenericObject} [properties]
 * @returns {NameValue}
 */
export function getLookupFieldNameValue(
  field: JsonSchemaMetadata,
  payload: JsonSchemaAutomationActionFieldLookupRequestPayload,
  properties?: GenericObject,
): NameValue {
  let value: string;
  if (field.lookupConfig && payload.selectedLookupValue) {
    value = payload.selectedLookupValue.value;
  } else if (payload.query && field.name === payload.fieldForLookup.field.name) {
    value = payload.query;
  } else {
    value = properties?.default;
  }
  return {
    name: field.name,
    value,
  };
}

automationCommonHelpers = {
  getAnythingByKey,
  getAvailableAutomationEntities,
  isAutomationDialogMode,
  convertColumnsToDatagridColumns,
  getDynamicValuesColumnsHelper,
  getDynamicValuesColumnsByNameHelper,
  getFilteredTestAttributes,
  getDataListForTesting,
  getAutomationCategory,
  isAutomationCategoryOnlySupportsIncomingData,
  getValueForDisplay,
  convertMetaFormFieldsForDisplay,
  cronExpressionDataFromScheduleOrDefault,
  isAutomationConnectorActionValid,
  getAutomationValid,
  getLookupFieldNameValue,
  getIrrecoverableConnectorActionsFromAutomation,
};
