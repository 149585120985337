/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject, PagedRequest, RuleGroupOperator, SortOn } from '@dpa/ui-common';
import { GridsterItem } from 'angular-gridster2';
import { cloneDeep, each, findLast, get, isUndefined, keyBy, last, mapValues, orderBy, replace, size, uniq, uniqBy } from 'lodash-es';

import {
  AggregationFunction,
  AggregationWidget,
  AggregationWidgetChartType,
  BucketSelection,
  Category,
  CategoryIndex,
  ChartDrilldownEvent,
  Column,
  COLUMN_NAMES,
  ColumnIndex,
  ComparisonQueryResponse,
  CustomReportPreviewSearchResponse,
  DashboardConfig,
  Entity,
  FilterRule,
  FocusedSeries,
  getUniqueId,
  Integration,
  isNumberDataType,
  LodashSortOrder,
  PreviewReportContentRequest,
  QueryBuilder,
  RuleGroup,
  StandardWidgetSubtype,
  Trend,
  TrendDateRange,
  TrendDefinition,
  TrendDefinitionDrilldownApplier,
  TrendMode,
  TrendResult,
  WidgetDataRequest,
  WidgetDetailDefinition,
  WidgetDetailPageSkinType,
  WidgetPosition,
  WidgetTemplate,
  WidgetUpdateDataRequest,
  WidgetWizardDialogMode,
} from '@ws1c/intelligence-models';

/**
 * Export helpers to support spy on functions
 */
export const helpers = {
  getInitialWizardWidget,
  isValidWigetData,
  isValidWiget,
  isValidTrendDefinition,
  getHistoricalChartOptionVisible,
  isSnapshotChartOptionVisible,
  getDefaultedPagedRequest,
  getDefaultedPagedRequestMedium,
  getFirstCounterValue,
  getCounterValue,
  getBucketCountValue,
  getBucketingAttributeValue,
  getFirstCounterValueFromSnapshot,
  getOffsetCounterData,
  getLastCount,
  getSummaryCounter,
  getComparisonQueryResponse,
  overrideWidgetDetailDefinition,
  applyDrilldownEventsToTrendDefinition,
  comprehensiveSpecifierAttributeValue,
  getWidgetDetailCveId,
  getWidgetDetailUserFlowName,
  injectAutomationFlagsIntoTrendDefinition,
  injectAutomationFlagsIntoTrend,
  getChartTypesById,
  getInvertModeById,
  getWidgetItem,
  getWidgetDetailTrendDefinition,
  getWidgetsDefaultTableColumnNames,
  getMostRecent24HourTrendResult,
  getWidgetDetailTablePreviewRequest,
  getTrendDefinitionsByStandardWidgetSubtypeWithOverrides,
  getVisibleWidgetSubtypes,
  getFilterSortPagedResponse,
  getWidgetPreviewTablePreviewRequest,
  getAvailableDashboardEntities,
  getWidgetDetailRuleGroup,
  removeColumnNameFromFilter,
  removeAppVersionFromScoreTrendDefs,
  getCustomeTableColumnNamesByCategoryIdAndSubtype,
  cleanFilterStrings,
  getCounterAttribute,
  getUniqMobileDevice,
};

/**
 * getInitialWizardWidget
 * @param {AggregationWidget} activeWidget
 * @param {WidgetTemplate} activeWidgetTemplate
 * @param {WidgetWizardDialogMode} wizardDialogMode
 * @returns {AggregationWidget}
 */
export function getInitialWizardWidget(
  activeWidget: AggregationWidget,
  activeWidgetTemplate: WidgetTemplate,
  wizardDialogMode: WidgetWizardDialogMode,
): AggregationWidget {
  if (wizardDialogMode === WidgetWizardDialogMode.EDIT) {
    return activeWidget;
  }
  if (activeWidgetTemplate) {
    return Object.assign(new AggregationWidget(), activeWidgetTemplate, {
      trend: new Trend({
        trendDefinition: activeWidgetTemplate.trendDefinition,
      }),
    });
  }
}

/**
 * isValidTrendDefinition
 * @export
 * @param {TrendDefinition} trendDefinition
 * @param {ColumnIndex} columns
 * @param {string} chartType
 * @returns {boolean}
 */
export function isValidTrendDefinition(trendDefinition: TrendDefinition, columns: ColumnIndex, chartType: string): boolean {
  if (!trendDefinition) {
    return false;
  }
  const counterDefinition = trendDefinition.counterDefinitions?.[0];
  if (!counterDefinition?.aggregateAttribute || !counterDefinition?.aggregationFunction) {
    return false;
  }
  const column: Column = columns?.[counterDefinition.aggregateAttribute];
  if (
    counterDefinition.aggregationFunction === AggregationFunction.LATEST &&
    column?.dataType &&
    !isNumberDataType(column.dataType) &&
    chartType !== AggregationWidgetChartType.TABLE
  ) {
    return false;
  }
  if (trendDefinition.trendMode !== TrendMode[TrendMode.SNAPSHOT] && !trendDefinition.dateRange) {
    return false;
  }
  return true;
}

/**
 * Check whether widget data is valid or not
 *
 * @export
 * @param {WidgetUpdateDataRequest} widgetUpdateData
 * @param {WidgetDataRequest} widgetDataPreview
 * @returns {boolean}
 */
export function isValidWigetData(widgetUpdateData: WidgetUpdateDataRequest, widgetDataPreview: WidgetDataRequest): boolean {
  if (!widgetUpdateData || !widgetUpdateData.widget || !widgetDataPreview) {
    return false;
  }
  const widget = widgetUpdateData.widget;
  if (!widget.name || !widget.chartType) {
    return false;
  }
  return widgetDataPreview.success;
}

/**
 * isValidWiget
 * @export
 * @param {AggregationWidget} widget
 * @param {WidgetDataRequest} widgetDataPreview
 * @returns {boolean}
 */
export function isValidWiget(widget: AggregationWidget, widgetDataPreview: WidgetDataRequest): boolean {
  if (!widget || !widgetDataPreview) {
    return false;
  }
  if (!widget.name || !widget.chartType) {
    return false;
  }
  return widgetDataPreview.success;
}

/**
 * getHistoricalChartOptionVisible
 *
 * @export
 * @param {boolean} supportsHistoricalChart
 * @param {CategoryIndex} categoryIndex
 * @param {AggregationWidget} activeWidget
 * @param {WidgetTemplate} activeWidgetTemplate
 * @returns {boolean}
 */
export function getHistoricalChartOptionVisible(
  supportsHistoricalChart: boolean,
  categoryIndex: CategoryIndex,
  activeWidget: AggregationWidget,
  activeWidgetTemplate: WidgetTemplate,
): boolean {
  if (!activeWidgetTemplate && !activeWidget) {
    return false;
  }
  let trendDefinition: TrendDefinition = null;
  if (activeWidgetTemplate && activeWidgetTemplate.trendDefinition) {
    trendDefinition = activeWidgetTemplate.trendDefinition;
  } else if (activeWidget && activeWidget.trend && activeWidget.trend.trendDefinition) {
    trendDefinition = activeWidget.trend.trendDefinition;
  }
  let widgetCategory: Category = null;
  if (trendDefinition && trendDefinition.categoryId) {
    widgetCategory = categoryIndex[trendDefinition.categoryId];
  }
  return (
    supportsHistoricalChart &&
    widgetCategory &&
    widgetCategory.supportedTrendModes &&
    widgetCategory.supportedTrendModes.includes(TrendMode[TrendMode.HISTORICAL])
  );
}

/**
 * isSnapshotChartOptionVisible
 *
 * @export
 * @param {CategoryIndex} categoryIndex
 * @param {AggregationWidget} activeWidget
 * @param {WidgetTemplate} activeWidgetTemplate
 * @returns {boolean}
 */
export function isSnapshotChartOptionVisible(
  categoryIndex: CategoryIndex,
  activeWidget: AggregationWidget,
  activeWidgetTemplate: WidgetTemplate,
): boolean {
  if (!activeWidgetTemplate && !activeWidget) {
    return false;
  }
  let trendDefinition: TrendDefinition = null;
  if (activeWidgetTemplate && activeWidgetTemplate.trendDefinition) {
    trendDefinition = activeWidgetTemplate.trendDefinition;
  } else if (activeWidget && activeWidget.trend && activeWidget.trend.trendDefinition) {
    trendDefinition = activeWidget.trend.trendDefinition;
  }
  const widgetCategory: Category = trendDefinition ? categoryIndex[trendDefinition.categoryId] : null;
  return widgetCategory ? widgetCategory.supportedTrendModes.includes(TrendMode[TrendMode.SNAPSHOT]) : false;
}

/**
 * getDefaultedPagedRequest
 * @param {PagedRequest} pagedRequest
 * @returns {PagedRequest}
 */
export function getDefaultedPagedRequest(pagedRequest: PagedRequest): PagedRequest {
  return (
    pagedRequest ||
    new PagedRequest({
      from: 0,
      size: 10,
    })
  );
}

/**
 * getDefaultedPagedRequestMedium
 * @param {PagedRequest} pagedRequest
 * @returns {PagedRequest}
 */
export function getDefaultedPagedRequestMedium(pagedRequest: PagedRequest): PagedRequest {
  return (
    pagedRequest ||
    new PagedRequest({
      from: 0,
      size: 25,
    })
  );
}

/**
 * getFirstCounterValue
 * @param {Trend} trend
 * @returns {T}
 * @export
 */
export function getFirstCounterValue<T = number>(trend: Trend): T {
  return getCounterValue(trend, 0, 0);
}

/**
 * getCounterValue
 * @param {Trend} trend
 * @param {number} counterIndex
 * @param {number} resultIndex
 * @returns {T}
 * @export
 */
export function getCounterValue<T = number>(trend: Trend, counterIndex: number, resultIndex: number): T {
  return get(trend, ['trendResults', resultIndex, 'counters', counterIndex, 'result', 'value']);
}

/**
 * getCounterAttribute
 * @param {Trend} trend
 * @param {number} counterIndex
 * @returns {string}
 * @export
 */
export function getCounterAttribute(trend: Trend, counterIndex: number): string {
  return get(trend, ['trendDefinition', 'counterDefinitions', counterIndex, 'aggregateAttribute']);
}

/**
 * getBucketingAttributeValue
 * @export
 * @param {Trend} trend
 * @param {number} bucketingAttributeIndex
 * @returns {any}
 */
export function getBucketingAttributeValue(trend: Trend, bucketingAttributeIndex: number): any {
  return get(trend, ['trendResults', '0', 'bucketingAttributes', bucketingAttributeIndex, 'value']);
}

/**
 * getBucketCountValue
 * @export
 * @param {Trend} trend
 * @param {string[]} bucketIndex
 * @returns {{ [key: string]: number; total: number }}
 */
export function getBucketCountValue(trend: Trend, bucketIndex: string[]): { [key: string]: number; total: number } {
  let total = 0;
  const stat = {};
  bucketIndex.forEach((nameIndex: string) => {
    const key = nameIndex.toLowerCase();
    stat[key] = 0;
    const trendResult: TrendResult = trend.trendResults?.find((result: TrendResult) => {
      return result.bucketingAttributes[0].value === nameIndex;
    });
    if (trendResult) {
      stat[key] = trendResult.counters[0].result.value;
      total += stat[key];
    }
  });
  return {
    ...stat,
    total,
  };
}

/**
 * getFirstCounterValueFromSnapshot
 * @param {Map<string, Trend>} standardDashboardData
 * @param {StandardWidgetSubtype} widgetSubtype
 * @returns {number}
 * @export
 */
export function getFirstCounterValueFromSnapshot(standardDashboardData: Map<string, Trend>, widgetSubtype: StandardWidgetSubtype): number {
  return getFirstCounterValue(standardDashboardData.get(StandardWidgetSubtype[widgetSubtype]));
}

/**
 * getOffsetCounterData
 * @param  {Map<string, Trend>} standardDashboardData
 * @param  {StandardWidgetSubtype} offsetSubType
 * @param  {StandardWidgetSubtype} subType
 * @param  {StandardWidgetSubtype} sparklineSubType
 * @returns {ComparisonQueryResponse}
 */
export function getOffsetCounterData(
  standardDashboardData: Map<string, Trend>,
  offsetSubType: StandardWidgetSubtype,
  subType: StandardWidgetSubtype,
  sparklineSubType?: StandardWidgetSubtype,
): ComparisonQueryResponse {
  const offsetTrend: Trend = standardDashboardData.get(StandardWidgetSubtype[offsetSubType]);
  const offsetLastCount = getLastCount(offsetTrend);
  const trend: Trend = standardDashboardData.get(StandardWidgetSubtype[subType]);
  const sparkline: Trend = standardDashboardData.get(StandardWidgetSubtype[sparklineSubType]);
  const lastCount = getLastCount(trend);
  return Object.assign(getComparisonQueryResponse(offsetLastCount, lastCount), { sparkline, trend });
}

/**
 * getLastCount
 * @param {Trend} trend
 * @returns {number|undefined}
 */
export function getLastCount(trend: Trend): number | undefined {
  if (!trend || !trend.hasTrendResults) {
    // return undefined while data request is in-flight for gradient loading would pick up
    // fyi counter trend always returns one empty result w/ 0 if there's none found
    return;
  }
  return last(trend.trendResults).counters[0].result.value || 0;
}

/**
 * getSummaryCounter
 * @param  {Map<string, Trend>} standardDashboardData
 * @param  {StandardWidgetSubtype} subType
 * @returns {ComparisonQueryResponse}
 * @export
 */
export function getSummaryCounter(standardDashboardData: Map<string, Trend>, subType: StandardWidgetSubtype): ComparisonQueryResponse {
  const summaryCounter: Trend = standardDashboardData.get(StandardWidgetSubtype[subType]);
  if (!summaryCounter || !summaryCounter.hasTrendResults) {
    return {
      firstCount: 0,
      lastCount: 0,
      ratio: 0,
    } as ComparisonQueryResponse;
  }
  const firstResult: TrendResult = summaryCounter.trendResults[0];
  const lastResult: TrendResult = summaryCounter.trendResults[summaryCounter.trendResults.length - 1];
  const firstCount = firstResult.counters[0].result.value;
  const lastCount = lastResult.counters[0].result.value;
  return getComparisonQueryResponse(firstCount, lastCount);
}

/**
 * getComparisonQueryResponse
 * @param {number} firstCount
 * @param {number} lastCount
 * @returns {ComparisonQueryResponse}
 */
export function getComparisonQueryResponse(firstCount: number, lastCount: number): ComparisonQueryResponse {
  return {
    firstCount,
    lastCount,
    ratio: firstCount ? (lastCount - firstCount) / Math.abs(firstCount) : 1,
  } as ComparisonQueryResponse;
}

/**
 * getUniqMobileDevice
 * @param {CustomReportPreviewSearchResponse} response
 * @param {Trend} trend
 * @returns {CustomReportPreviewSearchResponse}
 */
export function getUniqMobileDevice(response: CustomReportPreviewSearchResponse, trend: Trend): CustomReportPreviewSearchResponse {
  if (response?.total >= 0 && trend?.hasTrendDefinition) {
    const trendToResponse = new CustomReportPreviewSearchResponse({ size: 0, from: 0, total: 0 });
    trend?.trendResults?.forEach((result: TrendResult) => {
      trendToResponse.results.push({
        [COLUMN_NAMES.byFullyQualifiedName.airwatch_device_airwatch_device_guid]: result?.counters?.[0]?.result?.value,
        [COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_friendly_name]: result?.counters?.[1]?.result?.value,
        [COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_last_seen]: result?.counters?.[2]?.result?.value,
        [COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_platform]: result?.counters?.[3]?.result?.value,
        [COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_model]: result?.counters?.[4]?.result?.value,
        [COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_os_version]: result?.counters?.[5]?.result?.value,
        [COLUMN_NAMES.byName.integration]: Integration.APTELIGENT,
      });
    });
    response.results = uniqBy(
      [...response.results, ...trendToResponse.results],
      COLUMN_NAMES.byFullyQualifiedName.airwatch_device_airwatch_device_guid,
    );
    response.size = response.total = response.results.length;
  }
  return response;
}

/**
 * injectAutomationFlagsIntoTrendDefinition
 * @export
 * @param {TrendDefinition} trendDefinition
 * @param {CategoryIndex} categoryMap
 * @param {boolean} automationEnabled
 * @returns {TrendDefinition}
 */
export function injectAutomationFlagsIntoTrendDefinition(
  trendDefinition: TrendDefinition,
  categoryMap: CategoryIndex,
  automationEnabled: boolean,
): TrendDefinition {
  const trendCategory: Category = trendDefinition ? categoryMap[trendDefinition.categoryId] : undefined;
  const isCategoryAutomationSupported = !!trendCategory && trendCategory.supportsAutomation;
  return Object.assign(new TrendDefinition(), trendDefinition, {
    automationEnabled,
    isCategoryAutomationSupported,
  });
}

/**
 * injectAutomationFlagsIntoTrend
 * @export
 * @param {Trend} trend
 * @param {CategoryIndex} categoryMap
 * @param {boolean} isAutomationEnabled
 * @returns {Trend}
 */
export function injectAutomationFlagsIntoTrend(trend: Trend, categoryMap: CategoryIndex, isAutomationEnabled: boolean): Trend {
  return Object.assign(new Trend(), trend, {
    trendDefinition: helpers.injectAutomationFlagsIntoTrendDefinition(trend.trendDefinition, categoryMap, isAutomationEnabled),
  });
}

/**
 * getChartTypesById
 * @param {Record<string, AggregationWidget>} widgetsById
 * @param {Record<string, ChartDrilldownEvent[]>} drilldownEventsById
 * @returns {Record<string, AggregationWidgetChartType>}
 */
export function getChartTypesById(
  widgetsById: Record<string, AggregationWidget>,
  drilldownEventsById: Record<string, ChartDrilldownEvent[]>,
): Record<string, AggregationWidgetChartType> {
  // chartType information can come from the original widget object or from drilldownEvents
  const chartIdsWithChartTypeInfo = [...Object.keys(widgetsById), ...Object.keys(drilldownEventsById)];
  const chartTypesById = {};
  chartIdsWithChartTypeInfo.forEach((chartId: string) => {
    // gets default chart type info from widget
    const widget = widgetsById[chartId];
    const defaultChartType = widget && widget.chartType;

    // get override chart type info from drilldown events
    const drilldownEvents = drilldownEventsById[chartId] || [];
    const setChartTypeEvent = findLast(drilldownEvents, (chartDrilldownEvent: ChartDrilldownEvent) => !!chartDrilldownEvent.setChartType);
    const chartTypeOverride = setChartTypeEvent?.setChartType;

    chartTypesById[chartId] = AggregationWidgetChartType[chartTypeOverride || defaultChartType];
  });
  return chartTypesById;
}

/**
 * getInvertModeById
 * @export
 * @param {Record<string, AggregationWidget>} widgetsById
 * @param {Record<string, ChartDrilldownEvent[]>} drilldownEventsById
 * @returns {Record<string, boolean>}
 */
export function getInvertModeById(
  widgetsById: Record<string, AggregationWidget>,
  drilldownEventsById: Record<string, ChartDrilldownEvent[]>,
): Record<string, boolean> {
  // chartType information can come from the original widget object or from drilldownEvents
  const chartIdsWithInvertModeInfo = [...Object.keys(widgetsById), ...Object.keys(drilldownEventsById)];
  const invertModeById = {};
  chartIdsWithInvertModeInfo.forEach((chartId: string) => {
    const drilldownEvents = drilldownEventsById[chartId] || [];
    const setChartTypeEvent = findLast(
      drilldownEvents,
      (chartDrilldownEvent: ChartDrilldownEvent) => !isUndefined(chartDrilldownEvent.isInvertMode),
    );

    invertModeById[chartId] = setChartTypeEvent && setChartTypeEvent.isInvertMode;
  });
  return invertModeById;
}

/**
 * getWidgetItem
 * updates gridsterItem.widget topLeft and bottomLeft attributes to match GridsterItem
 * @param {GridsterItem} item
 * @param {CategoryIndex} categoryMap
 * @param {boolean} isAutomationEnabled
 * @param {Record<string, Column[]>} columnsByCategoryId
 * @param {RuleGroup} ruleGroup
 * @param {TrendDateRange} trendDateRange
 * @returns {GridsterItem}
 */
export function getWidgetItem(
  item: GridsterItem,
  categoryMap: CategoryIndex,
  isAutomationEnabled: boolean,
  columnsByCategoryId: Record<string, Column[]>,
  ruleGroup: RuleGroup,
  trendDateRange: TrendDateRange,
): GridsterItem {
  const topLeft = new WidgetPosition({
    columnNumber: item.x,
    rowNumber: item.y,
  });
  const bottomRight = new WidgetPosition({
    columnNumber: item.x + item.cols,
    rowNumber: item.y + item.rows,
  });
  const widget = new AggregationWidget({
    ...item.widget,
    topLeft,
    bottomRight,
    trend: helpers.injectAutomationFlagsIntoTrend(item.widget.trend, categoryMap, isAutomationEnabled),
  });
  // global filter for custom dashboard view
  if (ruleGroup?.rules.length) {
    const columns = columnsByCategoryId[widget.trend.trendDefinition.categoryId];
    const found = columns?.filter((col: Column) =>
      [
        COLUMN_NAMES.byName.device_location_group_name,
        COLUMN_NAMES.byName.device_location_group_lineage_list,
        COLUMN_NAMES.byName._airwatch_device__device_location_group_name,
        COLUMN_NAMES.byName._airwatch_device__device_location_group_lineage_list,
      ].includes(col.name),
    );
    if (found?.length) {
      const integration = widget.trend.trendDefinition?.integration;
      const entity = widget.trend.trendDefinition?.entity;
      ruleGroup = RuleGroup.transformGroupForAllCategories(
        ruleGroup,
        integration,
        entity,
        keyBy(columns, COLUMN_NAMES.byName.attributeName),
      );
      const addedFilter = new QueryBuilder(ruleGroup).getQueryString();
      if (widget.trend.trendDefinition.filter && addedFilter) {
        widget.trend.trendDefinition.filter = widget.trend.trendDefinition.filter.concat(` ${RuleGroupOperator.AND} ${addedFilter}`);
      } else if (!widget.trend.trendDefinition.filter) {
        widget.trend.trendDefinition.filter = addedFilter;
      }
    }
  }
  // global filter date range for custom dashboard view
  if (trendDateRange && widget.trend.trendDefinition.trendMode !== TrendMode[TrendMode.SNAPSHOT]) {
    widget.trend.trendDefinition.dateRange = Object.assign(new TrendDateRange(), widget.trend.trendDefinition.dateRange, {
      samplingFrequency:
        widget.trend.trendDefinition.dateRange?.rollingWindow || !widget.trend.trendDefinition.dateRange?.samplingFrequency
          ? undefined
          : trendDateRange.samplingFrequency,
      trendSpan: !widget.trend.trendDefinition.dateRange?.trendSpan ? undefined : trendDateRange.trendSpan,
      startDateMillis: trendDateRange.startDateMillis ?? widget.trend.trendDefinition.dateRange?.startDateMillis,
      endDateMillis: trendDateRange.endDateMillis ?? widget.trend.trendDefinition.dateRange?.endDateMillis,
    });
  }
  return {
    ...item,
    widget,
  } as GridsterItem;
}

/**
 * overrideWidgetDetailDefinition
 * A custom widget from the API can override values in the widgetDetailDefinitionFromParams
 * @export
 * @param {WidgetDetailDefinition} widgetDetailDefinitionFromParams
 * @param {AggregationWidget} widget
 * @returns {WidgetDetailDefinition}
 */
export function overrideWidgetDetailDefinition(
  widgetDetailDefinitionFromParams: WidgetDetailDefinition,
  widget: AggregationWidget,
): WidgetDetailDefinition {
  const widgetDetailOverrides: Partial<WidgetDetailDefinition> = widget
    ? {
        id: widget.id,
        widgetId: widget.id,
        dashboardId: widget.dashboardId,
        dashboardName: widget.dashboardName,
        chartTitle: widget.name,
        trendDefinition: widgetDetailDefinitionFromParams?.trendDefinition || widget.trend?.trendDefinition,
        returnCrumbs: widgetDetailDefinitionFromParams?.returnCrumbs,
        chartType: AggregationWidgetChartType[widget.chartType],
        showSeriesNames: true,
        showTable: widget.trend?.trendDefinition?.trendMode !== TrendMode[TrendMode.SNAPSHOT_PERIODICAL],
        disableDrilldown: widget.trend?.trendDefinition?.trendMode === TrendMode[TrendMode.SNAPSHOT_PERIODICAL],
        overlayTrendDefinition: widget?.overlayTrend?.trendDefinition,
      }
    : {};
  return Object.assign(new WidgetDetailDefinition(), widgetDetailDefinitionFromParams, widgetDetailOverrides);
}

/**
 * applyDrilldownEventsToTrendDefinition
 * @param {TrendDefinition} trendDefinition
 * @param {ChartDrilldownEvent[]} chartDrilldownEvents
 * @returns {TrendDefinition}
 */
export function applyDrilldownEventsToTrendDefinition(
  trendDefinition: TrendDefinition,
  chartDrilldownEvents: ChartDrilldownEvent[],
): TrendDefinition {
  const tdda = new TrendDefinitionDrilldownApplier();
  return tdda.applyDrilldownEventsToTrendDefinition(trendDefinition, chartDrilldownEvents);
}

/**
 * comprehensiveSpecifierAttributeValue
 * @param {RuleGroup} ruleGroup
 * @param {string} attributeName
 * @returns {any}
 */
export function comprehensiveSpecifierAttributeValue(ruleGroup: RuleGroup, attributeName: string) {
  const collectionConditions = new Set([FilterRule.FILTER_CONDITION.contains, FilterRule.FILTER_CONDITION.includes]);
  const attributeFilterRule = findLast(ruleGroup.rules, (filterRule: FilterRule) => {
    if (filterRule.attribute !== attributeName) {
      return false;
    }
    if (filterRule.condition === FilterRule.FILTER_CONDITION.equals) {
      return true;
    }
    if (collectionConditions.has(filterRule.condition) && filterRule.data.length === 1) {
      return true;
    }
  }) as FilterRule;
  return attributeFilterRule?.data[0];
}

/**
 * getWidgetDetailCveId
 * @param {ChartDrilldownEvent[]} widgetDetailDrilldownEvents
 * @returns {string}
 */
export function getWidgetDetailCveId(widgetDetailDrilldownEvents: ChartDrilldownEvent[]): string {
  const eventsWithId = widgetDetailDrilldownEvents
    .map((widgetDetailDrilldownEvent: ChartDrilldownEvent) => {
      const selectedBuckets: BucketSelection[] = widgetDetailDrilldownEvent.selectedBuckets;
      return selectedBuckets && selectedBuckets.find((selectedBucket: BucketSelection) => selectedBucket.bucketName === 'cve_id_list');
    })
    .filter(Boolean);

  if (eventsWithId.length) {
    return last(eventsWithId).selectedValue;
  }
}

/**
 * getWidgetDetailUserFlowName
 * @param {ChartDrilldownEvent[]} widgetDetailDrilldownEvents
 * @returns {string}
 */
export function getWidgetDetailUserFlowName(widgetDetailDrilldownEvents: ChartDrilldownEvent[]): string {
  const flatSelectedBuckets = [];
  widgetDetailDrilldownEvents.forEach((drilldownEvent: ChartDrilldownEvent) => {
    flatSelectedBuckets.push(...(drilldownEvent.selectedBuckets || []));
  });
  const nameBucket = findLast(flatSelectedBuckets, (selectedBucket: BucketSelection) => selectedBucket.bucketName === 'name');
  return nameBucket && nameBucket.selectedValue;
}

/**
 * getWidgetDetailTrendDefinition
 * @param {TrendDefinition} initialTrendDefinition
 * @returns {TrendDefinition}
 */
export function getWidgetDetailTrendDefinition(initialTrendDefinition: TrendDefinition) {
  const trendDefinition = new TrendDefinition({
    ...(initialTrendDefinition || {}),
  });
  const dateRange = trendDefinition.dateRange;
  if (dateRange && (dateRange.startDateMillis || dateRange.endDateMillis)) {
    trendDefinition.dateRange = new TrendDateRange({
      ...dateRange,
      trendSpan: undefined,
    });
  }
  const trendMode = trendDefinition.trendMode;
  if (trendMode === TrendMode[TrendMode.SNAPSHOT]) {
    trendDefinition.dateRange = undefined;
  }
  return trendDefinition;
}

/**
 * getWidgetsDefaultTableColumnNames
 * @param {string = ''} categoryId
 * @returns {string[]}
 */
// eslint-disable-next-line
export function getWidgetsDefaultTableColumnNames(categoryId: string = ''): string[] {
  switch (categoryId) {
    case getUniqueId(Integration.AIRWATCH, Entity.APPLICATION):
      return [
        COLUMN_NAMES.byName.app_name,
        COLUMN_NAMES.byName.app_modification_date,
        COLUMN_NAMES.byName.app_package_id,
        COLUMN_NAMES.byName.app_version,
        COLUMN_NAMES.byName.app_is_installed,
        COLUMN_NAMES.byName.app_install_status,
        COLUMN_NAMES.byName.device_id,
        COLUMN_NAMES.byName.device_name,
        COLUMN_NAMES.byName._device_platform,
        COLUMN_NAMES.byName.device_os_version,
      ];
    case getUniqueId(Integration.AIRWATCH, Entity.APPLICATION_CHANGE_EVENT):
      return [
        COLUMN_NAMES.byName.app_name,
        COLUMN_NAMES.byName.event_timestamp,
        COLUMN_NAMES.byName.event_type,
        COLUMN_NAMES.byName.app_package_id,
        COLUMN_NAMES.byName.app_version,
        COLUMN_NAMES.byName.app_is_installed,
        COLUMN_NAMES.byName.app_install_status,
        COLUMN_NAMES.byName.device_id,
        COLUMN_NAMES.byName.device_platform,
        COLUMN_NAMES.byName.device_os_version,
      ];
    case getUniqueId(Integration.AIRWATCH, Entity.APP_FEEDBACK):
      return [
        COLUMN_NAMES.byName.device_google_device_id,
        COLUMN_NAMES.byName.device_google_user_id,
        COLUMN_NAMES.byName.app_package_id,
        COLUMN_NAMES.byName.app_state_key,
        COLUMN_NAMES.byName.app_state_severity,
        COLUMN_NAMES.byName.app_state_data,
        COLUMN_NAMES.byName.app_state_message,
        COLUMN_NAMES.byName.app_state_last_updated,
      ];
    case getUniqueId(Integration.AIRWATCH, Entity.DEVICE):
      return [
        COLUMN_NAMES.byName.device_id,
        COLUMN_NAMES.byName.device_friendly_name,
        COLUMN_NAMES.byName.device_last_seen,
        COLUMN_NAMES.byName.device_model,
        COLUMN_NAMES.byName._device_platform,
        COLUMN_NAMES.byName.device_os_version,
      ];
    case getUniqueId(Integration.AIRWATCH, Entity.DEVICE_CUSTOM_ATTRIBUTES):
      return [COLUMN_NAMES.byName.device_guid];
    case getUniqueId(Integration.AIRWATCH, Entity.DEVICE_CHANGE_EVENT):
      return [
        COLUMN_NAMES.byName.device_id,
        COLUMN_NAMES.byName.device_friendly_name,
        COLUMN_NAMES.byName.event_timestamp,
        COLUMN_NAMES.byName.event_type,
        COLUMN_NAMES.byName.device_model,
        COLUMN_NAMES.byName.device_platform,
        COLUMN_NAMES.byName.device_os_version,
      ];
    case getUniqueId(Integration.AIRWATCH, Entity.DEVICESENSORS):
      return [COLUMN_NAMES.byName.device_guid];
    case getUniqueId(Integration.AIRWATCH, Entity.DEVICE_RISK_SCORE):
      return [
        COLUMN_NAMES.byName.device_friendly_name,
        COLUMN_NAMES.byName.device_udid,
        COLUMN_NAMES.byName.score_severity,
        COLUMN_NAMES.byName.score_type_name,
        COLUMN_NAMES.byName._device_platform,
        COLUMN_NAMES.byName.score_calculated_at,
      ];
    case getUniqueId(Integration.AIRWATCH, Entity.MACOSUPDATE):
      return [
        COLUMN_NAMES.byName.device_id,
        COLUMN_NAMES.byName.device_friendly_name,
        COLUMN_NAMES.byName.device_os_version,
        COLUMN_NAMES.byName.device_os_version_build_version,
        COLUMN_NAMES.byName.macos_update_available_os_update_name,
        COLUMN_NAMES.byName.macos_update_product_key,
        COLUMN_NAMES.byName.macos_update_update_version,
        COLUMN_NAMES.byName.macos_update_build_version,
      ];
    case getUniqueId(Integration.AIRWATCH, Entity.PRODUCT):
      return [
        COLUMN_NAMES.byName.product_name,
        COLUMN_NAMES.byName.product_type,
        COLUMN_NAMES.byName.product_job_id,
        COLUMN_NAMES.byName.product_job_log,
        COLUMN_NAMES.byName.product_active,
        COLUMN_NAMES.byName.device_location_group_name,
        COLUMN_NAMES.byName.device_id,
        COLUMN_NAMES.byName.device_friendly_name,
        COLUMN_NAMES.byName.device_os_version,
        COLUMN_NAMES.byName._device_platform,
      ];
    case getUniqueId(Integration.AIRWATCH, Entity.PROFILE):
      return [
        COLUMN_NAMES.byName.profile_name,
        COLUMN_NAMES.byName.device_id,
        COLUMN_NAMES.byName.device_friendly_name,
        COLUMN_NAMES.byName.device_enrollment_user_name,
        COLUMN_NAMES.byName.device_platform,
        COLUMN_NAMES.byName.device_model,
        COLUMN_NAMES.byName.profile_assigned_version,
        COLUMN_NAMES.byName.profile_installed_version,
        COLUMN_NAMES.byName.profile_installed_status,
        COLUMN_NAMES.byName.profile_status,
        COLUMN_NAMES.byName.profile_is_managed,
        COLUMN_NAMES.byName.profile_is_active,
        COLUMN_NAMES.byName.device_enrollment_status,
        COLUMN_NAMES.byName.adp_modified_at,
      ];
    case getUniqueId(Integration.AIRWATCH, Entity.USER_RISK_SCORE):
      return [
        COLUMN_NAMES.byName.device_enrollment_user_display_name,
        COLUMN_NAMES.byName.device_count,
        COLUMN_NAMES.byName.score_severity,
        COLUMN_NAMES.byName.score_calculated_at,
      ];
    case getUniqueId(Integration.AIRWATCH, Entity.WINDOWSPATCH):
      return [
        COLUMN_NAMES.byName.device_guid,
        COLUMN_NAMES.byName.device_friendly_name,
        COLUMN_NAMES.byName.device_model,
        COLUMN_NAMES.byName.device_os_version,
        COLUMN_NAMES.byName.winpatch_kb_number,
        COLUMN_NAMES.byName.winpatch_revision_id,
        COLUMN_NAMES.byName.winpatch_kb_subject,
        COLUMN_NAMES.byName.winpatch_update_status,
        COLUMN_NAMES.byName.winpatch_approval_status,
        COLUMN_NAMES.byName.winpatch_update_classification,
      ];
    case getUniqueId(Integration.AIRWATCH, Entity.WINDOWSPATCH_CHANGE_EVENT):
      return [
        COLUMN_NAMES.byName.device_guid,
        COLUMN_NAMES.byName.device_friendly_name,
        COLUMN_NAMES.byName.event_timestamp,
        COLUMN_NAMES.byName.event_type,
        COLUMN_NAMES.byName.device_model,
        COLUMN_NAMES.byName.device_os_version,
        COLUMN_NAMES.byName.winpatch_kb_number,
        COLUMN_NAMES.byName.winpatch_revision_id,
        COLUMN_NAMES.byName.winpatch_update_status,
      ];
    case getUniqueId(Integration.APTELIGENT, Entity.APPLOAD):
      return [
        COLUMN_NAMES.byName.platform,
        COLUMN_NAMES.byName.system_version,
        COLUMN_NAMES.byName._app_version,
        COLUMN_NAMES.byName._device_friendly_name,
        COLUMN_NAMES.byName.carrier,
      ];
    case getUniqueId(Integration.APTELIGENT, Entity.USER_FLOW):
      return [
        COLUMN_NAMES.byName._state_name,
        COLUMN_NAMES.byName.name,
        COLUMN_NAMES.byName.active_time_millis,
        COLUMN_NAMES.byName._device_friendly_name,
        COLUMN_NAMES.byName.user_name,
        COLUMN_NAMES.byName._app_version,
        COLUMN_NAMES.byName.device_model,
        COLUMN_NAMES.byName.platform,
        COLUMN_NAMES.byName.device_uuid,
        COLUMN_NAMES.byName.app_id,
        COLUMN_NAMES.byName.start_time,
        COLUMN_NAMES.byName.end_time,
      ];
    case getUniqueId(Integration.APTELIGENT, Entity.NET_EVENT):
      return [
        COLUMN_NAMES.byName._url,
        COLUMN_NAMES.byName._event_received_timestamp,
        COLUMN_NAMES.byName.http_status_code,
        COLUMN_NAMES.byName._http_status_message,
        COLUMN_NAMES.byName._latency,
        COLUMN_NAMES.byName.bytes_received,
        COLUMN_NAMES.byName.bytes_sent,
        COLUMN_NAMES.byName._app_version,
        COLUMN_NAMES.byName.system_version,
        COLUMN_NAMES.byName.carrier,
        COLUMN_NAMES.byName.device_model,
      ];
    case getUniqueId(Integration.APTELIGENT, Entity.NET_ERROR):
      return [
        COLUMN_NAMES.byName._url,
        COLUMN_NAMES.byName._event_received_timestamp,
        COLUMN_NAMES.byName._net_error_msg,
        COLUMN_NAMES.byName.bytes_sent,
        COLUMN_NAMES.byName._app_version,
        COLUMN_NAMES.byName.system_version,
        COLUMN_NAMES.byName.carrier,
        COLUMN_NAMES.byName.device_model,
      ];
    case getUniqueId(Integration.APTELIGENT, Entity.GROUPED_CRASH_ANDROID):
    case getUniqueId(Integration.APTELIGENT, Entity.GROUPED_CRASH_IOS):
    case getUniqueId(Integration.APTELIGENT, Entity.CRASH_ANDROID):
    case getUniqueId(Integration.APTELIGENT, Entity.CRASH_IOS):
    case getUniqueId(Integration.APTELIGENT, Entity.HANDLED_EXCEPTION_IOS):
    case getUniqueId(Integration.APTELIGENT, Entity.HANDLED_EXCEPTION_ANDROID):
    case getUniqueId(Integration.APTELIGENT, Entity.PLUGIN_EXCEPTION):
      return [
        COLUMN_NAMES.byName._error_name,
        COLUMN_NAMES.byName.error_reason,
        COLUMN_NAMES.byName._app_version,
        COLUMN_NAMES.byName._device_friendly_name,
        COLUMN_NAMES.byName.event_timestamp,
      ];
    case getUniqueId(Integration.CARBONBLACK, Entity.THREAT):
      return [
        COLUMN_NAMES.byName._alert_category,
        COLUMN_NAMES.byName.threatinfo_threatcause_causeeventid,
        COLUMN_NAMES.byName.deviceinfo_deviceid,
        COLUMN_NAMES.byName.deviceinfo_devicename,
        COLUMN_NAMES.byName.deviceinfo_deviceversion,
        COLUMN_NAMES.byName.eventdescription,
        COLUMN_NAMES.byName.eventtime,
        COLUMN_NAMES.byName.deviceinfo_externalipaddress,
        COLUMN_NAMES.byName.deviceinfo_internalipaddress,
        COLUMN_NAMES.byName.threatinfo_threatcause_actorprocessppid,
        COLUMN_NAMES.byName.threatinfo_score,
        COLUMN_NAMES.byName.threatinfo_threatcause_threatcategory,
        COLUMN_NAMES.byName.threatinfo_threatcause_actorname,
        COLUMN_NAMES.byName.threatinfo_threatcause_reason,
        COLUMN_NAMES.byName.threatinfo_summary,
        COLUMN_NAMES.byName.deviceinfo_uemid,
        COLUMN_NAMES.byName._event_id,
        COLUMN_NAMES.byName._event_type,
        COLUMN_NAMES.byName._device_os_version,
        COLUMN_NAMES.byName._device_platform,
        COLUMN_NAMES.byName._threat_id,
        COLUMN_NAMES.byName._threat_action,
        COLUMN_NAMES.byName._timestamp,
        COLUMN_NAMES.byName._airwatch_device_guid,
        COLUMN_NAMES.byName.threatinfo_threatcause_actor,
        COLUMN_NAMES.byName.threatinfo_threatcause_reputation,
        COLUMN_NAMES.byName.partner_name,
        COLUMN_NAMES.byName.event_timestamp,
        COLUMN_NAMES.byName._vmware_threat_type,
        COLUMN_NAMES.byName._threat_family,
        COLUMN_NAMES.byName._threat_severity,
      ];
    case getUniqueId(Integration.EMPLOYEE_EXPERIENCE, Entity.APPS):
      return [
        COLUMN_NAMES.byName.application,
        COLUMN_NAMES.byName.event_name,
        COLUMN_NAMES.byName.event_timestamp,
        COLUMN_NAMES.byName.exception_code,
        COLUMN_NAMES.byName.exception_offset,
        COLUMN_NAMES.byName.device_make,
        COLUMN_NAMES.byName.device_model,
        COLUMN_NAMES.byName.device_name,
        COLUMN_NAMES.byName.os_version,
        COLUMN_NAMES.byName.user,
        COLUMN_NAMES.byName._airwatch_device_guid,
        COLUMN_NAMES.byName.event_status,
      ];
    case getUniqueId(Integration.EMPLOYEE_EXPERIENCE, Entity.ASSET_INFO):
      return [
        COLUMN_NAMES.byName.processor,
        COLUMN_NAMES.byName.physical_memory_bytes,
        COLUMN_NAMES.byName.bios_version,
        COLUMN_NAMES.byName.bios_timestamp,
      ];
    case getUniqueId(Integration.EMPLOYEE_EXPERIENCE, Entity.DEVICES):
      return [
        COLUMN_NAMES.byName.event_name,
        COLUMN_NAMES.byName.user,
        COLUMN_NAMES.byName.device_make,
        COLUMN_NAMES.byName.device_model,
        COLUMN_NAMES.byName.device_name,
        COLUMN_NAMES.byName.os_version,
        COLUMN_NAMES.byName.module,
        COLUMN_NAMES.byName.error,
        COLUMN_NAMES.byName.event_timestamp,
        COLUMN_NAMES.byName._event_received_timestamp,
        COLUMN_NAMES.byName.event_status,
        COLUMN_NAMES.byName._airwatch_device_guid,
        COLUMN_NAMES.byName.duration_millis,
        COLUMN_NAMES.byName.pnp_load_duration_millis,
        COLUMN_NAMES.byName.main_pathload_duration_millis,
        COLUMN_NAMES.byName.perf_degradation,
        COLUMN_NAMES.byName.load_degradation_time_millis,
        COLUMN_NAMES.byName.winlogon_load_duration_millis,
        COLUMN_NAMES.byName.winlogon_waiting_duration_millis,
        COLUMN_NAMES.byName.system_session_millis,
        COLUMN_NAMES.byName.services_millis,
        COLUMN_NAMES.byName.user_session_millis,
      ];
    case getUniqueId(Integration.EMPLOYEE_EXPERIENCE, Entity.DEVICE_NETWORK_ADAPTER):
      return [
        COLUMN_NAMES.byName.device_name,
        COLUMN_NAMES.byName.device_model,
        COLUMN_NAMES.byName.device_serial_number,
        COLUMN_NAMES.byName.driver_name,
        COLUMN_NAMES.byName.driver_provider,
        COLUMN_NAMES.byName.driver_version,
        COLUMN_NAMES.byName.nic_type,
        COLUMN_NAMES.byName.nic_model,
        COLUMN_NAMES.byName.nic_description,
        COLUMN_NAMES.byName.nic_manufacturer,
      ];
    case getUniqueId(Integration.EMPLOYEE_EXPERIENCE, Entity.DEVICE_NET_EVENT):
      return [
        COLUMN_NAMES.byName.event_type,
        COLUMN_NAMES.byName.event_name,
        COLUMN_NAMES.byName.device_model,
        COLUMN_NAMES.byName.device_serial_number,
        COLUMN_NAMES.byName._device_platform,
        COLUMN_NAMES.byName._device_os_version,
        COLUMN_NAMES.byName.nic_type,
        COLUMN_NAMES.byName.nic_description,
      ];
    case getUniqueId(Integration.EMPLOYEE_EXPERIENCE, Entity.USER_ACTIONS):
      return [
        COLUMN_NAMES.byName._airwatch_device_guid,
        COLUMN_NAMES.byName.event_name,
        COLUMN_NAMES.byName.event_timestamp,
        COLUMN_NAMES.byName.event_status,
        COLUMN_NAMES.byName.device_name,
        COLUMN_NAMES.byName.os_version,
        COLUMN_NAMES.byName.user,
      ];
    case getUniqueId(Integration.EMPLOYEE_EXPERIENCE, Entity.SERVICES):
    case getUniqueId(Integration.EMPLOYEE_EXPERIENCE, Entity.OS_UPDATES):
      return [
        COLUMN_NAMES.byName._airwatch_device_guid,
        COLUMN_NAMES.byName.event_name,
        COLUMN_NAMES.byName.event_timestamp,
        COLUMN_NAMES.byName.event_status,
        COLUMN_NAMES.byName.device_name,
        COLUMN_NAMES.byName.os_version,
      ];
    case getUniqueId(Integration.IDM, Entity.LAUNCH):
      return [
        COLUMN_NAMES.byName.actorusername,
        COLUMN_NAMES.byName.actordomain,
        COLUMN_NAMES.byName.objectname,
        COLUMN_NAMES.byName.values_success,
        COLUMN_NAMES.byName.values_failuremessage,
        COLUMN_NAMES.byName.sourceip,
        COLUMN_NAMES.byName.event_type,
        COLUMN_NAMES.byName.event_timestamp,
      ];
    case getUniqueId(Integration.IDM, Entity.LOGIN):
      return [
        COLUMN_NAMES.byName.actorusername,
        COLUMN_NAMES.byName.actordomain,
        COLUMN_NAMES.byName.values_success,
        COLUMN_NAMES.byName.values_authmethods,
        COLUMN_NAMES.byName.values_failuremessage,
        COLUMN_NAMES.byName.sourceip,
        COLUMN_NAMES.byName.event_type,
        COLUMN_NAMES.byName.event_timestamp,
      ];
    case getUniqueId(Integration.IDM, Entity.LOGOUT):
      return [
        COLUMN_NAMES.byName.actorusername,
        COLUMN_NAMES.byName.actordomain,
        COLUMN_NAMES.byName.values_success,
        COLUMN_NAMES.byName.values_authmethods,
        COLUMN_NAMES.byName.sourceip,
        COLUMN_NAMES.byName.event_type,
        COLUMN_NAMES.byName.event_timestamp,
      ];
    case getUniqueId(Integration.CHECKPOINT, Entity.THREAT):
    case getUniqueId(Integration.CROWDSTRIKE, Entity.THREAT):
    case getUniqueId(Integration.LOOKOUT, Entity.THREAT):
    case getUniqueId(Integration.NETSKOPE, Entity.THREAT):
    case getUniqueId(Integration.PRADEO, Entity.THREAT):
    case getUniqueId(Integration.WANDERA, Entity.THREAT):
    case getUniqueId(Integration.ZIMPERIUM, Entity.THREAT):
    case getUniqueId(Integration.ZSCALER, Entity.THREAT):
      return [
        COLUMN_NAMES.byName.partner_name,
        COLUMN_NAMES.byName.event_timestamp,
        COLUMN_NAMES.byName._vmware_threat_type,
        COLUMN_NAMES.byName._threat_family,
        COLUMN_NAMES.byName._threat_severity,
      ];
    case getUniqueId(Integration.INTELLIGENCE, Entity.WORKFLOW_EXECUTION):
      return [
        COLUMN_NAMES.byName.target_id,
        COLUMN_NAMES.byName.target_type,
        COLUMN_NAMES.byName.service_type,
        COLUMN_NAMES.byName.action,
        COLUMN_NAMES.byName.status,
      ];
    case getUniqueId(Integration.INTELLIGENCE, Entity.EXPERIENCESCORE_USER):
      return [COLUMN_NAMES.byName.username, COLUMN_NAMES.byName._user_overall_score, COLUMN_NAMES.byName.score_calculation_timestamp];
    case getUniqueId(Integration.INTELLIGENCE, Entity.EXPERIENCESCORE_DESKTOP_APP):
      return [
        COLUMN_NAMES.byName.app_name,
        COLUMN_NAMES.byName._application_name,
        COLUMN_NAMES.byName.app_version,
        COLUMN_NAMES.byName.platform,
        COLUMN_NAMES.byName.app_crash_rate,
        COLUMN_NAMES.byName.app_hang_rate,
        COLUMN_NAMES.byName._desktop_app_overall_score,
      ];
    case getUniqueId(Integration.INTELLIGENCE, Entity.EXPERIENCESCORE_MOBILE_APP):
      return [
        COLUMN_NAMES.byName.app_package_id,
        COLUMN_NAMES.byName.app_version,
        COLUMN_NAMES.byName.platform,
        COLUMN_NAMES.byName.app_net_error_rate,
      ];
    case getUniqueId(Integration.INTELLIGENCE, Entity.EXPERIENCESCORE_DEVICE):
      return [
        COLUMN_NAMES.byName.device_name,
        COLUMN_NAMES.byName.device_model,
        COLUMN_NAMES.byName.platform,
        COLUMN_NAMES.byName.crashes,
        COLUMN_NAMES.byName.average_boot_time_millis,
        COLUMN_NAMES.byName.average_shutdown_time_millis,
        COLUMN_NAMES.byName.battery_health,
      ];
    case getUniqueId(Integration.INTELLIGENCE, Entity.AUDIT_LOG):
      return [
        COLUMN_NAMES.byName.actor_display_name,
        COLUMN_NAMES.byName.object_name,
        COLUMN_NAMES.byName.object_type,
        COLUMN_NAMES.byName.created_at,
      ];
    case getUniqueId(Integration.UAG, Entity.TUNNEL):
      return [
        COLUMN_NAMES.byName.device_name,
        COLUMN_NAMES.byName.user_name,
        COLUMN_NAMES.byName.dest_port,
        COLUMN_NAMES.byName.host_name,
        COLUMN_NAMES.byName.dest_ip,
        COLUMN_NAMES.byName.down_bytes,
        COLUMN_NAMES.byName.up_bytes,
        COLUMN_NAMES.byName.uag_name,
        COLUMN_NAMES.byName.protocol,
        COLUMN_NAMES.byName.app_bundle_id,
      ];
    default:
      return [];
  }
}

/**
 * getMostRecent24HourTrendResult
 * @param  {TrendResult[]} trendResults
 * @returns {TrendResult}
 */
export function getMostRecent24HourTrendResult(trendResults: TrendResult[]): TrendResult {
  return findLast(trendResults, (trendResult: TrendResult) => {
    return trendResult.endMillis - trendResult.startMillis >= 1000 * 60 * 60 * 24;
  });
}

/**
 * getWidgetDetailTablePreviewRequest
 * @export
 * @param {PagedRequest} pagedRequest
 * @param {Trend} widgetDetailTrend
 * @param {SortOn[]} sortOns
 * @param {WidgetDetailPageSkinType} skinType
 * @param {FocusedSeries} focusedSeries
 * @param {string} initialFilterString
 * @param {string[]} [visibleColumnNames=[]]
 * @param {string[]} [hiddenColumnNames=[]]
 * @param {ColumnIndex} [columnsByName]
 * @returns {PreviewReportContentRequest}
 */
export function getWidgetDetailTablePreviewRequest(
  pagedRequest: PagedRequest,
  widgetDetailTrend: Trend,
  sortOns: SortOn[],
  skinType: WidgetDetailPageSkinType,
  focusedSeries: FocusedSeries,
  initialFilterString: string,
  visibleColumnNames: string[] = [],
  hiddenColumnNames: string[] = [],
  columnsByName?: ColumnIndex,
): PreviewReportContentRequest {
  if (!widgetDetailTrend) {
    return;
  }
  const trendDefinition = widgetDetailTrend?.trendDefinition;
  let startDateMillis;
  let endDateMillis;

  // Table for WidgetDetailPageSkinType.DEVICE_RISK and WidgetDetailPageSkinType.USER_RISK uses date range of the most recent full datapoint
  const mostRecent24HourTrendResult = getMostRecent24HourTrendResult(widgetDetailTrend.trendResults);
  if ([WidgetDetailPageSkinType.DEVICE_RISK, WidgetDetailPageSkinType.USER_RISK].includes(skinType) && mostRecent24HourTrendResult) {
    startDateMillis = mostRecent24HourTrendResult.startMillis;
    endDateMillis = mostRecent24HourTrendResult.endMillis;
  } else {
    startDateMillis = trendDefinition.dateRange && trendDefinition.dateRange.startDateMillis;
    endDateMillis = trendDefinition.dateRange && trendDefinition.dateRange.endDateMillis;
  }

  const filterStrings = [initialFilterString];
  // Add in focusedSeries filters if it's focusing on a valid bucketAttribute
  // If it's not a valid bucketAttribute, it is focusing on a composited series
  if (
    focusedSeries &&
    focusedSeries.seriesNames.length &&
    trendDefinition.bucketingAttributes?.includes(focusedSeries.colorizedAttributeName)
  ) {
    const filterRule = new FilterRule({
      condition: DashboardConfig.DRILLDOWN_TABLE_CONDITION_TYPE[focusedSeries.seriesDataType] ?? FilterRule.FILTER_CONDITION.includes,
      attribute: focusedSeries.colorizedAttributeName,
      data: isNumberDataType(focusedSeries.seriesDataType)
        ? focusedSeries.seriesNames.map((seriesName) => Number(seriesName))
        : focusedSeries.seriesNames,
    });
    filterStrings.push(QueryBuilder.getRuleString(filterRule));
  }
  if (trendDefinition.isSnapshotPeriodical) {
    filterStrings.push(trendDefinition.precomputedAggregationFilter);
  }
  // clean filterStrings
  const cleaned = helpers.cleanFilterStrings(trendDefinition, filterStrings);

  let fields = uniq([...visibleColumnNames, ...hiddenColumnNames]);
  // SECURITY_THREAT widget still requireds v1 format
  if (!trendDefinition.hasV2Attributes && fields[0]?.split('.').length === 3) {
    fields = fields.map((field: string) => field.split('.')[2]);
  }
  if (size(columnsByName) > 0) {
    // columnsByName will contain only the valid attributes.
    // This will help in filtering out wrong column preferences that were saved due to some bug in the code previously.
    fields = fields.filter((field: string) => !!columnsByName[field]);
  }
  return new PreviewReportContentRequest({
    entity: trendDefinition.entity,
    integration: trendDefinition.integration,
    entitiesByIntegration: trendDefinition.entitiesByIntegration,
    filter: cleaned.filter(Boolean).join(` ${RuleGroupOperator.AND} `),
    offset: pagedRequest.from,
    pageSize: pagedRequest.size,
    fields,
    sortOns,
    trendMode: TrendMode[trendDefinition.trendMode],
    startDateMillis,
    endDateMillis,
  });
}

/**
 * cleanFilterStrings
 * @param {TrendDefinition} trendDefinition
 * @param {string[]} filters
 * @returns {string[]}
 */
export function cleanFilterStrings(trendDefinition: TrendDefinition, filters: string[]): string[] {
  if (!trendDefinition) {
    return;
  }
  const unidentifiedFilterSuffix = new RegExp(`${DashboardConfig.Integration}.${DashboardConfig.Entity}`, 'g');
  const correctedFilterSuffix = `${trendDefinition.integration}.${trendDefinition.entity}`;
  return filters.map((filter: string) => replace(filter, unidentifiedFilterSuffix, correctedFilterSuffix));
}

/**
 * getWidgetPreviewTablePreviewRequest
 * @param {string[]} columnNames
 * @param {PagedRequest} pagedRequest
 * @param {Trend} trend
 * @param {SortOn[]} sortOns
 * @returns {PreviewReportContentRequest}
 */
export function getWidgetPreviewTablePreviewRequest(
  columnNames: string[],
  pagedRequest: PagedRequest,
  trend: Trend,
  sortOns: SortOn[],
): PreviewReportContentRequest {
  const trendDefinition = trend.trendDefinition;
  const filter = trendDefinition.isSnapshotPeriodical
    ? [trendDefinition.filter, trendDefinition.precomputedAggregationFilter].join(` ${RuleGroupOperator.AND} `)
    : trendDefinition.filter;
  return new PreviewReportContentRequest({
    entity: trendDefinition.entity,
    integration: trendDefinition.integration,
    entitiesByIntegration: trendDefinition.entitiesByIntegration,
    filter,
    offset: pagedRequest.from,
    pageSize: pagedRequest.size,
    fields: columnNames,
    sortOns,
    trendMode: TrendMode[trendDefinition.trendMode],
    startDateMillis: trendDefinition.dateRange && trendDefinition.dateRange.startDateMillis,
    endDateMillis: trendDefinition.dateRange && trendDefinition.dateRange.endDateMillis,
  });
}

/**
 * getTrendDefinitionsByStandardWidgetSubtypeWithOverrides
 * @param {any} appDashboardOverrides
 * @param {any} networkInsightsOverrides
 * @param {any} trendDefinitionsBySubtypes
 * @returns {Record<string, TrendDefinition>}
 */
export function getTrendDefinitionsByStandardWidgetSubtypeWithOverrides(
  appDashboardOverrides: any,
  networkInsightsOverrides: any,
  trendDefinitionsBySubtypes: GenericObject,
): Record<string, TrendDefinition> {
  return mapValues(trendDefinitionsBySubtypes, (trendDefinition: TrendDefinition, subType: StandardWidgetSubtype) => {
    const appDashboardOverride = appDashboardOverrides[subType] || {};
    const networkInsightsOverride = networkInsightsOverrides[subType] || {};
    return helpers.removeAppVersionFromScoreTrendDefs(
      subType,
      new TrendDefinition({
        ...trendDefinition,
        ...appDashboardOverride,
        ...networkInsightsOverride,
      }),
    );
  });
}

/**
 * getVisibleWidgetSubtypes
 * @param {any} visibleWidgetCountBySubtypes
 * @returns {string[]}
 */
export function getVisibleWidgetSubtypes(visibleWidgetCountBySubtypes: any): string[] {
  const visibleWidgetSubtypes = [];
  each(visibleWidgetCountBySubtypes, (count: number, subtype: StandardWidgetSubtype) => {
    if (count) {
      visibleWidgetSubtypes.push(subtype);
    }
  });
  return visibleWidgetSubtypes;
}

/**
 * getFilterSortPagedResponse
 * @param {any} originalResponse
 * @param {string} filter
 * @param {SortOn[]} sortOns
 * @param {PagedRequest} pagedRequest
 * @param {string} searchKey
 * @returns {any}
 */
export function getFilterSortPagedResponse(
  originalResponse: any,
  filter: string,
  sortOns: SortOn[],
  pagedRequest: PagedRequest,
  searchKey: string,
) {
  if (!originalResponse) {
    return;
  }
  const nextSearchResponse = Object.assign(cloneDeep(originalResponse), {
    ...pagedRequest,
    total: originalResponse.results.length,
  });

  if (filter) {
    nextSearchResponse.results = nextSearchResponse.results.filter((event: any) => {
      const regExp = new RegExp(filter, 'i');
      return regExp.test(event[searchKey]);
    });
    nextSearchResponse.total = nextSearchResponse.results.length;
  }
  sortOns.forEach((sortOn: SortOn) => {
    nextSearchResponse.results = orderBy(
      nextSearchResponse.results,
      [sortOn.by],
      sortOn.reverse ? LodashSortOrder.DESC : LodashSortOrder.ASC,
    );
  });
  nextSearchResponse.results = nextSearchResponse.results.slice(pagedRequest.from, pagedRequest.from + pagedRequest.size);

  return nextSearchResponse;
}

/**
 * Get only categories that support Dashboard
 *
 * @export
 * @param {Category[]} categories
 * @returns {Category[]}
 */
export function getAvailableDashboardEntities(categories: Category[]): Category[] {
  return categories.filter((category: Category) => category.supportsDashboards);
}

/**
 * getWidgetDetailRuleGroup
 *
 * @export
 * @param {Trend} trend
 * @param {FocusedSeries} focusedSeries
 * @returns {RuleGroup}
 */
export function getWidgetDetailRuleGroup(trend: Trend, focusedSeries: FocusedSeries): RuleGroup {
  if (!trend) {
    return;
  }
  const { trendDefinition }: { trendDefinition: TrendDefinition } = trend;
  let rules = [];
  if (focusedSeries?.seriesNames?.length && trendDefinition.bucketingAttributes?.includes(focusedSeries.colorizedAttributeName)) {
    const filterRule = new FilterRule({
      condition: FilterRule.FILTER_CONDITION.includes,
      attribute: focusedSeries.colorizedAttributeName,
      data: isNumberDataType(focusedSeries.seriesDataType)
        ? focusedSeries.seriesNames.map((seriesName: string) => Number(seriesName))
        : focusedSeries.seriesNames,
      label: focusedSeries.colorizedAttributeLabel,
      dataType: focusedSeries.seriesDataType,
    });
    rules = [...trendDefinition.getTrendDefinitionRuleGroup().rules, filterRule];
  } else {
    rules = trendDefinition.getTrendDefinitionRuleGroup().rules;
  }
  if (trendDefinition?.isSnapshotPeriodical && trendDefinition.precomputedAggregationFilterCondition) {
    rules.push(QueryBuilder.treeNodeToFilterRule(trendDefinition.precomputedAggregationFilterCondition));
  }
  return new RuleGroup(rules, trendDefinition.getTrendDefinitionRuleGroup().operator);
}

/**
 * removeColumnNameFromFilter
 * @param {string} filter
 * @param {string} columnName
 * @returns {string}
 */
export function removeColumnNameFromFilter(filter: string, columnName: string): string {
  const filterRulesWithoutAppVersion = (filter || '').split(` ${RuleGroupOperator.AND} `).filter((filterRule: string) => {
    return filterRule && !filterRule.includes(columnName);
  });
  return [...filterRulesWithoutAppVersion].join(` ${RuleGroupOperator.AND} `);
}

/**
 * removeAppVersionFromScoreTrendDefs
 * @param {StandardWidgetSubtype} subType
 * @param {TrendDefinition} trendDefinition
 * @returns {TrendDefinition}
 */
export function removeAppVersionFromScoreTrendDefs(subType: StandardWidgetSubtype, trendDefinition: TrendDefinition): TrendDefinition {
  const subTypesToOverride: StandardWidgetSubtype[] = [StandardWidgetSubtype.DEEM_APP_SCORE_OVER_TIME];
  if (!subTypesToOverride.includes(subType)) {
    return trendDefinition;
  }
  const columnName = trendDefinition.isV2
    ? COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_desktop_app_app_version
    : COLUMN_NAMES.byName._app_version;
  return new TrendDefinition({
    ...trendDefinition,
    filter: helpers.removeColumnNameFromFilter(trendDefinition.filter, columnName),
  });
}

/**
 * getCustomeTableColumnNamesByCategoryIdAndSubtype
 * @export
 * @param {string} tabId
 * @param {string} [categoryId='']
 * @returns {string[]}
 */
export function getCustomeTableColumnNamesByCategoryIdAndSubtype(tabId: string, categoryId: string = ''): string[] {
  switch (`${categoryId}_${tabId}`) {
    case getUniqueId(Integration.HORIZON, Entity.SESSION_SNAPSHOT, StandardWidgetSubtype.HORIZON_SESSION_ISSUES_BY_LATENCY_TYPE):
      return [
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_healthy_status,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_user,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_status,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_type,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_protocol_latency,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_login_time,
        COLUMN_NAMES.byFullyQualifiedName.horizon_pod_name,
        COLUMN_NAMES.byFullyQualifiedName.horizon_pod_deployment_type,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_pool,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_pool_type,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_host,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_logon_duration,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_shell_load_time,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_profile_load_duration,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_interactive_duration,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_client_ip,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_client_type,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_protocol,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_packet_loss,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_bandwidth,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_protocol_frame_rate,
      ];
    case getUniqueId(Integration.HORIZON, Entity.SESSION_SNAPSHOT, StandardWidgetSubtype.HORIZON_SESSION_ISSUES_BY_LOGON_TYPE):
      return [
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_healthy_status,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_user,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_status,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_type,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_logon_duration,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_shell_load_time,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_profile_load_duration,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_login_time,
        COLUMN_NAMES.byFullyQualifiedName.horizon_pod_name,
        COLUMN_NAMES.byFullyQualifiedName.horizon_pod_deployment_type,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_pool,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_pool_type,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_host,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_interactive_duration,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_client_ip,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_client_type,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_protocol,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_protocol_latency,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_packet_loss,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_bandwidth,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_protocol_frame_rate,
      ];
    case getUniqueId(Integration.HORIZON, Entity.SESSION_SNAPSHOT, StandardWidgetSubtype.HORIZON_SESSION_ISSUES_BY_PROTOCOL_TYPE):
      return [
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_healthy_status,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_user,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_status,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_type,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_protocol,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_protocol_latency,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_packet_loss,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_bandwidth,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_protocol_frame_rate,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_login_time,
        COLUMN_NAMES.byFullyQualifiedName.horizon_pod_name,
        COLUMN_NAMES.byFullyQualifiedName.horizon_pod_deployment_type,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_pool,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_pool_type,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_host,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_logon_duration,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_shell_load_time,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_profile_load_duration,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_interactive_duration,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_client_ip,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_client_type,
      ];
    case getUniqueId(Integration.HORIZON, Entity.SESSION_SNAPSHOT_TITAN, StandardWidgetSubtype.HORIZON_SESSION_ISSUES_BY_LATENCY_TYPE):
      return [
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_session_health,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_user,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_status,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_s_type,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_protocol_latency,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_login_time,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_edge_name,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_edge_type,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_template_name,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_template_type,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_host,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_logon_duration,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_shell_load_time,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_profile_load_duration,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_interactive_duration,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_client_ip,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_client_type,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_protocol,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_packet_loss,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_protocol_frame_rate,
      ];
    case getUniqueId(Integration.HORIZON, Entity.SESSION_SNAPSHOT_TITAN, StandardWidgetSubtype.HORIZON_SESSION_ISSUES_BY_LOGON_TYPE):
      return [
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_session_health,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_user,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_status,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_s_type,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_logon_duration,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_shell_load_time,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_profile_load_duration,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_login_time,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_edge_name,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_edge_type,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_template_name,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_template_type,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_host,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_interactive_duration,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_client_ip,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_client_type,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_protocol,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_protocol_latency,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_packet_loss,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_protocol_frame_rate,
      ];
    case getUniqueId(Integration.HORIZON, Entity.SESSION_SNAPSHOT_TITAN, StandardWidgetSubtype.HORIZON_SESSION_ISSUES_BY_PROTOCOL_TYPE):
      return [
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_session_health,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_user,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_status,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_s_type,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_protocol,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_protocol_latency,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_packet_loss,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_protocol_frame_rate,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_login_time,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_edge_name,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_edge_type,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_template_name,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_template_type,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_host,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_logon_duration,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_shell_load_time,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_profile_load_duration,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_interactive_duration,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_client_ip,
        COLUMN_NAMES.byFullyQualifiedName.horizon_session_snapshot_titan_client_type,
      ];
    default:
      return [];
  }
}
