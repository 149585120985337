/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { DataDecorator, DataUnit, GenericObject, PagedRequest, SortOn, TrendSpan } from '@dpa/ui-common';

import { DeemContributorDetailPageConfigProps } from '@ws1c/deem-solution/models/deem-contributor-detail-page-config-props.interface';
import { DeemContributorWidgetComponentType } from '@ws1c/deem-solution/models/deem-contributor-widget-component-type.enum';
import { DeemDashboardFilterProps } from '@ws1c/deem-solution/models/deem-dashboard-filter-props.interface';
import { DeemSettingsUxScoreRowIndex } from '@ws1c/deem-solution/models/deem-settings-ux-score-row-index.enum';
import { DeemSurvey, DeemSurveyQuestion } from '@ws1c/deem-solution/models/deem-surveys';
import { UNIT_DECORATOR_CONFIG } from '@ws1c/intelligence-common';
import {
  AggregationFunction,
  AggregationWidgetChartType,
  AppDetailTabType,
  AppPlatform,
  AutomationEventType,
  AutomationWorkflowExecutionStatus,
  ChronoUnit,
  Column,
  COLUMN_NAMES,
  CounterDefinition,
  DashboardConfig,
  DataType,
  DeemHealth,
  DeemSolutionDashboardConfig,
  Entity,
  FilterRule,
  getUniqueId,
  Integration,
  LocalDataGridSettings,
  LodashSortOrder,
  NgxTrendResultFlattener,
  PreviewReportContentRequest,
  QueryBuilder,
  RawThresholdAttribute,
  RuleGroup,
  ScoreType,
  SolutionFilterTag,
  SolutionPlatform,
  StandardDashboardRequest,
  StandardDashboardType,
  StandardWidgetSubtype,
  ThresholdAttribute,
  TrendDateRange,
  TrendDefinition,
  TrendDefinitionIndex,
  TrendMode,
} from '@ws1c/intelligence-models';
import { SubDashboardPlatform } from './deem-physical-sub-dashboard.enum';
import { DEEM_ROUTE_NAMES } from './deem-route-names';

/**
 * DeemDashboardConfig
 * @export
 * @class DeemDashboardConfig
 */
export class DeemDashboardConfig {
  public static readonly userColumns = [COLUMN_NAMES.byName.username, COLUMN_NAMES.byName._user_overall_score];
  public static readonly userCounters = [
    new CounterDefinition({
      aggregateAttribute: COLUMN_NAMES.byName.score_calculation_timestamp,
      aggregationFunction: AggregationFunction.MAX,
    }),
  ];

  public static readonly desktopAppCounters = [
    new CounterDefinition({
      aggregateAttribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_desktop_app_device_platform,
      aggregationFunction: AggregationFunction.LATEST,
    }),
    new CounterDefinition({
      aggregateAttribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_desktop_app_app_crash_count,
      aggregationFunction: AggregationFunction.AVG,
    }),
    new CounterDefinition({
      aggregateAttribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_desktop_app_app_hang_count,
      aggregationFunction: AggregationFunction.AVG,
    }),
    new CounterDefinition({
      aggregateAttribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_desktop_app_device_platform,
      aggregationFunction: AggregationFunction.LATEST,
    }),
    new CounterDefinition({
      aggregateAttribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_desktop_app_desktop_app_overall_score,
      aggregationFunction: AggregationFunction.LATEST,
    }),
    new CounterDefinition({
      aggregateAttribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_desktop_app_score_calculation_timestamp,
      aggregationFunction: AggregationFunction.LATEST,
    }),
    new CounterDefinition({
      aggregateAttribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_desktop_app_impact,
      aggregationFunction: AggregationFunction.AVG,
    }),
    new CounterDefinition({
      aggregateAttribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_desktop_app_potential_impact,
      aggregationFunction: AggregationFunction.AVG,
    }),
    new CounterDefinition({
      // eslint-disable-next-line max-len
      aggregateAttribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_desktop_app_org_total_enrolled_device_count,
      aggregationFunction: AggregationFunction.AVG,
    }),
  ];

  // Fields required to link to apteligent app detail page
  public static readonly hiddenMobileAppColumns = [
    // INTEL-31302 - This temp fix will impact UEM data on App Details Page.
    // COLUMN_NAMES.byName.app_package_id,
    COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_mobile_app_app_id,
  ];
  public static readonly mobileAppCounters = [
    new CounterDefinition({
      aggregateAttribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_mobile_app_app_config_app_name,
      aggregationFunction: AggregationFunction.LATEST,
    }),
    new CounterDefinition({
      aggregateAttribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_mobile_app_device_platform,
      aggregationFunction: AggregationFunction.LATEST,
    }),
    new CounterDefinition({
      aggregateAttribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_mobile_app_app_crash_count,
      aggregationFunction: AggregationFunction.LATEST,
    }),
    new CounterDefinition({
      aggregateAttribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_mobile_app_app_handled_exceptions,
      aggregationFunction: AggregationFunction.LATEST,
    }),
    new CounterDefinition({
      aggregateAttribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_mobile_app_app_net_error_rate,
      aggregationFunction: AggregationFunction.LATEST,
    }),
    new CounterDefinition({
      aggregateAttribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_mobile_app_avg_app_latency_millis,
      aggregationFunction: AggregationFunction.LATEST,
    }),
    new CounterDefinition({
      aggregateAttribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_mobile_app_app_type,
      aggregationFunction: AggregationFunction.LATEST,
    }),
    new CounterDefinition({
      aggregateAttribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_mobile_app_app_type,
      aggregationFunction: AggregationFunction.LATEST,
    }),
    new CounterDefinition({
      aggregateAttribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_mobile_app_mobile_app_overall_score,
      aggregationFunction: AggregationFunction.LATEST,
    }),
    new CounterDefinition({
      aggregateAttribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_mobile_app_score_calculation_timestamp,
      aggregationFunction: AggregationFunction.LATEST,
    }),
    new CounterDefinition({
      aggregateAttribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_mobile_app_impact,
      aggregationFunction: AggregationFunction.AVG,
    }),
  ];

  /* eslint-disable max-len */
  public static readonly scoreColumnsByColumnName = {
    // device
    [COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_average_boot_time_millis]:
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_average_boot_time_millis_score,
    [COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_average_shutdown_time_millis]:
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_average_shutdown_time_millis_score,
    [COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_battery_health]:
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_battery_health_score,
    [COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_crashes]:
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_device_crash_count_score,
    [COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_cpu_health]:
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_cpu_health_score,
    [COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_gpu_health]:
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_gpu_health_score,
    [COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_memory_health]:
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_memory_health_score,
    [COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_wifi_strength]:
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_wifi_strength_score,
    [COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_download_speed]:
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_download_speed_score,
    [COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_upload_speed]:
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_upload_speed_score,
    // desktop app
    [COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_desktop_app_app_crash_count]:
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_desktop_app_app_crash_count_score,
    [COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_desktop_app_app_hang_count]:
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_desktop_app_app_hang_rate_score,
    // mobile app
    [COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_mobile_app_app_crash_count]:
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_mobile_app_app_crash_count_score,
    [COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_mobile_app_app_handled_exceptions]:
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_mobile_app_mobile_app_handled_exceptions_score,
    [COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_mobile_app_app_net_error_rate]:
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_mobile_app_mobile_app_net_error_rate_score,
    [COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_mobile_app_avg_app_latency_millis]:
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_mobile_app_mobile_app_avg_app_latency_millis_score,
  };
  public static readonly virtualScoreColumnsByColumnName = {
    [COLUMN_NAMES.byFullyQualifiedName.internal_horizon_experiencescore_session_logon_duration]:
      COLUMN_NAMES.byFullyQualifiedName.internal_horizon_experiencescore_session_logon_duration_score,
    [COLUMN_NAMES.byFullyQualifiedName.internal_horizon_experiencescore_session_packet_loss_rate]:
      COLUMN_NAMES.byFullyQualifiedName.internal_horizon_experiencescore_session_packet_loss_score,
    [COLUMN_NAMES.byFullyQualifiedName.internal_horizon_experiencescore_session_protocol_rtt]:
      COLUMN_NAMES.byFullyQualifiedName.internal_horizon_experiencescore_session_protocol_rtt_score,
    [COLUMN_NAMES.byFullyQualifiedName.internal_horizon_experiencescore_session_cpu_consumption]:
      COLUMN_NAMES.byFullyQualifiedName.internal_horizon_experiencescore_session_cpu_consumption_score,
    [COLUMN_NAMES.byFullyQualifiedName.internal_horizon_experiencescore_session_memory_consumption]:
      COLUMN_NAMES.byFullyQualifiedName.internal_horizon_experiencescore_session_memory_consumption_score,
    [COLUMN_NAMES.byFullyQualifiedName.internal_horizon_experiencescore_session_disk_latency]:
      COLUMN_NAMES.byFullyQualifiedName.internal_horizon_experiencescore_session_disk_latency_score,
    [COLUMN_NAMES.byFullyQualifiedName.internal_horizon_experiencescore_session_disk_iops]:
      COLUMN_NAMES.byFullyQualifiedName.internal_horizon_experiencescore_session_disk_iops_score,
    [COLUMN_NAMES.byFullyQualifiedName.internal_horizon_experiencescore_session_lan_packet_loss_rate]:
      COLUMN_NAMES.byFullyQualifiedName.internal_horizon_experiencescore_session_lan_packet_loss_rate_score,
    [COLUMN_NAMES.byFullyQualifiedName.internal_horizon_experiencescore_session_wan_packet_loss_rate]:
      COLUMN_NAMES.byFullyQualifiedName.internal_horizon_experiencescore_session_wan_packet_loss_rate_score,
  };

  public static readonly classByScoreValue = {
    Good: 'good',
    Neutral: 'neutral',
    Poor: 'poor',
  };

  public static readonly classByRawScoreValue = {
    [10]: 'good',
    [0]: 'neutral',
    [-10]: 'poor',
  };

  public static readonly impactColors = ['#E62700', '#089DE2', '#C4C4C4'];
  public static readonly UNIT_DECORATOR = UNIT_DECORATOR_CONFIG;
  public static readonly PHYSICAL_FILTER_TAGS = [
    'android',
    'desktop_app',
    'device',
    'ios',
    'macos',
    'mobile_app',
    'organization',
    'windows',
  ];
  public static readonly VIRTUAL_FILTER_TAGS = ['organization', 'virtual', 'virtual_session', 'horizon_app', 'horizon_infrastructure'];
  public static readonly scoreRowIndexToDeemSettingTags = {
    DEVICE_HEALTH_EXPERIENCE_SCORE: SolutionFilterTag.DEVICE,
    DESKTOP_APP_EXPERIENCE_SCORE: SolutionFilterTag.DESK_APP,
    MOBILE_APP_EXPERIENCE_SCORE: SolutionFilterTag.MOBILE_APP,
    ORGANIZATION_EXPERIENCE_SCORE: SolutionFilterTag.ORGANIZATION,
    VIRTUAL_SESSION: SolutionFilterTag.VIRTUAL_SESSION,
    VIRTUAL_EXPERIENCE: SolutionFilterTag.ORGANIZATION,
    HORIZON_APP: SolutionFilterTag.HORIZON_APP,
    HORIZON_INFRASTRUCTURE: SolutionFilterTag.HORIZON_INFRASTRUCTURE,
  };
  public static readonly scoreLevelIconMapping = {
    good: 'check-circle',
    neutral: 'exclamation-triangle',
    poor: 'exclamation-circle',
  };
  public static readonly widgetSubtypeContributorLabelMapping = {
    DEEM_DEVICE_WIFI_STRENGTH_LAST_4_HR: 'DEEM_COMMON_MESSAGES.WIFI_STRENGTH',
    DEEM_DEVICE_DOWNLOAD_SPEED_LAST_4_HR: 'DEEM_COMMON_MESSAGES.DOWNLOAD_SPEED',
    DEEM_DEVICE_UPLOAD_SPEED_LAST_4_HR: 'DEEM_COMMON_MESSAGES.UPLOAD_SPEED',
    DEEM_DEVICE_CPU_HEALTH_LAST_4_HR: 'DEEM_COMMON_MESSAGES.CPU_HEALTH',
    DEEM_DEVICE_CPU_TEMPERATURE_LAST_4_HR: 'DEEM_COMMON_MESSAGES.CPU_TEMPERATURE',
    DEEM_DEVICE_CRASH_COUNT_LAST_4_HR: 'DEEM_COMMON_MESSAGES.CRASH_COUNT',
    DEEM_DEVICE_BATTERY_HEALTH_LAST_4_HR: 'DEEM_COMMON_MESSAGES.BATTERY_HEALTH',
    DEEM_DEVICE_MEMORY_HEALTH_LAST_4_HR: 'DEEM_COMMON_MESSAGES.MEMORY_HEALTH',
    DEEM_DEVICE_GPU_HEALTH_LAST_4_HR: 'DEEM_COMMON_MESSAGES.GPU_HEALTH',
    DEEM_DEVICE_SHUTDOWN_TIME_LAST_4_HR: 'DEEM_COMMON_MESSAGES.SHUTDOWN_TIME',
    DEEM_DEVICE_BOOT_TIME_LAST_4_HR: 'DEEM_COMMON_MESSAGES.BOOT_TIME',
    DEEM_MOBILE_APP_CRASH_SCORE_LAST_4_HR: 'DEEM_COMMON_MESSAGES.APP_CRASH',
    DEEM_MOBILE_APP_HANDLED_EXCEPTIONS_SCORE_LAST_4_HR: 'DEEM_COMMON_MESSAGES.HANDLED_EXCEPTIONS',
    DEEM_MOBILE_APP_NETWORK_ERROR_SCORE_LAST_4_HR: 'DEEM_COMMON_MESSAGES.NETWORK_ERRORS',
    DEEM_MOBILE_APP_RESPONSE_SCORE_LAST_4_HR: 'DEEM_COMMON_MESSAGES.RESPONSE_TIME',
    DEEM_DESKTOP_APP_CRASH_COUNT_SCORE_LAST_4_HR: 'DEEM_COMMON_MESSAGES.APP_CRASH',
    DEEM_DESKTOP_APP_HANG_RATE_SCORE_LAST_4_HR: 'DEEM_COMMON_MESSAGES.APP_HANG',
    DEEM_DESKTOP_APP_CPU_USAGE_SCORE_LAST_4_HR: 'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.APP_CPU_USAGE_EXPERIENCESCORE_DESKTOP_APP.METRIC',
    DEEM_DESKTOP_APP_GPU_USAGE_SCORE_LAST_4_HR: 'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.APP_GPU_USAGE_EXPERIENCESCORE_DESKTOP_APP.METRIC',
    DEEM_DESKTOP_APP_MEMORY_USAGE_SCORE_LAST_4_HR: 'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.APP_MEMORY_USAGE_EXPERIENCESCORE_DESKTOP_APP.METRIC',
    DEEM_VIRTUAL_SESSION_COUNT_BY_LAN_LATENCY_SCORE_LAST_4_HR:
      'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.LAN_AVERAGE_LATENCY_HORIZON_EXPERIENCESCORE_SESSION.TITLE',
    DEEM_VIRTUAL_SESSION_COUNT_BY_WAN_LATENCY_SCORE_LAST_4_HR:
      'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.WAN_AVERAGE_LATENCY_HORIZON_EXPERIENCESCORE_SESSION.TITLE',
    DEEM_VIRTUAL_SESSION_COUNT_BY_PACKET_LOSS_SCORE_LAST_4_HR:
      'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.PACKET_LOSS_RATE_HORIZON_EXPERIENCESCORE_SESSION.TITLE',
    DEEM_VIRTUAL_SESSION_COUNT_BY_WIFI_SIGNAL_STRENGTH_SCORE_LAST_4_HR:
      'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.WIFI_SIGNAL_STRENGTH_HORIZON_EXPERIENCESCORE_SESSION.TITLE',
    DEEM_VIRTUAL_SESSION_COUNT_BY_PROTOCOL_LATENCY_SCORE_LAST_4_HR:
      'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.PROTOCOL_LATENCY_HORIZON_EXPERIENCESCORE_SESSION.TITLE',
    DEEM_VIRTUAL_SESSION_COUNT_BY_CPU_USAGE_SCORE_LAST_4_HR:
      'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.CPU_PERCENT_HORIZON_EXPERIENCESCORE_SESSION.TITLE',
    DEEM_VIRTUAL_SESSION_COUNT_BY_MEMORY_USAGE_SCORE_LAST_4_HR:
      'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.MEMORY_PERCENT_HORIZON_EXPERIENCESCORE_SESSION.TITLE',
    DEEM_VIRTUAL_SESSION_COUNT_BY_DISK_LATENCY_SCORE_LAST_4_HR:
      'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.DISK_LATENCY_HORIZON_EXPERIENCESCORE_SESSION.TITLE',
    DEEM_VIRTUAL_SESSION_COUNT_BY_DISK_IOPS_SCORE_LAST_4_HR:
      'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.DISK_IOPS_HORIZON_EXPERIENCESCORE_SESSION.TITLE',
    DEEM_DESKTOP_APP_UI_UNRESPONSIVE_RATE_SCORE_LAST_4_HR:
      'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.APP_UI_UNRESPONSIVE_RATE_EXPERIENCESCORE_DESKTOP_APP.TITLE',
    DEEM_DESKTOP_APP_UI_UNRESPONSIVE_DURATION_SCORE_LAST_4_HR:
      'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.AVERAGE_APP_UI_UNRESPONSIVE_DURATION_EXPERIENCESCORE_DESKTOP_APP.TITLE',
    DEEM_DESKTOP_APP_UI_UNRESPONSIVE_COUNT_SCORE_LAST_4_HR:
      'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.AVERAGE_APP_UI_UNRESPONSIVE_EVENTS_PER_DEVICE_EXPERIENCESCORE_DESKTOP_APP.TITLE',
    DEEM_VIRTUAL_APP_CRASH_SCORE_LAST_4_HR: 'DEEM_COMMON_MESSAGES.APP_CRASH',
    DEEM_VIRTUAL_APP_HANG_SCORE_LAST_4_HR: 'DEEM_COMMON_MESSAGES.APP_HANG',
    DEEM_VIRTUAL_APP_CPU_USAGE_SCORE_LAST_4_HR: 'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.APP_CPU_USAGE_HORIZON_EXPERIENCESCORE_APP.METRIC',
    DEEM_VIRTUAL_APP_MEMORY_USAGE_SCORE_LAST_4_HR: 'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.APP_MEMORY_USAGE_HORIZON_EXPERIENCESCORE_APP.METRIC',
    DEEM_VIRTUAL_CONNECTION_SERVER_CERT_SCORE_COUNT: 'DEEM_VIRTUAL_SUMMARY.CONNECTION_SERVER_CERT_EXPIRATION',
    DEEM_VIRTUAL_CONNECTION_SERVER_CPU_SCORE_COUNT: 'DEEM_VIRTUAL_SUMMARY.CONNECTION_SERVER_CPU',
    DEEM_VIRTUAL_CONNECTION_SERVER_MEMORY_SCORE_COUNT: 'DEEM_VIRTUAL_SUMMARY.CONNECTION_SERVER_MEMORY',
    DEEM_VIRTUAL_CONNECTION_SERVER_SESSION_SCORE_COUNT: 'DEEM_VIRTUAL_SUMMARY.CONNECTION_SERVER_SESSIONS',
    DEEM_VIRTUAL_CONNECTION_SERVER_STATUS_SCORE_COUNT: 'DEEM_VIRTUAL_SUMMARY.CONNECTION_SERVER_STATUS',
    DEEM_VIRTUAL_UAG_APPLIANCE_CERT_SCORE_COUNT: 'DEEM_VIRTUAL_SUMMARY.UAG_APPLIANCE_CERT_EXPIRATION',
    DEEM_VIRTUAL_UAG_APPLIANCE_CPU_SCORE_COUNT: 'DEEM_VIRTUAL_SUMMARY.UAG_APPLIANCE_CPU',
    DEEM_VIRTUAL_UAG_APPLIANCE_MEMORY_SCORE_COUNT: 'DEEM_VIRTUAL_SUMMARY.UAG_APPLIANCE_MEMORY',
    DEEM_VIRTUAL_UAG_APPLIANCE_CONNECTION_SCORE_COUNT: 'DEEM_VIRTUAL_SUMMARY.UAG_APPLIANCE_CONNECTIONS',
    DEEM_VIRTUAL_UAG_APPLIANCE_STATUS_SCORE_COUNT: 'DEEM_VIRTUAL_SUMMARY.UAG_APPLIANCE_STATUS',
    DEEM_VIRTUAL_SESSION_LOGON_DURATION: 'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.LOGON_DURATION_HORIZON_EXPERIENCESCORE_SESSION.METRIC',
    DEEM_VIRTUAL_SESSION_CPU_USAGE: 'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.CPU_PERCENT_HORIZON_EXPERIENCESCORE_SESSION.METRIC',
    DEEM_VIRTUAL_SESSION_DISK_LATENCY: 'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.DISK_LATENCY_HORIZON_EXPERIENCESCORE_SESSION.METRIC',
    DEEM_VIRTUAL_SESSION_DISK_IOPS: 'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.DISK_IOPS_HORIZON_EXPERIENCESCORE_SESSION.METRIC',
    DEEM_VIRTUAL_SESSION_MEMORY_USAGE: 'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.MEMORY_PERCENT_HORIZON_EXPERIENCESCORE_SESSION.METRIC',
    DEEM_VIRTUAL_SESSION_LAN_LATENCY: 'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.LAN_AVERAGE_LATENCY_HORIZON_EXPERIENCESCORE_SESSION.METRIC',
    DEEM_VIRTUAL_SESSION_WAN_LATENCY: 'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.WAN_AVERAGE_LATENCY_HORIZON_EXPERIENCESCORE_SESSION.METRIC',
    DEEM_VIRTUAL_SESSION_PROTOCOL_LATENCY: 'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.PROTOCOL_LATENCY_HORIZON_EXPERIENCESCORE_SESSION.METRIC',
    DEEM_VIRTUAL_SESSION_PROTOCOL_PACKET_LOSS: 'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.PACKET_LOSS_RATE_HORIZON_EXPERIENCESCORE_SESSION.METRIC',
    DEEM_VIRTUAL_SESSION_WIFI_SIGNAL_STRENGTH:
      'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.DOT11_SIGNAL_QUALITY_HORIZON_EXPERIENCESCORE_SESSION.METRIC',
  };
  public static readonly widgetSubtypeContributorMapping = {
    DEEM_DEVICE_WIFI_STRENGTH_LAST_4_HR: ThresholdAttribute.WIFI_STRENGTH_EXPERIENCESCORE_DEVICE,
    DEEM_DEVICE_DOWNLOAD_SPEED_LAST_4_HR: ThresholdAttribute.DOWNLOAD_SPEED_EXPERIENCESCORE_DEVICE,
    DEEM_DEVICE_UPLOAD_SPEED_LAST_4_HR: ThresholdAttribute.UPLOAD_SPEED_EXPERIENCESCORE_DEVICE,
    DEEM_DEVICE_CPU_HEALTH_LAST_4_HR: ThresholdAttribute.CPU_HEALTH_EXPERIENCESCORE_DEVICE,
    DEEM_DEVICE_CPU_TEMPERATURE_LAST_4_HR: ThresholdAttribute.DEVICE_TEMPERATURE_EXPERIENCESCORE_DEVICE,
    DEEM_DEVICE_CRASH_COUNT_LAST_4_HR: ThresholdAttribute.CRASH_COUNT_EXPERIENCESCORE_DEVICE,
    DEEM_DEVICE_BATTERY_HEALTH_LAST_4_HR: ThresholdAttribute.BATTERY_HEALTH_EXPERIENCESCORE_DEVICE,
    DEEM_DEVICE_MEMORY_HEALTH_LAST_4_HR: ThresholdAttribute.MEMORY_HEALTH_EXPERIENCESCORE_DEVICE,
    DEEM_DEVICE_GPU_HEALTH_LAST_4_HR: ThresholdAttribute.GPU_HEALTH_EXPERIENCESCORE_DEVICE,
    DEEM_DEVICE_SHUTDOWN_TIME_LAST_4_HR: ThresholdAttribute.AVERAGE_SHUTDOWN_TIME_MILLIS_EXPERIENCESCORE_DEVICE,
    DEEM_DEVICE_BOOT_TIME_LAST_4_HR: ThresholdAttribute.AVERAGE_BOOT_TIME_MILLIS_EXPERIENCESCORE_DEVICE,
    DEEM_DESKTOP_APP_CRASH_COUNT_SCORE_LAST_4_HR: ThresholdAttribute.APP_CRASH_COUNT_EXPERIENCESCORE_DESKTOP_APP,
    DEEM_DESKTOP_APP_HANG_RATE_SCORE_LAST_4_HR: ThresholdAttribute.APP_HANG_RATE_EXPERIENCESCORE_DESKTOP_APP,
    DEEM_DESKTOP_APP_CPU_USAGE_SCORE_LAST_4_HR: ThresholdAttribute.APP_CPU_USAGE_EXPERIENCESCORE_DESKTOP_APP,
    DEEM_DESKTOP_APP_GPU_USAGE_SCORE_LAST_4_HR: ThresholdAttribute.APP_GPU_USAGE_EXPERIENCESCORE_DESKTOP_APP,
    DEEM_DESKTOP_APP_MEMORY_USAGE_SCORE_LAST_4_HR: ThresholdAttribute.APP_MEMORY_USAGE_EXPERIENCESCORE_DESKTOP_APP,
    DEEM_DESKTOP_APP_UI_UNRESPONSIVE_RATE_SCORE_LAST_4_HR: ThresholdAttribute.APP_UI_UNRESPONSIVE_RATE_EXPERIENCESCORE_DESKTOP_APP,
    DEEM_DESKTOP_APP_UI_UNRESPONSIVE_DURATION_SCORE_LAST_4_HR:
      ThresholdAttribute.AVERAGE_APP_UI_UNRESPONSIVE_DURATION_EXPERIENCESCORE_DESKTOP_APP,
    DEEM_DESKTOP_APP_UI_UNRESPONSIVE_COUNT_SCORE_LAST_4_HR:
      ThresholdAttribute.AVERAGE_APP_UI_UNRESPONSIVE_EVENTS_PER_DEVICE_EXPERIENCESCORE_DESKTOP_APP,
    DEEM_MOBILE_APP_CRASH_SCORE_LAST_4_HR: ThresholdAttribute.APP_CRASH_COUNT_EXPERIENCESCORE_MOBILE_APP,
    DEEM_MOBILE_APP_HANDLED_EXCEPTIONS_SCORE_LAST_4_HR: ThresholdAttribute.APP_HANDLED_EXCEPTIONS_EXPERIENCESCORE_MOBILE_APP,
    DEEM_MOBILE_APP_NETWORK_ERROR_SCORE_LAST_4_HR: ThresholdAttribute.APP_NET_ERROR_RATE_EXPERIENCESCORE_MOBILE_APP,
    DEEM_MOBILE_APP_RESPONSE_SCORE_LAST_4_HR: ThresholdAttribute.AVG_APP_LATENCY_MILLIS_EXPERIENCESCORE_MOBILE_APP,
    DEEM_VIRTUAL_SESSION_COUNT_BY_LAN_LATENCY_SCORE_LAST_4_HR: ThresholdAttribute.LAN_AVERAGE_LATENCY_HORIZON_EXPERIENCESCORE_SESSION,
    DEEM_VIRTUAL_SESSION_COUNT_BY_WAN_LATENCY_SCORE_LAST_4_HR: ThresholdAttribute.WAN_AVERAGE_LATENCY_HORIZON_EXPERIENCESCORE_SESSION,
    DEEM_VIRTUAL_SESSION_COUNT_BY_PACKET_LOSS_SCORE_LAST_4_HR: ThresholdAttribute.PACKET_LOSS_RATE_HORIZON_EXPERIENCESCORE_SESSION,
    DEEM_VIRTUAL_SESSION_COUNT_BY_WIFI_SIGNAL_STRENGTH_SCORE_LAST_4_HR:
      ThresholdAttribute.DOT11_SIGNAL_QUALITY_HORIZON_EXPERIENCESCORE_SESSION,
    DEEM_VIRTUAL_SESSION_COUNT_BY_PROTOCOL_LATENCY_SCORE_LAST_4_HR: ThresholdAttribute.PROTOCOL_LATENCY_HORIZON_EXPERIENCESCORE_SESSION,
    DEEM_VIRTUAL_SESSION_COUNT_BY_CPU_USAGE_SCORE_LAST_4_HR: ThresholdAttribute.CPU_PERCENT_HORIZON_EXPERIENCESCORE_SESSION,
    DEEM_VIRTUAL_SESSION_COUNT_BY_MEMORY_USAGE_SCORE_LAST_4_HR: ThresholdAttribute.MEMORY_PERCENT_HORIZON_EXPERIENCESCORE_SESSION,
    DEEM_VIRTUAL_SESSION_COUNT_BY_DISK_LATENCY_SCORE_LAST_4_HR: ThresholdAttribute.DISK_LATENCY_HORIZON_EXPERIENCESCORE_SESSION,
    DEEM_VIRTUAL_SESSION_COUNT_BY_DISK_IOPS_SCORE_LAST_4_HR: ThresholdAttribute.DISK_IOPS_HORIZON_EXPERIENCESCORE_SESSION,
    DEEM_VIRTUAL_APP_CRASH_SCORE_LAST_4_HR: ThresholdAttribute.APP_CRASH_COUNT_HORIZON_EXPERIENCESCORE_APP,
    DEEM_VIRTUAL_APP_HANG_SCORE_LAST_4_HR: ThresholdAttribute.APP_HANG_RATE_HORIZON_EXPERIENCESCORE_APP,
    DEEM_VIRTUAL_APP_CPU_USAGE_SCORE_LAST_4_HR: ThresholdAttribute.APP_CPU_USAGE_HORIZON_EXPERIENCESCORE_APP,
    DEEM_VIRTUAL_APP_MEMORY_USAGE_SCORE_LAST_4_HR: ThresholdAttribute.APP_MEMORY_USAGE_HORIZON_EXPERIENCESCORE_APP,
    DEEM_VIRTUAL_CONNECTION_SERVER_CERT_SCORE_COUNT:
      ThresholdAttribute.DAYS_BEFORE_CERTIFICATE_EXPIRATION_HORIZON_EXPERIENCESCORE_CONNECTION_SERVER,
    DEEM_VIRTUAL_CONNECTION_SERVER_CPU_SCORE_COUNT: ThresholdAttribute.CPU_USAGE_HORIZON_EXPERIENCESCORE_CONNECTION_SERVER,
    DEEM_VIRTUAL_CONNECTION_SERVER_MEMORY_SCORE_COUNT: ThresholdAttribute.MEMORY_USAGE_HORIZON_EXPERIENCESCORE_CONNECTION_SERVER,
    DEEM_VIRTUAL_CONNECTION_SERVER_SESSION_SCORE_COUNT: ThresholdAttribute.SESSION_COUNT_HORIZON_EXPERIENCESCORE_CONNECTION_SERVER,
    DEEM_VIRTUAL_CONNECTION_SERVER_STATUS_SCORE_COUNT: ThresholdAttribute.STATUS_HORIZON_EXPERIENCESCORE_CONNECTION_SERVER,
    DEEM_VIRTUAL_UAG_APPLIANCE_CERT_SCORE_COUNT:
      ThresholdAttribute.DAYS_BEFORE_CERTIFICATE_EXPIRATION_HORIZON_EXPERIENCESCORE_UAG_APPLIANCE,
    DEEM_VIRTUAL_UAG_APPLIANCE_CPU_SCORE_COUNT: ThresholdAttribute.CPU_USAGE_HORIZON_EXPERIENCESCORE_UAG_APPLIANCE,
    DEEM_VIRTUAL_UAG_APPLIANCE_MEMORY_SCORE_COUNT: ThresholdAttribute.MEMORY_USAGE_HORIZON_EXPERIENCESCORE_UAG_APPLIANCE,
    DEEM_VIRTUAL_UAG_APPLIANCE_CONNECTION_SCORE_COUNT: ThresholdAttribute.CONNECTION_COUNT_HORIZON_EXPERIENCESCORE_UAG_APPLIANCE,
    DEEM_VIRTUAL_UAG_APPLIANCE_STATUS_SCORE_COUNT: ThresholdAttribute.STATUS_HORIZON_EXPERIENCESCORE_UAG_APPLIANCE,
  };

  public static readonly widgetSubtypeToThresholdAttributeMap = {
    [StandardWidgetSubtype.DEEM_CRASH_COUNT_POOR_SESSIONS]: RawThresholdAttribute.CRASH_COUNT,
    [StandardWidgetSubtype.DEEM_CPU_USAGE_POOR_SESSIONS]: RawThresholdAttribute.CPU_HEALTH,
    [StandardWidgetSubtype.DEEM_MEMORY_USAGE_POOR_SESSIONS]: RawThresholdAttribute.MEMORY_HEALTH,
    [StandardWidgetSubtype.DEEM_GPU_USAGE_POOR_SESSIONS]: RawThresholdAttribute.GPU_HEALTH,
    [StandardWidgetSubtype.DEEM_CPU_TEMPERATURE_POOR_SESSIONS]: RawThresholdAttribute.DEVICE_TEMPERATURE,
    [StandardWidgetSubtype.DEEM_DOWNLOAD_SPEED_POOR_SESSIONS]: RawThresholdAttribute.DOWNLOAD_SPEED,
    [StandardWidgetSubtype.DEEM_UPLOAD_SPEED_POOR_SESSIONS]: RawThresholdAttribute.UPLOAD_SPEED,
    [StandardWidgetSubtype.DEEM_WIFI_STRENGTH_POOR_SESSIONS]: RawThresholdAttribute.WIFI_STRENGTH,
    [StandardWidgetSubtype.DEEM_BATTERY_HEALTH_POOR_SESSIONS]: RawThresholdAttribute.BATTERY_HEALTH,
    [StandardWidgetSubtype.DEEM_SHUTDOWN_TIME_POOR_SESSIONS]: RawThresholdAttribute.AVERAGE_SHUTDOWN_TIME_MILLIS,
    [StandardWidgetSubtype.DEEM_BOOT_TIME_POOR_SESSIONS]: RawThresholdAttribute.AVERAGE_BOOT_TIME_MILLIS,
  };

  public static readonly contributorKeyNeedPlatformPostFix = [
    ThresholdAttribute.APP_CRASH_COUNT_EXPERIENCESCORE_DESKTOP_APP.toString(),
    ThresholdAttribute.APP_HANG_RATE_EXPERIENCESCORE_DESKTOP_APP.toString(),
    ThresholdAttribute.CRASH_COUNT_EXPERIENCESCORE_DEVICE.toString(),
    ThresholdAttribute.APP_CPU_USAGE_EXPERIENCESCORE_DESKTOP_APP.toString(),
    ThresholdAttribute.APP_MEMORY_USAGE_EXPERIENCESCORE_DESKTOP_APP.toString(),
  ];

  public static readonly EntityToThresholdAttributeMap = {
    [getUniqueId(Entity.EXPERIENCESCORE_DEVICE, SolutionPlatform.WINDOWS)]: [
      RawThresholdAttribute.CRASH_COUNT,
      RawThresholdAttribute.CPU_HEALTH,
      RawThresholdAttribute.MEMORY_HEALTH,
      RawThresholdAttribute.GPU_HEALTH,
      RawThresholdAttribute.BATTERY_HEALTH,
      RawThresholdAttribute.AVERAGE_BOOT_TIME_MILLIS,
      RawThresholdAttribute.AVERAGE_SHUTDOWN_TIME_MILLIS,
    ],
    [getUniqueId(Entity.EXPERIENCESCORE_DEVICE, SolutionPlatform.MACOS)]: [
      RawThresholdAttribute.CRASH_COUNT,
      RawThresholdAttribute.CPU_HEALTH,
      RawThresholdAttribute.MEMORY_HEALTH,
      RawThresholdAttribute.DEVICE_TEMPERATURE,
    ],
    [Entity.EXPERIENCESCORE_DESKTOP_APP]: [
      RawThresholdAttribute.APP_CRASH_COUNT,
      RawThresholdAttribute.APP_HANG_RATE,
      RawThresholdAttribute.APP_CPU_USAGE,
      RawThresholdAttribute.APP_GPU_USAGE,
      RawThresholdAttribute.APP_MEMORY_USAGE,
      RawThresholdAttribute.APP_UI_UNRESPONSIVE_RATE,
      RawThresholdAttribute.AVERAGE_APP_UI_UNRESPONSIVE_DURATION,
      RawThresholdAttribute.AVERAGE_APP_UI_UNRESPONSIVE_EVENTS_PER_DEVICE,
    ],
    [getUniqueId(Entity.EXPERIENCESCORE_DESKTOP_APP, SolutionPlatform.MACOS)]: [
      RawThresholdAttribute.APP_CRASH_COUNT,
      RawThresholdAttribute.APP_HANG_RATE,
      RawThresholdAttribute.APP_CPU_USAGE,
      RawThresholdAttribute.APP_MEMORY_USAGE,
      RawThresholdAttribute.APP_UI_UNRESPONSIVE_RATE,
      RawThresholdAttribute.AVERAGE_APP_UI_UNRESPONSIVE_DURATION,
      RawThresholdAttribute.AVERAGE_APP_UI_UNRESPONSIVE_EVENTS_PER_DEVICE,
    ],
    [Entity.EXPERIENCESCORE_MOBILE_APP]: [
      RawThresholdAttribute.APP_CRASH_COUNT,
      RawThresholdAttribute.APP_HANG_RATE,
      RawThresholdAttribute.APP_HANDLED_EXCEPTIONS,
      RawThresholdAttribute.APP_NET_ERROR_RATE,
      RawThresholdAttribute.AVG_APP_LATENCY_MILLIS,
    ],
    [getUniqueId(Entity.EXPERIENCESCORE_HORIZON_SESSION, SubDashboardPlatform.NETWORK_PROTOCOL)]: [
      RawThresholdAttribute.LAN_AVERAGE_LATENCY,
      RawThresholdAttribute.WAN_AVERAGE_LATENCY,
      RawThresholdAttribute.DOT11_SIGNAL_QUALITY,
      RawThresholdAttribute.PACKET_LOSS_RATE,
      RawThresholdAttribute.PROTOCOL_LATENCY,
    ],
    [getUniqueId(Entity.EXPERIENCESCORE_HORIZON_SESSION, SubDashboardPlatform.VM_PERFORMANCE)]: [
      RawThresholdAttribute.CPU_PERCENT,
      RawThresholdAttribute.MEMORY_PERCENT,
      RawThresholdAttribute.DISK_LATENCY,
      RawThresholdAttribute.DISK_IOPS,
    ],
    [Entity.HORIZON_EXPERIENCESCORE_APP]: [
      RawThresholdAttribute.APP_CRASH_COUNT,
      RawThresholdAttribute.APP_HANG_RATE,
      RawThresholdAttribute.APP_CPU_USAGE,
      RawThresholdAttribute.APP_MEMORY_USAGE,
    ],
  };
  public static readonly MgtCompThresholdAttributes = [
    ThresholdAttribute.CPU_USAGE_HORIZON_EXPERIENCESCORE_CONNECTION_SERVER,
    ThresholdAttribute.MEMORY_USAGE_HORIZON_EXPERIENCESCORE_CONNECTION_SERVER,
    ThresholdAttribute.SESSION_COUNT_HORIZON_EXPERIENCESCORE_CONNECTION_SERVER,
    ThresholdAttribute.STATUS_HORIZON_EXPERIENCESCORE_CONNECTION_SERVER,
    ThresholdAttribute.DAYS_BEFORE_CERTIFICATE_EXPIRATION_HORIZON_EXPERIENCESCORE_CONNECTION_SERVER,
    ThresholdAttribute.CPU_USAGE_HORIZON_EXPERIENCESCORE_UAG_APPLIANCE,
    ThresholdAttribute.MEMORY_USAGE_HORIZON_EXPERIENCESCORE_UAG_APPLIANCE,
    ThresholdAttribute.STATUS_HORIZON_EXPERIENCESCORE_UAG_APPLIANCE,
    ThresholdAttribute.CONNECTION_COUNT_HORIZON_EXPERIENCESCORE_UAG_APPLIANCE,
    ThresholdAttribute.DAYS_BEFORE_CERTIFICATE_EXPIRATION_HORIZON_EXPERIENCESCORE_UAG_APPLIANCE,
  ];

  public static readonly DeemDashboardFilterProps: Record<string, DeemDashboardFilterProps> = {
    [StandardDashboardType.DEEM_PHYSICAL_USER]: {
      integration: Integration.INTELLIGENCE,
      entity: Entity.EXPERIENCESCORE_USER,
      tagAttributes: [{ attribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_user_user_overall_score }],
    },
    [StandardDashboardType.DEEM_PHYSICAL_DEVICES]: {
      integration: Integration.INTELLIGENCE,
      entity: Entity.EXPERIENCESCORE_DEVICE,
      tagAttributes: [{ attribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_device_overall_score }],
    },
    [StandardDashboardType.DEEM_PHYSICAL_DEVICES_WINDOWS]: {
      integration: Integration.INTELLIGENCE,
      entity: Entity.EXPERIENCESCORE_DEVICE,
      tagAttributes: [
        { attribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_device_overall_score },
        { attribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_device_platform, isHidden: true },
      ],
      defaultRuleGroup: new RuleGroup({
        rules: [
          new FilterRule({
            attribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_device_platform,
            condition: FilterRule.FILTER_CONDITION.includes,
            data: [AppPlatform.WINDOWS_DESKTOP],
            dataType: DataType[DataType.STRING],
          }),
        ],
      }),
    },
    [StandardDashboardType.DEEM_PHYSICAL_DEVICES_MACOS]: {
      integration: Integration.INTELLIGENCE,
      entity: Entity.EXPERIENCESCORE_DEVICE,
      tagAttributes: [
        { attribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_device_overall_score },
        { attribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_device_platform, isHidden: true },
      ],
      defaultRuleGroup: new RuleGroup({
        rules: [
          new FilterRule({
            attribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_device_platform,
            condition: FilterRule.FILTER_CONDITION.includes,
            data: [AppPlatform.APPLE_MACOS],
            dataType: DataType[DataType.STRING],
          }),
        ],
      }),
    },
    [StandardDashboardType.DEEM_PHYSICAL_DESKTOP_APPS]: {
      integration: Integration.INTELLIGENCE,
      entity: Entity.EXPERIENCESCORE_DESKTOP_APP,
      tagAttributes: [{ attribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_desktop_app_desktop_app_overall_score }],
      defaultRuleGroup: new RuleGroup({
        rules: [
          new FilterRule({
            attribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_desktop_app_desktop_app_overall_score,
            condition: FilterRule.FILTER_CONDITION.includes,
            data: [DeemHealth.POOR],
            dataType: DataType[DataType.STRING],
          }),
        ],
      }),
    },
    [StandardDashboardType.DEEM_PHYSICAL_DESKTOP_APPS_MACOS]: {
      integration: Integration.INTELLIGENCE,
      entity: Entity.EXPERIENCESCORE_DESKTOP_APP,
      tagAttributes: [
        { attribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_desktop_app_desktop_app_overall_score },
        { attribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_desktop_app_device_platform, isHidden: true },
      ],
      defaultRuleGroup: new RuleGroup({
        rules: [
          new FilterRule({
            attribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_desktop_app_device_platform,
            condition: FilterRule.FILTER_CONDITION.includes,
            data: [AppPlatform.APPLE_MACOS],
            dataType: DataType[DataType.STRING],
          }),
          new FilterRule({
            attribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_desktop_app_desktop_app_overall_score,
            condition: FilterRule.FILTER_CONDITION.includes,
            data: [DeemHealth.POOR],
            dataType: DataType[DataType.STRING],
          }),
        ],
      }),
    },
    [StandardDashboardType.DEEM_PHYSICAL_DESKTOP_APPS_WINDOWS]: {
      integration: Integration.INTELLIGENCE,
      entity: Entity.EXPERIENCESCORE_DESKTOP_APP,
      tagAttributes: [
        { attribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_desktop_app_desktop_app_overall_score },
        { attribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_desktop_app_device_platform, isHidden: true },
      ],
      defaultRuleGroup: new RuleGroup({
        rules: [
          new FilterRule({
            attribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_desktop_app_device_platform,
            condition: FilterRule.FILTER_CONDITION.includes,
            data: [AppPlatform.WINDOWS_DESKTOP],
            dataType: DataType[DataType.STRING],
          }),
          new FilterRule({
            attribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_desktop_app_desktop_app_overall_score,
            condition: FilterRule.FILTER_CONDITION.includes,
            data: [DeemHealth.POOR],
            dataType: DataType[DataType.STRING],
          }),
        ],
      }),
    },
    [StandardDashboardType.DEEM_PHYSICAL_DESKTOP_APP]: {
      integration: Integration.INTELLIGENCE,
      entity: Entity.EXPERIENCESCORE_DESKTOP_APP,
      tagAttributes: [{ attribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_desktop_app_desktop_app_overall_score }],
    },
    [StandardDashboardType.DEEM_PHYSICAL_APP_CRASH_COUNT_EXPERIENCESCORE_DESKTOP_APP_MACOS_ERROR_DETAILS]: {
      integration: Integration.EMPLOYEE_EXPERIENCE,
      entity: Entity.APPS,
    },
    [StandardDashboardType.DEEM_PHYSICAL_APP_CRASH_COUNT_EXPERIENCESCORE_DESKTOP_APP_WINDOWS_ERROR_DETAILS]: {
      integration: Integration.EMPLOYEE_EXPERIENCE,
      entity: Entity.APPS,
    },
    [StandardDashboardType.DEEM_PHYSICAL_APP_HANG_RATE_EXPERIENCESCORE_DESKTOP_APP_MACOS_ERROR_DETAILS]: {
      integration: Integration.EMPLOYEE_EXPERIENCE,
      entity: Entity.APPS,
    },
    [StandardDashboardType.DEEM_PHYSICAL_APP_HANG_RATE_EXPERIENCESCORE_DESKTOP_APP_WINDOWS_ERROR_DETAILS]: {
      integration: Integration.EMPLOYEE_EXPERIENCE,
      entity: Entity.APPS,
    },
    [StandardDashboardType.DEEM_PHYSICAL_MOBILE_APPS]: {
      integration: Integration.INTELLIGENCE,
      entity: Entity.EXPERIENCESCORE_MOBILE_APP,
      tagAttributes: [{ attribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_mobile_app_mobile_app_overall_score }],
    },
    [StandardDashboardType.DEEM_PHYSICAL_MOBILE_APPS_ANDROID]: {
      integration: Integration.INTELLIGENCE,
      entity: Entity.EXPERIENCESCORE_MOBILE_APP,
      tagAttributes: [
        { attribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_mobile_app_mobile_app_overall_score },
        { attribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_mobile_app_device_platform, isHidden: true },
      ],
      defaultRuleGroup: new RuleGroup({
        rules: [
          new FilterRule({
            attribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_mobile_app_device_platform,
            condition: FilterRule.FILTER_CONDITION.includes,
            data: [AppPlatform.ANDROID],
            dataType: DataType[DataType.STRING],
          }),
        ],
      }),
    },
    [StandardDashboardType.DEEM_PHYSICAL_MOBILE_APPS_IOS]: {
      integration: Integration.INTELLIGENCE,
      entity: Entity.EXPERIENCESCORE_MOBILE_APP,
      tagAttributes: [
        { attribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_mobile_app_mobile_app_overall_score },
        { attribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_mobile_app_device_platform, isHidden: true },
      ],
      defaultRuleGroup: new RuleGroup({
        rules: [
          new FilterRule({
            attribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_mobile_app_device_platform,
            condition: FilterRule.FILTER_CONDITION.includes,
            data: [AppPlatform.APPLE_IOS],
            dataType: DataType[DataType.STRING],
          }),
        ],
      }),
    },
    [StandardDashboardType.DEEM_PHYSICAL_MOBILE_APP]: {
      integration: Integration.INTELLIGENCE,
      entity: Entity.EXPERIENCESCORE_MOBILE_APP,
      tagAttributes: [{ attribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_mobile_app_mobile_app_overall_score }],
    },
    [StandardDashboardType.DEEM_PHYSICAL_DEVICE_TEMPERATURE_EXPERIENCESCORE_DEVICE]: {
      integration: Integration.INTELLIGENCE,
      entity: Entity.EXPERIENCESCORE_DEVICE,
      tagAttributes: [
        { attribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_device_temperature_score },
        { attribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_device_platform, isHidden: true },
      ],
    },
    [StandardDashboardType.DEEM_PHYSICAL_CRASH_COUNT_EXPERIENCESCORE_DEVICE]: {
      integration: Integration.INTELLIGENCE,
      entity: Entity.EXPERIENCESCORE_DEVICE,
      tagAttributes: [
        { attribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_device_crash_count_score },
        { attribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_device_platform, isHidden: true },
      ],
    },
    [StandardDashboardType.DEEM_PHYSICAL_CPU_HEALTH_EXPERIENCESCORE_DEVICE]: {
      integration: Integration.INTELLIGENCE,
      entity: Entity.EXPERIENCESCORE_DEVICE,
      tagAttributes: [
        { attribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_cpu_health_score },
        { attribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_device_platform, isHidden: true },
      ],
    },
    [StandardDashboardType.DEEM_PHYSICAL_MEMORY_HEALTH_EXPERIENCESCORE_DEVICE]: {
      integration: Integration.INTELLIGENCE,
      entity: Entity.EXPERIENCESCORE_DEVICE,
      tagAttributes: [
        { attribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_memory_health_score },
        { attribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_device_platform, isHidden: true },
      ],
    },
    [StandardDashboardType.DEEM_PHYSICAL_GPU_HEALTH_EXPERIENCESCORE_DEVICE]: {
      integration: Integration.INTELLIGENCE,
      entity: Entity.EXPERIENCESCORE_DEVICE,
      tagAttributes: [
        { attribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_gpu_health_score },
        { attribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_device_platform, isHidden: true },
      ],
    },
    [StandardDashboardType.DEEM_PHYSICAL_BATTERY_HEALTH_EXPERIENCESCORE_DEVICE]: {
      integration: Integration.INTELLIGENCE,
      entity: Entity.EXPERIENCESCORE_DEVICE,
      tagAttributes: [
        { attribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_battery_health_score },
        { attribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_device_platform, isHidden: true },
      ],
    },
    [StandardDashboardType.DEEM_PHYSICAL_AVERAGE_BOOT_TIME_MILLIS_EXPERIENCESCORE_DEVICE]: {
      integration: Integration.INTELLIGENCE,
      entity: Entity.EXPERIENCESCORE_DEVICE,
    },
    [StandardDashboardType.DEEM_PHYSICAL_AVERAGE_SHUTDOWN_TIME_MILLIS_EXPERIENCESCORE_DEVICE]: {
      integration: Integration.INTELLIGENCE,
      entity: Entity.EXPERIENCESCORE_DEVICE,
      tagAttributes: [
        { attribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_average_shutdown_time_millis_score },
        { attribute: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_device_platform, isHidden: true },
      ],
    },
    [StandardDashboardType.DEEM_PHYSICAL_APP_CRASH_COUNT_EXPERIENCESCORE_DESKTOP_APP]: {
      integration: Integration.EMPLOYEE_EXPERIENCE,
      entity: Entity.APPS,
      tagAttributes: [{ attribute: COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_device_platform, isHidden: true }],
    },
    [StandardDashboardType.DEEM_PHYSICAL_APP_HANG_RATE_EXPERIENCESCORE_DESKTOP_APP]: {
      integration: Integration.EMPLOYEE_EXPERIENCE,
      entity: Entity.APPS,
      tagAttributes: [{ attribute: COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_device_platform, isHidden: true }],
    },
    [StandardDashboardType.DEEM_PHYSICAL_APP_CPU_USAGE_EXPERIENCESCORE_DESKTOP_APP]: {
      integration: Integration.INTELLIGENCE,
      entity: Entity.EXPERIENCESCORE_DESKTOP_APP,
    },
    [StandardDashboardType.DEEM_PHYSICAL_APP_GPU_USAGE_EXPERIENCESCORE_DESKTOP_APP]: {
      integration: Integration.INTELLIGENCE,
      entity: Entity.EXPERIENCESCORE_DESKTOP_APP,
    },
    [StandardDashboardType.DEEM_PHYSICAL_APP_MEMORY_USAGE_EXPERIENCESCORE_DESKTOP_APP]: {
      integration: Integration.INTELLIGENCE,
      entity: Entity.EXPERIENCESCORE_DESKTOP_APP,
    },
    [StandardDashboardType.DEEM_PHYSICAL_AVERAGE_APP_UI_UNRESPONSIVE_EVENTS_PER_DEVICE_EXPERIENCESCORE_DESKTOP_APP]: {
      integration: Integration.EMPLOYEE_EXPERIENCE,
      entity: Entity.APPS,
    },
    [StandardDashboardType.DEEM_PHYSICAL_AVERAGE_APP_UI_UNRESPONSIVE_DURATION_EXPERIENCESCORE_DESKTOP_APP]: {
      integration: Integration.EMPLOYEE_EXPERIENCE,
      entity: Entity.APPS,
    },
    [StandardDashboardType.DEEM_PHYSICAL_APP_UI_UNRESPONSIVE_RATE_EXPERIENCESCORE_DESKTOP_APP]: {
      integration: Integration.EMPLOYEE_EXPERIENCE,
      entity: Entity.APPS,
    },
    [StandardDashboardType.DEEM_VIRTUAL_APPS]: {
      integration: Integration.INTELLIGENCE,
      entity: Entity.HORIZON_EXPERIENCESCORE_APP,
      tagAttributes: [{ attribute: COLUMN_NAMES.byFullyQualifiedName.internal_horizon_experiencescore_app_desktop_app_overall_score }],
    },
    [StandardDashboardType.DEEM_VIRTUAL_APP_DETAIL]: {
      integration: Integration.INTELLIGENCE,
      entity: Entity.HORIZON_EXPERIENCESCORE_APP,
    },
    [StandardDashboardType.DEEM_VIRTUAL_LOGON_DURATION_USER_DETAIL]: {
      integration: Integration.INTELLIGENCE,
      entity: Entity.HORIZON_EXPERIENCESCORE_SESSION_LOGON,
      tagAttributes: [
        { attribute: COLUMN_NAMES.byFullyQualifiedName.internal_horizon_experiencescore_session_logon_logon_experience_score },
      ],
    },
    [StandardDashboardType.DEEM_VIRTUAL_LOGON_EXPERIENCE]: {
      integration: Integration.INTELLIGENCE,
      entity: Entity.HORIZON_EXPERIENCESCORE_SESSION_LOGON,
      tagAttributes: [
        { attribute: COLUMN_NAMES.byFullyQualifiedName.internal_horizon_experiencescore_session_logon_logon_experience_score },
      ],
    },
    [StandardDashboardType.DEEM_VIRTUAL_LAN_AVERAGE_LATENCY_HORIZON_EXPERIENCESCORE_SESSION]: {
      integration: Integration.INTELLIGENCE,
      entity: Entity.EXPERIENCESCORE_HORIZON_SESSION,
      tagAttributes: [{ attribute: COLUMN_NAMES.byFullyQualifiedName.internal_horizon_experiencescore_session_lan_average_latency_score }],
    },
    [StandardDashboardType.DEEM_VIRTUAL_WAN_AVERAGE_LATENCY_HORIZON_EXPERIENCESCORE_SESSION]: {
      integration: Integration.INTELLIGENCE,
      entity: Entity.EXPERIENCESCORE_HORIZON_SESSION,
      tagAttributes: [{ attribute: COLUMN_NAMES.byFullyQualifiedName.internal_horizon_experiencescore_session_wan_average_latency_score }],
    },
    [StandardDashboardType.DEEM_VIRTUAL_DOT11_SIGNAL_QUALITY_HORIZON_EXPERIENCESCORE_SESSION]: {
      integration: Integration.INTELLIGENCE,
      entity: Entity.EXPERIENCESCORE_HORIZON_SESSION,
      tagAttributes: [{ attribute: COLUMN_NAMES.byFullyQualifiedName.internal_horizon_experiencescore_session_dot11_score }],
    },
    [StandardDashboardType.DEEM_VIRTUAL_PACKET_LOSS_RATE_HORIZON_EXPERIENCESCORE_SESSION]: {
      integration: Integration.INTELLIGENCE,
      entity: Entity.EXPERIENCESCORE_HORIZON_SESSION,
      tagAttributes: [{ attribute: COLUMN_NAMES.byFullyQualifiedName.internal_horizon_experiencescore_session_lan_packet_loss_rate_score }],
    },
    [StandardDashboardType.DEEM_VIRTUAL_PROTOCOL_LATENCY_HORIZON_EXPERIENCESCORE_SESSION]: {
      integration: Integration.INTELLIGENCE,
      entity: Entity.EXPERIENCESCORE_HORIZON_SESSION,
      tagAttributes: [{ attribute: COLUMN_NAMES.byFullyQualifiedName.internal_horizon_experiencescore_session_protocol_rtt_score }],
    },
    [StandardDashboardType.DEEM_VIRTUAL_CPU_PERCENT_HORIZON_EXPERIENCESCORE_SESSION]: {
      integration: Integration.INTELLIGENCE,
      entity: Entity.EXPERIENCESCORE_HORIZON_SESSION,
      tagAttributes: [{ attribute: COLUMN_NAMES.byFullyQualifiedName.internal_horizon_experiencescore_session_cpu_consumption_score }],
    },
    [StandardDashboardType.DEEM_VIRTUAL_MEMORY_PERCENT_HORIZON_EXPERIENCESCORE_SESSION]: {
      integration: Integration.INTELLIGENCE,
      entity: Entity.EXPERIENCESCORE_HORIZON_SESSION,
      tagAttributes: [{ attribute: COLUMN_NAMES.byFullyQualifiedName.internal_horizon_experiencescore_session_memory_consumption_score }],
    },
    [StandardDashboardType.DEEM_VIRTUAL_DISK_LATENCY_HORIZON_EXPERIENCESCORE_SESSION]: {
      integration: Integration.INTELLIGENCE,
      entity: Entity.EXPERIENCESCORE_HORIZON_SESSION,
      tagAttributes: [{ attribute: COLUMN_NAMES.byFullyQualifiedName.internal_horizon_experiencescore_session_disk_latency_score }],
    },
    [StandardDashboardType.DEEM_VIRTUAL_DISK_IOPS_HORIZON_EXPERIENCESCORE_SESSION]: {
      integration: Integration.INTELLIGENCE,
      entity: Entity.EXPERIENCESCORE_HORIZON_SESSION,
      tagAttributes: [{ attribute: COLUMN_NAMES.byFullyQualifiedName.internal_horizon_experiencescore_session_disk_iops_score }],
    },
    [StandardDashboardType.DEEM_VIRTUAL_APP_CRASH_COUNT_ERROR_DETAILS]: {
      integration: Integration.EMPLOYEE_EXPERIENCE,
      entity: Entity.HORIZON_APPS,
    },
    [StandardDashboardType.DEEM_VIRTUAL_APP_CRASH_COUNT_HORIZON_EXPERIENCESCORE_APP]: {
      integration: Integration.EMPLOYEE_EXPERIENCE,
      entity: Entity.HORIZON_APPS,
    },
    [StandardDashboardType.DEEM_VIRTUAL_APP_HANG_RATE_HORIZON_EXPERIENCESCORE_APP]: {
      integration: Integration.EMPLOYEE_EXPERIENCE,
      entity: Entity.HORIZON_APPS,
    },
    [StandardDashboardType.DEEM_ZOOM_USERS]: {
      integration: Integration.ZOOM,
      entity: Entity.QUALITY_OF_SERVICE,
      tagAttributes: [
        { attribute: COLUMN_NAMES.byFullyQualifiedName.zoom_qos_payload_object_participant_qos_details_quality },
        { attribute: COLUMN_NAMES.byFullyQualifiedName.zoom_qos__device_platform },
      ],
    },
    [StandardDashboardType.DEEM_ZOOM_MEETINGS]: {
      integration: Integration.ZOOM,
      entity: Entity.QUALITY_OF_SERVICE,
      tagAttributes: [
        { attribute: COLUMN_NAMES.byFullyQualifiedName.zoom_qos_payload_object_participant_qos_details_quality },
        { attribute: COLUMN_NAMES.byFullyQualifiedName.zoom_qos__device_platform },
      ],
    },
    [StandardDashboardType.DEEM_ZOOM_CONTRIBUTORS]: {
      integration: Integration.ZOOM,
      entity: Entity.QUALITY_OF_SERVICE,
      tagAttributes: [
        { attribute: COLUMN_NAMES.byFullyQualifiedName.zoom_qos_payload_object_participant_qos_details_quality },
        { attribute: COLUMN_NAMES.byFullyQualifiedName.zoom_qos__device_platform },
      ],
    },
    [StandardDashboardType.DEEM_VIRTUAL_MGT_COMPONENTS]: {
      integration: Integration.HORIZON,
      entity: Entity.EDGE_TITAN,
    },
    [StandardDashboardType.DEEM_VIRTUAL_DAYS_BEFORE_CERTIFICATE_EXPIRATION_HORIZON_EXPERIENCESCORE_CONNECTION_SERVER]: {
      integration: Integration.INTELLIGENCE,
      entity: Entity.HORIZON_EXPERIENCESCORE_CONNECTION_SERVER,
    },
    [StandardDashboardType.DEEM_VIRTUAL_CPU_USAGE_HORIZON_EXPERIENCESCORE_CONNECTION_SERVER]: {
      integration: Integration.INTELLIGENCE,
      entity: Entity.HORIZON_EXPERIENCESCORE_CONNECTION_SERVER,
    },
    [StandardDashboardType.DEEM_VIRTUAL_MEMORY_USAGE_HORIZON_EXPERIENCESCORE_CONNECTION_SERVER]: {
      integration: Integration.INTELLIGENCE,
      entity: Entity.HORIZON_EXPERIENCESCORE_CONNECTION_SERVER,
    },
    [StandardDashboardType.DEEM_VIRTUAL_SESSION_COUNT_HORIZON_EXPERIENCESCORE_CONNECTION_SERVER]: {
      integration: Integration.INTELLIGENCE,
      entity: Entity.HORIZON_EXPERIENCESCORE_CONNECTION_SERVER,
    },
    [StandardDashboardType.DEEM_VIRTUAL_STATUS_HORIZON_EXPERIENCESCORE_CONNECTION_SERVER]: {
      integration: Integration.INTELLIGENCE,
      entity: Entity.HORIZON_EXPERIENCESCORE_CONNECTION_SERVER,
    },
    [StandardDashboardType.DEEM_VIRTUAL_CPU_USAGE_HORIZON_EXPERIENCESCORE_UAG_APPLIANCE]: {
      integration: Integration.INTELLIGENCE,
      entity: Entity.HORIZON_EXPERIENCESCORE_UAG_APPLIANCE,
    },
    [StandardDashboardType.DEEM_VIRTUAL_MEMORY_USAGE_HORIZON_EXPERIENCESCORE_UAG_APPLIANCE]: {
      integration: Integration.INTELLIGENCE,
      entity: Entity.HORIZON_EXPERIENCESCORE_UAG_APPLIANCE,
    },
    [StandardDashboardType.DEEM_VIRTUAL_CONNECTION_COUNT_HORIZON_EXPERIENCESCORE_UAG_APPLIANCE]: {
      integration: Integration.INTELLIGENCE,
      entity: Entity.HORIZON_EXPERIENCESCORE_UAG_APPLIANCE,
    },
    [StandardDashboardType.DEEM_VIRTUAL_STATUS_HORIZON_EXPERIENCESCORE_UAG_APPLIANCE]: {
      integration: Integration.INTELLIGENCE,
      entity: Entity.HORIZON_EXPERIENCESCORE_UAG_APPLIANCE,
    },
    [StandardDashboardType.DEEM_VIRTUAL_DAYS_BEFORE_CERTIFICATE_EXPIRATION_HORIZON_EXPERIENCESCORE_UAG_APPLIANCE]: {
      integration: Integration.INTELLIGENCE,
      entity: Entity.HORIZON_EXPERIENCESCORE_UAG_APPLIANCE,
    },
    [StandardDashboardType.DEEM_VIRTUAL_MGT_COMP_CONNECTION_SERVER_DETAIL]: {
      integration: Integration.INTELLIGENCE,
      entity: Entity.HORIZON_EXPERIENCESCORE_CONNECTION_SERVER,
    },
    [StandardDashboardType.DEEM_VIRTUAL_MGT_COMP_UAG_APPLIANCE_DETAIL]: {
      integration: Integration.INTELLIGENCE,
      entity: Entity.HORIZON_EXPERIENCESCORE_UAG_APPLIANCE,
    },
    [StandardDashboardType.DEEM_VIRTUAL_SESSIONS]: {
      integration: Integration.INTELLIGENCE,
      entity: Entity.EXPERIENCESCORE_HORIZON_SESSION,
    },
  };

  public static readonly devicePropsColNamesByEntity: Record<string, Record<string, string>> = {
    [Entity.EXPERIENCESCORE_DEVICE]: {
      deviceName: NgxTrendResultFlattener.getCounterKey(1),
      model: NgxTrendResultFlattener.getCounterKey(3),
      id1: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_device_guid,
      id2: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_airwatch_device_guid,
    },
    [Entity.EXPERIENCESCORE_DESKTOP_APP]: {
      deviceName: NgxTrendResultFlattener.getCounterKey(1),
      model: NgxTrendResultFlattener.getCounterKey(3),
      id1: COLUMN_NAMES.byFullyQualifiedName.employee_experience_resource_consumption_airwatch_device_guid,
    },
  };

  public static readonly filterAttributeByThreshold: Record<string, string> = {
    [ThresholdAttribute.APP_CPU_USAGE_EXPERIENCESCORE_DESKTOP_APP]:
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_desktop_app_device_platform,
    [ThresholdAttribute.APP_GPU_USAGE_EXPERIENCESCORE_DESKTOP_APP]:
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_desktop_app_device_platform,
    [ThresholdAttribute.APP_MEMORY_USAGE_EXPERIENCESCORE_DESKTOP_APP]:
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_desktop_app_device_platform,
    [ThresholdAttribute.APP_UI_UNRESPONSIVE_RATE_EXPERIENCESCORE_DESKTOP_APP]:
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_desktop_app_device_platform,
    [ThresholdAttribute.AVERAGE_APP_UI_UNRESPONSIVE_DURATION_EXPERIENCESCORE_DESKTOP_APP]:
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_desktop_app_device_platform,
    [ThresholdAttribute.AVERAGE_APP_UI_UNRESPONSIVE_EVENTS_PER_DEVICE_EXPERIENCESCORE_DESKTOP_APP]:
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_desktop_app_device_platform,
    [ThresholdAttribute.APP_CRASH_COUNT_EXPERIENCESCORE_DESKTOP_APP]:
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_device_platform,
    [ThresholdAttribute.APP_HANG_RATE_EXPERIENCESCORE_DESKTOP_APP]:
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_device_platform,
    [ThresholdAttribute.AVERAGE_BOOT_TIME_MILLIS_EXPERIENCESCORE_DEVICE]:
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_device_platform,
    [ThresholdAttribute.AVERAGE_SHUTDOWN_TIME_MILLIS_EXPERIENCESCORE_DEVICE]:
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_device_platform,
    [ThresholdAttribute.BATTERY_HEALTH_EXPERIENCESCORE_DEVICE]:
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_device_platform,
    [ThresholdAttribute.CPU_HEALTH_EXPERIENCESCORE_DEVICE]:
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_device_platform,
    [ThresholdAttribute.CRASH_COUNT_EXPERIENCESCORE_DEVICE]:
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_device_platform,
    [ThresholdAttribute.DEVICE_TEMPERATURE_EXPERIENCESCORE_DEVICE]:
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_device_platform,
    [ThresholdAttribute.GPU_HEALTH_EXPERIENCESCORE_DEVICE]:
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_device_platform,
    [ThresholdAttribute.MEMORY_HEALTH_EXPERIENCESCORE_DEVICE]:
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_device_platform,
    [ThresholdAttribute.APP_CRASH_COUNT_HORIZON_EXPERIENCESCORE_APP]:
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_horizon_apps_device_platform,
    [ThresholdAttribute.APP_HANG_RATE_HORIZON_EXPERIENCESCORE_APP]:
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_horizon_apps_device_platform,
  };

  public static readonly filterNonEditableThresholdAttributes: string[] = [
    ThresholdAttribute.AVERAGE_BOOT_TIME_MILLIS_EXPERIENCESCORE_DEVICE,
    ThresholdAttribute.APP_CPU_USAGE_EXPERIENCESCORE_DESKTOP_APP,
    ThresholdAttribute.APP_GPU_USAGE_EXPERIENCESCORE_DESKTOP_APP,
    ThresholdAttribute.APP_MEMORY_USAGE_EXPERIENCESCORE_DESKTOP_APP,
    ThresholdAttribute.APP_UI_UNRESPONSIVE_RATE_EXPERIENCESCORE_DESKTOP_APP,
    ThresholdAttribute.AVERAGE_APP_UI_UNRESPONSIVE_DURATION_EXPERIENCESCORE_DESKTOP_APP,
    ThresholdAttribute.AVERAGE_APP_UI_UNRESPONSIVE_EVENTS_PER_DEVICE_EXPERIENCESCORE_DESKTOP_APP,
    ThresholdAttribute.APP_CPU_USAGE_HORIZON_EXPERIENCESCORE_APP,
    ThresholdAttribute.APP_MEMORY_USAGE_HORIZON_EXPERIENCESCORE_APP,
  ];

  public static readonly tableColumnNamesForNetworkProtocol = [
    NgxTrendResultFlattener.getCounterKey(1),
    NgxTrendResultFlattener.getCounterKey(0),
    NgxTrendResultFlattener.getCounterKey(7),
    NgxTrendResultFlattener.getCounterKey(8),
    NgxTrendResultFlattener.getCounterKey(9),
    NgxTrendResultFlattener.getCounterKey(10),
    NgxTrendResultFlattener.getCounterKey(2),
    NgxTrendResultFlattener.getCounterKey(3),
    NgxTrendResultFlattener.getCounterKey(4),
  ];

  public static readonly tableColumnNamesForVMPerformance = [
    NgxTrendResultFlattener.getCounterKey(1),
    NgxTrendResultFlattener.getCounterKey(0),
    NgxTrendResultFlattener.getCounterKey(7),
    NgxTrendResultFlattener.getCounterKey(8),
    NgxTrendResultFlattener.getCounterKey(9),
    NgxTrendResultFlattener.getCounterKey(2),
    NgxTrendResultFlattener.getCounterKey(3),
    NgxTrendResultFlattener.getCounterKey(4),
  ];

  public static readonly tableColumnNamesForDeemDevice = [
    NgxTrendResultFlattener.getCounterKey(1),
    NgxTrendResultFlattener.getCounterKey(2),
    NgxTrendResultFlattener.getCounterKey(3),
    NgxTrendResultFlattener.getCounterKey(0),
    COLUMN_NAMES.byName.session,
    NgxTrendResultFlattener.getCounterKey(4),
  ];

  public static readonly tableColumnNamesForDeemErrorByDefault = [
    NgxTrendResultFlattener.getCounterKey(0),
    NgxTrendResultFlattener.getCounterKey(1),
    NgxTrendResultFlattener.getCounterKey(2),
    NgxTrendResultFlattener.getCounterKey(3),
  ];

  public static readonly tableColumnNamesForDeemDesktopAppCpuGpuMemoryDevice = [
    NgxTrendResultFlattener.getCounterKey(1),
    NgxTrendResultFlattener.getCounterKey(2),
    NgxTrendResultFlattener.getCounterKey(3),
    NgxTrendResultFlattener.getCounterKey(0),
    NgxTrendResultFlattener.getCounterKey(4),
    NgxTrendResultFlattener.getCounterKey(5),
    NgxTrendResultFlattener.getCounterKey(6),
    NgxTrendResultFlattener.getCounterKey(7),
  ];

  public static readonly tableColumnNamesForDeemDesktopAppDevices = [
    NgxTrendResultFlattener.getCounterKey(1),
    NgxTrendResultFlattener.getCounterKey(2),
    NgxTrendResultFlattener.getCounterKey(3),
    NgxTrendResultFlattener.getCounterKey(0),
    NgxTrendResultFlattener.getCounterKey(4),
  ];

  public static readonly tableColumnNamesForMgtComponents = [NgxTrendResultFlattener.getCounterKey(1), COLUMN_NAMES.byName.count_of_scores];

  public static readonly tableColumnLabelsForDeemErrorByDefault = {
    [NgxTrendResultFlattener.getCounterKey(0)]: 'COMMON_MESSAGES.OCCURENCES',
    [NgxTrendResultFlattener.getCounterKey(1)]: 'COMMON_MESSAGES.USERS_AFFECTED',
    [NgxTrendResultFlattener.getCounterKey(2)]: 'COMMON_MESSAGES.FIRST_OCCURRED',
    [NgxTrendResultFlattener.getCounterKey(3)]: 'COMMON_MESSAGES.LAST_OCCURRED',
  };

  public static readonly tableColumnLabelsForDeemDeviceByDefault = {
    [NgxTrendResultFlattener.getCounterKey(1)]: 'DEEM_COMMON_MESSAGES.DEVICE_NAME',
    [NgxTrendResultFlattener.getCounterKey(2)]: 'DEEM_COMMON_MESSAGES.PLATFORM',
    [NgxTrendResultFlattener.getCounterKey(3)]: 'DEEM_COMMON_MESSAGES.DEVICE_MODEL',
    [NgxTrendResultFlattener.getCounterKey(4)]: 'STANDARD_DASHBOARD.DEVICES.DEVICE_HEALTH_SCORE',
  };

  public static readonly tableColumnLabelsForDeemSessionByDefault = {
    [NgxTrendResultFlattener.getCounterKey(1)]: 'STANDARD_DASHBOARD.USER_PROFILE.USER_NAME',
    [NgxTrendResultFlattener.getCounterKey(2)]: 'DEEM_COMMON_MESSAGES.POD_OR_EDGE_NAME',
    [NgxTrendResultFlattener.getCounterKey(3)]: 'DEEM_COMMON_MESSAGES.POOL_OR_FARM_NAME',
    [NgxTrendResultFlattener.getCounterKey(4)]: 'DEEM_COMMON_MESSAGES.CLIENT_DEVICE_NAME',
  };

  public static readonly tableColumnLabelsForDeemDesktopAppUIUnresponsiveCount = {
    [NgxTrendResultFlattener.getCounterKey(1)]: 'DEEM_COMMON_MESSAGES.DEVICE_NAME',
    [NgxTrendResultFlattener.getCounterKey(2)]: 'COMMON_MESSAGES.USER',
    [NgxTrendResultFlattener.getCounterKey(3)]: 'DEEM_COMMON_MESSAGES.DEVICE_MODEL',
    [NgxTrendResultFlattener.getCounterKey(0)]: 'SOLUTIONS.APP_UI_UNRESPONSIVE_COUNT',
    [NgxTrendResultFlattener.getCounterKey(4)]: 'SOLUTIONS.AVERAGE_APP_UI_UNRESPONSIVE_DURATION',
  };

  public static readonly tableColumnLabelsForConnectionServer = {
    [NgxTrendResultFlattener.getCounterKey(1)]: 'DEEM_VIRTUAL_SUMMARY.CONNECTION_SERVER_NAME',
    [COLUMN_NAMES.byName.count_of_scores]: 'DEEM_COMMON_MESSAGES.COUNT_OF_SCORES',
  };

  public static readonly tableColumnLabelsForUAGAppliance = {
    [NgxTrendResultFlattener.getCounterKey(1)]: 'DEEM_VIRTUAL_SUMMARY.UAG_APPLIANCE_NAME',
    [COLUMN_NAMES.byName.count_of_scores]: 'DEEM_COMMON_MESSAGES.COUNT_OF_SCORES',
  };

  public static readonly deviceCrashLeaderboards = [
    {
      subtype: StandardWidgetSubtype.DEVICES_PERFORMANCE_CRASH_OS_VERSION,
      titleKey: 'STANDARD_DASHBOARD.OS_VERSIONS',
      isRollup: true,
      tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_OS_VERSIONS',
    },
    {
      subtype: StandardWidgetSubtype.DEVICES_PERFORMANCE_CRASH_PROCESSOR,
      titleKey: 'STANDARD_DASHBOARD.DEVICES_DETAILS.PROCESSOR',
      isRollup: true,
      tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_PROCESSOR',
    },
    {
      subtype: StandardWidgetSubtype.DEVICES_PERFORMANCE_CRASH_MODEL,
      titleKey: 'STANDARD_DASHBOARD.DEVICES.DEVICE_MODELS',
      isRollup: true,
      tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_DEVICE_MODELS',
    },
    {
      subtype: StandardWidgetSubtype.DEVICES_PERFORMANCE_CRASH_ORG_GROUP,
      titleKey: 'STANDARD_DASHBOARD.APP_DEPLOYMENT.ORGANIZATION_GROUP',
      isRollup: true,
      tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_ORG_GROUP',
    },
  ];

  public static readonly desktopAppCrashLeaderboards = [
    {
      subtype: StandardWidgetSubtype.DEEM_APP_ERRORS_COUNT_BY_OS_VERSION,
      titleKey: 'STANDARD_DASHBOARD.OS_VERSIONS',
      tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_OS_VERSIONS',
    },
    {
      subtype: StandardWidgetSubtype.DEEM_APP_ERRORS_COUNT_BY_DEVICE_MODEL,
      titleKey: 'STANDARD_DASHBOARD.DEVICES.DEVICE_MODELS',
      tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_DEVICE_MODELS',
    },
    {
      subtype: StandardWidgetSubtype.DEEM_APP_ERRORS_COUNT_BY_APP_VERSION,
      titleKey: 'APTELIGENT.APP_VERSIONS',
      tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_APP_VERSIONS',
    },
    {
      subtype: StandardWidgetSubtype.DEEM_APP_ERRORS_COUNT_BY_APP_NAME,
      titleKey: 'STANDARD_DASHBOARD.APP_EXECUTABLE',
      tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_APP_EXECUTABLE',
    },
  ];

  public static readonly desktopAppHangLeaderboards = [
    {
      subtype: StandardWidgetSubtype.DEEM_APP_HANG_COUNT_BY_OS_VERSION,
      titleKey: 'STANDARD_DASHBOARD.OS_VERSIONS',
      tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_OS_VERSIONS',
    },
    {
      subtype: StandardWidgetSubtype.DEEM_APP_HANG_COUNT_BY_DEVICE_MODEL,
      titleKey: 'STANDARD_DASHBOARD.DEVICES.DEVICE_MODELS',
      tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_DEVICE_MODELS',
    },
    {
      subtype: StandardWidgetSubtype.DEEM_APP_HANG_COUNT_BY_APP_VERSION,
      titleKey: 'APTELIGENT.APP_VERSIONS',
      tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_APP_VERSIONS',
    },
    {
      subtype: StandardWidgetSubtype.DEEM_APP_HANG_COUNT_BY_APP_NAME,
      titleKey: 'STANDARD_DASHBOARD.APP_EXECUTABLE',
      tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_APP_EXECUTABLE',
    },
  ];

  public static readonly desktopAppCPUUsageLeaderboards = [
    {
      subtype: StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_CPU_USAGE_BY_FRIENDLY_OS_VERSION_NAME,
      titleKey: 'STANDARD_DASHBOARD.OS_VERSIONS',
      dataUnit: [undefined, DataUnit.PERCENT],
      tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_OS_VERSIONS',
    },
    {
      subtype: StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_CPU_USAGE_BY_DEVICE_MODEL,
      titleKey: 'STANDARD_DASHBOARD.DEVICES.DEVICE_MODELS',
      dataUnit: [undefined, DataUnit.PERCENT],
      tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_DEVICE_MODELS',
    },
    {
      subtype: StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_CPU_USAGE_BY_ORG_GROUP,
      titleKey: 'STANDARD_DASHBOARD.ORGANIZATION_GROUP',
      dataUnit: [undefined, DataUnit.PERCENT],
      tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_ORG_GROUP',
    },
    {
      subtype: StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_CPU_USAGE_BY_DEVICE_TAGS,
      titleKey: 'STANDARD_DASHBOARD.DEVICES_DETAILS.DEVICE_TAGS',
      dataUnit: [undefined, DataUnit.PERCENT],
      tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_DEVICE_TAGS',
    },
  ];

  public static readonly desktopAppMemoryUsageLeaderboards = [
    {
      subtype: StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_MEMORY_USAGE_BY_FRIENDLY_OS_VERSION_NAME,
      titleKey: 'STANDARD_DASHBOARD.OS_VERSIONS',
      dataUnit: [undefined, DataUnit.PERCENT],
      tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_OS_VERSIONS',
    },
    {
      subtype: StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_MEMORY_USAGE_BY_DEVICE_MODEL,
      titleKey: 'STANDARD_DASHBOARD.DEVICES.DEVICE_MODELS',
      dataUnit: [undefined, DataUnit.PERCENT],
      tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_DEVICE_MODELS',
    },
    {
      subtype: StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_MEMORY_USAGE_BY_ORG_GROUP,
      titleKey: 'STANDARD_DASHBOARD.ORGANIZATION_GROUP',
      dataUnit: [undefined, DataUnit.PERCENT],
      tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_ORG_GROUP',
    },
    {
      subtype: StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_MEMORY_USAGE_BY_DEVICE_TAGS,
      titleKey: 'STANDARD_DASHBOARD.DEVICES_DETAILS.DEVICE_TAGS',
      dataUnit: [undefined, DataUnit.PERCENT],
      tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_DEVICE_TAGS',
    },
  ];

  public static readonly tableSettingsByDefault = new LocalDataGridSettings({
    sortOns: [
      new SortOn({
        by: NgxTrendResultFlattener.getCounterKey(0),
        reverse: true,
      }),
    ],
    pagedRequest: new PagedRequest({
      size: 10,
    }),
  });

  public static readonly AppPlatformToDashboardPlatformMap: Record<string, string> = {
    [AppPlatform.APPLE_MACOS]: SubDashboardPlatform.MACOS,
    [AppPlatform.WINDOWS_DESKTOP]: SubDashboardPlatform.WINDOWS,
  };

  public static readonly DeemContributorDetailConfig: Record<string, DeemContributorDetailPageConfigProps> = {
    [ThresholdAttribute.DEVICE_TEMPERATURE_EXPERIENCESCORE_DEVICE]: {
      visibleWidgetSubTypes: [
        StandardWidgetSubtype.DEVICES_PERFORMANCE_CPU_TEMPERATURE,
        StandardWidgetSubtype.DEVICES_PERFORMANCE_CPU_TEMPERATURE_OS_VERSION,
        StandardWidgetSubtype.DEVICES_PERFORMANCE_CPU_TEMPERATURE_PROCESSOR,
        StandardWidgetSubtype.DEVICES_PERFORMANCE_CPU_TEMPERATURE_MODEL,
        StandardWidgetSubtype.DEVICES_PERFORMANCE_CPU_TEMPERATURE_ORG_GROUP,
        StandardWidgetSubtype.DEVICES_PERFORMANCE_TOTAL_DEVICES,
        StandardWidgetSubtype.__DEVICES_PERFORMANCE_CPU_TEMPERATURE_TABLE,
      ],
      widgetConfigs: [
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype.DEVICES_PERFORMANCE_CPU_TEMPERATURE,
          chartHeaderConfig: {
            scoreSummaryWidgetId: StandardWidgetSubtype.DEVICES_PERFORMANCE_CPU_TEMPERATURE_STAT,
            customChartHeaderConfig: [
              {
                widgetId: StandardWidgetSubtype.DEVICES_PERFORMANCE_TOTAL_DEVICES,
                titleKey: 'COMMON_MESSAGES.TOTAL_DEVICES',
              },
            ],
            showPlatform: true,
          },
          chartYAxisLabelOverrideKey: 'COMMON_MESSAGES.DEVICE_COUNT',
          leaderboards: [
            {
              subtype: StandardWidgetSubtype.DEVICES_PERFORMANCE_CPU_TEMPERATURE_OS_VERSION,
              titleKey: 'STANDARD_DASHBOARD.OS_VERSIONS',
              isRollup: true,
              tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_OS_VERSIONS',
            },
            {
              subtype: StandardWidgetSubtype.DEVICES_PERFORMANCE_CPU_TEMPERATURE_PROCESSOR,
              titleKey: 'STANDARD_DASHBOARD.DEVICES_DETAILS.PROCESSOR',
              isRollup: true,
              tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_PROCESSOR',
            },
            {
              subtype: StandardWidgetSubtype.DEVICES_PERFORMANCE_CPU_TEMPERATURE_MODEL,
              titleKey: 'STANDARD_DASHBOARD.DEVICES.DEVICE_MODELS',
              isRollup: true,
              tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_DEVICE_MODELS',
            },
            {
              subtype: StandardWidgetSubtype.DEVICES_PERFORMANCE_CPU_TEMPERATURE_ORG_GROUP,
              titleKey: 'STANDARD_DASHBOARD.APP_DEPLOYMENT.ORGANIZATION_GROUP',
              isRollup: true,
              tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_ORG_GROUP',
            },
          ],
          showOuterActions: true,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_CHART_COMPONENT,
          widgetId: StandardWidgetSubtype.__DEVICES_PERFORMANCE_CPU_TEMPERATURE_TABLE,
          chartType: AggregationWidgetChartType.TABLE,
          tableColumnNames: DeemDashboardConfig.tableColumnNamesForDeemDevice,
          tableColumnLabels: {
            [NgxTrendResultFlattener.getCounterKey(0)]: 'STANDARD_DASHBOARD.DEVICES.AVG_CPU_TEMPERATURE',
            ...DeemDashboardConfig.tableColumnLabelsForDeemDeviceByDefault,
            [COLUMN_NAMES.byName.session]: 'STANDARD_DASHBOARD.DEVICES.TEMPERATURE_SESSION',
          },
          tableSettings: DeemDashboardConfig.tableSettingsByDefault,
        },
      ],
    },
    [ThresholdAttribute.CRASH_COUNT_EXPERIENCESCORE_DEVICE_WINDOWS]: {
      visibleWidgetSubTypes: [
        StandardWidgetSubtype.DEVICES_PERFORMANCE_CRASH,
        StandardWidgetSubtype.DEVICES_PERFORMANCE_CRASH_OS_VERSION,
        StandardWidgetSubtype.DEVICES_PERFORMANCE_CRASH_PROCESSOR,
        StandardWidgetSubtype.DEVICES_PERFORMANCE_CRASH_MODEL,
        StandardWidgetSubtype.DEVICES_PERFORMANCE_CRASH_ORG_GROUP,
        StandardWidgetSubtype.__DEVICES_PERFORMANCE_CRASH_COUNT_TABLE,
        StandardWidgetSubtype.DEVICES_PERFORMANCE_TOTAL_DEVICES,
        StandardWidgetSubtype.LIST_OF_OS_CRASHES_FOR_WINDOWS,
      ],
      widgetConfigs: [
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype.DEVICES_PERFORMANCE_CRASH,
          chartHeaderConfig: {
            scoreSummaryWidgetId: StandardWidgetSubtype.DEVICES_PERFORMANCE_CRASH_STAT,
            customChartHeaderConfig: [
              {
                widgetId: StandardWidgetSubtype.DEVICES_PERFORMANCE_TOTAL_DEVICES,
                titleKey: 'COMMON_MESSAGES.TOTAL_DEVICES',
              },
            ],
            showPlatform: true,
          },
          chartYAxisLabelOverrideKey: 'COMMON_MESSAGES.DEVICE_COUNT',
          leaderboards: DeemDashboardConfig.deviceCrashLeaderboards,
          showOuterActions: true,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_CHART_COMPONENT,
          widgetId: StandardWidgetSubtype.__DEVICES_PERFORMANCE_CRASH_COUNT_TABLE,
          chartType: AggregationWidgetChartType.TABLE,
          tableColumnNames: DeemDashboardConfig.tableColumnNamesForDeemDevice,
          tableColumnLabels: {
            [NgxTrendResultFlattener.getCounterKey(0)]: 'DEEM_COMMON_MESSAGES.AVERAGE_OS_CRASHES',
            ...DeemDashboardConfig.tableColumnLabelsForDeemDeviceByDefault,
            [COLUMN_NAMES.byName.session]: 'STANDARD_DASHBOARD.DEVICES.CRASH_SESSION',
          },
          tableSettings: DeemDashboardConfig.tableSettingsByDefault,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_CHART_COMPONENT,
          widgetId: StandardWidgetSubtype.LIST_OF_OS_CRASHES_FOR_WINDOWS,
          chartType: AggregationWidgetChartType.TABLE,
          tableColumnNames: [
            COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_module,
            ...DeemDashboardConfig.tableColumnNamesForDeemErrorByDefault,
          ],
          tableColumnLabels: {
            [COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_module]: 'COMMON_MESSAGES.ERROR_NAME',
            [NgxTrendResultFlattener.getCounterKey(0)]: 'COMMON_MESSAGES.DEVICES_AFFECTED',
            [NgxTrendResultFlattener.getCounterKey(1)]: 'COMMON_MESSAGES.OCCURENCES',
            [NgxTrendResultFlattener.getCounterKey(2)]: 'COMMON_MESSAGES.LAST_OCCURRED',
            [NgxTrendResultFlattener.getCounterKey(3)]: 'COMMON_MESSAGES.FIRST_OCCURRED',
          },
          tableSettings: new LocalDataGridSettings({
            sortOns: [
              new SortOn({
                by: NgxTrendResultFlattener.getCounterKey(1),
                reverse: true,
              }),
            ],
            pagedRequest: new PagedRequest({
              size: 10,
            }),
          }),
        },
      ],
    },
    [ThresholdAttribute.CRASH_COUNT_EXPERIENCESCORE_DEVICE_MACOS]: {
      visibleWidgetSubTypes: [
        StandardWidgetSubtype.DEVICES_PERFORMANCE_CRASH,
        StandardWidgetSubtype.DEVICES_PERFORMANCE_CRASH_OS_VERSION,
        StandardWidgetSubtype.DEVICES_PERFORMANCE_CRASH_PROCESSOR,
        StandardWidgetSubtype.DEVICES_PERFORMANCE_CRASH_MODEL,
        StandardWidgetSubtype.DEVICES_PERFORMANCE_CRASH_ORG_GROUP,
        StandardWidgetSubtype.__DEVICES_PERFORMANCE_CRASH_COUNT_TABLE,
        StandardWidgetSubtype.DEVICES_PERFORMANCE_TOTAL_DEVICES,
        StandardWidgetSubtype.LIST_OF_OS_CRASHES_FOR_MACOS,
      ],
      widgetConfigs: [
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype.DEVICES_PERFORMANCE_CRASH,
          chartHeaderConfig: {
            scoreSummaryWidgetId: StandardWidgetSubtype.DEVICES_PERFORMANCE_CRASH_STAT,
            customChartHeaderConfig: [
              {
                widgetId: StandardWidgetSubtype.DEVICES_PERFORMANCE_TOTAL_DEVICES,
                titleKey: 'COMMON_MESSAGES.TOTAL_DEVICES',
              },
            ],
            showPlatform: true,
          },
          chartYAxisLabelOverrideKey: 'COMMON_MESSAGES.DEVICE_COUNT',
          leaderboards: DeemDashboardConfig.deviceCrashLeaderboards,
          showOuterActions: true,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_CHART_COMPONENT,
          widgetId: StandardWidgetSubtype.__DEVICES_PERFORMANCE_CRASH_COUNT_TABLE,
          chartType: AggregationWidgetChartType.TABLE,
          tableColumnNames: DeemDashboardConfig.tableColumnNamesForDeemDevice,
          tableColumnLabels: {
            ...DeemDashboardConfig.tableColumnLabelsForDeemDeviceByDefault,
            [COLUMN_NAMES.byName.session]: 'STANDARD_DASHBOARD.DEVICES.CRASH_SESSION',
          },
          tableSettings: DeemDashboardConfig.tableSettingsByDefault,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_CHART_COMPONENT,
          widgetId: StandardWidgetSubtype.LIST_OF_OS_CRASHES_FOR_MACOS,
          chartType: AggregationWidgetChartType.TABLE,
          tableColumnNames: [
            COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_process,
            ...DeemDashboardConfig.tableColumnNamesForDeemErrorByDefault,
          ],
          tableColumnLabels: {
            [COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_process]: 'COMMON_MESSAGES.PROCESS',
            [NgxTrendResultFlattener.getCounterKey(0)]: 'COMMON_MESSAGES.DEVICES_AFFECTED',
            [NgxTrendResultFlattener.getCounterKey(1)]: 'COMMON_MESSAGES.OCCURENCES',
            [NgxTrendResultFlattener.getCounterKey(2)]: 'COMMON_MESSAGES.LAST_OCCURRED',
            [NgxTrendResultFlattener.getCounterKey(3)]: 'COMMON_MESSAGES.FIRST_OCCURRED',
          },
          tableSettings: new LocalDataGridSettings({
            sortOns: [
              new SortOn({
                by: NgxTrendResultFlattener.getCounterKey(1),
                reverse: true,
              }),
            ],
            pagedRequest: new PagedRequest({
              size: 10,
            }),
          }),
        },
      ],
    },
    [ThresholdAttribute.CPU_HEALTH_EXPERIENCESCORE_DEVICE]: {
      visibleWidgetSubTypes: [
        StandardWidgetSubtype.DEVICES_PERFORMANCE_CPU,
        StandardWidgetSubtype.DEVICES_PERFORMANCE_CPU_OS_VERSION,
        StandardWidgetSubtype.DEVICES_PERFORMANCE_CPU_PROCESSOR,
        StandardWidgetSubtype.DEVICES_PERFORMANCE_CPU_MODEL,
        StandardWidgetSubtype.DEVICES_PERFORMANCE_CPU_ORG_GROUP,
        StandardWidgetSubtype.DEVICES_PERFORMANCE_TOTAL_DEVICES,
        StandardWidgetSubtype.__DEVICES_PERFORMANCE_CPU_TABLE,
      ],
      widgetConfigs: [
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype.DEVICES_PERFORMANCE_CPU,
          chartHeaderConfig: {
            scoreSummaryWidgetId: StandardWidgetSubtype.DEVICES_PERFORMANCE_CPU_STAT,
            customChartHeaderConfig: [
              {
                widgetId: StandardWidgetSubtype.DEVICES_PERFORMANCE_TOTAL_DEVICES,
                titleKey: 'COMMON_MESSAGES.TOTAL_DEVICES',
              },
            ],
            showPlatform: true,
          },
          chartYAxisLabelOverrideKey: 'COMMON_MESSAGES.DEVICE_COUNT',
          leaderboards: [
            {
              subtype: StandardWidgetSubtype.DEVICES_PERFORMANCE_CPU_OS_VERSION,
              titleKey: 'STANDARD_DASHBOARD.OS_VERSIONS',
              isRollup: true,
              tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_OS_VERSIONS',
            },
            {
              subtype: StandardWidgetSubtype.DEVICES_PERFORMANCE_CPU_PROCESSOR,
              titleKey: 'STANDARD_DASHBOARD.DEVICES_DETAILS.PROCESSOR',
              isRollup: true,
              tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_PROCESSOR',
            },
            {
              subtype: StandardWidgetSubtype.DEVICES_PERFORMANCE_CPU_MODEL,
              titleKey: 'STANDARD_DASHBOARD.DEVICES.DEVICE_MODELS',
              isRollup: true,
              tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_DEVICE_MODELS',
            },
            {
              subtype: StandardWidgetSubtype.DEVICES_PERFORMANCE_CPU_ORG_GROUP,
              titleKey: 'STANDARD_DASHBOARD.APP_DEPLOYMENT.ORGANIZATION_GROUP',
              isRollup: true,
              tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_ORG_GROUP',
            },
          ],
          showOuterActions: true,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_CHART_COMPONENT,
          widgetId: StandardWidgetSubtype.__DEVICES_PERFORMANCE_CPU_TABLE,
          chartType: AggregationWidgetChartType.TABLE,
          tableColumnNames: DeemDashboardConfig.tableColumnNamesForDeemDevice,
          tableColumnLabels: {
            [NgxTrendResultFlattener.getCounterKey(0)]: 'STANDARD_DASHBOARD.DEVICES.AVG_CPU_USAGE',
            ...DeemDashboardConfig.tableColumnLabelsForDeemDeviceByDefault,
            [COLUMN_NAMES.byName.session]: 'STANDARD_DASHBOARD.DEVICES.CPU_SESSION',
          },
          tableSettings: DeemDashboardConfig.tableSettingsByDefault,
        },
      ],
    },
    [ThresholdAttribute.MEMORY_HEALTH_EXPERIENCESCORE_DEVICE]: {
      visibleWidgetSubTypes: [
        StandardWidgetSubtype.DEVICES_PERFORMANCE_MEMORY,
        StandardWidgetSubtype.DEVICES_PERFORMANCE_MEMORY_OS_VERSION,
        StandardWidgetSubtype.DEVICES_PERFORMANCE_MEMORY_PROCESSOR,
        StandardWidgetSubtype.DEVICES_PERFORMANCE_MEMORY_MODEL,
        StandardWidgetSubtype.DEVICES_PERFORMANCE_MEMORY_ORG_GROUP,
        StandardWidgetSubtype.DEVICES_PERFORMANCE_TOTAL_DEVICES,
        StandardWidgetSubtype.__DEVICES_PERFORMANCE_MEMORY_TABLE,
      ],
      widgetConfigs: [
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype.DEVICES_PERFORMANCE_MEMORY,
          chartHeaderConfig: {
            scoreSummaryWidgetId: StandardWidgetSubtype.DEVICES_PERFORMANCE_MEMORY_STAT,
            customChartHeaderConfig: [
              {
                widgetId: StandardWidgetSubtype.DEVICES_PERFORMANCE_TOTAL_DEVICES,
                titleKey: 'COMMON_MESSAGES.TOTAL_DEVICES',
              },
            ],
            showPlatform: true,
          },
          chartYAxisLabelOverrideKey: 'COMMON_MESSAGES.DEVICE_COUNT',
          leaderboards: [
            {
              subtype: StandardWidgetSubtype.DEVICES_PERFORMANCE_MEMORY_OS_VERSION,
              titleKey: 'STANDARD_DASHBOARD.OS_VERSIONS',
              isRollup: true,
              tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_OS_VERSIONS',
            },
            {
              subtype: StandardWidgetSubtype.DEVICES_PERFORMANCE_MEMORY_PROCESSOR,
              titleKey: 'STANDARD_DASHBOARD.DEVICES_DETAILS.PROCESSOR',
              isRollup: true,
              tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_PROCESSOR',
            },
            {
              subtype: StandardWidgetSubtype.DEVICES_PERFORMANCE_MEMORY_MODEL,
              titleKey: 'STANDARD_DASHBOARD.DEVICES.DEVICE_MODELS',
              isRollup: true,
              tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_DEVICE_MODELS',
            },
            {
              subtype: StandardWidgetSubtype.DEVICES_PERFORMANCE_MEMORY_ORG_GROUP,
              titleKey: 'STANDARD_DASHBOARD.APP_DEPLOYMENT.ORGANIZATION_GROUP',
              isRollup: true,
              tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_ORG_GROUP',
            },
          ],
          showOuterActions: true,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_CHART_COMPONENT,
          widgetId: StandardWidgetSubtype.__DEVICES_PERFORMANCE_MEMORY_TABLE,
          chartType: AggregationWidgetChartType.TABLE,
          tableColumnNames: DeemDashboardConfig.tableColumnNamesForDeemDevice,
          tableColumnLabels: {
            [NgxTrendResultFlattener.getCounterKey(0)]: 'STANDARD_DASHBOARD.DEVICES.AVG_MEMORY_USAGE',
            ...DeemDashboardConfig.tableColumnLabelsForDeemDeviceByDefault,
            [COLUMN_NAMES.byName.session]: 'STANDARD_DASHBOARD.DEVICES.MEMORY_SESSION',
          },
          tableSettings: DeemDashboardConfig.tableSettingsByDefault,
        },
      ],
    },
    [ThresholdAttribute.GPU_HEALTH_EXPERIENCESCORE_DEVICE]: {
      visibleWidgetSubTypes: [
        StandardWidgetSubtype.DEVICES_PERFORMANCE_GPU,
        StandardWidgetSubtype.DEVICES_PERFORMANCE_GPU_OS_VERSION,
        StandardWidgetSubtype.DEVICES_PERFORMANCE_GPU_PROCESSOR,
        StandardWidgetSubtype.DEVICES_PERFORMANCE_GPU_MODEL,
        StandardWidgetSubtype.DEVICES_PERFORMANCE_GPU_ORG_GROUP,
        StandardWidgetSubtype.DEVICES_PERFORMANCE_TOTAL_DEVICES,
        StandardWidgetSubtype.__DEVICES_PERFORMANCE_GPU_TABLE,
      ],
      widgetConfigs: [
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype.DEVICES_PERFORMANCE_GPU,
          chartHeaderConfig: {
            scoreSummaryWidgetId: StandardWidgetSubtype.DEVICES_PERFORMANCE_GPU_STAT,
            customChartHeaderConfig: [
              {
                widgetId: StandardWidgetSubtype.DEVICES_PERFORMANCE_TOTAL_DEVICES,
                titleKey: 'COMMON_MESSAGES.TOTAL_DEVICES',
              },
            ],
            showPlatform: true,
          },
          chartYAxisLabelOverrideKey: 'COMMON_MESSAGES.DEVICE_COUNT',
          leaderboards: [
            {
              subtype: StandardWidgetSubtype.DEVICES_PERFORMANCE_GPU_OS_VERSION,
              titleKey: 'STANDARD_DASHBOARD.OS_VERSIONS',
              isRollup: true,
              tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_OS_VERSIONS',
            },
            {
              subtype: StandardWidgetSubtype.DEVICES_PERFORMANCE_GPU_PROCESSOR,
              titleKey: 'STANDARD_DASHBOARD.DEVICES_DETAILS.PROCESSOR',
              isRollup: true,
              tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_PROCESSOR',
            },
            {
              subtype: StandardWidgetSubtype.DEVICES_PERFORMANCE_GPU_MODEL,
              titleKey: 'STANDARD_DASHBOARD.DEVICES.DEVICE_MODELS',
              isRollup: true,
              tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_DEVICE_MODELS',
            },
            {
              subtype: StandardWidgetSubtype.DEVICES_PERFORMANCE_GPU_ORG_GROUP,
              titleKey: 'STANDARD_DASHBOARD.APP_DEPLOYMENT.ORGANIZATION_GROUP',
              isRollup: true,
              tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_ORG_GROUP',
            },
          ],
          showOuterActions: true,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_CHART_COMPONENT,
          widgetId: StandardWidgetSubtype.__DEVICES_PERFORMANCE_GPU_TABLE,
          chartType: AggregationWidgetChartType.TABLE,
          tableColumnNames: DeemDashboardConfig.tableColumnNamesForDeemDevice,
          tableColumnLabels: {
            [NgxTrendResultFlattener.getCounterKey(0)]: 'STANDARD_DASHBOARD.DEVICES.AVG_GPU_USAGE',
            ...DeemDashboardConfig.tableColumnLabelsForDeemDeviceByDefault,
            [COLUMN_NAMES.byName.session]: 'STANDARD_DASHBOARD.DEVICES.GPU_SESSION',
          },
          tableSettings: DeemDashboardConfig.tableSettingsByDefault,
        },
      ],
    },
    [ThresholdAttribute.BATTERY_HEALTH_EXPERIENCESCORE_DEVICE]: {
      visibleWidgetSubTypes: [
        StandardWidgetSubtype.DEVICES_PERFORMANCE_BATTERY,
        StandardWidgetSubtype.DEVICES_PERFORMANCE_BATTERY_OS_VERSION,
        StandardWidgetSubtype.DEVICES_PERFORMANCE_BATTERY_PROCESSOR,
        StandardWidgetSubtype.DEVICES_PERFORMANCE_BATTERY_MODEL,
        StandardWidgetSubtype.DEVICES_PERFORMANCE_BATTERY_ORG_GROUP,
        StandardWidgetSubtype.DEVICES_PERFORMANCE_TOTAL_DEVICES,
        StandardWidgetSubtype.__DEVICES_PERFORMANCE_BATTERY_TABLE,
      ],
      widgetConfigs: [
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype.DEVICES_PERFORMANCE_BATTERY,
          chartHeaderConfig: {
            scoreSummaryWidgetId: StandardWidgetSubtype.DEVICES_PERFORMANCE_BATTERY_STAT,
            customChartHeaderConfig: [
              {
                widgetId: StandardWidgetSubtype.DEVICES_PERFORMANCE_TOTAL_DEVICES,
                titleKey: 'COMMON_MESSAGES.TOTAL_DEVICES',
              },
            ],
            showPlatform: true,
          },
          chartYAxisLabelOverrideKey: 'COMMON_MESSAGES.DEVICE_COUNT',
          leaderboards: [
            {
              subtype: StandardWidgetSubtype.DEVICES_PERFORMANCE_BATTERY_OS_VERSION,
              titleKey: 'STANDARD_DASHBOARD.OS_VERSIONS',
              isRollup: true,
              tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_OS_VERSIONS',
            },
            {
              subtype: StandardWidgetSubtype.DEVICES_PERFORMANCE_BATTERY_PROCESSOR,
              titleKey: 'STANDARD_DASHBOARD.DEVICES_DETAILS.PROCESSOR',
              isRollup: true,
              tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_PROCESSOR',
            },
            {
              subtype: StandardWidgetSubtype.DEVICES_PERFORMANCE_BATTERY_MODEL,
              titleKey: 'STANDARD_DASHBOARD.DEVICES.DEVICE_MODELS',
              isRollup: true,
              tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_DEVICE_MODELS',
            },
            {
              subtype: StandardWidgetSubtype.DEVICES_PERFORMANCE_BATTERY_ORG_GROUP,
              titleKey: 'STANDARD_DASHBOARD.APP_DEPLOYMENT.ORGANIZATION_GROUP',
              isRollup: true,
              tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_ORG_GROUP',
            },
          ],
          showOuterActions: true,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_CHART_COMPONENT,
          widgetId: StandardWidgetSubtype.__DEVICES_PERFORMANCE_BATTERY_TABLE,
          chartType: AggregationWidgetChartType.TABLE,
          tableColumnNames: DeemDashboardConfig.tableColumnNamesForDeemDevice,
          tableColumnLabels: {
            [NgxTrendResultFlattener.getCounterKey(0)]: 'STANDARD_DASHBOARD.DEVICES.AVG_BATTERY_HEALTH',
            ...DeemDashboardConfig.tableColumnLabelsForDeemDeviceByDefault,
            [COLUMN_NAMES.byName.session]: 'STANDARD_DASHBOARD.DEVICES.BATTERY_HEALTH_SESSION',
          },
          tableSettings: DeemDashboardConfig.tableSettingsByDefault,
        },
      ],
    },
    [ThresholdAttribute.AVERAGE_BOOT_TIME_MILLIS_EXPERIENCESCORE_DEVICE]: {
      visibleWidgetSubTypes: [
        StandardWidgetSubtype.DEVICES_PERFORMANCE_BOOT_TIME,
        StandardWidgetSubtype.DEVICES_PERFORMANCE_BOOT_TIME_OS_VERSION,
        StandardWidgetSubtype.DEVICES_PERFORMANCE_BOOT_TIME_PROCESSOR,
        StandardWidgetSubtype.DEVICES_PERFORMANCE_BOOT_TIME_MODEL,
        StandardWidgetSubtype.DEVICES_PERFORMANCE_BOOT_TIME_ORG_GROUP,
        StandardWidgetSubtype.DEVICES_PERFORMANCE_BOOT_TIME_DEGRADATION,
        StandardWidgetSubtype.DEVICES_PERFORMANCE_TOTAL_DEVICES,
        StandardWidgetSubtype.__DEVICES_PERFORMANCE_BOOT_TIME_TABLE,
      ],
      widgetConfigs: [
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype.DEVICES_PERFORMANCE_BOOT_TIME,
          chartHeaderConfig: {
            scoreSummaryWidgetId: StandardWidgetSubtype.DEVICES_PERFORMANCE_BOOT_TIME_STAT,
            customChartHeaderConfig: [
              {
                widgetId: StandardWidgetSubtype.DEVICES_PERFORMANCE_TOTAL_DEVICES,
                titleKey: 'COMMON_MESSAGES.TOTAL_DEVICES',
              },
            ],
            showPlatform: true,
          },
          chartYAxisLabelOverrideKey: 'COMMON_MESSAGES.DEVICE_COUNT',
          leaderboards: [
            {
              subtype: StandardWidgetSubtype.DEVICES_PERFORMANCE_BOOT_TIME_OS_VERSION,
              titleKey: 'STANDARD_DASHBOARD.OS_VERSIONS',
              isRollup: true,
              tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_OS_VERSIONS',
            },
            {
              subtype: StandardWidgetSubtype.DEVICES_PERFORMANCE_BOOT_TIME_PROCESSOR,
              titleKey: 'STANDARD_DASHBOARD.DEVICES_DETAILS.PROCESSOR',
              isRollup: true,
              tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_PROCESSOR',
            },
            {
              subtype: StandardWidgetSubtype.DEVICES_PERFORMANCE_BOOT_TIME_MODEL,
              titleKey: 'STANDARD_DASHBOARD.DEVICES.DEVICE_MODELS',
              isRollup: true,
              tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_DEVICE_MODELS',
            },
            {
              subtype: StandardWidgetSubtype.DEVICES_PERFORMANCE_BOOT_TIME_ORG_GROUP,
              titleKey: 'STANDARD_DASHBOARD.APP_DEPLOYMENT.ORGANIZATION_GROUP',
              isRollup: true,
              tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_ORG_GROUP',
            },
          ],
          showOuterActions: true,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_CHART_COMPONENT,
          widgetId: StandardWidgetSubtype.__DEVICES_PERFORMANCE_BOOT_TIME_TABLE,
          chartType: AggregationWidgetChartType.TABLE,
          chartTitle: 'STANDARD_DASHBOARD.DEVICES_DETAILS.TOP_DEVICES_WITH_LONGEST_AVG_BOOT_TIME',
          tableColumnNames: DeemDashboardConfig.tableColumnNamesForDeemDevice,
          tableColumnLabels: {
            [NgxTrendResultFlattener.getCounterKey(0)]: 'STANDARD_DASHBOARD.DEVICES.AVERAGE_BOOT_TIME',
            ...DeemDashboardConfig.tableColumnLabelsForDeemDeviceByDefault,
            [COLUMN_NAMES.byName.session]: 'STANDARD_DASHBOARD.DEVICES.BOOT_TIME_SESSION',
          },
          tableSettings: DeemDashboardConfig.tableSettingsByDefault,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_CHART_COMPONENT,
          widgetId: StandardWidgetSubtype.DEVICES_PERFORMANCE_BOOT_TIME_DEGRADATION,
          chartType: AggregationWidgetChartType.TABLE,
          chartTitle: 'STANDARD_DASHBOARD.DEVICES_DETAILS.TOP_APPS_CAUSING_BOOT_DEGRADATION',
          tableColumnNames: [
            COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_application_name,
            NgxTrendResultFlattener.getCounterKey(0),
            NgxTrendResultFlattener.getCounterKey(1),
            NgxTrendResultFlattener.getCounterKey(2),
          ],
          tableColumnLabels: {
            [COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_application_name]: 'STANDARD_DASHBOARD.DEVICES_DETAILS.APP_NAME',
            [NgxTrendResultFlattener.getCounterKey(0)]: 'STANDARD_DASHBOARD.DEVICES.COUNT_OF_EVENTS',
            [NgxTrendResultFlattener.getCounterKey(1)]: 'STANDARD_DASHBOARD.DEVICES.AVG_DURATION',
            [NgxTrendResultFlattener.getCounterKey(2)]: 'STANDARD_DASHBOARD.DEVICES.AVG_DEGRADATION',
          },
          tableSettings: DeemDashboardConfig.tableSettingsByDefault,
        },
      ],
    },
    [ThresholdAttribute.AVERAGE_SHUTDOWN_TIME_MILLIS_EXPERIENCESCORE_DEVICE]: {
      visibleWidgetSubTypes: [
        StandardWidgetSubtype.DEVICES_PERFORMANCE_SHUTDOWN_TIME,
        StandardWidgetSubtype.DEVICES_PERFORMANCE_SHUTDOWN_TIME_OS_VERSION,
        StandardWidgetSubtype.DEVICES_PERFORMANCE_SHUTDOWN_TIME_PROCESSOR,
        StandardWidgetSubtype.DEVICES_PERFORMANCE_SHUTDOWN_TIME_MODEL,
        StandardWidgetSubtype.DEVICES_PERFORMANCE_SHUTDOWN_TIME_ORG_GROUP,
        StandardWidgetSubtype.DEVICES_PERFORMANCE_TOTAL_DEVICES,
        StandardWidgetSubtype.__DEVICES_PERFORMANCE_SHUTDOWN_TIME_TABLE,
      ],
      widgetConfigs: [
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype.DEVICES_PERFORMANCE_SHUTDOWN_TIME,
          chartHeaderConfig: {
            scoreSummaryWidgetId: StandardWidgetSubtype.DEVICES_PERFORMANCE_SHUTDOWN_TIME_STAT,
            customChartHeaderConfig: [
              {
                widgetId: StandardWidgetSubtype.DEVICES_PERFORMANCE_TOTAL_DEVICES,
                titleKey: 'COMMON_MESSAGES.TOTAL_DEVICES',
              },
            ],
            showPlatform: true,
          },
          chartYAxisLabelOverrideKey: 'COMMON_MESSAGES.DEVICE_COUNT',
          leaderboards: [
            {
              subtype: StandardWidgetSubtype.DEVICES_PERFORMANCE_SHUTDOWN_TIME_OS_VERSION,
              titleKey: 'STANDARD_DASHBOARD.OS_VERSIONS',
              isRollup: true,
              tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_OS_VERSIONS',
            },
            {
              subtype: StandardWidgetSubtype.DEVICES_PERFORMANCE_SHUTDOWN_TIME_PROCESSOR,
              titleKey: 'STANDARD_DASHBOARD.DEVICES_DETAILS.PROCESSOR',
              isRollup: true,
              tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_PROCESSOR',
            },
            {
              subtype: StandardWidgetSubtype.DEVICES_PERFORMANCE_SHUTDOWN_TIME_MODEL,
              titleKey: 'STANDARD_DASHBOARD.DEVICES.DEVICE_MODELS',
              isRollup: true,
              tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_DEVICE_MODELS',
            },
            {
              subtype: StandardWidgetSubtype.DEVICES_PERFORMANCE_SHUTDOWN_TIME_ORG_GROUP,
              titleKey: 'STANDARD_DASHBOARD.APP_DEPLOYMENT.ORGANIZATION_GROUP',
              isRollup: true,
              tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_ORG_GROUP',
            },
          ],
          showOuterActions: true,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_CHART_COMPONENT,
          widgetId: StandardWidgetSubtype.__DEVICES_PERFORMANCE_SHUTDOWN_TIME_TABLE,
          chartType: AggregationWidgetChartType.TABLE,
          tableColumnNames: DeemDashboardConfig.tableColumnNamesForDeemDevice,
          tableColumnLabels: {
            [NgxTrendResultFlattener.getCounterKey(0)]: 'STANDARD_DASHBOARD.DEVICES.AVERAGE_SHUTDOWN_TIME',
            ...DeemDashboardConfig.tableColumnLabelsForDeemDeviceByDefault,
            [COLUMN_NAMES.byName.session]: 'STANDARD_DASHBOARD.DEVICES.SHUTDOWN_TIME_SESSION',
          },
          tableSettings: DeemDashboardConfig.tableSettingsByDefault,
        },
      ],
    },
    [ThresholdAttribute.APP_CRASH_COUNT_EXPERIENCESCORE_DESKTOP_APP_MACOS]: {
      visibleWidgetSubTypes: [
        StandardWidgetSubtype.DEEM_APP_ERRORS_COUNT,
        StandardWidgetSubtype.DEEM_APP_ERRORS_DEVICE_COUNT,
        StandardWidgetSubtype.DEEM_APP_ERRORS,
        StandardWidgetSubtype.DEEM_APP_ERRORS_LIST_MACOS,
        StandardWidgetSubtype.DEEM_APP_ERRORS_COUNT_BY_OS_VERSION,
        StandardWidgetSubtype.DEEM_APP_ERRORS_COUNT_BY_DEVICE_MODEL,
        StandardWidgetSubtype.DEEM_APP_ERRORS_COUNT_BY_APP_VERSION,
        StandardWidgetSubtype.DEEM_APP_ERRORS_COUNT_BY_APP_NAME,
      ],
      widgetConfigs: [
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype.DEEM_APP_ERRORS,
          chartHeaderConfig: {
            customChartHeaderConfig: [
              {
                widgetId: StandardWidgetSubtype.DEEM_APP_ERRORS_COUNT,
                titleKey: 'COMMON_MESSAGES.COUNT_OF_CRASHES',
              },
              {
                widgetId: StandardWidgetSubtype.DEEM_APP_ERRORS_DEVICE_COUNT,
                titleKey: 'COMMON_MESSAGES.DEVICE_COUNT',
              },
            ],
            showPlatform: true,
          },
          chartYAxisLabelOverrideKey: 'COMMON_MESSAGES.COUNT_OF_CRASHES',
          leaderboards: DeemDashboardConfig.desktopAppCrashLeaderboards,
          showOuterActions: true,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_CHART_COMPONENT,
          widgetId: StandardWidgetSubtype.DEEM_APP_ERRORS_LIST_MACOS,
          chartType: AggregationWidgetChartType.TABLE,
          tableColumnNames: [
            COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_application,
            ...DeemDashboardConfig.tableColumnNamesForDeemErrorByDefault,
          ],
          tableColumnLabels: {
            [COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_application]: 'COMMON_MESSAGES.ERROR_NAME',
            ...DeemDashboardConfig.tableColumnLabelsForDeemErrorByDefault,
          },
          showTableFilter: true,
          tableSettings: DeemDashboardConfig.tableSettingsByDefault,
        },
      ],
    },
    [ThresholdAttribute.APP_CRASH_COUNT_EXPERIENCESCORE_DESKTOP_APP_WINDOWS]: {
      visibleWidgetSubTypes: [
        StandardWidgetSubtype.DEEM_APP_ERRORS_COUNT,
        StandardWidgetSubtype.DEEM_APP_ERRORS_DEVICE_COUNT,
        StandardWidgetSubtype.DEEM_APP_ERRORS,
        StandardWidgetSubtype.DEEM_APP_ERRORS_LIST_WINDOWS,
        StandardWidgetSubtype.DEEM_APP_ERRORS_COUNT_BY_OS_VERSION,
        StandardWidgetSubtype.DEEM_APP_ERRORS_COUNT_BY_DEVICE_MODEL,
        StandardWidgetSubtype.DEEM_APP_ERRORS_COUNT_BY_APP_VERSION,
        StandardWidgetSubtype.DEEM_APP_ERRORS_COUNT_BY_APP_NAME,
      ],
      widgetConfigs: [
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype.DEEM_APP_ERRORS,
          chartHeaderConfig: {
            customChartHeaderConfig: [
              {
                widgetId: StandardWidgetSubtype.DEEM_APP_ERRORS_COUNT,
                titleKey: 'COMMON_MESSAGES.COUNT_OF_CRASHES',
              },
              {
                widgetId: StandardWidgetSubtype.DEEM_APP_ERRORS_DEVICE_COUNT,
                titleKey: 'COMMON_MESSAGES.DEVICE_COUNT',
              },
            ],
            showPlatform: true,
          },
          chartYAxisLabelOverrideKey: 'COMMON_MESSAGES.COUNT_OF_CRASHES',
          leaderboards: DeemDashboardConfig.desktopAppCrashLeaderboards,
          showOuterActions: true,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_CHART_COMPONENT,
          widgetId: StandardWidgetSubtype.DEEM_APP_ERRORS_LIST_WINDOWS,
          chartType: AggregationWidgetChartType.TABLE,
          tableColumnNames: [
            COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_module,
            ...DeemDashboardConfig.tableColumnNamesForDeemErrorByDefault,
          ],
          tableColumnLabels: {
            [COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_module]: 'COMMON_MESSAGES.ERROR_NAME',
            ...DeemDashboardConfig.tableColumnLabelsForDeemErrorByDefault,
          },
          showTableFilter: true,
          tableSettings: DeemDashboardConfig.tableSettingsByDefault,
        },
      ],
    },
    [ThresholdAttribute.APP_HANG_RATE_EXPERIENCESCORE_DESKTOP_APP_WINDOWS]: {
      visibleWidgetSubTypes: [
        StandardWidgetSubtype.DEEM_APP_HANG_COUNT,
        StandardWidgetSubtype.DEEM_APP_HANG,
        StandardWidgetSubtype.DEEM_APP_HANG_COUNT_BY_OS_VERSION,
        StandardWidgetSubtype.DEEM_APP_HANG_COUNT_BY_DEVICE_MODEL,
        StandardWidgetSubtype.DEEM_APP_HANG_COUNT_BY_APP_VERSION,
        StandardWidgetSubtype.DEEM_APP_HANG_COUNT_BY_APP_NAME,
        StandardWidgetSubtype.DEEM_APP_HANG_LIST_WINDOWS,
      ],
      widgetConfigs: [
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype.DEEM_APP_HANG,
          chartHeaderConfig: {
            customChartHeaderConfig: [
              {
                widgetId: StandardWidgetSubtype.DEEM_APP_HANG_COUNT,
                titleKey: 'COMMON_MESSAGES.APP_HANGS',
              },
            ],
            showPlatform: true,
          },
          chartYAxisLabelOverrideKey: 'COMMON_MESSAGES.APP_HANGS',
          leaderboards: DeemDashboardConfig.desktopAppHangLeaderboards,
          showOuterActions: true,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_CHART_COMPONENT,
          widgetId: StandardWidgetSubtype.DEEM_APP_HANG_LIST_WINDOWS,
          chartType: AggregationWidgetChartType.TABLE,
          tableColumnNames: [
            COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_module,
            ...DeemDashboardConfig.tableColumnNamesForDeemErrorByDefault,
          ],
          tableColumnLabels: {
            [COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_module]: 'COMMON_MESSAGES.ERROR_NAME',
            ...DeemDashboardConfig.tableColumnLabelsForDeemErrorByDefault,
          },
          tableSettings: DeemDashboardConfig.tableSettingsByDefault,
        },
      ],
    },
    [ThresholdAttribute.APP_HANG_RATE_EXPERIENCESCORE_DESKTOP_APP_MACOS]: {
      visibleWidgetSubTypes: [
        StandardWidgetSubtype.DEEM_APP_HANG_COUNT,
        StandardWidgetSubtype.DEEM_APP_HANG,
        StandardWidgetSubtype.DEEM_APP_HANG_COUNT_BY_OS_VERSION,
        StandardWidgetSubtype.DEEM_APP_HANG_COUNT_BY_DEVICE_MODEL,
        StandardWidgetSubtype.DEEM_APP_HANG_COUNT_BY_APP_VERSION,
        StandardWidgetSubtype.DEEM_APP_HANG_COUNT_BY_APP_NAME,
        StandardWidgetSubtype.DEEM_APP_HANG_LIST_MACOS,
      ],
      widgetConfigs: [
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype.DEEM_APP_HANG,
          chartHeaderConfig: {
            customChartHeaderConfig: [
              {
                widgetId: StandardWidgetSubtype.DEEM_APP_HANG_COUNT,
                titleKey: 'COMMON_MESSAGES.APP_HANGS',
              },
            ],
            showPlatform: true,
          },
          chartYAxisLabelOverrideKey: 'COMMON_MESSAGES.APP_HANGS',
          leaderboards: DeemDashboardConfig.desktopAppHangLeaderboards,
          showOuterActions: true,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_CHART_COMPONENT,
          widgetId: StandardWidgetSubtype.DEEM_APP_HANG_LIST_MACOS,
          chartType: AggregationWidgetChartType.TABLE,
          tableColumnNames: [
            COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_application,
            ...DeemDashboardConfig.tableColumnNamesForDeemErrorByDefault,
          ],
          tableColumnLabels: {
            [COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_application]: 'COMMON_MESSAGES.ERROR_NAME',
            ...DeemDashboardConfig.tableColumnLabelsForDeemErrorByDefault,
          },
          tableSettings: DeemDashboardConfig.tableSettingsByDefault,
        },
      ],
    },
    [ThresholdAttribute.APP_CPU_USAGE_EXPERIENCESCORE_DESKTOP_APP_WINDOWS]: {
      visibleWidgetSubTypes: [
        StandardWidgetSubtype.DEEM_DESKTOP_APP_TOTAL_USERS_COUNT,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_CPU_STAT,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_CPU_USAGE_PERCENTAGE,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_CPU_USAGE_BY_FRIENDLY_OS_VERSION_NAME,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_CPU_USAGE_BY_DEVICE_MODEL,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_CPU_USAGE_BY_ORG_GROUP,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_CPU_USAGE_BY_DEVICE_TAGS,
        StandardWidgetSubtype._DEEM_DESKTOP_APP_DEVICES_BY_CPU_USAGE,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_CPU_USAGE_DEVICES,
      ],
      widgetConfigs: [
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_CPU_STAT,
          chartHeaderConfig: {
            scoreSummaryWidgetId: StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_EVENT_COUNT_BY_CPU_SCORE,
            customChartHeaderConfig: [
              {
                widgetId: StandardWidgetSubtype.DEEM_DESKTOP_APP_TOTAL_USERS_COUNT,
                titleKey: 'DEEM_COMMON_MESSAGES.TOTAL_USERS',
              },
            ],
            showPlatform: true,
          },
          chartYAxisLabelOverrideKey: 'DEEM_COMMON_MESSAGES.COUNT_OF_SCORES',
          showOuterActions: true,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_CPU_USAGE_PERCENTAGE,
          chartTitle: 'SOLUTIONS.AVG_CPU_USAGE',
          chartType: AggregationWidgetChartType.LINE,
          leaderboards: DeemDashboardConfig.desktopAppCPUUsageLeaderboards,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype._DEEM_DESKTOP_APP_DEVICES_BY_CPU_USAGE,
          chartTitle: 'SOLUTIONS.DEVICE_REPORTING_CPU_USAGE_VS_DEVICES_USING_THE_APP',
          chartType: AggregationWidgetChartType.VERTICAL_GROUP,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_CHART_COMPONENT,
          widgetId: StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_CPU_USAGE_DEVICES,
          chartType: AggregationWidgetChartType.TABLE,
          chartTitle: 'SOLUTIONS.TOP_DEVICES_WITH_HIGH_AVG_CPU_USAGE',
          showTableFilter: true,
          tableColumnNames: DeemDashboardConfig.tableColumnNamesForDeemDesktopAppCpuGpuMemoryDevice,
          tableColumnLabels: {
            [NgxTrendResultFlattener.getCounterKey(1)]: 'DEEM_COMMON_MESSAGES.DEVICE_NAME',
            [NgxTrendResultFlattener.getCounterKey(2)]: 'COMMON_MESSAGES.USER',
            [NgxTrendResultFlattener.getCounterKey(3)]: 'DEEM_COMMON_MESSAGES.DEVICE_MODEL',
            [NgxTrendResultFlattener.getCounterKey(0)]:
              'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.APP_CPU_USAGE_EXPERIENCESCORE_DESKTOP_APP.TITLE',
            [NgxTrendResultFlattener.getCounterKey(4)]:
              'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.APP_MEMORY_USAGE_EXPERIENCESCORE_DESKTOP_APP.TITLE',
            [NgxTrendResultFlattener.getCounterKey(5)]: 'SOLUTIONS.APP_GPU_0_USAGE',
            [NgxTrendResultFlattener.getCounterKey(6)]: 'SOLUTIONS.APP_GPU_1_USAGE',
            [NgxTrendResultFlattener.getCounterKey(7)]: 'SOLUTIONS.APP_GPU_2_USAGE',
          },
          tableSettings: DeemDashboardConfig.tableSettingsByDefault,
        },
      ],
    },
    [ThresholdAttribute.APP_CPU_USAGE_EXPERIENCESCORE_DESKTOP_APP_MACOS]: {
      visibleWidgetSubTypes: [
        StandardWidgetSubtype.DEEM_DESKTOP_APP_TOTAL_USERS_COUNT,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_CPU_STAT,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_CPU_USAGE_PERCENTAGE,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_CPU_USAGE_BY_FRIENDLY_OS_VERSION_NAME,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_CPU_USAGE_BY_DEVICE_MODEL,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_CPU_USAGE_BY_ORG_GROUP,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_CPU_USAGE_BY_DEVICE_TAGS,
        StandardWidgetSubtype._DEEM_DESKTOP_APP_DEVICES_BY_CPU_USAGE,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_CPU_USAGE_DEVICES,
      ],
      widgetConfigs: [
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_CPU_STAT,
          chartHeaderConfig: {
            scoreSummaryWidgetId: StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_EVENT_COUNT_BY_CPU_SCORE,
            customChartHeaderConfig: [
              {
                widgetId: StandardWidgetSubtype.DEEM_DESKTOP_APP_TOTAL_USERS_COUNT,
                titleKey: 'DEEM_COMMON_MESSAGES.TOTAL_USERS',
              },
            ],
            showPlatform: true,
          },
          chartYAxisLabelOverrideKey: 'DEEM_COMMON_MESSAGES.COUNT_OF_SCORES',
          showOuterActions: true,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_CPU_USAGE_PERCENTAGE,
          chartTitle: 'SOLUTIONS.AVG_CPU_USAGE',
          chartType: AggregationWidgetChartType.LINE,
          leaderboards: DeemDashboardConfig.desktopAppCPUUsageLeaderboards,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype._DEEM_DESKTOP_APP_DEVICES_BY_CPU_USAGE,
          chartTitle: 'SOLUTIONS.DEVICE_REPORTING_CPU_USAGE_VS_DEVICES_USING_THE_APP',
          chartType: AggregationWidgetChartType.VERTICAL_GROUP,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_CHART_COMPONENT,
          widgetId: StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_CPU_USAGE_DEVICES,
          chartType: AggregationWidgetChartType.TABLE,
          chartTitle: 'SOLUTIONS.TOP_DEVICES_WITH_HIGH_AVG_CPU_USAGE',
          showTableFilter: true,
          tableColumnNames: DeemDashboardConfig.tableColumnNamesForDeemDesktopAppDevices,
          tableColumnLabels: {
            [NgxTrendResultFlattener.getCounterKey(1)]: 'DEEM_COMMON_MESSAGES.DEVICE_NAME',
            [NgxTrendResultFlattener.getCounterKey(2)]: 'COMMON_MESSAGES.USER',
            [NgxTrendResultFlattener.getCounterKey(3)]: 'DEEM_COMMON_MESSAGES.DEVICE_MODEL',
            [NgxTrendResultFlattener.getCounterKey(0)]:
              'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.APP_CPU_USAGE_EXPERIENCESCORE_DESKTOP_APP.TITLE',
            [NgxTrendResultFlattener.getCounterKey(4)]:
              'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.APP_MEMORY_USAGE_EXPERIENCESCORE_DESKTOP_APP.TITLE',
          },
          tableSettings: DeemDashboardConfig.tableSettingsByDefault,
        },
      ],
    },
    [ThresholdAttribute.APP_GPU_USAGE_EXPERIENCESCORE_DESKTOP_APP]: {
      visibleWidgetSubTypes: [
        StandardWidgetSubtype.DEEM_DESKTOP_APP_TOTAL_USERS_COUNT,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_GPU_STAT,
        StandardWidgetSubtype._DEEM_DESKTOP_APP_PERFORMANCE_GPU_USAGE_PERCENTAGE,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_GPU0_USAGE_BY_FRIENDLY_OS_VERSION_NAME,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_GPU0_USAGE_BY_DEVICE_MODEL,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_GPU0_USAGE_BY_ORG_GROUP,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_GPU0_USAGE_BY_DEVICE_TAGS,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_GPU1_USAGE_BY_FRIENDLY_OS_VERSION_NAME,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_GPU1_USAGE_BY_DEVICE_MODEL,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_GPU1_USAGE_BY_ORG_GROUP,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_GPU1_USAGE_BY_DEVICE_TAGS,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_GPU2_USAGE_BY_FRIENDLY_OS_VERSION_NAME,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_GPU2_USAGE_BY_DEVICE_MODEL,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_GPU2_USAGE_BY_ORG_GROUP,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_GPU2_USAGE_BY_DEVICE_TAGS,
        StandardWidgetSubtype._DEEM_DESKTOP_APP_DEVICES_BY_GPU_USAGE,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_GPU_USAGE_DEVICES,
      ],
      widgetConfigs: [
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_GPU_STAT,
          chartHeaderConfig: {
            scoreSummaryWidgetId: StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_EVENT_COUNT_BY_GPU_SCORE,
            customChartHeaderConfig: [
              {
                widgetId: StandardWidgetSubtype.DEEM_DESKTOP_APP_TOTAL_USERS_COUNT,
                titleKey: 'DEEM_COMMON_MESSAGES.TOTAL_USERS',
              },
            ],
            showPlatform: true,
          },
          chartYAxisLabelOverrideKey: 'DEEM_COMMON_MESSAGES.COUNT_OF_SCORES',
          showOuterActions: true,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype._DEEM_DESKTOP_APP_PERFORMANCE_GPU_USAGE_PERCENTAGE,
          chartTitle: 'SOLUTIONS.AVG_GPU_USAGE',
          chartType: AggregationWidgetChartType.LINE,
          leaderboards: [
            {
              subtype: StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_GPU0_USAGE_BY_FRIENDLY_OS_VERSION_NAME,
              titleKey: 'SOLUTIONS.OS_VERSIONS_GPU_0_USAGE',
              dataUnit: [undefined, DataUnit.PERCENT],
              tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_OS_VERSIONS_GPU_0_USAGE',
            },
            {
              subtype: StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_GPU0_USAGE_BY_DEVICE_MODEL,
              titleKey: 'SOLUTIONS.DEVICE_MODELS_GPU_0_USAGE',
              dataUnit: [undefined, DataUnit.PERCENT],
              tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_DEVICE_MODELS_GPU_0_USAGE',
            },
            {
              subtype: StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_GPU0_USAGE_BY_ORG_GROUP,
              titleKey: 'SOLUTIONS.ORGANIZATION_GROUP_GPU_0_USAGE',
              dataUnit: [undefined, DataUnit.PERCENT],
              tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_ORG_GROUP_GPU_0_USAGE',
            },
            {
              subtype: StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_GPU0_USAGE_BY_DEVICE_TAGS,
              titleKey: 'SOLUTIONS.DEVICE_TAGS_GPU_0_USAGE',
              dataUnit: [undefined, DataUnit.PERCENT],
              tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_DEVICE_TAGS_GPU_0_USAGE',
            },
            {
              subtype: StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_GPU1_USAGE_BY_FRIENDLY_OS_VERSION_NAME,
              titleKey: 'SOLUTIONS.OS_VERSIONS_GPU_1_USAGE',
              dataUnit: [undefined, DataUnit.PERCENT],
              tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_OS_VERSIONS_GPU_1_USAGE',
            },
            {
              subtype: StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_GPU1_USAGE_BY_DEVICE_MODEL,
              titleKey: 'SOLUTIONS.DEVICE_MODELS_GPU_1_USAGE',
              dataUnit: [undefined, DataUnit.PERCENT],
              tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_DEVICE_MODELS_GPU_1_USAGE',
            },
            {
              subtype: StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_GPU1_USAGE_BY_ORG_GROUP,
              titleKey: 'SOLUTIONS.ORGANIZATION_GROUP_GPU_1_USAGE',
              dataUnit: [undefined, DataUnit.PERCENT],
              tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_ORG_GROUP_GPU_1_USAGE',
            },
            {
              subtype: StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_GPU1_USAGE_BY_DEVICE_TAGS,
              titleKey: 'SOLUTIONS.DEVICE_TAGS_GPU_1_USAGE',
              dataUnit: [undefined, DataUnit.PERCENT],
              tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_DEVICE_TAGS_GPU_1_USAGE',
            },

            {
              subtype: StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_GPU2_USAGE_BY_FRIENDLY_OS_VERSION_NAME,
              titleKey: 'SOLUTIONS.OS_VERSIONS_GPU_2_USAGE',
              dataUnit: [undefined, DataUnit.PERCENT],
              tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_OS_VERSIONS_GPU_2_USAGE',
            },
            {
              subtype: StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_GPU2_USAGE_BY_DEVICE_MODEL,
              titleKey: 'SOLUTIONS.DEVICE_MODELS_GPU_2_USAGE',
              dataUnit: [undefined, DataUnit.PERCENT],
              tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_DEVICE_MODELS_GPU_2_USAGE',
            },
            {
              subtype: StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_GPU2_USAGE_BY_ORG_GROUP,
              titleKey: 'SOLUTIONS.ORGANIZATION_GROUP_GPU_2_USAGE',
              dataUnit: [undefined, DataUnit.PERCENT],
              tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_ORG_GROUP_GPU_2_USAGE',
            },
            {
              subtype: StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_GPU2_USAGE_BY_DEVICE_TAGS,
              titleKey: 'SOLUTIONS.DEVICE_TAGS_GPU_2_USAGE',
              dataUnit: [undefined, DataUnit.PERCENT],
              tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_DEVICE_TAGS_GPU_2_USAGE',
            },
          ],
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype._DEEM_DESKTOP_APP_DEVICES_BY_GPU_USAGE,
          chartTitle: 'SOLUTIONS.DEVICE_REPORTING_GPU_USAGE_VS_DEVICES_USING_THE_APP',
          chartType: AggregationWidgetChartType.VERTICAL_GROUP,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_CHART_COMPONENT,
          widgetId: StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_GPU_USAGE_DEVICES,
          chartTitle: 'SOLUTIONS.TOP_DEVICES_WITH_HIGH_AVG_GPU_USAGE',
          chartType: AggregationWidgetChartType.TABLE,
          showTableFilter: true,
          tableColumnNames: DeemDashboardConfig.tableColumnNamesForDeemDesktopAppCpuGpuMemoryDevice,
          tableColumnLabels: {
            [NgxTrendResultFlattener.getCounterKey(1)]: 'DEEM_COMMON_MESSAGES.DEVICE_NAME',
            [NgxTrendResultFlattener.getCounterKey(2)]: 'COMMON_MESSAGES.USER',
            [NgxTrendResultFlattener.getCounterKey(3)]: 'DEEM_COMMON_MESSAGES.DEVICE_MODEL',
            [NgxTrendResultFlattener.getCounterKey(0)]: 'SOLUTIONS.APP_GPU_0_USAGE',
            [NgxTrendResultFlattener.getCounterKey(4)]: 'SOLUTIONS.APP_GPU_1_USAGE',
            [NgxTrendResultFlattener.getCounterKey(5)]: 'SOLUTIONS.APP_GPU_2_USAGE',
            [NgxTrendResultFlattener.getCounterKey(6)]:
              'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.APP_CPU_USAGE_EXPERIENCESCORE_DESKTOP_APP.TITLE',
            [NgxTrendResultFlattener.getCounterKey(7)]:
              'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.APP_MEMORY_USAGE_EXPERIENCESCORE_DESKTOP_APP.TITLE',
          },
          tableSettings: DeemDashboardConfig.tableSettingsByDefault,
        },
      ],
    },
    [ThresholdAttribute.APP_MEMORY_USAGE_EXPERIENCESCORE_DESKTOP_APP_WINDOWS]: {
      visibleWidgetSubTypes: [
        StandardWidgetSubtype.DEEM_DESKTOP_APP_TOTAL_USERS_COUNT,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_MEMORY_STAT,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_MEMORY_USAGE_PERCENTAGE,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_MEMORY_USAGE_BY_FRIENDLY_OS_VERSION_NAME,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_MEMORY_USAGE_BY_DEVICE_MODEL,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_MEMORY_USAGE_BY_ORG_GROUP,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_MEMORY_USAGE_BY_DEVICE_TAGS,
        StandardWidgetSubtype._DEEM_DESKTOP_APP_DEVICES_BY_MEMORY_USAGE,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_MEMORY_USAGE_DEVICES,
      ],
      widgetConfigs: [
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_MEMORY_STAT,
          chartHeaderConfig: {
            scoreSummaryWidgetId: StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_EVENT_COUNT_BY_MEMORY_SCORE,
            customChartHeaderConfig: [
              {
                widgetId: StandardWidgetSubtype.DEEM_DESKTOP_APP_TOTAL_USERS_COUNT,
                titleKey: 'DEEM_COMMON_MESSAGES.TOTAL_USERS',
              },
            ],
            showPlatform: true,
          },
          chartYAxisLabelOverrideKey: 'DEEM_COMMON_MESSAGES.COUNT_OF_SCORES',
          showOuterActions: true,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_MEMORY_USAGE_PERCENTAGE,
          chartTitle: 'SOLUTIONS.AVG_MEMORY_USAGE',
          chartType: AggregationWidgetChartType.LINE,
          leaderboards: DeemDashboardConfig.desktopAppMemoryUsageLeaderboards,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype._DEEM_DESKTOP_APP_DEVICES_BY_MEMORY_USAGE,
          chartTitle: 'SOLUTIONS.DEVICE_REPORTING_MEMORY_USAGE_VS_DEVICES_USING_THE_APP',
          chartType: AggregationWidgetChartType.VERTICAL_GROUP,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_CHART_COMPONENT,
          widgetId: StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_MEMORY_USAGE_DEVICES,
          chartTitle: 'SOLUTIONS.TOP_DEVICES_WITH_HIGH_AVG_MEMORY_USAGE',
          chartType: AggregationWidgetChartType.TABLE,
          showTableFilter: true,
          tableColumnNames: DeemDashboardConfig.tableColumnNamesForDeemDesktopAppCpuGpuMemoryDevice,
          tableColumnLabels: {
            [NgxTrendResultFlattener.getCounterKey(1)]: 'DEEM_COMMON_MESSAGES.DEVICE_NAME',
            [NgxTrendResultFlattener.getCounterKey(2)]: 'COMMON_MESSAGES.USER',
            [NgxTrendResultFlattener.getCounterKey(3)]: 'DEEM_COMMON_MESSAGES.DEVICE_MODEL',
            [NgxTrendResultFlattener.getCounterKey(0)]:
              'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.APP_MEMORY_USAGE_EXPERIENCESCORE_DESKTOP_APP.TITLE',
            [NgxTrendResultFlattener.getCounterKey(4)]:
              'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.APP_CPU_USAGE_EXPERIENCESCORE_DESKTOP_APP.TITLE',
            [NgxTrendResultFlattener.getCounterKey(5)]: 'SOLUTIONS.APP_GPU_0_USAGE',
            [NgxTrendResultFlattener.getCounterKey(6)]: 'SOLUTIONS.APP_GPU_1_USAGE',
            [NgxTrendResultFlattener.getCounterKey(7)]: 'SOLUTIONS.APP_GPU_2_USAGE',
          },
          tableSettings: DeemDashboardConfig.tableSettingsByDefault,
        },
      ],
    },
    [ThresholdAttribute.APP_MEMORY_USAGE_EXPERIENCESCORE_DESKTOP_APP_MACOS]: {
      visibleWidgetSubTypes: [
        StandardWidgetSubtype.DEEM_DESKTOP_APP_TOTAL_USERS_COUNT,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_MEMORY_STAT,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_MEMORY_USAGE_PERCENTAGE,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_MEMORY_USAGE_BY_FRIENDLY_OS_VERSION_NAME,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_MEMORY_USAGE_BY_DEVICE_MODEL,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_MEMORY_USAGE_BY_ORG_GROUP,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_MEMORY_USAGE_BY_DEVICE_TAGS,
        StandardWidgetSubtype._DEEM_DESKTOP_APP_DEVICES_BY_MEMORY_USAGE,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_MEMORY_USAGE_DEVICES,
      ],
      widgetConfigs: [
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_MEMORY_STAT,
          chartHeaderConfig: {
            scoreSummaryWidgetId: StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_EVENT_COUNT_BY_MEMORY_SCORE,
            customChartHeaderConfig: [
              {
                widgetId: StandardWidgetSubtype.DEEM_DESKTOP_APP_TOTAL_USERS_COUNT,
                titleKey: 'DEEM_COMMON_MESSAGES.TOTAL_USERS',
              },
            ],
            showPlatform: true,
          },
          chartYAxisLabelOverrideKey: 'DEEM_COMMON_MESSAGES.COUNT_OF_SCORES',
          showOuterActions: true,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_MEMORY_USAGE_PERCENTAGE,
          chartTitle: 'SOLUTIONS.AVG_MEMORY_USAGE',
          chartType: AggregationWidgetChartType.LINE,
          leaderboards: DeemDashboardConfig.desktopAppMemoryUsageLeaderboards,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype._DEEM_DESKTOP_APP_DEVICES_BY_MEMORY_USAGE,
          chartTitle: 'SOLUTIONS.DEVICE_REPORTING_MEMORY_USAGE_VS_DEVICES_USING_THE_APP',
          chartType: AggregationWidgetChartType.VERTICAL_GROUP,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_CHART_COMPONENT,
          widgetId: StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_MEMORY_USAGE_DEVICES,
          chartTitle: 'SOLUTIONS.TOP_DEVICES_WITH_HIGH_AVG_MEMORY_USAGE',
          chartType: AggregationWidgetChartType.TABLE,
          showTableFilter: true,
          tableColumnNames: DeemDashboardConfig.tableColumnNamesForDeemDesktopAppDevices,
          tableColumnLabels: {
            [NgxTrendResultFlattener.getCounterKey(1)]: 'DEEM_COMMON_MESSAGES.DEVICE_NAME',
            [NgxTrendResultFlattener.getCounterKey(2)]: 'COMMON_MESSAGES.USER',
            [NgxTrendResultFlattener.getCounterKey(3)]: 'DEEM_COMMON_MESSAGES.DEVICE_MODEL',
            [NgxTrendResultFlattener.getCounterKey(0)]:
              'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.APP_MEMORY_USAGE_EXPERIENCESCORE_DESKTOP_APP.TITLE',
            [NgxTrendResultFlattener.getCounterKey(4)]:
              'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.APP_CPU_USAGE_EXPERIENCESCORE_DESKTOP_APP.TITLE',
          },
          tableSettings: DeemDashboardConfig.tableSettingsByDefault,
        },
      ],
    },
    [ThresholdAttribute.AVERAGE_APP_UI_UNRESPONSIVE_EVENTS_PER_DEVICE_EXPERIENCESCORE_DESKTOP_APP]: {
      visibleWidgetSubTypes: [
        StandardWidgetSubtype.DEEM_DESKTOP_APP_TOTAL_USERS_COUNT,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_TOTAL_DEVICES_COUNT,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_COUNT_OF_APP_UI_UNRESPONSIVE_SCORE,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_UI_UNRESPONSIVE_COUNT,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_UI_UNRESPONSIVE_COUNT_BY_OS_VERSION,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_UI_UNRESPONSIVE_COUNT_BY_DEVICE_MODEL,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_UI_UNRESPONSIVE_COUNT_BY_APP_VERSION,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_UI_UNRESPONSIVE_COUNT_BY_APPLICATION_NAME,
        StandardWidgetSubtype._DEEM_DESKTOP_APP_DEVICES_BY_APP_UI_UNRESPONSIVE,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_TOP_DEVICES_WITH_HIGH_APP_UI_UNRESPONSIVE_COUNT,
      ],
      widgetConfigs: [
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype.DEEM_DESKTOP_APP_COUNT_OF_APP_UI_UNRESPONSIVE_SCORE,
          chartHeaderConfig: {
            scoreSummaryWidgetId: StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_EVENT_COUNT_BY_APP_UI_UNRESPONSIVE_COUNT_SCORE,
            customChartHeaderConfig: [
              {
                widgetId: StandardWidgetSubtype.DEEM_DESKTOP_APP_TOTAL_USERS_COUNT,
                titleKey: 'DEEM_COMMON_MESSAGES.TOTAL_USERS',
              },
              {
                widgetId: StandardWidgetSubtype.DEEM_DESKTOP_APP_TOTAL_DEVICES_COUNT,
                titleKey: 'DEEM_COMMON_MESSAGES.TOTAL_DEVICES',
              },
            ],
            showPlatform: true,
          },
          chartYAxisLabelOverrideKey: 'DEEM_COMMON_MESSAGES.COUNT_OF_SCORES',
          showOuterActions: true,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype.DEEM_DESKTOP_APP_UI_UNRESPONSIVE_COUNT,
          chartTitle: 'SOLUTIONS.APP_UI_UNRESPONSIVE_COUNT',
          chartType: AggregationWidgetChartType.VERTICAL,
          leaderboards: [
            {
              subtype: StandardWidgetSubtype.DEEM_DESKTOP_APP_UI_UNRESPONSIVE_COUNT_BY_OS_VERSION,
              titleKey: 'STANDARD_DASHBOARD.OS_VERSIONS',
              tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_OS_VERSIONS',
            },
            {
              subtype: StandardWidgetSubtype.DEEM_DESKTOP_APP_UI_UNRESPONSIVE_COUNT_BY_DEVICE_MODEL,
              titleKey: 'STANDARD_DASHBOARD.DEVICES.DEVICE_MODELS',
              tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_DEVICE_MODELS',
            },
            {
              subtype: StandardWidgetSubtype.DEEM_DESKTOP_APP_UI_UNRESPONSIVE_COUNT_BY_APP_VERSION,
              titleKey: 'APTELIGENT.APP_VERSIONS',
              tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_APP_VERSIONS',
            },
            {
              subtype: StandardWidgetSubtype.DEEM_DESKTOP_APP_UI_UNRESPONSIVE_COUNT_BY_APPLICATION_NAME,
              titleKey: 'STANDARD_DASHBOARD.APP_EXECUTABLE',
              tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_APP_EXECUTABLE',
            },
          ],
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype._DEEM_DESKTOP_APP_DEVICES_BY_APP_UI_UNRESPONSIVE,
          chartTitle: 'SOLUTIONS.DEVICE_REPORTING_APP_UI_UNRESPONSIVE_EVENTS_VS_DEVICES_USING_THE_APP',
          chartType: AggregationWidgetChartType.VERTICAL_GROUP,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_CHART_COMPONENT,
          widgetId: StandardWidgetSubtype.DEEM_DESKTOP_APP_TOP_DEVICES_WITH_HIGH_APP_UI_UNRESPONSIVE_COUNT,
          chartTitle: 'SOLUTIONS.TOP_DEVICES_WITH_HIGH_APP_UI_UNRESPONSIVE_COUNT',
          chartType: AggregationWidgetChartType.TABLE,
          showTableFilter: true,
          tableColumnNames: DeemDashboardConfig.tableColumnNamesForDeemDesktopAppDevices,
          tableColumnLabels: DeemDashboardConfig.tableColumnLabelsForDeemDesktopAppUIUnresponsiveCount,
          tableSettings: DeemDashboardConfig.tableSettingsByDefault,
        },
      ],
    },
    [ThresholdAttribute.AVERAGE_APP_UI_UNRESPONSIVE_DURATION_EXPERIENCESCORE_DESKTOP_APP]: {
      visibleWidgetSubTypes: [
        StandardWidgetSubtype.DEEM_DESKTOP_APP_TOTAL_USERS_COUNT,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_TOTAL_DEVICES_COUNT,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_COUNT_OF_APP_UI_UNRESPONSIVE_DURATION_SCORE,
        StandardWidgetSubtype._DEEM_DESKTOP_APP_DEVICES_BY_APP_UI_UNRESPONSIVE,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_AVERAGE_APP_UI_UNRESPONSIVE_DURATION,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_AVERAGE_APP_UI_UNRESPONSIVE_DURATION_BY_OS_VERSION,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_AVERAGE_APP_UI_UNRESPONSIVE_DURATION_BY_DEVICE_MODEL,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_AVERAGE_APP_UI_UNRESPONSIVE_DURATION_BY_APP_VERSION,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_AVERAGE_APP_UI_UNRESPONSIVE_DURATION_BY_APPLICATION_NAME,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_TOP_DEVICES_WITH_HIGH_APP_UI_UNRESPONSIVE_DURATION,
      ],
      widgetConfigs: [
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype.DEEM_DESKTOP_APP_COUNT_OF_APP_UI_UNRESPONSIVE_DURATION_SCORE,
          chartHeaderConfig: {
            scoreSummaryWidgetId: StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_EVENT_COUNT_BY_APP_UI_UNRESPONSIVE_DURATION_SCORE,
            customChartHeaderConfig: [
              {
                widgetId: StandardWidgetSubtype.DEEM_DESKTOP_APP_TOTAL_USERS_COUNT,
                titleKey: 'DEEM_COMMON_MESSAGES.TOTAL_USERS',
              },
              {
                widgetId: StandardWidgetSubtype.DEEM_DESKTOP_APP_TOTAL_DEVICES_COUNT,
                titleKey: 'DEEM_COMMON_MESSAGES.TOTAL_DEVICES',
              },
            ],
            showPlatform: true,
          },
          chartYAxisLabelOverrideKey: 'DEEM_COMMON_MESSAGES.COUNT_OF_SCORES',
          showOuterActions: true,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype.DEEM_DESKTOP_APP_AVERAGE_APP_UI_UNRESPONSIVE_DURATION,
          chartTitle: 'SOLUTIONS.AVERAGE_APP_UI_UNRESPONSIVE_DURATION',
          chartType: AggregationWidgetChartType.LINE,
          leaderboards: [
            {
              subtype: StandardWidgetSubtype.DEEM_DESKTOP_APP_AVERAGE_APP_UI_UNRESPONSIVE_DURATION_BY_OS_VERSION,
              titleKey: 'STANDARD_DASHBOARD.OS_VERSIONS',
              dataUnit: [undefined, DataUnit.MILLISECONDS],
              tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_OS_VERSIONS',
            },
            {
              subtype: StandardWidgetSubtype.DEEM_DESKTOP_APP_AVERAGE_APP_UI_UNRESPONSIVE_DURATION_BY_DEVICE_MODEL,
              titleKey: 'STANDARD_DASHBOARD.DEVICES.DEVICE_MODELS',
              dataUnit: [undefined, DataUnit.MILLISECONDS],
              tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_DEVICE_MODELS',
            },
            {
              subtype: StandardWidgetSubtype.DEEM_DESKTOP_APP_AVERAGE_APP_UI_UNRESPONSIVE_DURATION_BY_APP_VERSION,
              titleKey: 'APTELIGENT.APP_VERSIONS',
              dataUnit: [undefined, DataUnit.MILLISECONDS],
              tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_APP_VERSIONS',
            },
            {
              subtype: StandardWidgetSubtype.DEEM_DESKTOP_APP_AVERAGE_APP_UI_UNRESPONSIVE_DURATION_BY_APPLICATION_NAME,
              titleKey: 'STANDARD_DASHBOARD.APP_EXECUTABLE',
              dataUnit: [undefined, DataUnit.MILLISECONDS],
              tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_APP_EXECUTABLE',
            },
          ],
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype._DEEM_DESKTOP_APP_DEVICES_BY_APP_UI_UNRESPONSIVE,
          chartTitle: 'SOLUTIONS.DEVICE_REPORTING_APP_UI_UNRESPONSIVE_EVENTS_VS_DEVICES_USING_THE_APP',
          chartType: AggregationWidgetChartType.VERTICAL_GROUP,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_CHART_COMPONENT,
          widgetId: StandardWidgetSubtype.DEEM_DESKTOP_APP_TOP_DEVICES_WITH_HIGH_APP_UI_UNRESPONSIVE_DURATION,
          chartTitle: 'SOLUTIONS.TOP_DEVICES_WITH_HIGH_APP_UI_UNRESPONSIVE_DURATION',
          chartType: AggregationWidgetChartType.TABLE,
          showTableFilter: true,
          tableColumnNames: DeemDashboardConfig.tableColumnNamesForDeemDesktopAppDevices,
          tableColumnLabels: {
            [NgxTrendResultFlattener.getCounterKey(1)]: 'DEEM_COMMON_MESSAGES.DEVICE_NAME',
            [NgxTrendResultFlattener.getCounterKey(2)]: 'COMMON_MESSAGES.USER',
            [NgxTrendResultFlattener.getCounterKey(3)]: 'DEEM_COMMON_MESSAGES.DEVICE_MODEL',
            [NgxTrendResultFlattener.getCounterKey(0)]: 'SOLUTIONS.AVERAGE_APP_UI_UNRESPONSIVE_DURATION',
            [NgxTrendResultFlattener.getCounterKey(4)]: 'SOLUTIONS.APP_UI_UNRESPONSIVE_COUNT',
          },
          tableSettings: DeemDashboardConfig.tableSettingsByDefault,
        },
      ],
    },
    [ThresholdAttribute.APP_UI_UNRESPONSIVE_RATE_EXPERIENCESCORE_DESKTOP_APP]: {
      visibleWidgetSubTypes: [
        StandardWidgetSubtype.DEEM_DESKTOP_APP_TOTAL_USERS_COUNT,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_TOTAL_DEVICES_COUNT,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_COUNT_OF_APP_UI_UNRESPONSIVE_RATE_SCORE,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_COUNT_OF_APP_UI_UNRESPONSIVE_RATE_SCORE_BY_APP_VERSION,
        StandardWidgetSubtype._DEEM_DESKTOP_APP_DEVICES_BY_APP_UI_UNRESPONSIVE,
        StandardWidgetSubtype.DEEM_DESKTOP_APP_TOP_DEVICES_WITH_HIGH_APP_UI_UNRESPONSIVE_COUNT,
        StandardWidgetSubtype._DEEM_DESKTOP_APP_UI_UNRESPONSIVE_RATE,
      ],
      widgetConfigs: [
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype.DEEM_DESKTOP_APP_COUNT_OF_APP_UI_UNRESPONSIVE_RATE_SCORE,
          chartHeaderConfig: {
            scoreSummaryWidgetId: StandardWidgetSubtype.DEEM_DESKTOP_APP_PERFORMANCE_EVENT_COUNT_BY_APP_UI_UNRESPONSIVE_RATE_SCORE,
            customChartHeaderConfig: [
              {
                widgetId: StandardWidgetSubtype.DEEM_DESKTOP_APP_TOTAL_USERS_COUNT,
                titleKey: 'DEEM_COMMON_MESSAGES.TOTAL_USERS',
              },
              {
                widgetId: StandardWidgetSubtype.DEEM_DESKTOP_APP_TOTAL_DEVICES_COUNT,
                titleKey: 'DEEM_COMMON_MESSAGES.TOTAL_DEVICES',
              },
            ],
            showPlatform: true,
          },
          chartYAxisLabelOverrideKey: 'DEEM_COMMON_MESSAGES.COUNT_OF_SCORES',
          leaderboards: [
            {
              subtype: StandardWidgetSubtype.DEEM_DESKTOP_APP_COUNT_OF_APP_UI_UNRESPONSIVE_RATE_SCORE_BY_APP_VERSION,
              titleKey: 'APTELIGENT.APP_VERSIONS',
              isRollup: true,
              tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_APP_VERSIONS',
            },
          ],
          showOuterActions: true,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype._DEEM_DESKTOP_APP_UI_UNRESPONSIVE_RATE,
          chartTitle: 'SOLUTIONS.APP_UI_UNRESPONSIVE_RATE',
          isPercentageOverride: true,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype._DEEM_DESKTOP_APP_DEVICES_BY_APP_UI_UNRESPONSIVE,
          chartTitle: 'SOLUTIONS.DEVICE_REPORTING_APP_UI_UNRESPONSIVE_EVENTS_VS_DEVICES_USING_THE_APP',
          chartType: AggregationWidgetChartType.VERTICAL_GROUP,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_CHART_COMPONENT,
          widgetId: StandardWidgetSubtype.DEEM_DESKTOP_APP_TOP_DEVICES_WITH_HIGH_APP_UI_UNRESPONSIVE_COUNT,
          chartTitle: 'SOLUTIONS.TOP_DEVICES_WITH_HIGH_APP_UI_UNRESPONSIVE_COUNT',
          chartType: AggregationWidgetChartType.TABLE,
          showTableFilter: true,
          tableColumnNames: DeemDashboardConfig.tableColumnNamesForDeemDesktopAppDevices,
          tableColumnLabels: DeemDashboardConfig.tableColumnLabelsForDeemDesktopAppUIUnresponsiveCount,
          tableSettings: DeemDashboardConfig.tableSettingsByDefault,
        },
      ],
    },
    [ThresholdAttribute.LAN_AVERAGE_LATENCY_HORIZON_EXPERIENCESCORE_SESSION]: {
      visibleWidgetSubTypes: [
        StandardWidgetSubtype.DEEM_VIRTUAL_SESSION_COUNT_BY_LAN_LATENCY_SCORE,
        StandardWidgetSubtype.DEEM_VIRTUAL_SESSION_LIST_BY_LAN_LATENCY,
      ],
      widgetConfigs: [
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype.DEEM_VIRTUAL_SESSION_COUNT_BY_LAN_LATENCY_SCORE,
          showOuterActions: true,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_CHART_COMPONENT,
          widgetId: StandardWidgetSubtype.DEEM_VIRTUAL_SESSION_LIST_BY_LAN_LATENCY,
          chartTitle: 'SOLUTIONS.TOP_SESSIONS_WITH_HIGH_AVG_LAN_LATENCY',
          chartType: AggregationWidgetChartType.TABLE,
          showTableFilter: true,
          tableColumnNames: DeemDashboardConfig.tableColumnNamesForNetworkProtocol,
          tableColumnLabels: {
            [NgxTrendResultFlattener.getCounterKey(0)]:
              'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.LAN_AVERAGE_LATENCY_HORIZON_EXPERIENCESCORE_SESSION.TITLE',
            ...DeemDashboardConfig.tableColumnLabelsForDeemSessionByDefault,
            [NgxTrendResultFlattener.getCounterKey(7)]:
              'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.WAN_AVERAGE_LATENCY_HORIZON_EXPERIENCESCORE_SESSION.TITLE',
            [NgxTrendResultFlattener.getCounterKey(8)]:
              'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.PACKET_LOSS_RATE_HORIZON_EXPERIENCESCORE_SESSION.TITLE',
            [NgxTrendResultFlattener.getCounterKey(9)]:
              'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.WIFI_SIGNAL_STRENGTH_HORIZON_EXPERIENCESCORE_SESSION.TITLE',
            [NgxTrendResultFlattener.getCounterKey(10)]:
              'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.PROTOCOL_LATENCY_HORIZON_EXPERIENCESCORE_SESSION.TITLE',
          },
          tableSettings: DeemDashboardConfig.tableSettingsByDefault,
        },
      ],
    },
    [ThresholdAttribute.WAN_AVERAGE_LATENCY_HORIZON_EXPERIENCESCORE_SESSION]: {
      visibleWidgetSubTypes: [
        StandardWidgetSubtype.DEEM_VIRTUAL_SESSION_COUNT_BY_WAN_LATENCY_SCORE,
        StandardWidgetSubtype.DEEM_VIRTUAL_SESSION_LIST_BY_WAN_LATENCY,
      ],
      widgetConfigs: [
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype.DEEM_VIRTUAL_SESSION_COUNT_BY_WAN_LATENCY_SCORE,
          showOuterActions: true,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_CHART_COMPONENT,
          widgetId: StandardWidgetSubtype.DEEM_VIRTUAL_SESSION_LIST_BY_WAN_LATENCY,
          chartTitle: 'SOLUTIONS.TOP_SESSIONS_WITH_HIGH_AVG_WAN_LATENCY',
          chartType: AggregationWidgetChartType.TABLE,
          showTableFilter: true,
          tableColumnNames: DeemDashboardConfig.tableColumnNamesForNetworkProtocol,
          tableColumnLabels: {
            [NgxTrendResultFlattener.getCounterKey(0)]:
              'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.WAN_AVERAGE_LATENCY_HORIZON_EXPERIENCESCORE_SESSION.TITLE',
            ...DeemDashboardConfig.tableColumnLabelsForDeemSessionByDefault,
            [NgxTrendResultFlattener.getCounterKey(7)]:
              'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.LAN_AVERAGE_LATENCY_HORIZON_EXPERIENCESCORE_SESSION.TITLE',
            [NgxTrendResultFlattener.getCounterKey(8)]:
              'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.PACKET_LOSS_RATE_HORIZON_EXPERIENCESCORE_SESSION.TITLE',
            [NgxTrendResultFlattener.getCounterKey(9)]:
              'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.WIFI_SIGNAL_STRENGTH_HORIZON_EXPERIENCESCORE_SESSION.TITLE',
            [NgxTrendResultFlattener.getCounterKey(10)]:
              'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.PROTOCOL_LATENCY_HORIZON_EXPERIENCESCORE_SESSION.TITLE',
          },
          tableSettings: DeemDashboardConfig.tableSettingsByDefault,
        },
      ],
    },
    [ThresholdAttribute.DOT11_SIGNAL_QUALITY_HORIZON_EXPERIENCESCORE_SESSION]: {
      visibleWidgetSubTypes: [
        StandardWidgetSubtype.DEEN_VIRTUAL_SESSION_COUNT_BY_WIFI_SIGNAL_STRENGTH_SCORE,
        StandardWidgetSubtype.DEEM_VIRTUAL_SESSION_LIST_BY_WIFI_SIGNAL_STRENGTH,
      ],
      widgetConfigs: [
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype.DEEN_VIRTUAL_SESSION_COUNT_BY_WIFI_SIGNAL_STRENGTH_SCORE,
          showOuterActions: true,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_CHART_COMPONENT,
          widgetId: StandardWidgetSubtype.DEEM_VIRTUAL_SESSION_LIST_BY_WIFI_SIGNAL_STRENGTH,
          chartTitle: 'SOLUTIONS.TOP_SESSIONS_WITH_HIGH_AVG_WIFI_STRENGTH',
          chartType: AggregationWidgetChartType.TABLE,
          showTableFilter: true,
          tableColumnNames: DeemDashboardConfig.tableColumnNamesForNetworkProtocol,
          tableColumnLabels: {
            [NgxTrendResultFlattener.getCounterKey(0)]:
              'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.WIFI_SIGNAL_STRENGTH_HORIZON_EXPERIENCESCORE_SESSION.TITLE',
            ...DeemDashboardConfig.tableColumnLabelsForDeemSessionByDefault,
            [NgxTrendResultFlattener.getCounterKey(7)]:
              'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.WAN_AVERAGE_LATENCY_HORIZON_EXPERIENCESCORE_SESSION.TITLE',
            [NgxTrendResultFlattener.getCounterKey(8)]:
              'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.LAN_AVERAGE_LATENCY_HORIZON_EXPERIENCESCORE_SESSION.TITLE',
            [NgxTrendResultFlattener.getCounterKey(9)]:
              'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.PACKET_LOSS_RATE_HORIZON_EXPERIENCESCORE_SESSION.TITLE',
            [NgxTrendResultFlattener.getCounterKey(10)]:
              'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.PROTOCOL_LATENCY_HORIZON_EXPERIENCESCORE_SESSION.TITLE',
          },
          tableSettings: DeemDashboardConfig.tableSettingsByDefault,
        },
      ],
    },
    [ThresholdAttribute.PACKET_LOSS_RATE_HORIZON_EXPERIENCESCORE_SESSION]: {
      visibleWidgetSubTypes: [
        StandardWidgetSubtype.DEEM_VIRTUAL_SESSION_COUNT_BY_PACKET_LOSS_SCORE,
        StandardWidgetSubtype.DEEM_VIRTUAL_SESSION_LIST_BY_PACKET_LOSS,
      ],
      widgetConfigs: [
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype.DEEM_VIRTUAL_SESSION_COUNT_BY_PACKET_LOSS_SCORE,
          showOuterActions: true,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_CHART_COMPONENT,
          widgetId: StandardWidgetSubtype.DEEM_VIRTUAL_SESSION_LIST_BY_PACKET_LOSS,
          chartTitle: 'SOLUTIONS.TOP_SESSIONS_WITH_HIGH_AVG_PACKET_LOSS',
          chartType: AggregationWidgetChartType.TABLE,
          showTableFilter: true,
          tableColumnNames: DeemDashboardConfig.tableColumnNamesForNetworkProtocol,
          tableColumnLabels: {
            [NgxTrendResultFlattener.getCounterKey(0)]:
              'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.PACKET_LOSS_RATE_HORIZON_EXPERIENCESCORE_SESSION.TITLE',
            ...DeemDashboardConfig.tableColumnLabelsForDeemSessionByDefault,
            [NgxTrendResultFlattener.getCounterKey(7)]:
              'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.LAN_AVERAGE_LATENCY_HORIZON_EXPERIENCESCORE_SESSION.TITLE',
            [NgxTrendResultFlattener.getCounterKey(8)]:
              'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.WAN_AVERAGE_LATENCY_HORIZON_EXPERIENCESCORE_SESSION.TITLE',
            [NgxTrendResultFlattener.getCounterKey(9)]:
              'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.WIFI_SIGNAL_STRENGTH_HORIZON_EXPERIENCESCORE_SESSION.TITLE',
            [NgxTrendResultFlattener.getCounterKey(10)]:
              'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.PROTOCOL_LATENCY_HORIZON_EXPERIENCESCORE_SESSION.TITLE',
          },
          tableSettings: DeemDashboardConfig.tableSettingsByDefault,
        },
      ],
    },
    [ThresholdAttribute.PROTOCOL_LATENCY_HORIZON_EXPERIENCESCORE_SESSION]: {
      visibleWidgetSubTypes: [
        StandardWidgetSubtype.DEEM_VIRTUAL_SESSION_COUNT_BY_PROTOCOL_LATENCY_SCORE,
        StandardWidgetSubtype.DEEM_VIRTUAL_SESSION_LIST_BY_PROTOCOL_LATENCY,
      ],
      widgetConfigs: [
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype.DEEM_VIRTUAL_SESSION_COUNT_BY_PROTOCOL_LATENCY_SCORE,
          showOuterActions: true,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_CHART_COMPONENT,
          widgetId: StandardWidgetSubtype.DEEM_VIRTUAL_SESSION_LIST_BY_PROTOCOL_LATENCY,
          chartTitle: 'SOLUTIONS.TOP_SESSIONS_WITH_HIGH_AVG_PROTOCOL_LATENCY',
          chartType: AggregationWidgetChartType.TABLE,
          showTableFilter: true,
          tableColumnNames: DeemDashboardConfig.tableColumnNamesForNetworkProtocol,
          tableColumnLabels: {
            [NgxTrendResultFlattener.getCounterKey(0)]:
              'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.PROTOCOL_LATENCY_HORIZON_EXPERIENCESCORE_SESSION.TITLE',
            ...DeemDashboardConfig.tableColumnLabelsForDeemSessionByDefault,
            [NgxTrendResultFlattener.getCounterKey(7)]:
              'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.LAN_AVERAGE_LATENCY_HORIZON_EXPERIENCESCORE_SESSION.TITLE',
            [NgxTrendResultFlattener.getCounterKey(8)]:
              'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.WAN_AVERAGE_LATENCY_HORIZON_EXPERIENCESCORE_SESSION.TITLE',
            [NgxTrendResultFlattener.getCounterKey(9)]:
              'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.PACKET_LOSS_RATE_HORIZON_EXPERIENCESCORE_SESSION.TITLE',
            [NgxTrendResultFlattener.getCounterKey(10)]:
              'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.WIFI_SIGNAL_STRENGTH_HORIZON_EXPERIENCESCORE_SESSION.TITLE',
          },
          tableSettings: DeemDashboardConfig.tableSettingsByDefault,
        },
      ],
    },
    [ThresholdAttribute.CPU_PERCENT_HORIZON_EXPERIENCESCORE_SESSION]: {
      visibleWidgetSubTypes: [
        StandardWidgetSubtype.DEEM_VIRTUAL_SESSION_COUNT_BY_CPU_USAGE_SCORE,
        StandardWidgetSubtype.DEEM_VIRTUAL_SESSION_LIST_BY_CPU_USAGE,
      ],
      widgetConfigs: [
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype.DEEM_VIRTUAL_SESSION_COUNT_BY_CPU_USAGE_SCORE,
          showOuterActions: true,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_CHART_COMPONENT,
          widgetId: StandardWidgetSubtype.DEEM_VIRTUAL_SESSION_LIST_BY_CPU_USAGE,
          chartTitle: 'SOLUTIONS.TOP_SESSIONS_WITH_HIGH_AVG_CPU_USAGE',
          chartType: AggregationWidgetChartType.TABLE,
          showTableFilter: true,
          tableColumnNames: DeemDashboardConfig.tableColumnNamesForVMPerformance,
          tableColumnLabels: {
            [NgxTrendResultFlattener.getCounterKey(0)]:
              'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.CPU_PERCENT_HORIZON_EXPERIENCESCORE_SESSION.TITLE',
            ...DeemDashboardConfig.tableColumnLabelsForDeemSessionByDefault,
            [NgxTrendResultFlattener.getCounterKey(7)]:
              'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.MEMORY_PERCENT_HORIZON_EXPERIENCESCORE_SESSION.TITLE',
            [NgxTrendResultFlattener.getCounterKey(8)]:
              'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.DISK_LATENCY_HORIZON_EXPERIENCESCORE_SESSION.TITLE',
            [NgxTrendResultFlattener.getCounterKey(9)]:
              'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.DISK_IOPS_HORIZON_EXPERIENCESCORE_SESSION.TITLE',
          },
          tableSettings: DeemDashboardConfig.tableSettingsByDefault,
        },
      ],
    },
    [ThresholdAttribute.MEMORY_PERCENT_HORIZON_EXPERIENCESCORE_SESSION]: {
      visibleWidgetSubTypes: [
        StandardWidgetSubtype.DEEM_VIRTUAL_SESSION_COUNT_BY_MEMORY_USAGE_SCORE,
        StandardWidgetSubtype.DEEM_VIRTUAL_SESSION_LIST_BY_MEMORY_USAGE,
      ],
      widgetConfigs: [
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype.DEEM_VIRTUAL_SESSION_COUNT_BY_MEMORY_USAGE_SCORE,
          showOuterActions: true,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_CHART_COMPONENT,
          widgetId: StandardWidgetSubtype.DEEM_VIRTUAL_SESSION_LIST_BY_MEMORY_USAGE,
          chartTitle: 'SOLUTIONS.TOP_SESSIONS_WITH_HIGH_AVG_MEMORY_USAGE',
          chartType: AggregationWidgetChartType.TABLE,
          showTableFilter: true,
          tableColumnNames: DeemDashboardConfig.tableColumnNamesForVMPerformance,
          tableColumnLabels: {
            [NgxTrendResultFlattener.getCounterKey(0)]:
              'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.MEMORY_PERCENT_HORIZON_EXPERIENCESCORE_SESSION.TITLE',
            ...DeemDashboardConfig.tableColumnLabelsForDeemSessionByDefault,
            [NgxTrendResultFlattener.getCounterKey(7)]:
              'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.CPU_PERCENT_HORIZON_EXPERIENCESCORE_SESSION.TITLE',
            [NgxTrendResultFlattener.getCounterKey(8)]:
              'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.DISK_LATENCY_HORIZON_EXPERIENCESCORE_SESSION.TITLE',
            [NgxTrendResultFlattener.getCounterKey(9)]:
              'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.DISK_IOPS_HORIZON_EXPERIENCESCORE_SESSION.TITLE',
          },
          tableSettings: DeemDashboardConfig.tableSettingsByDefault,
        },
      ],
    },
    [ThresholdAttribute.DISK_LATENCY_HORIZON_EXPERIENCESCORE_SESSION]: {
      visibleWidgetSubTypes: [
        StandardWidgetSubtype.DEEM_VIRTUAL_SESSION_COUNT_BY_DISK_LATENCY_SCORE,
        StandardWidgetSubtype.DEEM_VIRTUAL_SESSION_LIST_BY_DISK_LATENCY,
      ],
      widgetConfigs: [
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype.DEEM_VIRTUAL_SESSION_COUNT_BY_DISK_LATENCY_SCORE,
          showOuterActions: true,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_CHART_COMPONENT,
          widgetId: StandardWidgetSubtype.DEEM_VIRTUAL_SESSION_LIST_BY_DISK_LATENCY,
          chartTitle: 'SOLUTIONS.TOP_SESSIONS_WITH_HIGH_AVG_DISK_LATENCY',
          chartType: AggregationWidgetChartType.TABLE,
          showTableFilter: true,
          tableColumnNames: DeemDashboardConfig.tableColumnNamesForVMPerformance,
          tableColumnLabels: {
            [NgxTrendResultFlattener.getCounterKey(0)]:
              'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.DISK_LATENCY_HORIZON_EXPERIENCESCORE_SESSION.TITLE',
            ...DeemDashboardConfig.tableColumnLabelsForDeemSessionByDefault,
            [NgxTrendResultFlattener.getCounterKey(7)]:
              'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.CPU_PERCENT_HORIZON_EXPERIENCESCORE_SESSION.TITLE',
            [NgxTrendResultFlattener.getCounterKey(8)]:
              'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.MEMORY_PERCENT_HORIZON_EXPERIENCESCORE_SESSION.TITLE',
            [NgxTrendResultFlattener.getCounterKey(9)]:
              'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.DISK_IOPS_HORIZON_EXPERIENCESCORE_SESSION.TITLE',
          },
          tableSettings: DeemDashboardConfig.tableSettingsByDefault,
        },
      ],
    },
    [ThresholdAttribute.DISK_IOPS_HORIZON_EXPERIENCESCORE_SESSION]: {
      visibleWidgetSubTypes: [
        StandardWidgetSubtype.DEEM_VIRTUAL_SESSION_COUNT_BY_DISK_IOPS_SCORE,
        StandardWidgetSubtype.DEEM_VIRTUAL_SESSION_LIST_BY_DISK_IOPS,
      ],
      widgetConfigs: [
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype.DEEM_VIRTUAL_SESSION_COUNT_BY_DISK_IOPS_SCORE,
          showOuterActions: true,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_CHART_COMPONENT,
          widgetId: StandardWidgetSubtype.DEEM_VIRTUAL_SESSION_LIST_BY_DISK_IOPS,
          chartTitle: 'SOLUTIONS.TOP_SESSIONS_WITH_HIGH_AVG_DISK_IOPS',
          chartType: AggregationWidgetChartType.TABLE,
          showTableFilter: true,
          tableColumnNames: DeemDashboardConfig.tableColumnNamesForVMPerformance,
          tableColumnLabels: {
            [NgxTrendResultFlattener.getCounterKey(0)]:
              'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.DISK_IOPS_HORIZON_EXPERIENCESCORE_SESSION.TITLE',
            ...DeemDashboardConfig.tableColumnLabelsForDeemSessionByDefault,
            [NgxTrendResultFlattener.getCounterKey(7)]:
              'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.CPU_PERCENT_HORIZON_EXPERIENCESCORE_SESSION.TITLE',
            [NgxTrendResultFlattener.getCounterKey(8)]:
              'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.MEMORY_PERCENT_HORIZON_EXPERIENCESCORE_SESSION.TITLE',
            [NgxTrendResultFlattener.getCounterKey(9)]:
              'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.DISK_LATENCY_HORIZON_EXPERIENCESCORE_SESSION.TITLE',
          },
          tableSettings: DeemDashboardConfig.tableSettingsByDefault,
        },
      ],
    },
    [ThresholdAttribute.APP_CPU_USAGE_HORIZON_EXPERIENCESCORE_APP]: {
      visibleWidgetSubTypes: [
        StandardWidgetSubtype.DEEM_VIRTUAL_APP_TOTAL_USERS_COUNT,
        StandardWidgetSubtype.DEEM_VIRTUAL_APP_PERFORMANCE_CPU_STAT,
        StandardWidgetSubtype.DEEM_VIRTUAL_APP_PERFORMANCE_SESSION_LIST_BY_CPU_USAGE,
        StandardWidgetSubtype.DEEM_VIRTUAL_APP_PERFORMANCE_CPU_USAGE_PERCENTAGE,
        StandardWidgetSubtype.DEEM_VIRTUAL_APP_PERFORMANCE_CPU_USAGE_PERCENTILE,
        StandardWidgetSubtype.DEEM_VIRTUAL_APP_PERFORMANCE_CPU_USAGE_BY_OS_VERSION_NAME,
        StandardWidgetSubtype.DEEM_VIRTUAL_APP_PERFORMANCE_CPU_USAGE_BY_POOL_NAME,
        StandardWidgetSubtype._DEEM_VIRTUAL_APP_SESSION_BY_CPU_USAGE,
      ],
      widgetConfigs: [
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype.DEEM_VIRTUAL_APP_PERFORMANCE_CPU_STAT,
          chartHeaderConfig: {
            scoreSummaryWidgetId: StandardWidgetSubtype.DEEM_VIRTUAL_APP_PERFORMANCE_EVENT_COUNT_BY_CPU_SCORE,
            customChartHeaderConfig: [
              {
                widgetId: StandardWidgetSubtype.DEEM_VIRTUAL_APP_TOTAL_USERS_COUNT,
                titleKey: 'DEEM_COMMON_MESSAGES.TOTAL_USERS',
              },
            ],
            showPlatform: true,
          },
          chartYAxisLabelOverrideKey: 'DEEM_COMMON_MESSAGES.COUNT_OF_SCORES',
          showOuterActions: true,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype.DEEM_VIRTUAL_APP_PERFORMANCE_CPU_USAGE_PERCENTAGE,
          chartTitle: 'SOLUTIONS.AVG_CPU_USAGE',
          chartType: AggregationWidgetChartType.LINE,
          leaderboards: [
            {
              subtype: StandardWidgetSubtype.DEEM_VIRTUAL_APP_PERFORMANCE_CPU_USAGE_BY_OS_VERSION_NAME,
              titleKey: 'STANDARD_DASHBOARD.OS_VERSIONS',
              dataUnit: [undefined, DataUnit.PERCENT],
              tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_OS_VERSIONS',
            },
            {
              subtype: StandardWidgetSubtype.DEEM_VIRTUAL_APP_PERFORMANCE_CPU_USAGE_BY_POOL_NAME,
              titleKey: 'STANDARD_DASHBOARD.HORIZON.POOLS',
              dataUnit: [undefined, DataUnit.PERCENT],
              tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_HORIZON_POOLS',
            },
          ],
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype.DEEM_VIRTUAL_APP_PERFORMANCE_CPU_USAGE_PERCENTILE,
          chartTitle: 'DEEM_COMMON_MESSAGES.CPU_95_PERCENTILE',
          chartType: AggregationWidgetChartType.LINE,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype._DEEM_VIRTUAL_APP_SESSION_BY_CPU_USAGE,
          chartTitle: 'DEEM_COMMON_MESSAGES.TOTAL_SESSIONS_VS_TOTAL_SESSIONS_USING_APPS',
          chartType: AggregationWidgetChartType.VERTICAL_GROUP,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_CHART_COMPONENT,
          widgetId: StandardWidgetSubtype.DEEM_VIRTUAL_APP_PERFORMANCE_SESSION_LIST_BY_CPU_USAGE,
          chartTitle: 'SOLUTIONS.TOP_SESSIONS_WITH_HIGH_AVG_CPU_USAGE',
          chartType: AggregationWidgetChartType.TABLE,
          showTableFilter: true,
          tableColumnNames: [
            NgxTrendResultFlattener.getCounterKey(1),
            NgxTrendResultFlattener.getCounterKey(2),
            NgxTrendResultFlattener.getCounterKey(0),
            NgxTrendResultFlattener.getCounterKey(3),
          ],
          tableColumnLabels: {
            [NgxTrendResultFlattener.getCounterKey(1)]: 'CURRENT_USER.USER_NAME',
            [NgxTrendResultFlattener.getCounterKey(2)]: 'DEEM_VIRTUAL_SUMMARY.DESKTOP_NAME',
          },
          tableSettings: DeemDashboardConfig.tableSettingsByDefault,
        },
      ],
    },
    [ThresholdAttribute.APP_MEMORY_USAGE_HORIZON_EXPERIENCESCORE_APP]: {
      visibleWidgetSubTypes: [
        StandardWidgetSubtype.DEEM_VIRTUAL_APP_TOTAL_USERS_COUNT,
        StandardWidgetSubtype.DEEM_VIRTUAL_APP_PERFORMANCE_MEMORY_STAT,
        StandardWidgetSubtype.DEEM_VIRTUAL_APP_PERFORMANCE_SESSION_LIST_BY_MEMORY_USAGE,
        StandardWidgetSubtype.DEEM_VIRTUAL_APP_PERFORMANCE_MEMORY_USAGE_PERCENTAGE,
        StandardWidgetSubtype.DEEM_VIRTUAL_APP_PERFORMANCE_MEMORY_USAGE_PERCENTILE,
        StandardWidgetSubtype.DEEM_VIRTUAL_APP_PERFORMANCE_MEMORY_USAGE_BY_OS_VERSION_NAME,
        StandardWidgetSubtype.DEEM_VIRTUAL_APP_PERFORMANCE_MEMORY_USAGE_BY_POOL_NAME,
        StandardWidgetSubtype._DEEM_VIRTUAL_APP_SESSION_BY_MEMORY_USAGE,
      ],
      widgetConfigs: [
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype.DEEM_VIRTUAL_APP_PERFORMANCE_MEMORY_STAT,
          chartHeaderConfig: {
            scoreSummaryWidgetId: StandardWidgetSubtype.DEEM_VIRTUAL_APP_PERFORMANCE_EVENT_COUNT_BY_MEMORY_SCORE,
            customChartHeaderConfig: [
              {
                widgetId: StandardWidgetSubtype.DEEM_VIRTUAL_APP_TOTAL_USERS_COUNT,
                titleKey: 'DEEM_COMMON_MESSAGES.TOTAL_USERS',
              },
            ],
            showPlatform: true,
          },
          chartYAxisLabelOverrideKey: 'DEEM_COMMON_MESSAGES.COUNT_OF_SCORES',
          showOuterActions: true,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype.DEEM_VIRTUAL_APP_PERFORMANCE_MEMORY_USAGE_PERCENTAGE,
          chartTitle: 'SOLUTIONS.AVG_MEMORY_USAGE',
          chartType: AggregationWidgetChartType.LINE,
          leaderboards: [
            {
              subtype: StandardWidgetSubtype.DEEM_VIRTUAL_APP_PERFORMANCE_MEMORY_USAGE_BY_OS_VERSION_NAME,
              titleKey: 'STANDARD_DASHBOARD.OS_VERSIONS',
              dataUnit: [undefined, DataUnit.PERCENT],
              tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_OS_VERSIONS',
            },
            {
              subtype: StandardWidgetSubtype.DEEM_VIRTUAL_APP_PERFORMANCE_MEMORY_USAGE_BY_POOL_NAME,
              titleKey: 'STANDARD_DASHBOARD.HORIZON.POOLS',
              dataUnit: [undefined, DataUnit.PERCENT],
              tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_HORIZON_POOLS',
            },
          ],
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype.DEEM_VIRTUAL_APP_PERFORMANCE_MEMORY_USAGE_PERCENTILE,
          chartTitle: 'DEEM_COMMON_MESSAGES.MEMORY_95_PERCENTILE',
          chartType: AggregationWidgetChartType.LINE,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype._DEEM_VIRTUAL_APP_SESSION_BY_MEMORY_USAGE,
          chartTitle: 'DEEM_COMMON_MESSAGES.TOTAL_SESSIONS_VS_TOTAL_SESSIONS_USING_APPS',
          chartType: AggregationWidgetChartType.VERTICAL_GROUP,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_CHART_COMPONENT,
          widgetId: StandardWidgetSubtype.DEEM_VIRTUAL_APP_PERFORMANCE_SESSION_LIST_BY_MEMORY_USAGE,
          chartType: AggregationWidgetChartType.TABLE,
          chartTitle: 'SOLUTIONS.TOP_SESSIONS_WITH_HIGH_AVG_MEMORY_USAGE',
          showTableFilter: true,
          tableColumnNames: [
            NgxTrendResultFlattener.getCounterKey(1),
            NgxTrendResultFlattener.getCounterKey(2),
            NgxTrendResultFlattener.getCounterKey(0),
            NgxTrendResultFlattener.getCounterKey(3),
          ],
          tableColumnLabels: {
            [NgxTrendResultFlattener.getCounterKey(1)]: 'CURRENT_USER.USER_NAME',
            [NgxTrendResultFlattener.getCounterKey(2)]: 'DEEM_VIRTUAL_SUMMARY.DESKTOP_NAME',
          },
          tableSettings: DeemDashboardConfig.tableSettingsByDefault,
        },
      ],
    },
    [ThresholdAttribute.APP_CRASH_COUNT_HORIZON_EXPERIENCESCORE_APP]: {
      visibleWidgetSubTypes: [
        StandardWidgetSubtype.DEEM_VIRTUAL_APP_ERRORS_COUNT,
        StandardWidgetSubtype.DEEM_VIRTUAL_APP_ERROR_DEVICE_COUNT,
        StandardWidgetSubtype.DEEM_VIRTUAL_APP_ERRORS,
        StandardWidgetSubtype.DEEM_VIRTUAL_APP_ERRORS_COUNT_BY_OS_VERSION,
        StandardWidgetSubtype.DEEM_VIRTUAL_APP_ERRORS_COUNT_BY_POOL,
        StandardWidgetSubtype.DEEM_VIRTUAL_APP_ERRORS_COUNT_BY_APP_VERSION,
        StandardWidgetSubtype.DEEM_VIRTUAL_APP_ERRORS_LIST,
      ],
      widgetConfigs: [
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype.DEEM_VIRTUAL_APP_ERRORS,
          chartHeaderConfig: {
            customChartHeaderConfig: [
              {
                widgetId: StandardWidgetSubtype.DEEM_VIRTUAL_APP_ERRORS_COUNT,
                titleKey: 'COMMON_MESSAGES.COUNT_OF_CRASHES',
              },
              {
                widgetId: StandardWidgetSubtype.DEEM_VIRTUAL_APP_ERROR_DEVICE_COUNT,
                titleKey: 'COMMON_MESSAGES.DEVICE_COUNT',
              },
            ],
            showPlatform: true,
          },
          chartYAxisLabelOverrideKey: 'COMMON_MESSAGES.COUNT_OF_CRASHES',
          leaderboards: [
            {
              subtype: StandardWidgetSubtype.DEEM_VIRTUAL_APP_ERRORS_COUNT_BY_OS_VERSION,
              titleKey: 'STANDARD_DASHBOARD.OS_VERSIONS',
              tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_OS_VERSIONS',
            },
            {
              subtype: StandardWidgetSubtype.DEEM_VIRTUAL_APP_ERRORS_COUNT_BY_POOL,
              titleKey: 'DEEM_COMMON_MESSAGES.POOLS',
              tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_POOLS',
            },
            {
              subtype: StandardWidgetSubtype.DEEM_VIRTUAL_APP_ERRORS_COUNT_BY_APP_VERSION,
              titleKey: 'APTELIGENT.APP_VERSIONS',
              tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_APP_VERSIONS',
            },
          ],
          showOuterActions: true,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_CHART_COMPONENT,
          widgetId: StandardWidgetSubtype.DEEM_VIRTUAL_APP_ERRORS_LIST,
          chartType: AggregationWidgetChartType.TABLE,
          showTableFilter: true,
          tableColumnNames: [
            COLUMN_NAMES.byFullyQualifiedName.employee_experience_horizon_apps_module,
            ...DeemDashboardConfig.tableColumnNamesForDeemErrorByDefault,
            NgxTrendResultFlattener.getCounterKey(4),
          ],
          tableColumnLabels: {
            [NgxTrendResultFlattener.getCounterKey(0)]: 'COMMON_MESSAGES.OCCURENCES',
            [NgxTrendResultFlattener.getCounterKey(1)]: 'COMMON_MESSAGES.SESSIONS_AFFECTED',
            [NgxTrendResultFlattener.getCounterKey(2)]: 'COMMON_MESSAGES.VMS_AFFECTED',
            [NgxTrendResultFlattener.getCounterKey(3)]: 'COMMON_MESSAGES.FIRST_OCCURRED',
            [NgxTrendResultFlattener.getCounterKey(4)]: 'COMMON_MESSAGES.LAST_OCCURRED',
          },
          tableSettings: DeemDashboardConfig.tableSettingsByDefault,
        },
      ],
    },
    [ThresholdAttribute.APP_HANG_RATE_HORIZON_EXPERIENCESCORE_APP]: {
      visibleWidgetSubTypes: [
        StandardWidgetSubtype.DEEM_VIRTUAL_APP_HANG_COUNT,
        StandardWidgetSubtype.DEEM_VIRTUAL_APP_HANG,
        StandardWidgetSubtype.DEEM_VIRTUAL_APP_HANG_COUNT_BY_OS_VERSION,
        StandardWidgetSubtype.DEEM_VIRTUAL_APP_HANG_COUNT_BY_POOL,
      ],
      widgetConfigs: [
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype.DEEM_VIRTUAL_APP_HANG,
          chartHeaderConfig: {
            customChartHeaderConfig: [
              {
                widgetId: StandardWidgetSubtype.DEEM_VIRTUAL_APP_HANG_COUNT,
                titleKey: 'COMMON_MESSAGES.APP_HANGS',
              },
            ],
            showPlatform: true,
          },
          chartYAxisLabelOverrideKey: 'COMMON_MESSAGES.APP_HANGS',
          leaderboards: [
            {
              subtype: StandardWidgetSubtype.DEEM_VIRTUAL_APP_HANG_COUNT_BY_OS_VERSION,
              titleKey: 'STANDARD_DASHBOARD.OS_VERSIONS',
              tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_OS_VERSIONS',
            },
            {
              subtype: StandardWidgetSubtype.DEEM_VIRTUAL_APP_HANG_COUNT_BY_POOL,
              titleKey: 'DEEM_COMMON_MESSAGES.POOLS',
              tooltipKey: 'DEEM_COMMON_MESSAGES.TOP_5_POOLS',
            },
          ],
          showOuterActions: true,
        },
      ],
    },
    [ThresholdAttribute.DAYS_BEFORE_CERTIFICATE_EXPIRATION_HORIZON_EXPERIENCESCORE_CONNECTION_SERVER]: {
      visibleWidgetSubTypes: [
        StandardWidgetSubtype.CONNECTION_SERVER_CERT_SCORE_STAT,
        StandardWidgetSubtype.COUNT_OF_SCORES_BY_CONNECTION_SERVER_CERT_SCORE,
        StandardWidgetSubtype.__CONNECTION_SERVER_LIST_BY_CERT_SCORE,
      ],
      widgetConfigs: [
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype.CONNECTION_SERVER_CERT_SCORE_STAT,
          chartHeaderConfig: {
            scoreSummaryWidgetId: StandardWidgetSubtype.COUNT_OF_SCORES_BY_CONNECTION_SERVER_CERT_SCORE,
            showPlatform: false,
          },
          chartYAxisLabelOverrideKey: 'DEEM_COMMON_MESSAGES.COUNT_OF_SCORES',
          showOuterActions: true,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_CHART_COMPONENT,
          widgetId: StandardWidgetSubtype.__CONNECTION_SERVER_LIST_BY_CERT_SCORE,
          chartType: AggregationWidgetChartType.TABLE,
          tableColumnNames: DeemDashboardConfig.tableColumnNamesForMgtComponents,
          tableColumnLabels: DeemDashboardConfig.tableColumnLabelsForConnectionServer,
        },
      ],
    },
    [ThresholdAttribute.CPU_USAGE_HORIZON_EXPERIENCESCORE_CONNECTION_SERVER]: {
      visibleWidgetSubTypes: [
        StandardWidgetSubtype.CONNECTION_SERVER_CPU_UTILIZATION_SCORE_STAT,
        StandardWidgetSubtype.COUNT_OF_SCORES_BY_CONNECTION_SERVER_CPU_UTILIZATION_SCORE,
        StandardWidgetSubtype.__CONNECTION_SERVER_LIST_BY_CPU_SCORE,
      ],
      widgetConfigs: [
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype.CONNECTION_SERVER_CPU_UTILIZATION_SCORE_STAT,
          chartHeaderConfig: {
            scoreSummaryWidgetId: StandardWidgetSubtype.COUNT_OF_SCORES_BY_CONNECTION_SERVER_CPU_UTILIZATION_SCORE,
            showPlatform: false,
          },
          chartYAxisLabelOverrideKey: 'DEEM_COMMON_MESSAGES.COUNT_OF_SCORES',
          showOuterActions: true,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_CHART_COMPONENT,
          widgetId: StandardWidgetSubtype.__CONNECTION_SERVER_LIST_BY_CPU_SCORE,
          chartType: AggregationWidgetChartType.TABLE,
          tableColumnNames: DeemDashboardConfig.tableColumnNamesForMgtComponents,
          tableColumnLabels: DeemDashboardConfig.tableColumnLabelsForConnectionServer,
        },
      ],
    },
    [ThresholdAttribute.MEMORY_USAGE_HORIZON_EXPERIENCESCORE_CONNECTION_SERVER]: {
      visibleWidgetSubTypes: [
        StandardWidgetSubtype.CONNECTION_SERVER_MEMORY_UTILIZATION_SCORE_STAT,
        StandardWidgetSubtype.COUNT_OF_SCORES_BY_CONNECTION_SERVER_MEMORY_UTILIZATION_SCORE,
        StandardWidgetSubtype.__CONNECTION_SERVER_LIST_BY_MEMORY_SCORE,
      ],
      widgetConfigs: [
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype.CONNECTION_SERVER_MEMORY_UTILIZATION_SCORE_STAT,
          chartHeaderConfig: {
            scoreSummaryWidgetId: StandardWidgetSubtype.COUNT_OF_SCORES_BY_CONNECTION_SERVER_MEMORY_UTILIZATION_SCORE,
            showPlatform: false,
          },
          chartYAxisLabelOverrideKey: 'DEEM_COMMON_MESSAGES.COUNT_OF_SCORES',
          showOuterActions: true,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_CHART_COMPONENT,
          widgetId: StandardWidgetSubtype.__CONNECTION_SERVER_LIST_BY_MEMORY_SCORE,
          chartType: AggregationWidgetChartType.TABLE,
          tableColumnNames: DeemDashboardConfig.tableColumnNamesForMgtComponents,
          tableColumnLabels: DeemDashboardConfig.tableColumnLabelsForConnectionServer,
        },
      ],
    },
    [ThresholdAttribute.SESSION_COUNT_HORIZON_EXPERIENCESCORE_CONNECTION_SERVER]: {
      visibleWidgetSubTypes: [
        StandardWidgetSubtype.CONNECTION_SERVER_NUM_SESSIONS_SCORE_STAT,
        StandardWidgetSubtype.COUNT_OF_SCORES_BY_CONNECTION_SERVER_NUM_SESSIONS_SCORE,
        StandardWidgetSubtype.__CONNECTION_SERVER_LIST_BY_NUM_SESSION_SCORE,
      ],
      widgetConfigs: [
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype.CONNECTION_SERVER_NUM_SESSIONS_SCORE_STAT,
          chartHeaderConfig: {
            scoreSummaryWidgetId: StandardWidgetSubtype.COUNT_OF_SCORES_BY_CONNECTION_SERVER_NUM_SESSIONS_SCORE,
            showPlatform: false,
          },
          chartYAxisLabelOverrideKey: 'DEEM_COMMON_MESSAGES.COUNT_OF_SCORES',
          showOuterActions: true,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_CHART_COMPONENT,
          widgetId: StandardWidgetSubtype.__CONNECTION_SERVER_LIST_BY_NUM_SESSION_SCORE,
          chartType: AggregationWidgetChartType.TABLE,
          tableColumnNames: DeemDashboardConfig.tableColumnNamesForMgtComponents,
          tableColumnLabels: DeemDashboardConfig.tableColumnLabelsForConnectionServer,
        },
      ],
    },
    [ThresholdAttribute.STATUS_HORIZON_EXPERIENCESCORE_CONNECTION_SERVER]: {
      visibleWidgetSubTypes: [
        StandardWidgetSubtype.CONNECTION_SERVER_STATUS_SCORE_STAT,
        StandardWidgetSubtype.COUNT_OF_SCORES_BY_CONNECTION_SERVER_STATUS_SCORE,
        StandardWidgetSubtype.__CONNECTION_SERVER_LIST_BY_STATUS_SCORE,
      ],
      widgetConfigs: [
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype.CONNECTION_SERVER_STATUS_SCORE_STAT,
          chartHeaderConfig: {
            scoreSummaryWidgetId: StandardWidgetSubtype.COUNT_OF_SCORES_BY_CONNECTION_SERVER_STATUS_SCORE,
            showPlatform: false,
          },
          chartYAxisLabelOverrideKey: 'DEEM_COMMON_MESSAGES.COUNT_OF_SCORES',
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_CHART_COMPONENT,
          widgetId: StandardWidgetSubtype.__CONNECTION_SERVER_LIST_BY_STATUS_SCORE,
          chartType: AggregationWidgetChartType.TABLE,
          tableColumnNames: DeemDashboardConfig.tableColumnNamesForMgtComponents,
          tableColumnLabels: DeemDashboardConfig.tableColumnLabelsForConnectionServer,
        },
      ],
    },

    [ThresholdAttribute.CPU_USAGE_HORIZON_EXPERIENCESCORE_UAG_APPLIANCE]: {
      visibleWidgetSubTypes: [
        StandardWidgetSubtype.UAG_APPLIANCE_CPU_UTILIZATION_SCORE_STAT,
        StandardWidgetSubtype.COUNT_OF_SCORES_BY_UAG_APPLIANCE_CPU_UTILIZATION_SCORE,
        StandardWidgetSubtype.__UAG_APPLIANCE_LIST_BY_CPU_SCORE,
      ],
      widgetConfigs: [
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype.UAG_APPLIANCE_CPU_UTILIZATION_SCORE_STAT,
          chartHeaderConfig: {
            scoreSummaryWidgetId: StandardWidgetSubtype.COUNT_OF_SCORES_BY_UAG_APPLIANCE_CPU_UTILIZATION_SCORE,
            showPlatform: false,
          },
          chartYAxisLabelOverrideKey: 'DEEM_COMMON_MESSAGES.COUNT_OF_SCORES',
          showOuterActions: true,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_CHART_COMPONENT,
          widgetId: StandardWidgetSubtype.__UAG_APPLIANCE_LIST_BY_CPU_SCORE,
          chartType: AggregationWidgetChartType.TABLE,
          tableColumnNames: DeemDashboardConfig.tableColumnNamesForMgtComponents,
          tableColumnLabels: DeemDashboardConfig.tableColumnLabelsForUAGAppliance,
        },
      ],
    },
    [ThresholdAttribute.MEMORY_USAGE_HORIZON_EXPERIENCESCORE_UAG_APPLIANCE]: {
      visibleWidgetSubTypes: [
        StandardWidgetSubtype.UAG_APPLIANCE_MEMORY_USAGE_SCORE_STAT,
        StandardWidgetSubtype.COUNT_OF_SCORES_BY_UAG_APPLIANCE_MEMORY_USAGE_SCORE,
        StandardWidgetSubtype.__UAG_APPLIANCE_LIST_BY_MEMORY_SCORE,
      ],
      widgetConfigs: [
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype.UAG_APPLIANCE_MEMORY_USAGE_SCORE_STAT,
          chartHeaderConfig: {
            scoreSummaryWidgetId: StandardWidgetSubtype.COUNT_OF_SCORES_BY_UAG_APPLIANCE_MEMORY_USAGE_SCORE,
            showPlatform: false,
          },
          chartYAxisLabelOverrideKey: 'DEEM_COMMON_MESSAGES.COUNT_OF_SCORES',
          showOuterActions: true,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_CHART_COMPONENT,
          widgetId: StandardWidgetSubtype.__UAG_APPLIANCE_LIST_BY_MEMORY_SCORE,
          chartType: AggregationWidgetChartType.TABLE,
          tableColumnNames: DeemDashboardConfig.tableColumnNamesForMgtComponents,
          tableColumnLabels: DeemDashboardConfig.tableColumnLabelsForUAGAppliance,
        },
      ],
    },
    [ThresholdAttribute.DAYS_BEFORE_CERTIFICATE_EXPIRATION_HORIZON_EXPERIENCESCORE_UAG_APPLIANCE]: {
      visibleWidgetSubTypes: [
        StandardWidgetSubtype.UAG_APPLIANCE_CERT_SCORE_STAT,
        StandardWidgetSubtype.COUNT_OF_SCORES_BY_UAG_APPLIANCE_CERT_SCORE,
        StandardWidgetSubtype.__UAG_APPLIANCE_LIST_BY_CERT_SCORE,
      ],
      widgetConfigs: [
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype.UAG_APPLIANCE_CERT_SCORE_STAT,
          chartHeaderConfig: {
            scoreSummaryWidgetId: StandardWidgetSubtype.COUNT_OF_SCORES_BY_UAG_APPLIANCE_CERT_SCORE,
            showPlatform: false,
          },
          chartYAxisLabelOverrideKey: 'DEEM_COMMON_MESSAGES.COUNT_OF_SCORES',
          showOuterActions: true,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_CHART_COMPONENT,
          widgetId: StandardWidgetSubtype.__UAG_APPLIANCE_LIST_BY_CERT_SCORE,
          chartType: AggregationWidgetChartType.TABLE,
          tableColumnNames: DeemDashboardConfig.tableColumnNamesForMgtComponents,
          tableColumnLabels: DeemDashboardConfig.tableColumnLabelsForUAGAppliance,
        },
      ],
    },
    [ThresholdAttribute.CONNECTION_COUNT_HORIZON_EXPERIENCESCORE_UAG_APPLIANCE]: {
      visibleWidgetSubTypes: [
        StandardWidgetSubtype.UAG_APPLIANCE_CONNECTION_COUNT_SCORE_STAT,
        StandardWidgetSubtype.COUNT_OF_SCORES_BY_UAG_APPLIANCE_CONNECTION_COUNT_SCORE,
        StandardWidgetSubtype.__UAG_APPLIANCE_LIST_BY_CONNECTION_COUNT_SCORE,
      ],
      widgetConfigs: [
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype.UAG_APPLIANCE_CONNECTION_COUNT_SCORE_STAT,
          chartHeaderConfig: {
            scoreSummaryWidgetId: StandardWidgetSubtype.COUNT_OF_SCORES_BY_UAG_APPLIANCE_CONNECTION_COUNT_SCORE,
            showPlatform: false,
          },
          chartYAxisLabelOverrideKey: 'DEEM_COMMON_MESSAGES.COUNT_OF_SCORES',
          showOuterActions: true,
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_CHART_COMPONENT,
          widgetId: StandardWidgetSubtype.__UAG_APPLIANCE_LIST_BY_CONNECTION_COUNT_SCORE,
          chartType: AggregationWidgetChartType.TABLE,
          tableColumnNames: DeemDashboardConfig.tableColumnNamesForMgtComponents,
          tableColumnLabels: DeemDashboardConfig.tableColumnLabelsForUAGAppliance,
        },
      ],
    },
    [ThresholdAttribute.STATUS_HORIZON_EXPERIENCESCORE_UAG_APPLIANCE]: {
      visibleWidgetSubTypes: [
        StandardWidgetSubtype.UAG_APPLIANCE_STATUS_SCORE_STAT,
        StandardWidgetSubtype.COUNT_OF_SCORES_BY_UAG_APPLIANCE_STATUS_SCORE,
        StandardWidgetSubtype.__UAG_APPLIANCE_LIST_BY_STATUS_SCORE,
      ],
      widgetConfigs: [
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_WIDGET_COMPONENT,
          widgetId: StandardWidgetSubtype.UAG_APPLIANCE_STATUS_SCORE_STAT,
          chartHeaderConfig: {
            scoreSummaryWidgetId: StandardWidgetSubtype.COUNT_OF_SCORES_BY_UAG_APPLIANCE_STATUS_SCORE,
            showPlatform: false,
          },
          chartYAxisLabelOverrideKey: 'DEEM_COMMON_MESSAGES.COUNT_OF_SCORES',
        },
        {
          componentType: DeemContributorWidgetComponentType.STANDARD_CHART_COMPONENT,
          widgetId: StandardWidgetSubtype.__UAG_APPLIANCE_LIST_BY_STATUS_SCORE,
          chartType: AggregationWidgetChartType.TABLE,
          tableColumnNames: DeemDashboardConfig.tableColumnNamesForMgtComponents,
          tableColumnLabels: DeemDashboardConfig.tableColumnLabelsForUAGAppliance,
        },
      ],
    },
  };

  public static readonly DashboardIdToListMap: Record<string, StandardDashboardType[]> = {
    [StandardDashboardType.DEEM_PHYSICAL_USER]: [
      StandardDashboardType.DEEM_PHYSICAL_USER,
      StandardDashboardType.DEEM_PHYSICAL_USER_OVERVIEW,
    ],
    [StandardDashboardType.DEEM_PHYSICAL_DEVICES]: [
      StandardDashboardType.DEEM_PHYSICAL_DEVICES,
      StandardDashboardType.DEEM_PHYSICAL_DEVICES_OVERVIEW,
    ],
    [StandardDashboardType.DEEM_PHYSICAL_DESKTOP_APPS]: [
      StandardDashboardType.DEEM_PHYSICAL_DESKTOP_APPS,
      StandardDashboardType.DEEM_PHYSICAL_DESKTOP_APPS_OVERVIEW,
    ],
    [StandardDashboardType.DEEM_PHYSICAL_DESKTOP_APPS_MACOS]: [
      StandardDashboardType.DEEM_PHYSICAL_DESKTOP_APPS,
      StandardDashboardType.DEEM_PHYSICAL_DESKTOP_APPS_MACOS_OVERVIEW,
    ],
    [StandardDashboardType.DEEM_PHYSICAL_DESKTOP_APPS_WINDOWS]: [
      StandardDashboardType.DEEM_PHYSICAL_DESKTOP_APPS,
      StandardDashboardType.DEEM_PHYSICAL_DESKTOP_APPS_WINDOWS_OVERVIEW,
    ],
    [StandardDashboardType.DEEM_PHYSICAL_MOBILE_APPS]: [
      StandardDashboardType.DEEM_PHYSICAL_MOBILE_APPS,
      StandardDashboardType.DEEM_PHYSICAL_MOBILE_APPS_OVERVIEW,
    ],
    [StandardDashboardType.DEEM_PHYSICAL_MOBILE_APPS_ANDROID]: [
      StandardDashboardType.DEEM_PHYSICAL_MOBILE_APPS,
      StandardDashboardType.DEEM_PHYSICAL_MOBILE_APPS_ANDROID_OVERVIEW,
    ],
    [StandardDashboardType.DEEM_PHYSICAL_MOBILE_APPS_IOS]: [
      StandardDashboardType.DEEM_PHYSICAL_MOBILE_APPS,
      StandardDashboardType.DEEM_PHYSICAL_MOBILE_APPS_IOS_OVERVIEW,
    ],
    [StandardDashboardType.DEEM_PHYSICAL_DESKTOP_APP]: [
      StandardDashboardType.DEEM_PHYSICAL_DESKTOP_APP,
      StandardDashboardType.DEEM_PHYSICAL_DESKTOP_APP_OVERVIEW,
    ],
    [StandardDashboardType.DEEM_PHYSICAL_MOBILE_APP]: [
      StandardDashboardType.DEEM_PHYSICAL_MOBILE_APP,
      StandardDashboardType.DEEM_PHYSICAL_MOBILE_APP_OVERVIEW,
    ],
    [StandardDashboardType.DEEM_PHYSICAL_ORG]: [
      StandardDashboardType.DEEM_PHYSICAL_ORG,
      StandardDashboardType.DEEM_PHYSICAL_ORG_CURRENT_EXPERIENCE,
      StandardDashboardType.DEEM_PHYSICAL_ORG_SCORE_ANALYSIS,
    ],
    [StandardDashboardType.DEEM_PHYSICAL_DEVICES_WINDOWS]: [
      StandardDashboardType.DEEM_PHYSICAL_DEVICES_WINDOWS,
      StandardDashboardType.DEEM_PHYSICAL_DEVICES_WINDOWS_OVERVIEW,
    ],
    [StandardDashboardType.DEEM_PHYSICAL_DEVICES_MACOS]: [
      StandardDashboardType.DEEM_PHYSICAL_DEVICES_MACOS,
      StandardDashboardType.DEEM_PHYSICAL_DEVICES_MACOS_OVERVIEW,
    ],
    [StandardDashboardType.DEEM_PHYSICAL_CRASH_COUNT_EXPERIENCESCORE_DEVICE]: [
      StandardDashboardType.DEEM_PHYSICAL_CRASH_COUNT_EXPERIENCESCORE_DEVICE,
      StandardDashboardType.DEEM_PHYSICAL_CRASH_COUNT_LIST,
    ],
    [StandardDashboardType.DEEM_VIRTUAL_LOGON_DURATION_USER_DETAIL]: [
      StandardDashboardType.DEEM_VIRTUAL_LOGON_DURATION_USER_DETAIL,
      StandardDashboardType.DEEM_VIRTUAL_LOGON_DURATION_USER_LATEST_LOGON_INFO,
    ],
    [StandardDashboardType.DEEM_VIRTUAL_LOGON_EXPERIENCE]: [
      StandardDashboardType.DEEM_VIRTUAL_LOGON_EXPERIENCE,
      StandardDashboardType.DEEM_VIRTUAL_LOGON_EXPERIENCE_OVERVIEW,
    ],
    [StandardDashboardType.DEEM_VIRTUAL_APP_DETAIL]: [
      StandardDashboardType.DEEM_VIRTUAL_APP_DETAIL,
      StandardDashboardType.DEEM_VIRTUAL_APP_DETAIL_OVERVIEW,
    ],
    [StandardDashboardType.DEEM_VIRTUAL_APPS]: [StandardDashboardType.DEEM_VIRTUAL_APPS, StandardDashboardType.DEEM_VIRTUAL_APPS_OVERVIEW],
    [StandardDashboardType.DEEM_VIRTUAL_SESSION_DETAILS_OVERVIEW]: [
      StandardDashboardType.DEEM_VIRTUAL_SESSION_DETAILS_OVERVIEW,
      StandardDashboardType.DEEM_VIRTUAL_SESSION_DETAILS_APP_PERFORMANCE,
      StandardDashboardType.DEEM_VIRTUAL_SESSION_DETAILS_EXPERIENCE,
    ],
    [StandardDashboardType.DEEM_VIRTUAL_MGT_COMPONENTS]: [
      StandardDashboardType.DEEM_VIRTUAL_MGT_COMPONENTS,
      StandardDashboardType.DEEM_VIRTUAL_MGT_COMPONENTS_CONNECTION_SERVER,
      StandardDashboardType.DEEM_VIRTUAL_MGT_COMPONENTS_UAG_APPLIANCE,
    ],
  };

  public static readonly DashboardTypesNeedRuleGroup: StandardDashboardType[] = [
    StandardDashboardType.DEEM_PHYSICAL_ORG,
    StandardDashboardType.DEEM_PHYSICAL_DEVICES,
    StandardDashboardType.DEEM_PHYSICAL_DEVICES_WINDOWS,
    StandardDashboardType.DEEM_PHYSICAL_DEVICES_MACOS,
    StandardDashboardType.DEEM_PHYSICAL_DESKTOP_APPS,
    StandardDashboardType.DEEM_PHYSICAL_DESKTOP_APP,
    StandardDashboardType.DEEM_PHYSICAL_MOBILE_APPS,
    StandardDashboardType.DEEM_PHYSICAL_MOBILE_APP,
    StandardDashboardType.DEEM_PHYSICAL_USER,
    StandardDashboardType.DEEM_PHYSICAL_DEVICE_TEMPERATURE_EXPERIENCESCORE_DEVICE,
    StandardDashboardType.DEEM_PHYSICAL_CRASH_COUNT_EXPERIENCESCORE_DEVICE,
    StandardDashboardType.DEEM_PHYSICAL_CPU_HEALTH_EXPERIENCESCORE_DEVICE,
    StandardDashboardType.DEEM_PHYSICAL_MEMORY_HEALTH_EXPERIENCESCORE_DEVICE,
    StandardDashboardType.DEEM_PHYSICAL_GPU_HEALTH_EXPERIENCESCORE_DEVICE,
    StandardDashboardType.DEEM_PHYSICAL_BATTERY_HEALTH_EXPERIENCESCORE_DEVICE,
    StandardDashboardType.DEEM_PHYSICAL_AVERAGE_BOOT_TIME_MILLIS_EXPERIENCESCORE_DEVICE,
    StandardDashboardType.DEEM_PHYSICAL_AVERAGE_SHUTDOWN_TIME_MILLIS_EXPERIENCESCORE_DEVICE,
    StandardDashboardType.DEEM_PHYSICAL_APP_CRASH_COUNT_EXPERIENCESCORE_DESKTOP_APP,
    StandardDashboardType.DEEM_PHYSICAL_APP_HANG_RATE_EXPERIENCESCORE_DESKTOP_APP,
    StandardDashboardType.DEEM_PHYSICAL_APP_CPU_USAGE_EXPERIENCESCORE_DESKTOP_APP,
    StandardDashboardType.DEEM_PHYSICAL_APP_GPU_USAGE_EXPERIENCESCORE_DESKTOP_APP,
    StandardDashboardType.DEEM_PHYSICAL_APP_MEMORY_USAGE_EXPERIENCESCORE_DESKTOP_APP,
    StandardDashboardType.DEEM_PHYSICAL_APP_UI_UNRESPONSIVE_RATE_EXPERIENCESCORE_DESKTOP_APP,
    StandardDashboardType.DEEM_PHYSICAL_AVERAGE_APP_UI_UNRESPONSIVE_DURATION_EXPERIENCESCORE_DESKTOP_APP,
    StandardDashboardType.DEEM_PHYSICAL_AVERAGE_APP_UI_UNRESPONSIVE_EVENTS_PER_DEVICE_EXPERIENCESCORE_DESKTOP_APP,
    StandardDashboardType.DEEM_VIRTUAL_APPS,
    StandardDashboardType.DEEM_VIRTUAL_LOGON_DURATION_USER_DETAIL,
    StandardDashboardType.DEEM_VIRTUAL_LOGON_EXPERIENCE,
    StandardDashboardType.DEEM_VIRTUAL_LAN_AVERAGE_LATENCY_HORIZON_EXPERIENCESCORE_SESSION,
    StandardDashboardType.DEEM_VIRTUAL_WAN_AVERAGE_LATENCY_HORIZON_EXPERIENCESCORE_SESSION,
    StandardDashboardType.DEEM_VIRTUAL_DOT11_SIGNAL_QUALITY_HORIZON_EXPERIENCESCORE_SESSION,
    StandardDashboardType.DEEM_VIRTUAL_PACKET_LOSS_RATE_HORIZON_EXPERIENCESCORE_SESSION,
    StandardDashboardType.DEEM_VIRTUAL_PROTOCOL_LATENCY_HORIZON_EXPERIENCESCORE_SESSION,
    StandardDashboardType.DEEM_VIRTUAL_CPU_PERCENT_HORIZON_EXPERIENCESCORE_SESSION,
    StandardDashboardType.DEEM_VIRTUAL_MEMORY_PERCENT_HORIZON_EXPERIENCESCORE_SESSION,
    StandardDashboardType.DEEM_VIRTUAL_DISK_LATENCY_HORIZON_EXPERIENCESCORE_SESSION,
    StandardDashboardType.DEEM_VIRTUAL_DISK_IOPS_HORIZON_EXPERIENCESCORE_SESSION,
    StandardDashboardType.DEEM_VIRTUAL_APP_CRASH_COUNT_ERROR_DETAILS,
    StandardDashboardType.DEEM_VIRTUAL_APP_CRASH_COUNT_HORIZON_EXPERIENCESCORE_APP,
    StandardDashboardType.DEEM_VIRTUAL_APP_HANG_RATE_HORIZON_EXPERIENCESCORE_APP,
    StandardDashboardType.DEEM_ZOOM_MEETINGS,
    StandardDashboardType.DEEM_ZOOM_USERS,
    StandardDashboardType.DEEM_ZOOM_CONTRIBUTORS,
    StandardDashboardType.DEEM_VIRTUAL_MGT_COMPONENTS,
    StandardDashboardType.DEEM_VIRTUAL_MGT_COMPONENTS_CONNECTION_SERVER,
    StandardDashboardType.DEEM_VIRTUAL_MGT_COMPONENTS_UAG_APPLIANCE,
    StandardDashboardType.DEEM_VIRTUAL_DAYS_BEFORE_CERTIFICATE_EXPIRATION_HORIZON_EXPERIENCESCORE_CONNECTION_SERVER,
    StandardDashboardType.DEEM_VIRTUAL_CPU_USAGE_HORIZON_EXPERIENCESCORE_CONNECTION_SERVER,
    StandardDashboardType.DEEM_VIRTUAL_MEMORY_USAGE_HORIZON_EXPERIENCESCORE_CONNECTION_SERVER,
    StandardDashboardType.DEEM_VIRTUAL_SESSION_COUNT_HORIZON_EXPERIENCESCORE_CONNECTION_SERVER,
    StandardDashboardType.DEEM_VIRTUAL_STATUS_HORIZON_EXPERIENCESCORE_CONNECTION_SERVER,
    StandardDashboardType.DEEM_VIRTUAL_CPU_USAGE_HORIZON_EXPERIENCESCORE_UAG_APPLIANCE,
    StandardDashboardType.DEEM_VIRTUAL_MEMORY_USAGE_HORIZON_EXPERIENCESCORE_UAG_APPLIANCE,
    StandardDashboardType.DEEM_VIRTUAL_CONNECTION_COUNT_HORIZON_EXPERIENCESCORE_UAG_APPLIANCE,
    StandardDashboardType.DEEM_VIRTUAL_STATUS_HORIZON_EXPERIENCESCORE_UAG_APPLIANCE,
    StandardDashboardType.DEEM_VIRTUAL_DAYS_BEFORE_CERTIFICATE_EXPIRATION_HORIZON_EXPERIENCESCORE_UAG_APPLIANCE,
    StandardDashboardType.DEEM_VIRTUAL_MGT_COMP_CONNECTION_SERVER_DETAIL,
    StandardDashboardType.DEEM_VIRTUAL_MGT_COMP_UAG_APPLIANCE_DETAIL,
  ];

  public static readonly ThresholdToTabMapping = {
    [ThresholdAttribute.APP_CRASH_COUNT_EXPERIENCESCORE_MOBILE_APP]: AppDetailTabType.ERRORS,
    [ThresholdAttribute.APP_HANDLED_EXCEPTIONS_EXPERIENCESCORE_MOBILE_APP]: AppDetailTabType.HANDLED_EXCEPTION,
    [ThresholdAttribute.APP_NET_ERROR_RATE_EXPERIENCESCORE_MOBILE_APP]: AppDetailTabType.NETWORK_INSIGHTS,
    [ThresholdAttribute.AVG_APP_LATENCY_MILLIS_EXPERIENCESCORE_MOBILE_APP]: AppDetailTabType.NETWORK_INSIGHTS,
  };

  public static readonly BreadCrumbUrlConfig: Record<string, GenericObject> = {
    ORGANIZATION_ALL: {
      pathUrl: DEEM_ROUTE_NAMES.SUMMARY_PHYSICAL_ORGANIZATION,
      pathLabelKey: 'DEEM_COMMON_MESSAGES.ORGANIZATION',
    },
    DEVICES_ALL: {
      pathUrl: DEEM_ROUTE_NAMES.SUMMARY_PHYSICAL_DEVICES,
      pathLabelKey: 'DEEM_COMMON_MESSAGES.DEVICES',
    },
    DESKTOP_APPS_ALL: {
      pathUrl: DEEM_ROUTE_NAMES.SUMMARY_PHYSICAL_DESKTOP_APPS,
      pathLabelKey: 'DEEM_COMMON_MESSAGES.DESKTOP_APPS',
    },
    MOBILE_APPS_ALL: {
      pathUrl: DEEM_ROUTE_NAMES.SUMMARY_PHYSICAL_MOBILE_APPS,
      pathLabelKey: 'DEEM_COMMON_MESSAGES.MOBILE_APPS',
    },
    USERS_ALL: {
      pathUrl: DEEM_ROUTE_NAMES.SUMMARY_PHYSICAL_USERS,
      pathLabelKey: 'COMMON_MESSAGES.USER_EXPERIENCE',
    },
    DEVICES_WINDOWS: {
      pathUrl: DEEM_ROUTE_NAMES.SUMMARY_PHYSICAL_DEVICES_WINDOWS,
      pathLabelKey: 'DEEM_COMMON_MESSAGES.WINDOWS',
    },
    DEVICES_MACOS: {
      pathUrl: DEEM_ROUTE_NAMES.SUMMARY_PHYSICAL_DEVICES_MACOS,
      pathLabelKey: 'DEEM_COMMON_MESSAGES.MACOS',
    },
    DESKTOP_APPS_WINDOWS: {
      pathUrl: DEEM_ROUTE_NAMES.SUMMARY_PHYSICAL_DESKTOP_APPS_WINDOWS,
      pathLabelKey: 'DEEM_COMMON_MESSAGES.WINDOWS',
    },
    DESKTOP_APPS_MACOS: {
      pathUrl: DEEM_ROUTE_NAMES.SUMMARY_PHYSICAL_DESKTOP_APPS_MACOS,
      pathLabelKey: 'DEEM_COMMON_MESSAGES.MACOS',
    },
    MOBILE_APPS_ANDROID: {
      pathUrl: DEEM_ROUTE_NAMES.SUMMARY_PHYSICAL_MOBILE_APPS_ANDROID,
      pathLabelKey: 'DEEM_COMMON_MESSAGES.ANDROID',
    },
    MOBILE_APPS_IOS: {
      pathUrl: DEEM_ROUTE_NAMES.SUMMARY_PHYSICAL_MOBILE_APPS_IOS,
      pathLabelKey: 'DEEM_COMMON_MESSAGES.IOS',
    },
    HORIZON_SESSIONS_ALL: {
      pathUrl: DEEM_ROUTE_NAMES.SUMMARY_VIRTUAL_HORIZON_SESSIONS,
      pathLabelKey: 'DEEM_COMMON_MESSAGES.HORIZON_SESSIONS',
    },
    MANAGEMENT_COMPONENTS_ALL: {
      pathUrl: DEEM_ROUTE_NAMES.SUMMARY_VIRTUAL_MANAGEMENT_COMPONENTS,
      pathLabelKey: 'DEEM_COMMON_MESSAGES.MANAGEMENT_COMPONENTS',
    },
    HORIZON_SESSIONS_LOGON_EXPERIENCE: {
      pathUrl: DEEM_ROUTE_NAMES.SUMMARY_VIRTUAL_HORIZON_SESSIONS_LOGON_EXPERIENCE,
      pathLabelKey: 'DEEM_COMMON_MESSAGES.LOGON_UX',
    },
    HORIZON_SESSIONS_NETWORK_PROTOCOL: {
      pathUrl: DEEM_ROUTE_NAMES.SUMMARY_VIRTUAL_HORIZON_SESSIONS_NETWORK_PROTOCOL,
      pathLabelKey: 'DEEM_COMMON_MESSAGES.NETWORK_PROTOCOL',
    },
    HORIZON_SESSIONS_VM_PERFORMANCE: {
      pathUrl: DEEM_ROUTE_NAMES.SUMMARY_VIRTUAL_HORIZON_SESSIONS_VM_PERFORMANCE,
      pathLabelKey: 'DEEM_COMMON_MESSAGES.VM_PERFORMANCE',
    },
    HORIZON_APPS_ALL: {
      pathUrl: DEEM_ROUTE_NAMES.SUMMARY_VIRTUAL_HORIZON_APPS,
      pathLabelKey: 'COMMON_MESSAGES.APPS',
    },
    APP_INTEGRATIONS_ALL: {
      pathUrl: DEEM_ROUTE_NAMES.APP_INTEGRATIONS_ALL,
      pathLabelKey: 'DEEM_COMMON_MESSAGES.APP_INTEGRATIONS',
    },
    APP_INTEGRATIONS_ZOOM: {
      pathUrl: DEEM_ROUTE_NAMES.ZOOM,
      pathLabelKey: 'DEEM_COMMON_MESSAGES.ZOOM_EXPERIENCE_SCORE',
    },
  };

  public static readonly SubDashboardUrlToQueryParams: Record<string, GenericObject> = {
    [DEEM_ROUTE_NAMES.SUMMARY_PHYSICAL_ORGANIZATION_PREFIX]: {
      tags: [
        DeemSettingsUxScoreRowIndex.ORGANIZATION_EXPERIENCE_SCORE,
        DeemSettingsUxScoreRowIndex.DEVICE_HEALTH_EXPERIENCE_SCORE,
        DeemSettingsUxScoreRowIndex.DESKTOP_APP_EXPERIENCE_SCORE,
        DeemSettingsUxScoreRowIndex.MOBILE_APP_EXPERIENCE_SCORE,
      ],
    },
    [DEEM_ROUTE_NAMES.SUMMARY_PHYSICAL_DEVICES_PREFIX]: {
      tags: [DeemSettingsUxScoreRowIndex.DEVICE_HEALTH_EXPERIENCE_SCORE],
    },
    [DEEM_ROUTE_NAMES.SUMMARY_PHYSICAL_DESKTOP_APPS_PREFIX]: {
      tags: [DeemSettingsUxScoreRowIndex.DESKTOP_APP_EXPERIENCE_SCORE],
    },
    [DEEM_ROUTE_NAMES.SUMMARY_PHYSICAL_MOBILE_APPS_PREFIX]: {
      tags: [DeemSettingsUxScoreRowIndex.MOBILE_APP_EXPERIENCE_SCORE],
    },
    [DEEM_ROUTE_NAMES.SUMMARY_PHYSICAL_USERS_PREFIX]: {
      tags: [
        DeemSettingsUxScoreRowIndex.ORGANIZATION_EXPERIENCE_SCORE,
        DeemSettingsUxScoreRowIndex.DEVICE_HEALTH_EXPERIENCE_SCORE,
        DeemSettingsUxScoreRowIndex.DESKTOP_APP_EXPERIENCE_SCORE,
        DeemSettingsUxScoreRowIndex.MOBILE_APP_EXPERIENCE_SCORE,
      ],
    },
    [DEEM_ROUTE_NAMES.SUMMARY_VIRTUAL_ORGANIZATION_PREFIX]: {
      tags: [
        DeemSettingsUxScoreRowIndex.VIRTUAL_SESSION,
        DeemSettingsUxScoreRowIndex.VIRTUAL_EXPERIENCE,
        DeemSettingsUxScoreRowIndex.HORIZON_APP,
        DeemSettingsUxScoreRowIndex.HORIZON_INFRASTRUCTURE,
      ],
    },
    [DEEM_ROUTE_NAMES.SUMMARY_VIRTUAL_HORIZON_SESSIONS_PREFIX]: {
      tags: [DeemSettingsUxScoreRowIndex.VIRTUAL_SESSION],
    },
    [DEEM_ROUTE_NAMES.SUMMARY_VIRTUAL_HORIZON_APPS_PREFIX]: {
      tags: [DeemSettingsUxScoreRowIndex.HORIZON_APP],
    },
    [DEEM_ROUTE_NAMES.SUMMARY_VIRTUAL_MANAGEMENT_COMPONENTS]: {
      tags: [DeemSettingsUxScoreRowIndex.HORIZON_INFRASTRUCTURE],
    },
  };

  public static readonly WidgetTitleKeysById: Record<string, string> = {
    [StandardWidgetSubtype.DEEM_DESKTOP_APPS_WITH_POOR_SCORE_COUNT_LAST_8_HR]: 'DEEM_COMMON_MESSAGES.DESKTOP_APPS',
    [StandardWidgetSubtype.DEEM_DESKTOP_APPS_WITH_POOR_SCORE_COUNT_LAST_4_HR]: 'DEEM_COMMON_MESSAGES.DESKTOP_APPS',
    [StandardWidgetSubtype.DEEM_MOBILE_APPS_WITH_POOR_SCORE_COUNT_LAST_8_HR]: 'DEEM_COMMON_MESSAGES.MOBILE_APPS',
    [StandardWidgetSubtype.DEEM_MOBILE_APPS_WITH_POOR_SCORE_COUNT_LAST_4_HR]: 'DEEM_COMMON_MESSAGES.MOBILE_APPS',
    [StandardWidgetSubtype.DEEM_DEVICES_WITH_POOR_SCORE_COUNT_LAST_8_HR]: 'DEEM_COMMON_MESSAGES.DEVICE_HEALTH',
    [StandardWidgetSubtype.DEEM_DEVICES_WITH_POOR_SCORE_COUNT_LAST_4_HR]: 'DEEM_COMMON_MESSAGES.DEVICE_HEALTH',
    [StandardWidgetSubtype.DEEM_DEVICES_SCORE_LIST]: 'DEEM_COMMON_MESSAGES.DEVICE_HEALTH',
    [StandardWidgetSubtype.DEEM_DESKTOP_APPS_LIST_LAST_4_HR]: 'DEEM_COMMON_MESSAGES.DESKTOP_APPS',
    [StandardWidgetSubtype.DEEM_MOBILE_APPS_LIST_LAST_4_HR]: 'DEEM_COMMON_MESSAGES.MOBILE_APPS',
    [StandardWidgetSubtype.DEEM_VIRTUAL_APP_LIST_LAST_4_HR]: 'DEEM_COMMON_MESSAGES.APPS',
    [StandardWidgetSubtype.DEEM_VIRTUAL_SESSION_COUNT_LAST_8_HR]: 'DEEM_COMMON_MESSAGES.ALL_SESSIONS',
    [StandardWidgetSubtype.DEEM_VIRTUAL_LOGON_DURATION_BY_GPO_LOAD]: 'DEEM_COMMON_MESSAGES.LOGON_UX',
  };

  public static readonly GroupedTableAttrsBySubtype: Record<string, string[]> = {
    [StandardWidgetSubtype.DEEM_DESKTOP_APPS_LIST_LAST_4_HR]: [
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_desktop_app_application_name,
    ],
    [StandardWidgetSubtype.DEEM_MOBILE_APPS_LIST_LAST_4_HR]: [COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_mobile_app_app_id],
  };

  public static readonly DefaultGroupedTableAttrLabelsBySubtype: Record<string, GenericObject> = {
    [StandardWidgetSubtype.DEEM_DESKTOP_APPS_LIST_LAST_4_HR]: {
      [NgxTrendResultFlattener.getCounterKey(0)]: 'DEEM_COMMON_MESSAGES.PLATFORM',
      [NgxTrendResultFlattener.getCounterKey(1)]: 'DEEM_COMMON_MESSAGES.APP_CRASH',
      [NgxTrendResultFlattener.getCounterKey(2)]: 'COMMON_MESSAGES.APP_HANGS',
      [NgxTrendResultFlattener.getCounterKey(3)]: 'DEEM_COMMON_MESSAGES.APPLICATION_EXPERIENCE_SCORE',
      [NgxTrendResultFlattener.getCounterKey(4)]: 'DEEM_COMMON_MESSAGES.SCORE_CALCULATED_AT',
    },
    [StandardWidgetSubtype.DEEM_MOBILE_APPS_LIST_LAST_4_HR]: {
      [NgxTrendResultFlattener.getCounterKey(0)]: 'DEEM_COMMON_MESSAGES.APPLICATION_CONFIG_NAME',
      [NgxTrendResultFlattener.getCounterKey(1)]: 'DEEM_COMMON_MESSAGES.PLATFORM',
      [NgxTrendResultFlattener.getCounterKey(2)]: 'DEEM_COMMON_MESSAGES.MOBILE_APPLICATION_CRASH_RATE',
      [NgxTrendResultFlattener.getCounterKey(3)]: 'DEEM_COMMON_MESSAGES.MOBILE_APPLICATION_HANDLED_EXCEPTIONS',
      [NgxTrendResultFlattener.getCounterKey(4)]: 'DEEM_COMMON_MESSAGES.MOBILE_APPLICATION_NETWORK_ERROR_RATE',
      [NgxTrendResultFlattener.getCounterKey(5)]: 'DEEM_COMMON_MESSAGES.MOBILE_APPLICATION_AVG_APP_LATENCY_MILLIS',
      [NgxTrendResultFlattener.getCounterKey(6)]: 'DEEM_COMMON_MESSAGES.APPLICATION_TYPE',
      [NgxTrendResultFlattener.getCounterKey(7)]: 'DEEM_COMMON_MESSAGES.MOBILE_APPLICATION_EXPERIENCE_SCORE',
      [NgxTrendResultFlattener.getCounterKey(8)]: 'DEEM_COMMON_MESSAGES.SCORE_CALCULATION_TIMESTAMP',
    },
  };

  public static readonly GroupedTableHiddenAttrsBySubtype: Record<string, string[]> = {
    [StandardWidgetSubtype.DEEM_MOBILE_APPS_LIST_LAST_4_HR]: DeemDashboardConfig.hiddenMobileAppColumns,
  };

  public static readonly GroupedTableCountersBySubtype: Record<string, CounterDefinition[]> = {
    [StandardWidgetSubtype.DEEM_DESKTOP_APPS_LIST_LAST_4_HR]: DeemDashboardConfig.desktopAppCounters,
    [StandardWidgetSubtype.DEEM_MOBILE_APPS_LIST_LAST_4_HR]: DeemDashboardConfig.mobileAppCounters,
  };

  public static readonly VirtualWidgetIdMetricNameMapping: Record<string, RawThresholdAttribute> = {
    [StandardWidgetSubtype.DEEM_VIRTUAL_SESSION_COUNT_BY_LOGON_DURATION_SCORE_V2]: RawThresholdAttribute.LOGON_DURATION,
    [StandardWidgetSubtype.DEEN_VIRTUAL_SESSION_COUNT_BY_WIFI_SIGNAL_STRENGTH_SCORE]: RawThresholdAttribute.DOT11_SIGNAL_QUALITY,
    [StandardWidgetSubtype.DEEM_VIRTUAL_SESSION_COUNT_BY_PACKET_LOSS_SCORE]: RawThresholdAttribute.PACKET_LOSS_RATE,
    [StandardWidgetSubtype.DEEM_VIRTUAL_SESSION_COUNT_BY_PROTOCOL_LATENCY_SCORE]: RawThresholdAttribute.PROTOCOL_LATENCY,
    [StandardWidgetSubtype.DEEM_VIRTUAL_SESSION_COUNT_BY_CPU_USAGE_SCORE]: RawThresholdAttribute.CPU_PERCENT,
    [StandardWidgetSubtype.DEEM_VIRTUAL_SESSION_COUNT_BY_MEMORY_USAGE_SCORE]: RawThresholdAttribute.MEMORY_PERCENT,
    [StandardWidgetSubtype.DEEM_VIRTUAL_SESSION_COUNT_BY_DISK_LATENCY_SCORE]: RawThresholdAttribute.DISK_LATENCY,
    [StandardWidgetSubtype.DEEM_VIRTUAL_SESSION_COUNT_BY_DISK_IOPS_SCORE]: RawThresholdAttribute.DISK_IOPS,
    [StandardWidgetSubtype.DEEM_VIRTUAL_SESSION_COUNT_BY_LAN_LATENCY_SCORE]: RawThresholdAttribute.LAN_AVERAGE_LATENCY,
    [StandardWidgetSubtype.DEEM_VIRTUAL_SESSION_COUNT_BY_WAN_LATENCY_SCORE]: RawThresholdAttribute.WAN_AVERAGE_LATENCY,
  };

  public static readonly ThresholdAttrMetaData: Record<
    string,
    { step: number; trendSpan: { duration: number; unit: string }; samplingFrequency: { duration: number; unit: string } }
  > = {
    [RawThresholdAttribute.APP_CPU_USAGE]: {
      step: 0.01,
      trendSpan: {
        duration: 7,
        unit: ChronoUnit[ChronoUnit.DAYS],
      },
      samplingFrequency: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
    },
    [ThresholdAttribute.APP_CPU_USAGE_HORIZON_EXPERIENCESCORE_APP]: {
      step: 0.01,
      trendSpan: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
      samplingFrequency: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
    },
    [RawThresholdAttribute.APP_CRASH_COUNT]: {
      step: 1,
      trendSpan: {
        duration: 7,
        unit: ChronoUnit[ChronoUnit.DAYS],
      },
      samplingFrequency: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
    },
    [ThresholdAttribute.APP_CRASH_COUNT_EXPERIENCESCORE_DESKTOP_APP]: {
      step: 1,
      trendSpan: {
        duration: 7,
        unit: ChronoUnit[ChronoUnit.DAYS],
      },
      samplingFrequency: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
    },
    [ThresholdAttribute.APP_CRASH_COUNT_EXPERIENCESCORE_MOBILE_APP]: {
      step: 1,
      trendSpan: {
        duration: 7,
        unit: ChronoUnit[ChronoUnit.DAYS],
      },
      samplingFrequency: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
    },
    [ThresholdAttribute.APP_CRASH_COUNT_HORIZON_EXPERIENCESCORE_APP]: {
      step: 1,
      trendSpan: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
      samplingFrequency: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
    },
    [RawThresholdAttribute.APP_GPU_USAGE]: {
      step: 0.01,
      trendSpan: {
        duration: 7,
        unit: ChronoUnit[ChronoUnit.DAYS],
      },
      samplingFrequency: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
    },
    [RawThresholdAttribute.APP_HANG_RATE]: {
      step: 1,
      trendSpan: {
        duration: 7,
        unit: ChronoUnit[ChronoUnit.DAYS],
      },
      samplingFrequency: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
    },
    [ThresholdAttribute.APP_HANG_RATE_HORIZON_EXPERIENCESCORE_APP]: {
      step: 0.1,
      trendSpan: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
      samplingFrequency: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
    },
    [RawThresholdAttribute.APP_HANDLED_EXCEPTIONS]: {
      step: 1,
      trendSpan: {
        duration: 7,
        unit: ChronoUnit[ChronoUnit.DAYS],
      },
      samplingFrequency: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
    },
    [RawThresholdAttribute.APP_NET_ERROR_RATE]: {
      step: 0.01,
      trendSpan: {
        duration: 7,
        unit: ChronoUnit[ChronoUnit.DAYS],
      },
      samplingFrequency: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
    },
    [RawThresholdAttribute.APP_MEMORY_USAGE]: {
      step: 0.01,
      trendSpan: {
        duration: 7,
        unit: ChronoUnit[ChronoUnit.DAYS],
      },
      samplingFrequency: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
    },
    [ThresholdAttribute.APP_MEMORY_USAGE_HORIZON_EXPERIENCESCORE_APP]: {
      step: 0.01,
      trendSpan: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
      samplingFrequency: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
    },
    [RawThresholdAttribute.AVG_APP_LATENCY_MILLIS]: {
      step: 1,
      trendSpan: {
        duration: 7,
        unit: ChronoUnit[ChronoUnit.DAYS],
      },
      samplingFrequency: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
    },
    [RawThresholdAttribute.CRASH_COUNT]: {
      step: 0.01,
      trendSpan: {
        duration: 7,
        unit: ChronoUnit[ChronoUnit.DAYS],
      },
      samplingFrequency: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
    },
    [RawThresholdAttribute.AVERAGE_BOOT_TIME_MILLIS]: {
      step: 1,
      trendSpan: {
        duration: 7,
        unit: ChronoUnit[ChronoUnit.DAYS],
      },
      samplingFrequency: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
    },
    [RawThresholdAttribute.AVERAGE_SHUTDOWN_TIME_MILLIS]: {
      step: 1,
      trendSpan: {
        duration: 7,
        unit: ChronoUnit[ChronoUnit.DAYS],
      },
      samplingFrequency: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
    },
    [RawThresholdAttribute.BATTERY_HEALTH]: {
      step: 0.01,
      trendSpan: {
        duration: 7,
        unit: ChronoUnit[ChronoUnit.DAYS],
      },
      samplingFrequency: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
    },
    [RawThresholdAttribute.CPU_HEALTH]: {
      step: 0.01,
      trendSpan: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
      samplingFrequency: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
    },
    [RawThresholdAttribute.DEVICE_TEMPERATURE]: {
      step: 0.01,
      trendSpan: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
      samplingFrequency: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
    },
    [RawThresholdAttribute.GPU_HEALTH]: {
      step: 0.01,
      trendSpan: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
      samplingFrequency: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
    },
    [RawThresholdAttribute.MEMORY_HEALTH]: {
      step: 0.01,
      trendSpan: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
      samplingFrequency: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
    },
    [RawThresholdAttribute.WIFI_STRENGTH]: {
      step: 0.01,
      trendSpan: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
      samplingFrequency: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
    },
    [RawThresholdAttribute.DOWNLOAD_SPEED]: {
      step: 1,
      trendSpan: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
      samplingFrequency: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
    },
    [RawThresholdAttribute.UPLOAD_SPEED]: {
      step: 1,
      trendSpan: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
      samplingFrequency: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
    },
    [RawThresholdAttribute.USERS_WITH_POOR_SCORE_PERCENT]: {
      step: 0.01,
      trendSpan: {
        duration: 7,
        unit: ChronoUnit[ChronoUnit.DAYS],
      },
      samplingFrequency: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
    },
    [RawThresholdAttribute.LOGON_DURATION]: {
      step: 1,
      trendSpan: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
      samplingFrequency: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
    },
    [RawThresholdAttribute.PROTOCOL_LATENCY]: {
      step: 1,
      trendSpan: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
      samplingFrequency: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
    },
    [RawThresholdAttribute.PACKET_LOSS_RATE]: {
      step: 0.01,
      trendSpan: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
      samplingFrequency: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
    },
    [RawThresholdAttribute.CPU_PERCENT]: {
      step: 0.01,
      trendSpan: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
      samplingFrequency: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
    },
    [RawThresholdAttribute.MEMORY_PERCENT]: {
      step: 0.01,
      trendSpan: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
      samplingFrequency: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
    },
    [RawThresholdAttribute.DISK_LATENCY]: {
      step: 1,
      trendSpan: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
      samplingFrequency: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
    },
    [RawThresholdAttribute.DISK_IOPS]: {
      step: 1,
      trendSpan: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
      samplingFrequency: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
    },
    [RawThresholdAttribute.DOT11_SIGNAL_QUALITY]: {
      step: 1,
      trendSpan: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
      samplingFrequency: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
    },
    [RawThresholdAttribute.LAN_AVERAGE_LATENCY]: {
      step: 1,
      trendSpan: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
      samplingFrequency: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
    },
    [RawThresholdAttribute.LAN_PACKET_LOSS_RATE]: {
      step: 0.01,
      trendSpan: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
      samplingFrequency: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
    },
    [RawThresholdAttribute.WAN_AVERAGE_LATENCY]: {
      step: 1,
      trendSpan: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
      samplingFrequency: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
    },
    [RawThresholdAttribute.WAN_PACKET_LOSS_RATE]: {
      step: 0.01,
      trendSpan: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
      samplingFrequency: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
    },
    [RawThresholdAttribute.AVERAGE_APP_UI_UNRESPONSIVE_EVENTS_PER_DEVICE]: {
      step: 1,
      trendSpan: {
        duration: 7,
        unit: ChronoUnit[ChronoUnit.DAYS],
      },
      samplingFrequency: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
    },
    [RawThresholdAttribute.AVERAGE_APP_UI_UNRESPONSIVE_DURATION]: {
      step: 1,
      trendSpan: {
        duration: 7,
        unit: ChronoUnit[ChronoUnit.DAYS],
      },
      samplingFrequency: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
    },
    [RawThresholdAttribute.APP_UI_UNRESPONSIVE_RATE]: {
      step: 0.1,
      trendSpan: {
        duration: 7,
        unit: ChronoUnit[ChronoUnit.DAYS],
      },
      samplingFrequency: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
    },
    [ThresholdAttribute.USERS_WITH_POOR_SCORE_PERCENT_HORIZON_EXPERIENCESCORE_ORG]: {
      step: 0.01,
      trendSpan: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
      samplingFrequency: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
    },
    [ThresholdAttribute.SESSION_COUNT_HORIZON_EXPERIENCESCORE_CONNECTION_SERVER]: {
      step: 1,
      trendSpan: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
      samplingFrequency: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
    },
    [ThresholdAttribute.CPU_USAGE_HORIZON_EXPERIENCESCORE_CONNECTION_SERVER]: {
      step: 0.01,
      trendSpan: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
      samplingFrequency: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
    },
    [ThresholdAttribute.DAYS_BEFORE_CERTIFICATE_EXPIRATION_HORIZON_EXPERIENCESCORE_CONNECTION_SERVER]: {
      step: 1,
      trendSpan: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
      samplingFrequency: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
    },
    [ThresholdAttribute.MEMORY_USAGE_HORIZON_EXPERIENCESCORE_CONNECTION_SERVER]: {
      step: 0.01,
      trendSpan: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
      samplingFrequency: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
    },
    [ThresholdAttribute.CONNECTION_COUNT_HORIZON_EXPERIENCESCORE_UAG_APPLIANCE]: {
      step: 1,
      trendSpan: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
      samplingFrequency: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
    },
    [ThresholdAttribute.MEMORY_USAGE_HORIZON_EXPERIENCESCORE_UAG_APPLIANCE]: {
      step: 0.01,
      trendSpan: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
      samplingFrequency: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
    },
    [ThresholdAttribute.DAYS_BEFORE_CERTIFICATE_EXPIRATION_HORIZON_EXPERIENCESCORE_UAG_APPLIANCE]: {
      step: 1,
      trendSpan: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
      samplingFrequency: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
    },
    [ThresholdAttribute.CPU_USAGE_HORIZON_EXPERIENCESCORE_UAG_APPLIANCE]: {
      step: 0.01,
      trendSpan: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
      samplingFrequency: {
        duration: 4,
        unit: ChronoUnit[ChronoUnit.HOURS],
      },
    },
  };

  public static readonly logonDurationByPhasesColors = [
    {
      name: DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.GPO_LOAD,
      value: '#004d8a',
    },
    {
      name: DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.PROFILE_LOAD,
      value: '#aadb1e',
    },
    {
      name: DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.SHELL_LOAD,
      value: '#089de2',
    },
    {
      name: DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.LOGON_SCRIPT,
      value: '#7ecdf1',
    },
    {
      name: DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.OTHER,
      value: '#007e7a',
    },
  ];

  public static readonly logonDurationSessionColumns: Column[] = [
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.internal_horizon_experiencescore_session_logon_horizon_session_user,
      label: 'CURRENT_USER.USER_NAME',
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.internal_horizon_experiencescore_session_logon_deployment_name,
      label: 'DEEM_COMMON_MESSAGES.POD_OR_EDGE_NAME',
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.internal_horizon_experiencescore_session_logon_pool_name,
      label: 'DEEM_COMMON_MESSAGES.POOL_OR_FARM_NAME',
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.internal_horizon_experiencescore_session_logon_s_logon_timestamp,
      label: 'DEEM_VIRTUAL_SUMMARY.LOGON_TIME',
      dataType: DataType[DataType.DATETIME],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.internal_horizon_experiencescore_session_logon_logon_experience_score,
      label: 'DEEM_VIRTUAL_SUMMARY.LOGON_DURATION_SCORE',
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.internal_horizon_experiencescore_session_logon_logon_duration,
      label: 'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.LOGON_DURATION_HORIZON_EXPERIENCESCORE_SESSION.TITLE',
      dataUnit: DataUnit[DataUnit.SECONDS],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.internal_horizon_experiencescore_session_logon_gpo_load_duration,
      label: 'DEEM_COMMON_MESSAGES.GPO_LOAD',
      dataType: DataType[DataType.DOUBLE],
      dataUnit: DataUnit[DataUnit.SECONDS],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.internal_horizon_experiencescore_session_logon_profile_load_duration,
      label: 'DEEM_COMMON_MESSAGES.PROFILE_LOAD',
      dataType: DataType[DataType.DOUBLE],
      dataUnit: DataUnit[DataUnit.SECONDS],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.internal_horizon_experiencescore_session_logon_shell_load_duration,
      label: 'DEEM_COMMON_MESSAGES.SHELL_LOAD',
      dataType: DataType[DataType.DOUBLE],
      dataUnit: DataUnit[DataUnit.SECONDS],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.internal_horizon_experiencescore_session_logon_logon_script_duration,
      label: 'DEEM_COMMON_MESSAGES.LOGON_SCRIPT',
      dataType: DataType[DataType.DOUBLE],
      dataUnit: DataUnit[DataUnit.SECONDS],
    }),
    new Column({
      name: COLUMN_NAMES.byFullyQualifiedName.internal_horizon_experiencescore_session_logon_guest_os_logon_other_duration,
      label: 'DEEM_COMMON_MESSAGES.OTHER',
      dataType: DataType[DataType.DOUBLE],
      dataUnit: DataUnit[DataUnit.SECONDS],
    }),
  ];

  public static readonly availableColumnsByDeemSubDashboardId: Record<string, Column[]> = {
    [StandardDashboardType.DEEM_VIRTUAL_SESSIONS]: [
      new Column({
        name: COLUMN_NAMES.byFullyQualifiedName.internal_horizon_experiencescore_session_horizon_session_user,
        label: 'CURRENT_USER.USER_NAME',
      }),
      new Column({
        name: COLUMN_NAMES.byFullyQualifiedName.internal_horizon_experiencescore_session_cpu_consumption,
        label: 'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.APP_CPU_USAGE_HORIZON_EXPERIENCESCORE_SESSION.METRIC',
        dataType: DataType[DataType.PERCENTAGE],
      }),
      new Column({
        name: COLUMN_NAMES.byFullyQualifiedName.internal_horizon_experiencescore_session_memory_consumption,
        label: 'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.APP_MEMORY_USAGE_HORIZON_EXPERIENCESCORE_SESSION.METRIC',
        dataType: DataType[DataType.PERCENTAGE],
      }),
      new Column({
        name: COLUMN_NAMES.byFullyQualifiedName.internal_horizon_experiencescore_session_pool,
        label: 'DEEM_COMMON_MESSAGES.POOL_NAME',
      }),
      new Column({
        name: COLUMN_NAMES.byFullyQualifiedName.internal_horizon_experiencescore_session_pod_name,
        label: 'DEEM_COMMON_MESSAGES.EDGE_NAME',
      }),
      new Column({
        name: COLUMN_NAMES.byFullyQualifiedName.internal_horizon_experiencescore_session_client_device_name,
        label: 'DEEM_COMMON_MESSAGES.CLIENT_DEVICE_NAME',
        dataType: DataType[DataType.STRING],
      }),
    ],
    [StandardDashboardType.DEEM_VIRTUAL_LOGON_EXPERIENCE]: DeemDashboardConfig.logonDurationSessionColumns,
    [StandardDashboardType.DEEM_VIRTUAL_LOGON_DURATION_USER_DETAIL]: DeemDashboardConfig.logonDurationSessionColumns,
  };

  public static readonly defaultSortOnsByDeemSubDashboardId: Record<string, SortOn[]> = {
    [StandardDashboardType.DEEM_VIRTUAL_SESSIONS]: [],
  };

  public static readonly deemSurveyWorkflowServiceName: string = 'Workspace ONE Hub Services';

  public static readonly deemSurveyWorkflowActionName: string = 'Send surveys';

  public static readonly performanceConfig: GenericObject[] = [
    {
      name: 'DEEM_VIRTUAL_SUMMARY.CPU',
      summaryInfo: [
        {
          name: 'DEEM_VIRTUAL_SUMMARY.CPU',
          formatType: DataDecorator.PERCENT_LONG,
          unitDescKey: 'COMMON_MESSAGES.AVG',
        },
        {
          name: 'DEEM_VIRTUAL_SUMMARY.CPU',
          formatType: DataDecorator.PERCENT_LONG,
          unitDescKey: 'COMMON_MESSAGES.MAX',
        },
      ],
      yScaleMax: 100,
      appDataExist: true,
    },
    {
      name: 'DEEM_VIRTUAL_SUMMARY.MEMORY',
      summaryInfo: [
        {
          name: 'DEEM_VIRTUAL_SUMMARY.MEMORY',
          formatType: DataDecorator.PERCENT_LONG,
          unitDescKey: 'COMMON_MESSAGES.AVG',
        },
        {
          name: 'DEEM_VIRTUAL_SUMMARY.MEMORY',
          formatType: DataDecorator.PERCENT_LONG,
          unitDescKey: 'COMMON_MESSAGES.MAX',
        },
      ],
      yScaleMax: 100,
      appDataExist: true,
    },
  ];
  public static readonly performanceConfigForSessionTimeline: GenericObject[] = [
    {
      name: 'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.DISK_IOPS_HORIZON_EXPERIENCESCORE_SESSION.METRIC',
      summaryInfo: [
        {
          name: 'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.DISK_IOPS_HORIZON_EXPERIENCESCORE_SESSION.METRIC',
          formatType: DataDecorator.COUNTER_PER_SEC,
          unitDescKey: 'COMMON_MESSAGES.AVG',
        },
        {
          name: 'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.DISK_IOPS_HORIZON_EXPERIENCESCORE_SESSION.METRIC',
          formatType: DataDecorator.COUNTER_PER_SEC,
          unitDescKey: 'COMMON_MESSAGES.MAX',
        },
      ],
      customColors: [
        {
          name: DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.AVG_DISK_IOPS,
          value: DashboardConfig.DEFAULT_COLORS[0],
        },
        {
          name: DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.MAX_DISK_IOPS,
          value: DashboardConfig.DEFAULT_COLORS[1],
        },
      ],
    },
    {
      name: 'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.DISK_LATENCY_HORIZON_EXPERIENCESCORE_SESSION.METRIC',
      summaryInfo: [
        {
          name: 'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.DISK_LATENCY_HORIZON_EXPERIENCESCORE_SESSION.METRIC',
          formatType: DataDecorator.TIME_DURATION,
          unitDescKey: 'COMMON_MESSAGES.AVG',
        },
        {
          name: 'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.DISK_LATENCY_HORIZON_EXPERIENCESCORE_SESSION.METRIC',
          formatType: DataDecorator.TIME_DURATION,
          unitDescKey: 'COMMON_MESSAGES.MAX',
        },
      ],
      customColors: [
        {
          name: DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.AVG_DISK_LATENCY,
          value: DashboardConfig.DEFAULT_COLORS[0],
        },
        {
          name: DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.MAX_DISK_LATENCY,
          value: DashboardConfig.DEFAULT_COLORS[1],
        },
      ],
    },
    {
      name: 'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.PROTOCOL_LATENCY_HORIZON_EXPERIENCESCORE_SESSION.METRIC',
      summaryInfo: [
        {
          name: 'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.PROTOCOL_LATENCY_HORIZON_EXPERIENCESCORE_SESSION.METRIC',
          formatType: DataDecorator.TIME_DURATION,
          unitDescKey: 'COMMON_MESSAGES.AVG',
        },
        {
          name: 'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.PROTOCOL_LATENCY_HORIZON_EXPERIENCESCORE_SESSION.METRIC',
          formatType: DataDecorator.TIME_DURATION,
          unitDescKey: 'COMMON_MESSAGES.MAX',
        },
      ],
      customColors: [
        {
          name: DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.AVG_PROTOCOL_LATENCY,
          value: DashboardConfig.DEFAULT_COLORS[0],
          unitDescKey: 'COMMON_MESSAGES.AVG',
        },
        {
          name: DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.MAX_PROTOCOL_LATENCY,
          value: DashboardConfig.DEFAULT_COLORS[1],
          unitDescKey: 'COMMON_MESSAGES.MAX',
        },
      ],
    },
    {
      name: 'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.PACKET_LOSS_RATE_HORIZON_EXPERIENCESCORE_SESSION.METRIC',
      summaryInfo: [
        {
          name: 'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.PACKET_LOSS_RATE_HORIZON_EXPERIENCESCORE_SESSION.METRIC',
          formatType: DataDecorator.PERCENT_LONG,
          unitDescKey: 'COMMON_MESSAGES.AVG',
        },
        {
          name: 'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.PACKET_LOSS_RATE_HORIZON_EXPERIENCESCORE_SESSION.METRIC',
          formatType: DataDecorator.PERCENT_LONG,
          unitDescKey: 'COMMON_MESSAGES.MAX',
        },
      ],
      customColors: [
        {
          name: DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.AVG_PROTOCOL_PACKET_LOSS,
          value: DashboardConfig.DEFAULT_COLORS[0],
          unitDescKey: 'COMMON_MESSAGES.AVG',
        },
        {
          name: DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.MAX_PROTOCOL_PACKET_LOSS,
          value: DashboardConfig.DEFAULT_COLORS[1],
          unitDescKey: 'COMMON_MESSAGES.MAX',
        },
      ],
      yScaleMax: 100,
    },
    {
      name: 'DEEM_VIRTUAL_SUMMARY.NETWORK_LATENCY',
      summaryInfo: [
        {
          name: 'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.LAN_AVERAGE_LATENCY_HORIZON_EXPERIENCESCORE_SESSION.METRIC',
          formatType: DataDecorator.TIME_DURATION,
          unitDescKey: 'COMMON_MESSAGES.AVG',
        },
        {
          name: 'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.LAN_AVERAGE_LATENCY_HORIZON_EXPERIENCESCORE_SESSION.METRIC',
          formatType: DataDecorator.TIME_DURATION,
          unitDescKey: 'COMMON_MESSAGES.MAX',
        },
        {
          name: 'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.WAN_AVERAGE_LATENCY_HORIZON_EXPERIENCESCORE_SESSION.METRIC',
          formatType: DataDecorator.TIME_DURATION,
          unitDescKey: 'COMMON_MESSAGES.AVG',
        },
        {
          name: 'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.WAN_AVERAGE_LATENCY_HORIZON_EXPERIENCESCORE_SESSION.METRIC',
          formatType: DataDecorator.TIME_DURATION,
          unitDescKey: 'COMMON_MESSAGES.MAX',
        },
      ],
      yScaleMax: 100,
      customColors: [
        {
          name: DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.AVG_LAN_LATENCY,
          value: DashboardConfig.DEFAULT_COLORS[0],
        },
        {
          name: DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.MAX_LAN_LATENCY,
          value: DashboardConfig.DEFAULT_COLORS[1],
        },
        {
          name: DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.AVG_WAN_LATENCY,
          value: DashboardConfig.DEFAULT_COLORS[2],
        },
        {
          name: DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.MAX_WAN_LATENCY,
          value: DashboardConfig.DEFAULT_COLORS[3],
        },
      ],
    },
    {
      name: 'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.WIFI_SIGNAL_STRENGTH_HORIZON_EXPERIENCESCORE_SESSION.METRIC',
      summaryInfo: [
        {
          name: 'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.WIFI_SIGNAL_STRENGTH_HORIZON_EXPERIENCESCORE_SESSION.METRIC',
          formatType: DataDecorator.DECIBEL_MILLIWATTS,
          unitDescKey: 'COMMON_MESSAGES.AVG',
        },
        {
          name: 'SOLUTIONS.DEEM_UX_SCORE.ATTRIBUTES.WIFI_SIGNAL_STRENGTH_HORIZON_EXPERIENCESCORE_SESSION.METRIC',
          formatType: DataDecorator.DECIBEL_MILLIWATTS,
          unitDescKey: 'COMMON_MESSAGES.MAX',
        },
      ],
      customColors: [
        {
          name: DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.AVG_WIFI_SIGNAL_STRENGTH,
          value: DashboardConfig.DEFAULT_COLORS[0],
          unitDescKey: 'COMMON_MESSAGES.AVG',
        },
        {
          name: DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.MAX_WIFI_SIGNAL_STRENGTH,
          value: DashboardConfig.DEFAULT_COLORS[1],
          unitDescKey: 'COMMON_MESSAGES.MAX',
        },
      ],
    },
  ];

  /**
   * getDeemSurveysResultsDashboardIndex
   * @static
   * @param {DeemSurvey} survey
   * @returns {TrendDefinitionIndex}
   * @memberof DeemDashboardConfig
   */
  public static getDeemSurveysResultsDashboardIndex(survey: DeemSurvey): TrendDefinitionIndex {
    const filter = QueryBuilder.queryStringFromFilterRules([
      new FilterRule({
        attribute: COLUMN_NAMES.byFullyQualifiedName.hub_survey_survey_action,
        condition: FilterRule.FILTER_CONDITION.includes,
        data: [AutomationWorkflowExecutionStatus.COMPLETED],
      }),
      new FilterRule({
        attribute: COLUMN_NAMES.byFullyQualifiedName.hub_survey_survey_id,
        condition: FilterRule.FILTER_CONDITION.includes,
        data: [survey?.id],
      }),
    ]);
    return survey.questions.reduce((accum: TrendDefinitionIndex, question: DeemSurveyQuestion) => {
      const bucket = `${COLUMN_NAMES.byFullyQualifiedName.hub_survey_responses}->${question.alias}`;
      accum[StandardWidgetSubtype[`SURVEY_RESULT_Q${question.order}`]] = new TrendDefinition({
        trendMode: TrendMode[TrendMode.HISTORICAL],
        bucketingAttributes: [bucket],
        counterDefinitions: [
          new CounterDefinition({
            aggregateAttribute: COLUMN_NAMES.byFullyQualifiedName.hub_survey_event_id_normalized,
            aggregationFunction: AggregationFunction.COUNT,
          }),
        ],
        filter,
        entitiesByIntegration: {
          [Integration.HUB]: [Entity.SURVEY],
        },
        cardinality: 10,
        accumulate: false,
        dateRange: new TrendDateRange({
          startDateMillis: survey.modifiedAt,
          endDateMillis: Date.now(),
          dateRangeOrder: LodashSortOrder.DESC,
        }),
      });
      return accum;
    }, {});
  }

  /**
   * getDeemSurveysOverviewDashboardRequest
   * @static
   * @param {DeemSurvey} survey
   * @returns {StandardDashboardRequest}
   * @memberof DeemDashboardConfig
   */
  public static getDeemSurveysOverviewDashboardRequest(survey: DeemSurvey): StandardDashboardRequest {
    let startDateMillis = survey.modifiedAt;
    const endDateMillis = Date.now();
    const dayMillis = TrendSpan.timeUnitsByName[ChronoUnit[ChronoUnit.DAYS]].value;
    if (endDateMillis - survey.modifiedAt < dayMillis) {
      startDateMillis = endDateMillis - dayMillis;
    }
    return new StandardDashboardRequest(
      StandardDashboardType.DEEM_SURVEYS_SUMMARY,
      undefined,
      new TrendDateRange({
        startDateMillis,
        endDateMillis,
        dateRangeOrder: LodashSortOrder.DESC,
        samplingFrequency: new TrendSpan({
          duration: 1,
          unit: ChronoUnit[ChronoUnit.DAYS],
        }),
      }),
      {
        survey_id: survey.id,
      },
    );
  }

  /**
   * getDeemSurveysAttemptedSurveysPreviewRequest
   * @static
   * @param {DeemSurvey} survey
   * @returns {PreviewReportContentRequest}
   * @memberof DeemDashboardConfig
   */
  public static getDeemSurveysAttemptedSurveysPreviewRequest(survey: DeemSurvey): PreviewReportContentRequest {
    return new PreviewReportContentRequest({
      pageSize: 10,
      offset: 0,
      filter: QueryBuilder.queryStringFromFilterRules([
        new FilterRule({
          attribute: COLUMN_NAMES.byFullyQualifiedName.internal_workflowexecution_automation_event_type,
          condition: FilterRule.FILTER_CONDITION.includes,
          data: [AutomationEventType.ACTION],
        }),
        new FilterRule({
          attribute: COLUMN_NAMES.byFullyQualifiedName.internal_workflowexecution_rule_id,
          condition: FilterRule.FILTER_CONDITION.includes,
          data: [survey?.metadata?.automationMetadata?.automationId],
        }),
        new FilterRule({
          attribute: COLUMN_NAMES.byFullyQualifiedName.internal_workflowexecution_status,
          condition: FilterRule.FILTER_CONDITION.includes,
          data: [AutomationWorkflowExecutionStatus.COMPLETED],
        }),
        new FilterRule({
          attribute: COLUMN_NAMES.byFullyQualifiedName.internal_workflowexecution_service_name,
          condition: FilterRule.FILTER_CONDITION.includes,
          data: [DeemDashboardConfig.deemSurveyWorkflowServiceName],
        }),
        new FilterRule({
          attribute: COLUMN_NAMES.byFullyQualifiedName.internal_workflowexecution_action_name,
          condition: FilterRule.FILTER_CONDITION.includes,
          data: [DeemDashboardConfig.deemSurveyWorkflowActionName],
        }),
      ]),
      entitiesByIntegration: {
        [Integration.INTELLIGENCE]: [Entity.WORKFLOW_EXECUTION],
      },
    });
  }

  /**
   * getScoreClass
   * @param {GenericObject} dataItem
   * @param {string} columnName
   * @returns {string}
   * @memberof DeemDashboardConfig
   */
  public static getScoreClass(dataItem: GenericObject, columnName: string): string {
    // if the score value col is put in counter_definitions in snowflake repo and the class style is based on its score
    // in snowflake repo, the the score value col should be followed by the raw aggregate_attribute col.
    // e.g. "counter_definitions": [{"aggregate_attribute": "internal.experiencescore_device.crashes", "aggregation_function": "AVG"}, {"aggregate_attribute": "internal.experiencescore_device.crash_count_score", "aggregation_function": "AVG"}]
    const COUNTER_KEY_PREFIX = `${NgxTrendResultFlattener.COUNTER_KEY}_`;
    if (columnName.includes(COUNTER_KEY_PREFIX)) {
      const keyIndex = Number(columnName.split(COUNTER_KEY_PREFIX).pop());
      return DeemDashboardConfig.classByRawScoreValue?.[dataItem[NgxTrendResultFlattener.getCounterKey(keyIndex + 1)]] ?? '';
    }

    const scoreColumn = DeemDashboardConfig.scoreColumnsByColumnName[columnName];
    return DeemDashboardConfig.classByScoreValue[dataItem[scoreColumn]] ?? '';
  }

  /**
   * getScoreLevelMap
   * @static
   * @param {boolean} withScoreSuffix
   * @returns {Record<ScoreType, { label: string; iconShape: string; color: string }>}
   * @memberof DeemDashboardConfig
   */
  public static getScoreLevelMap(withScoreSuffix = false): Record<
    ScoreType,
    {
      label: string;
      iconShape: string;
      color: string;
    }
  > {
    // DEEM_UX_SCORE.GOOD_SCORE -> Good Score
    // COMMON_MESSAGES.GOOD -> Good
    return {
      [ScoreType.GOOD]: {
        label: withScoreSuffix ? 'DEEM_UX_SCORE.GOOD_SCORE' : 'COMMON_MESSAGES.GOOD',
        iconShape: 'check-circle',
        color: DashboardConfig.poorNeutralGoodColors[0].value,
      },
      [ScoreType.NA]: {
        label: withScoreSuffix ? 'DEEM_UX_SCORE.NA_SCORE' : 'COMMON_MESSAGES.NA',
        iconShape: 'exclamation-triangle',
        color: DashboardConfig.unknownColorSet.color,
      },
      [ScoreType.NEUTRAL]: {
        label: withScoreSuffix ? 'DEEM_UX_SCORE.NEUTRAL_SCORE' : 'COMMON_MESSAGES.NEUTRAL',
        iconShape: 'exclamation-triangle',
        color: DashboardConfig.poorNeutralGoodColors[1].value,
      },
      [ScoreType.POOR]: {
        label: withScoreSuffix ? 'DEEM_UX_SCORE.POOR_SCORE' : 'COMMON_MESSAGES.POOR',
        iconShape: 'exclamation-circle',
        color: DashboardConfig.poorNeutralGoodColors[2].value,
      },
      [ScoreType.TOTAL]: undefined,
    };
  }
}
