/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { BreadCrumb, GenericObject, PagedRequest, SortOn, WebError } from '@dpa/ui-common';
import { createAction, props } from '@ngrx/store';
import { GridsterItem } from 'angular-gridster2';

import { ActiveWidgetDialogMode, CopyToWidget, DashboardActionOrigin } from '@ws1c/intelligence-core/store/dashboard/dashboard.state';
import {
  AggregationWidget,
  AggregationWidgetChartType,
  AppInsightWidgetDetail,
  ApteligentCrumbListLocator,
  Bookmark,
  ChartDrilldownEvent,
  CustomReportPreviewSearchResponse,
  Dashboard,
  DashboardSearchRequest,
  DashboardSearchResponse,
  DashboardTagFilter,
  DashboardView,
  DateRangeFilters,
  Device,
  DrilldownTrail,
  GenericSearchRequest,
  IncrementalLoadingTrendPreviewResponse,
  IncrementalLoadingWidgetDetails,
  LayoutType,
  LocalDataGridSettings,
  RiskIndicatorDetailsLocator,
  RuleGroup,
  StandardDashboard,
  StandardDashboardRequest,
  StandardDashboardType,
  StandardWidgetDrilldownEvent,
  TransferOwnershipActionOrigin,
  Trend,
  TrendDateRange,
  TrendDateRangeUpdateRequest,
  TrendDefinition,
  TrendDefinitionIndex,
  TrendPreviewResponse,
  UserAdminAccount,
  UserDetailPageType,
  WidgetColorSchema,
  WidgetDataset,
  WidgetDetailDefinition,
  WidgetDetailPage,
  WidgetPreferences,
  WidgetRangeRequest,
  WidgetTemplate,
  WidgetUpdateDataRequest,
} from '@ws1c/intelligence-models';

/**
 * DashboardActions
 * @export
 * @class DashboarActions
 */
@Injectable()
export class DashboardActions {
  public static readonly CATEGORY = '[DASHBOARD]';

  // CRUD Widget
  public static requestAddWidget = createAction(
    `${DashboardActions.CATEGORY} REQUEST_ADD_WIDGET`,
    props<{ dashboard: DashboardView | Dashboard }>(),
  );

  public static confirmAddWidget = createAction(
    `${DashboardActions.CATEGORY} CONFIRM_ADD_WIDGET`,
    props<{ widget: AggregationWidget; ignoreRedirection?: boolean }>(),
  );

  public static addWidgetSuccess = createAction(`${DashboardActions.CATEGORY} ADD_WIDGET_SUCCESS`, props<{ widget: AggregationWidget }>());

  public static addWidgetFailure = createAction(`${DashboardActions.CATEGORY} ADD_WIDGET_FAILURE`, props<{ error: WebError }>());

  public static requestUpdateWidget = createAction(
    `${DashboardActions.CATEGORY} REQUEST_UPDATE_WIDGET`,
    props<{ widget: AggregationWidget; returnCrumbs?: BreadCrumb[] }>(),
  );

  public static confirmUpdateWidget = createAction(`${DashboardActions.CATEGORY} CONFIRM_UPDATE_WIDGET`);

  public static updateWidgetSuccess = createAction(
    `${DashboardActions.CATEGORY} UPDATE_WIDGET_SUCCESS`,
    props<{ widget: AggregationWidget }>(),
  );

  public static updateWidgetFailure = createAction(`${DashboardActions.CATEGORY} UPDATE_WIDGET_FAILURE`, props<{ error: WebError }>());

  public static setWizardModeToEdit = createAction(`${DashboardActions.CATEGORY} SET_WIZARD_MODE_TO_EDIT`);

  public static requestRenameWidget = createAction(
    `${DashboardActions.CATEGORY} REQUEST_RENAME_WIDGET`,
    props<{ widget: AggregationWidget }>(),
  );

  public static confirmRenameWidget = createAction(
    `${DashboardActions.CATEGORY} CONFIRM_RENAME_WIDGET`,
    props<{ widget: AggregationWidget }>(),
  );

  public static setDashboardThumbnail = createAction(
    `${DashboardActions.CATEGORY} SET_DASHBOARD_THUMBNAIL`,
    props<{ widget: AggregationWidget }>(),
  );

  public static setDashboardThumbnailSuccess = createAction(
    `${DashboardActions.CATEGORY} SET_DASHBOARD_THUMBNAIL_SUCCESS`,
    props<{ dashboard: DashboardView }>(),
  );

  public static setDashboardThumbnailFailure = createAction(
    `${DashboardActions.CATEGORY} SET_DASHBOARD_THUMBNAIL_FAILURE`,
    props<{ error: WebError }>(),
  );

  public static setActiveWidgetDialogMode = createAction(
    `${DashboardActions.CATEGORY} SET_ACTIVE_WIDGET_DIALOG_MODE`,
    props<{ mode: ActiveWidgetDialogMode }>(),
  );

  public static requestDuplicateWidget = createAction(
    `${DashboardActions.CATEGORY} REQUEST_DUPLICATE_WIDGET`,
    props<{ widget: AggregationWidget }>(),
  );

  public static confirmDuplicateWidget = createAction(
    `${DashboardActions.CATEGORY} CONFIRM_DUPLICATE_WIDGET`,
    props<{ widget: AggregationWidget }>(),
  );

  public static duplicateWidgetSuccess = createAction(
    `${DashboardActions.CATEGORY} DUPLICATE_WIDGET_SUCCESS`,
    props<{ widget: AggregationWidget }>(),
  );

  public static duplicateWidgetFailure = createAction(
    `${DashboardActions.CATEGORY} DUPLICATE_WIDGET_FAILURE`,
    props<{ error: WebError }>(),
  );

  public static requestDeleteWidget = createAction(
    `${DashboardActions.CATEGORY} REQUEST_DELETE_WIDGET`,
    props<{ widget: AggregationWidget }>(),
  );

  public static confirmDeleteWidget = createAction(`${DashboardActions.CATEGORY} CONFIRM_DELETE_WIDGET`);

  public static deleteWidgetSuccess = createAction(`${DashboardActions.CATEGORY} DELETE_WIDGET_SUCCESS`, props<{ message: string }>());

  public static deleteWidgetFailure = createAction(`${DashboardActions.CATEGORY} DELETE_WIDGET_FAILURE`, props<{ error: WebError }>());

  public static requestCopyWidget = createAction(
    `${DashboardActions.CATEGORY} REQUEST_COPY_WIDGET`,
    props<{ widget: AggregationWidget }>(),
  );

  public static confirmCopyWidget = createAction(`${DashboardActions.CATEGORY} CONFIRM_COPY_WIDGET`, props<{ copyWidget: CopyToWidget }>());

  public static copyWidgetSuccess = createAction(`${DashboardActions.CATEGORY} COPY_WIDGET_SUCCESS`);

  public static copyWidgetToNewDashboard = createAction(
    `${DashboardActions.CATEGORY} COPY_WIDGET_TO_NEW_DASHBOARD`,
    props<{ copyWidget: CopyToWidget }>(),
  );

  public static copyWidgetToExistingDashboard = createAction(
    `${DashboardActions.CATEGORY} COPY_WIDGET_TO_EXISTING_DASHBOARD`,
    props<{ copyWidget: CopyToWidget }>(),
  );

  public static requestInfoWidget = createAction(
    `${DashboardActions.CATEGORY} REQUEST_INFO_WIDGET`,
    props<{ widget: AggregationWidget }>(),
  );

  public static requestEditThemeWidget = createAction(
    `${DashboardActions.CATEGORY} REQUEST_EDIT_THEME_WIDGET`,
    props<{ widget: AggregationWidget; widgetTheme: GenericObject }>(),
  );

  public static saveEditThemeWidget = createAction(
    `${DashboardActions.CATEGORY} SAVE_EDIT_THEME_WIDGET`,
    props<{ colorSchemas: WidgetColorSchema[] }>(),
  );

  public static setWidgetAttributePreferences = createAction(
    `${DashboardActions.CATEGORY} SET_WIDGET_ATTRIBUTE_PREFERENCES`,
    props<{
      widgetId: string;
      widgetPreferences: WidgetPreferences;
    }>(),
  );

  public static loadWidget = createAction(`${DashboardActions.CATEGORY} LOAD_WIDGET`, props<{ widgetId: string }>());

  public static loadWidgetSuccess = createAction(
    `${DashboardActions.CATEGORY} LOAD_WIDGET_SUCCESS`,
    props<{ widget: AggregationWidget }>(),
  );

  public static loadWidgetFailure = createAction(`${DashboardActions.CATEGORY} LOAD_WIDGET_FAILURE`, props<{ error: WebError }>());

  public static unsetActiveWidget = createAction(`${DashboardActions.CATEGORY} UNSET_ACTIVE_WIDGET`);

  public static loadWidgetData = createAction(`${DashboardActions.CATEGORY} LOAD_WIDGET_DATA`, props<{ widget: AggregationWidget }>());

  public static loadWidgetDataById = createAction(`${DashboardActions.CATEGORY} LOAD_WIDGET_DATA_BY_ID`, props<{ widgetId: string }>());
  public static loadWidgetDataByIdSuccess = createAction(
    `${DashboardActions.CATEGORY} LOAD_WIDGET_DATA_BY_ID_SUCCESS`,
    props<{ widget: AggregationWidget; trendPreview: TrendPreviewResponse }>(),
  );

  public static loadWidgetDataSuccess = createAction(
    `${DashboardActions.CATEGORY} LOAD_WIDGET_DATA_SUCCESS`,
    props<{ widget: AggregationWidget; trend: Trend; overlayTrend?: Trend }>(),
  );

  public static loadWidgetDataFailure = createAction(
    `${DashboardActions.CATEGORY} LOAD_WIDGET_DATA_FAILURE`,
    props<{ widget: AggregationWidget; error: WebError }>(),
  );

  public static updatePreviewChartType = createAction(
    `${DashboardActions.CATEGORY} UPDATE_PREVIEW_CHART_TYPE`,
    props<{ chartType: AggregationWidgetChartType }>(),
  );

  public static previewWidgetData = createAction(
    `${DashboardActions.CATEGORY} PREVIEW_WIDGET_DATA`,
    props<{ request: WidgetUpdateDataRequest; forOverlay?: boolean }>(),
  );

  public static previewWidgetDataV2 = createAction(
    `${DashboardActions.CATEGORY} PREVIEW_WIDGET_DATA_V2`,
    props<{ request: WidgetUpdateDataRequest; forOverlay?: boolean }>(),
  );

  public static previewWidgetDataSuccess = createAction(
    `${DashboardActions.CATEGORY} PREVIEW_WIDGET_DATA_SUCCESS`,
    props<{ trend: Trend; forOverlay?: boolean }>(),
  );

  public static previewWidgetDataFailure = createAction(
    `${DashboardActions.CATEGORY} PREVIEW_WIDGET_DATA_FAILURE`,
    props<{ error: WebError; forOverlay?: boolean }>(),
  );

  public static loadPreviewTable = createAction(`${DashboardActions.CATEGORY} LOAD_PREVIEW_TABLE`, props<{ forOverlay?: boolean }>());

  public static loadWidgetPreviewTableSuccess = createAction(
    `${DashboardActions.CATEGORY} LOAD_WIDGET_PREVIEW_TABLE_SUCCESS`,
    props<{ response: CustomReportPreviewSearchResponse; forOverlay?: boolean }>(),
  );

  public static loadWidgetPreviewTableFailure = createAction(
    `${DashboardActions.CATEGORY} LOAD_WIDGET_PREVIEW_TABLE_FAILURE`,
    props<{ error: WebError }>(),
  );

  public static updateTrendDateRange = createAction(
    `${DashboardActions.CATEGORY} UPDATE_TREND_DATE_RANGE`,
    props<{ request: TrendDateRangeUpdateRequest }>(),
  );

  public static updateTrendDateRangeSuccess = createAction(
    `${DashboardActions.CATEGORY} UPDATE_TREND_DATE_RANGE_SUCCESS`,
    props<{ widget: AggregationWidget }>(),
  );

  public static updateTrendDateRangeFailure = createAction(
    `${DashboardActions.CATEGORY} UPDATE_TREND_DATE_RANGE_FAILURE`,
    props<{ error: WebError }>(),
  );

  public static selectWidgetTemplate = createAction(
    `${DashboardActions.CATEGORY} SELECT_WIDGET_TEMPLATE`,
    props<{ template: WidgetTemplate }>(),
  );

  public static setActiveWidget = createAction(
    `${DashboardActions.CATEGORY} SET_ACTIVE_WIDGET`,
    props<{ widget: AggregationWidget; forQuickMode?: boolean }>(),
  );

  public static checkAndLoadDashboard = createAction(`${DashboardActions.CATEGORY} CHECK_AND_LOAD_DASHBOARD`, props<{ id: string }>());

  public static updateActiveWidgetTrendDefinition = createAction(
    `${DashboardActions.CATEGORY} UPDATE_ACTIVE_WIDGET_TREND_DEFINITION`,
    props<{ partialTrendDefinition: Partial<TrendDefinition> }>(),
  );

  public static updateActiveWidgetOverlayTrendDefinition = createAction(
    `${DashboardActions.CATEGORY} UPDATE_ACTIVE_WIDGET_OVERLAY_TREND_DEFINITION`,
    props<{ partialTrendDefinition: Partial<TrendDefinition> }>(),
  );

  public static addOverlay = createAction(`${DashboardActions.CATEGORY} ADD_OVERLAY`, props<{ widgetDataset: WidgetDataset }>());

  public static removeOverlay = createAction(`${DashboardActions.CATEGORY} REMOVE_OVERLAY`);

  public static setPrecomputedAggregationId = createAction(
    `${DashboardActions.CATEGORY} SET_PRECOMPUTED_AGGREGATION_ID`,
    props<{ precomputedAggregationId: string; forOverlay?: boolean }>(),
  );

  public static addWidgetDrilldownTrail = createAction(
    `${DashboardActions.CATEGORY} ADD_WIDGET_DRILLDOWN_TRAIL`,
    props<{ drilldownTrail: DrilldownTrail }>(),
  );
  public static clearWidgetDrilldownTrail = createAction(`${DashboardActions.CATEGORY} CLEAR_WIDGET_DRILLDOWN_TRAIL`);
  public static clearWidgetDrilldownTrailSuccess = createAction(`${DashboardActions.CATEGORY} CLEAR_WIDGET_DRILLDOWN_TRAIL_SUCCEED`);
  public static popWidgetDrilldownTrail = createAction(
    `${DashboardActions.CATEGORY} POP_WIDGET_DRILLDOWN_TRAIL`,
    props<{ drilldownKey: string }>(),
  );
  public static popWidgetDrilldownTrailSuccess = createAction(
    `${DashboardActions.CATEGORY} POP_WIDGET_DRILLDOWN_TRAIL_SUCCESS`,
    props<{ drilldownKey: string }>(),
  );
  public static setIncidentId = createAction(`${DashboardActions.CATEGORY} SET_INCIDENT_ID`, props<{ incidentId: string }>());
  public static clearIncidentId = createAction(`${DashboardActions.CATEGORY} CLEAR_INCIDENT_ID`);
  public static setRootUrl = createAction(`${DashboardActions.CATEGORY} SET_ROOT_URL`, props<{ rootUrl: string }>());
  public static resetRootUrl = createAction(`${DashboardActions.CATEGORY} RESET_ROOT_URL`);
  public static fetchIncidentDashboard = createAction(`${DashboardActions.CATEGORY} FETCH_INCIDENT_DASHBOARD`);
  public static setIncidentCustomDashboard = createAction(
    `${DashboardActions.CATEGORY} SET_INCIDENT_CUSTOM_DASHBOARD`,
    props<{ dashboard: Dashboard }>(),
  );
  public static resetIncidentCustomDashboard = createAction(`${DashboardActions.CATEGORY} RESET_INCIDENT_CUSTOM_DASHBOARD`);
  public static createCustomDashboard = createAction(
    `${DashboardActions.CATEGORY} CREATE_CUSTOM_DASHBOARD`,
    props<{ name: string; description: string; incidentId?: string; fromTemplateId?: string; sourceTemplateId?: string }>(),
  );

  public static loadPerformanceIndicatorsDashboard = createAction(
    `${DashboardActions.CATEGORY} LOAD_PERFORMANCE_INDICATORS_DASHBOARD`,
    props<{
      dashboardType: StandardDashboardType;
      isCrossCategory: boolean;
      ruleGroup: RuleGroup;
    }>(),
  );

  public static loadNestedTrendDashboard = createAction(
    `${DashboardActions.CATEGORY} LOAD_NESTED_TREND_DASHBOARD`,
    props<{
      request: StandardDashboardRequest;
    }>(),
  );

  public static loadStandardDashboard = createAction(
    `${DashboardActions.CATEGORY} LOAD_STANDARD_DASHBOARD`,
    props<{
      request: StandardDashboardRequest;
      isCrossCategory?: boolean;
      preferenceDataNeeded?: boolean;
      maskAttribute?: boolean;
    }>(),
  );

  public static loadStandardDashboardSuccess = createAction(
    `${DashboardActions.CATEGORY} LOAD_STANDARD_DASHBOARD_SUCCESS`,
    props<{ standardDashboard: StandardDashboard; preferenceDataNeeded?: boolean }>(),
  );

  public static loadStandardDashboardFailure = createAction(
    `${DashboardActions.CATEGORY} LOAD_STANDARD_DASHBOARD_FAILURE`,
    props<{ error: WebError }>(),
  );

  public static loadStandardDashboardData = createAction(
    `${DashboardActions.CATEGORY} LOAD_STANDARD_DASHBOARD_DATA`,
    props<{ trendDefinitionIndex: TrendDefinitionIndex }>(),
  );

  public static loadStandardDashboardDataV2 = createAction(
    `${DashboardActions.CATEGORY} LOAD_STANDARD_DASHBOARD_DATA_V2`,
    props<{ trendDefinitionIndex: TrendDefinitionIndex }>(),
  );

  public static loadStandardDashboardDataSuccess = createAction(
    `${DashboardActions.CATEGORY} LOAD_STANDARD_DASHBOARD_DATA_SUCCESS`,
    props<{ widgetSubtypes: string[]; standardDashboardData: Map<string, Trend> }>(),
  );

  public static loadStandardDashboardDataFailure = createAction(
    `${DashboardActions.CATEGORY} LOAD_STANDARD_DASHBOARD_DATA_FAILURE`,
    props<{ widgetSubtypes: string[]; error: WebError }>(),
  );

  public static searchDashboards = createAction(
    `${DashboardActions.CATEGORY} SEARCH_DASHBOARDS`,
    props<{
      query: string;
      access?: string;
    }>(),
  );

  public static requestAddCustomWidget = createAction(
    `${DashboardActions.CATEGORY} REQUEST_ADD_CUSTOM_WIDGET`,
    props<{ dashboard: DashboardView }>(),
  );

  public static getUnownedDashboards = createAction(`${DashboardActions.CATEGORY} GET_UNOWNED_DASHBOARDS`);

  public static getUnownedDashboardsFailure = createAction(`${DashboardActions.CATEGORY} GET_UNOWNED_DASHBOARDS_FAILURE`);

  public static requestTransferOwnershipDashboards = createAction(
    `${DashboardActions.CATEGORY} REQUEST_TRANSFER_OWNERSHIP_DASHBOARDS`,
    props<{
      dashboards: Dashboard[];
      actionOrigin?: TransferOwnershipActionOrigin;
    }>(),
  );

  public static transferOwnershipForDashboards = createAction(
    `${DashboardActions.CATEGORY} TRANSFER_OWNERSHIP_FOR_DASHBOARDS`,
    props<{ newOwner: UserAdminAccount }>(),
  );

  public static getSelectedDashboardOwnersDAPIds = createAction(`${DashboardActions.CATEGORY} GET_SELECTED_DASHBOARD_OWNERS_DAP_IDS`);

  public static getSelectedDashboardOwnersDAPIdsSuccess = createAction(
    `${DashboardActions.CATEGORY} GET_SELECTED_DASHBOARD_OWNERS_DAP_IDS_SUCCESS`,
    props<{ selectedDashboardOwnersDAPIds: Set<string> }>(),
  );

  public static setTransferToOwnerDAPId = createAction(
    `${DashboardActions.CATEGORY} SET_TRANSFER_TO_OWNER_DAP_ID`,
    props<{ transferToOwnerDAPId: string }>(),
  );

  public static closeDashboardDialog = createAction(`${DashboardActions.CATEGORY} CLOSE_DASHBOARD_DIALOG`);

  public static selectDashboards = createAction(
    `${DashboardActions.CATEGORY} SELECT_DASHBOARDS`,
    props<{ selectedDashboards: Dashboard[] }>(),
  );

  public static setDefaultSearchRequest = createAction(
    `${DashboardActions.CATEGORY} SET_DEFAULT_SEARCH_REQUEST`,
    props<{ dashboardSearchRequest: DashboardSearchRequest }>(),
  );

  public static setWidgetDetailTrend = createAction(`${DashboardActions.CATEGORY} SET_WIDGET_DETAIL_TREND`, props<{ trend: Trend }>());

  public static setWidgetDetailDefinition = createAction(
    `${DashboardActions.CATEGORY} SET_WIDGET_DETAIL_DEFINITION`,
    props<{ widgetDetailDefinition: WidgetDetailDefinition }>(),
  );

  public static setDeemV2IncidentCreationInfo = createAction(
    `${DashboardActions.CATEGORY} SET_DEEM_V2_INCIDENT_CREATION_INFO`,
    props<{ widgetDetailPage: WidgetDetailPage; trend: Trend; widgetDetailTablePreview: CustomReportPreviewSearchResponse }>(),
  );

  public static setMyDashboardViewFilters = createAction(
    `${DashboardActions.CATEGORY} SET_MY_DASHBOARD_VIEW_FILTERS`,
    props<{
      ruleGroup: RuleGroup;
      trendDateRange: TrendDateRange;
    }>(),
  );

  public static loadRiskIndicatorDetails = createAction(
    `${DashboardActions.CATEGORY} LOAD_RISK_INDICATOR_DETAILS`,
    props<RiskIndicatorDetailsLocator>(),
  );
  public static loadRiskIndicatorDetailsSuccess = createAction(
    `${DashboardActions.CATEGORY} LOAD_RISK_INDICATOR_DETAILS_SUCCESS`,
    props<RiskIndicatorDetailsLocator>(),
  );
  public static loadRiskIndicatorDetailsFailure = createAction(
    `${DashboardActions.CATEGORY} LOAD_RISK_INDICATOR_DETAILS_FAILURE`,
    props<RiskIndicatorDetailsLocator>(),
  );

  public static refreshAppDashboardFilters = createAction(
    `${DashboardActions.CATEGORY} REFRESH_APP_DASHBOARD_FILTERS`,
    props<DateRangeFilters>(),
  );

  public static loadApteligentDashboard = createAction(`${DashboardActions.CATEGORY} LOAD_APTELIGENT_DASHBOARD`);

  public static setApteligentDashboardFilters = createAction(
    `${DashboardActions.CATEGORY} SET_APTELIGENT_DASHBOARD_FILTERS`,
    props<DateRangeFilters>(),
  );

  public static loadAppsDashboard = createAction(`${DashboardActions.CATEGORY} LOAD_APPS_DASHBOARD`);

  public static setUserFlowsSubFilterRuleGroup = createAction(
    `${DashboardActions.CATEGORY} SET_USER_FLOWS_SUB_FILTER_RULE_GROUP`,
    props<{ ruleGroup: RuleGroup }>(),
  );

  public static addVisibleWidgetSubtypes = createAction(
    `${DashboardActions.CATEGORY} ADD_VISIBLE_WIDGET_SUBTYPES`,
    props<{ widgetSubtypes: string[] }>(),
  );

  public static removeVisibleWidgetSubtypes = createAction(
    `${DashboardActions.CATEGORY} REMOVE_VISIBLE_WIDGET_SUBTYPES`,
    props<{ widgetSubtypes: string[] }>(),
  );

  public static loadVisibleWidgetSubtypes = createAction(
    `${DashboardActions.CATEGORY} LOAD_VISIBLE_WIDGET_SUBTYPES`,
    props<{ widgetSubtypes: string[] }>(),
  );

  public static goToWidgetDetailPage = createAction(`${DashboardActions.CATEGORY} GO_TO_WIDGET_DETAIL_PAGE`, props<WidgetDetailPage>());

  public static loadActiveDashboard = createAction(`${DashboardActions.CATEGORY} LOAD_ACTIVE_DASHBOARD`);

  public static loadActiveDashboardSuccess = createAction(
    `${DashboardActions.CATEGORY} LOAD_ACTIVE_DASHBOARD_SUCCESS`,
    props<{ dashboardView: DashboardView }>(),
  );

  public static loadActiveDashboardFailure = createAction(
    `${DashboardActions.CATEGORY} LOAD_ACTIVE_DASHBOARD_FAILURE`,
    props<{ error: WebError }>(),
  );

  public static requestRefreshDashboard = createAction(
    `${DashboardActions.CATEGORY} REQUEST_REFRESH_DASHBOARD`,
    props<{ refresh: boolean }>(),
  );

  public static clearWidgetWizard = createAction(`${DashboardActions.CATEGORY} CLEAR_WIDGET_WIZARD`);

  public static changeDashboardLayout = createAction(
    `${DashboardActions.CATEGORY} CHANGE_DASHBOARD_LAYOUT`,
    props<{ widgetList: GridsterItem[] }>(),
  );

  public static changeDashboardLayoutSuccess = createAction(`${DashboardActions.CATEGORY} CHANGE_DASHBOARD_LAYOUT_SUCCESS`);

  public static changeDashboardLayoutFailure = createAction(
    `${DashboardActions.CATEGORY} CHANGE_DASHBOARD_LAYOUT_FAILURE`,
    props<{ error: WebError }>(),
  );

  public static loadDashboardFilter = createAction(
    `${DashboardActions.CATEGORY} LOAD_DASHBOARD_FILTER`,
    props<{ searchRequest: DashboardSearchRequest }>(),
  );

  public static loadDashboardFilterSuccess = createAction(
    `${DashboardActions.CATEGORY} LOAD_DASHBOARD_FILTER_SUCCESS`,
    props<{ searchResponse: DashboardSearchResponse }>(),
  );

  public static loadDashboardFilterFailure = createAction(
    `${DashboardActions.CATEGORY} LOAD_DASHBOARD_FILTER_FAILURE`,
    props<{ error: WebError }>(),
  );

  public static loadDashboardList = createAction(`${DashboardActions.CATEGORY} LOAD_DASHBOARD_LIST`);

  public static loadDashboardListSuccess = createAction(
    `${DashboardActions.CATEGORY} LOAD_DASHBOARD_LIST_SUCCESS`,
    props<{ searchResponse: DashboardSearchResponse }>(),
  );

  public static loadDashboardListFailure = createAction(
    `${DashboardActions.CATEGORY} LOAD_DASHBOARD_LIST_FAILURE`,
    props<{ error: WebError }>(),
  );

  public static requestCloneDashboard = createAction(
    `${DashboardActions.CATEGORY} REQUEST_CLONE_DASHBOARD`,
    props<{ dashboard: Dashboard | DashboardView }>(),
  );

  public static confirmCloneDashboard = createAction(`${DashboardActions.CATEGORY} CONFIRM_CLONE_DASHBOARD`, props<{ name: string }>());

  public static requestCloneDashboardSuccess = createAction(`${DashboardActions.CATEGORY} REQUEST_CLONE_DASHBOARD_SUCCESS`);

  public static requestCloneDashboardFailure = createAction(
    `${DashboardActions.CATEGORY} REQUEST_CLONE_DASHBOARD_FAILURE`,
    props<{ error: WebError }>(),
  );

  public static requestRenameDashboard = createAction(
    `${DashboardActions.CATEGORY} REQUEST_RENAME_DASHBOARD`,
    props<{ dashboard: Dashboard | DashboardView }>(),
  );

  public static confirmRenameDashboard = createAction(
    `${DashboardActions.CATEGORY} CONFIRM_RENAME_DASHBOARD`,
    props<{
      name: string;
      description: string;
    }>(),
  );

  public static requestRenameDashboardSuccess = createAction(`${DashboardActions.CATEGORY} REQUEST_RENAME_DASHBOARD_SUCCESS`);

  public static requestRenameDashboardFailure = createAction(
    `${DashboardActions.CATEGORY} REQUEST_RENAME_DASHBOARD_FAILURE`,
    props<{ error: WebError }>(),
  );

  public static requestImportDashboard = createAction(`${DashboardActions.CATEGORY} REQUEST_IMPORT_DASHBOARD`);

  public static importDashboards = createAction(`${DashboardActions.CATEGORY} IMPORT_DASHBOARDS`, props<{ files: File[] }>());

  public static importDashboardsSuccess = createAction(`${DashboardActions.CATEGORY} IMPORT_DASHBOARDS_SUCCCESS`);

  public static importDashboardsFailure = createAction(`${DashboardActions.CATEGORY} IMPORT_DASHBOARDS_FAILURE`);

  public static requestExportDashboard = createAction(
    `${DashboardActions.CATEGORY} REQUEST_EXPORT_DASHBOARD`,
    props<{ dashboard: Dashboard | DashboardView }>(),
  );

  public static requestExportDashboardSuccess = createAction(`${DashboardActions.CATEGORY} REQUEST_EXPORT_DASHBOARD_SUCCESS`);

  public static requestDeleteDashboard = createAction(
    `${DashboardActions.CATEGORY} REQUEST_DELETE_DASHBOARD`,
    props<{ dashboard: Dashboard | DashboardView }>(),
  );

  public static confirmDeleteDashboard = createAction(`${DashboardActions.CATEGORY} CONFIRM_DELETE_DASHBOARD`);

  public static requestDeleteDashboardSuccess = createAction(
    `${DashboardActions.CATEGORY} REQUEST_DELETE_DASHBOARD_SUCCESS`,
    props<{ dashboard: Dashboard | DashboardView }>(),
  );

  public static requestDeleteDashboardFailure = createAction(
    `${DashboardActions.CATEGORY} REQUEST_DELETE_DASHBOARD_FAILURE`,
    props<{ error: WebError }>(),
  );

  public static requestShareDashboard = createAction(
    `${DashboardActions.CATEGORY} REQUEST_SHARE_DASHBOARD`,
    props<{
      dashboard: Dashboard | DashboardView;
      origin: DashboardActionOrigin;
    }>(),
  );

  public static requestShareDashboardSuccess = createAction(`${DashboardActions.CATEGORY} REQUEST_SHARE_DASHBOARD_SUCCESS`);

  public static requestShareDashboardFailure = createAction(
    `${DashboardActions.CATEGORY} REQUEST_SHARE_DASHBOARD_FAILURE`,
    props<{ error: WebError }>(),
  );

  public static goToDashboardPage = createAction(`${DashboardActions.CATEGORY} GO_TO_DASHBOARD_PAGE`, props<{ dashboardId: string }>());

  public static goToDashboardPageEditLayout = createAction(
    `${DashboardActions.CATEGORY} GO_TO_DASHBOARD_PAGE_EDIT_LAYOUT`,
    props<{
      dashboard: Dashboard | DashboardView;
      filter?: boolean;
    }>(),
  );

  public static goToCreateDashboardPage = createAction(`${DashboardActions.CATEGORY} GO_TO_CREATE_DASHBOARD_PAGE`);

  public static goToDashboardListPage = createAction(`${DashboardActions.CATEGORY} GO_TO_DASHBOARD_LIST_PAGE`);

  public static loadDashboard = createAction(`${DashboardActions.CATEGORY} LOAD_DASHBOARD`, props<{ id: string }>());

  public static saveAndGotoDashboardPage = createAction(
    `${DashboardActions.CATEGORY} SAVE_AND_GO_TO_DASHBOARD_PAGE`,
    props<{
      name: string;
      description: string;
      dashboardType?: string;
    }>(),
  );

  public static searchDashboardsSuccess = createAction(
    `${DashboardActions.CATEGORY} SEARCH_DASHBOARDS_SUCCESS`,
    props<{ searchResponse: DashboardSearchResponse }>(),
  );

  public static searchDashboardsFailure = createAction(
    `${DashboardActions.CATEGORY} SEARCH_DASHBOARDS_FAILURE`,
    props<{ error: WebError }>(),
  );

  public static refreshDashboardList = createAction(`${DashboardActions.CATEGORY} REFRESH_DASHBOARD_LIST`);

  public static changePagination = createAction(
    `${DashboardActions.CATEGORY} CHANGE_PAGINATION_DASHBOARDS`,
    props<{ page: PagedRequest }>(),
  );

  public static sortDashboards = createAction(`${DashboardActions.CATEGORY} SORT_DASHBOARD_LIST`, props<{ sorts: SortOn[] }>());

  public static filterDashboards = createAction(`${DashboardActions.CATEGORY} FILTER_DASHBOARDS`, props<{ query: string }>());

  public static filterDashboardWidgetList = createAction(
    `${DashboardActions.CATEGORY} FILTER_DASHBOARD_WIDGET_LIST`,
    props<{ filterRequest: GenericSearchRequest }>(),
  );

  public static resetDashboardWidgetList = createAction(`${DashboardActions.CATEGORY} RESET_DASHBOARD_WIDGET_LIST`);

  public static updateDashboardListFilter = createAction(
    `${DashboardActions.CATEGORY} UPDATE_DASHBOARD_LIST_FILTER`,
    props<{ filter: DashboardTagFilter }>(),
  );

  public static updateDashboardListFilterSuccess = createAction(
    `${DashboardActions.CATEGORY} UPDATE_DASHBOARD_LIST_FILTER_SUCCESS`,
    props<{ request: DashboardSearchRequest }>(),
  );

  public static updateDashboardSuccess = createAction(
    `${DashboardActions.CATEGORY} UPDATE_DASHBOARD_SUCCESS`,
    props<{ dashboard: Dashboard }>(),
  );

  public static loadAppDashboard = createAction(`${DashboardActions.CATEGORY} LOAD_APP_DASHBOARD`);

  public static updateWidgetName = createAction(`${DashboardActions.CATEGORY} UPDATE_WIDGET_NAME`, props<{ name: string }>());

  public static updateWidgetBasicInfo = createAction(
    `${DashboardActions.CATEGORY} UPDATE_WIDGET_BASIC_INFO`,
    props<{ name: string; description: string }>(),
  );

  public static loadMergedStandardDashboard = createAction(
    `${DashboardActions.CATEGORY} LOAD_MERGED_STANDARD_DASHBOARD`,
    props<{ requests: StandardDashboardRequest[]; isCrossCategory?: boolean }>(),
  );

  public static setWidgetIdToDashboardIdMap = createAction(
    `${DashboardActions.CATEGORY} SET_MERGED_DASHBOARD_WIDGET_ID`,
    props<{ widgetIdToDashboardIdMap: Record<string, string> }>(),
  );

  public static setUnMergeWidgetSubTypeList = createAction(
    `${DashboardActions.CATEGORY} SET_UNMERGE_WIDGET_SUBTYPE_LIST`,
    props<{ list: string[] }>(),
  );

  public static resetUnMergeWidgetSubTypeList = createAction(`${DashboardActions.CATEGORY} RESET_UNMERGE_WIDGET_SUBTYPE_LIST`);

  public static addWidgetToBookmarks = createAction(
    `${DashboardActions.CATEGORY} ADD_WIDGET_TO_BOOKMARKS`,
    props<{ widgetDetailPage: WidgetDetailPage; bookmark: Bookmark }>(),
  );

  public static removeWidgetFromBookmarks = createAction(
    `${DashboardActions.CATEGORY} REMOVE_WIDGET_FROM_BOOKMARKS`,
    props<{ widget: AggregationWidget }>(),
  );

  public static initializeWidgetDetail = createAction(
    `${DashboardActions.CATEGORY} INITIALIZE_WIDGET_DETAIL`,
    props<{ widgetDetailPage: WidgetDetailPage }>(),
  );

  public static clearWidgetDetail = createAction(`${DashboardActions.CATEGORY} CLEAR_WIDGET_DETAIL`);

  public static setWidgetDetailDrilldownEventIndex = createAction(
    `${DashboardActions.CATEGORY} SET_WIDGET_DETAIL_DRILLDOWN_EVENT_INDEX`,
    props<{ drilldownEventIndex: number }>(),
  );

  public static setWidgetDetailUrl = createAction(
    `${DashboardActions.CATEGORY} SET_WIDGET_DETAIL_URL`,
    props<{ widgetDetailUrl: string }>(),
  );

  public static openEditRangeDialog = createAction(
    `${DashboardActions.CATEGORY} OPEN_EDIT_RANGE_DIALOG`,
    props<{ isOpen: boolean; groupByLabel?: string; editRangeWidgetId?: string }>(),
  );

  public static pushWidgetDetailDrilldownEvent = createAction(
    `${DashboardActions.CATEGORY} PUSH_WIDGET_DETAIL_DRILLDOWN_EVENT`,
    props<{ drilldownEvent: ChartDrilldownEvent }>(),
  );

  public static setWidgetDetailPagedRequest = createAction(
    `${DashboardActions.CATEGORY} SET_WIDGET_DETAIL_PAGED_REQUEST`,
    props<{ pagedRequest: PagedRequest; forOverlay?: boolean }>(),
  );

  public static setWidgetPreviewPagedRequest = createAction(
    `${DashboardActions.CATEGORY} SET_WIDGET_PREVIEW_PAGED_REQUEST`,
    props<{ pagedRequest: PagedRequest; forOverlay?: boolean }>(),
  );

  public static setWidgetDetailTableColumnNames = createAction(
    `${DashboardActions.CATEGORY} SET_WIDGET_DETAIL_TABLE_COLUMN_NAMES`,
    props<{
      columnNames: string[];
      widgetId: string;
      dashboardId: string;
      widgetDatasetId?: string;
      fromDetails?: boolean;
      forOverlay?: boolean;
    }>(),
  );

  public static setWidgetTableSortOns = createAction(
    `${DashboardActions.CATEGORY} SET_WIDGET_TABLE_SORT_ONS`,
    props<{ sortOns: SortOn[]; forOverlay?: boolean }>(),
  );

  public static setWidgetPreviewTableSortOns = createAction(
    `${DashboardActions.CATEGORY} SET_WIDGET_PREVIEW_TABLE_SORT_ONS`,
    props<{ sortOns: SortOn[]; forOverlay?: boolean }>(),
  );

  public static setWidgetRangeFilter = createAction(`${DashboardActions.CATEGORY} SET_WIDGET_RANGE_FILTER`, props<WidgetRangeRequest>());

  public static setWidgetRangeFilterSuccess = createAction(`${DashboardActions.CATEGORY} SET_WIDGET_RANGE_FILTER_SUCCESS`);

  public static loadCompositeWidgetDetail = createAction(`${DashboardActions.CATEGORY} LOAD_COMPOSITE_WIDGET_DETAIL`);

  public static loadCompositeWidgetDetailSuccess = createAction(
    `${DashboardActions.CATEGORY} LOAD_COMPOSITE_WIDGET_DETAIL_SUCCESS`,
    props<{ compositeTrendData: Map<string, Trend> }>(),
  );

  public static loadCompositeWidgetDetailFailure = createAction(
    `${DashboardActions.CATEGORY} LOAD_COMPOSITE_WIDGET_DETAIL_FAILURE`,
    props<{ webError: WebError }>(),
  );

  public static setWidgetDetailSelectedCompositeTableSubtype = createAction(
    `${DashboardActions.CATEGORY} SET_WIDGET_DETAIL_SELECTED_COMPOSITE_TABLE_SUBTYPE`,
    props<{
      widgetSubtype: string;
      sortOns?: SortOn[];
    }>(),
  );

  public static loadWidgetDetailTrend = createAction(`${DashboardActions.CATEGORY} LOAD_WIDGET_DETAIL_TREND`);

  public static loadWidgetDetailTablePreview = createAction(`${DashboardActions.CATEGORY} LOAD_WIDGET_DETAIL_TABLE_PREVIEW`);

  public static loadWidgetDetailSuccess = createAction(
    `${DashboardActions.CATEGORY} LOAD_WIDGET_DETAIL_SUCCESS`,
    props<{ widgetDetailTrend: Trend; widgetDetailOverlayTrend?: Trend }>(),
  );

  public static loadWidgetDetailFailure = createAction(
    `${DashboardActions.CATEGORY} LOAD_WIDGET_DETAIL_FAILURE`,
    props<{ error: WebError }>(),
  );

  public static loadWidgetDetailTablePreviewSuccess = createAction(
    `${DashboardActions.CATEGORY} LOAD_WIDGET_DETAIL_TABLE_PREVIEW_SUCCESS`,
    props<{ response: CustomReportPreviewSearchResponse; overlayResponse?: CustomReportPreviewSearchResponse }>(),
  );

  public static loadWidgetDetailTablePreviewFailure = createAction(
    `${DashboardActions.CATEGORY} LOAD_WIDGET_DETAIL_TABLE_PREVIEW_FAILURE`,
    props<{ error: WebError }>(),
  );

  public static securityWidgetDrilldown = createAction(
    `${DashboardActions.CATEGORY} SECURITY_WIDGET_DRILLDOWN`,
    props<{ drilldownEvent: StandardWidgetDrilldownEvent }>(),
  );

  public static loadUserFlowsCrumbList = createAction(
    `${DashboardActions.CATEGORY} LOAD_USER_FLOWS_CRUMB_LIST`,
    props<{ crumbListLocator: ApteligentCrumbListLocator }>(),
  );

  public static loadUserFlowsCrumbListSuccess = createAction(
    `${DashboardActions.CATEGORY} LOAD_USER_FLOWS_CRUMB_LIST_SUCCESS`,
    props<{ crumbListLocator: ApteligentCrumbListLocator; responses: CustomReportPreviewSearchResponse[] }>(),
  );

  public static loadUserFlowsCrumbListFailure = createAction(
    `${DashboardActions.CATEGORY} LOAD_USER_FLOWS_CRUMB_LIST_FAILURE`,
    props<{ crumbListLocator: ApteligentCrumbListLocator; error: WebError }>(),
  );

  public static scrollToBottom = createAction(`${DashboardActions.CATEGORY} SCROLL_TO_BOTTOM`, props<{ isScroll: boolean }>());

  public static layoutEditConfirm = createAction(`${DashboardActions.CATEGORY} LAYOUT_EDIT_CONFIRM`, props<{ isConfirmed: boolean }>());

  public static resetDashboardLayout = createAction(
    `${DashboardActions.CATEGORY} RESET_DASHBOARD_LAYOUT`,
    props<{ dashboardId: string }>(),
  );

  public static setDashboardBookmark = createAction(
    `${DashboardActions.CATEGORY} SET_DASHBOARD_BOOKMARK`,
    props<{ dashboardId: string }>(),
  );

  public static clearDashboardBookmark = createAction(
    `${DashboardActions.CATEGORY} CLEAR_DASHBOARD_BOOKMARK`,
    props<{ dashboardId: string }>(),
  );

  public static setDashboardBookmarksSuccess = createAction(
    `${DashboardActions.CATEGORY} SET_DASHBOARD_BOOKMARKS_SUCCESS`,
    props<{ dashboardView: DashboardView }>(),
  );

  public static setDashboardBookmarksFailure = createAction(
    `${DashboardActions.CATEGORY} SET_DASHBOARD_BOOKMARKS_FAILURE`,
    props<{ error: WebError }>(),
  );

  public static pushDrilldownEvent = createAction(
    `${DashboardActions.CATEGORY} PUSH_DRILLDOWN_EVENT`,
    props<{ widgetId: string; drilldownEvent: ChartDrilldownEvent }>(),
  );

  public static pushGlobalFilterAsDrilldownEvent = createAction(
    `${DashboardActions.CATEGORY} PUSH_GLOBAL_FILTER_AS_DRILLDOWN_EVENT`,
    props<{ widgetId: string; drilldownEvent: ChartDrilldownEvent }>(),
  );

  public static pushCustomWidgetDrilldownEvent = createAction(
    `${DashboardActions.CATEGORY} PUSH_CUSTOM_WIDGET_DRILLDOWN_EVENT`,
    props<{ widgetId: string; drilldownEvent: ChartDrilldownEvent; widget?: AggregationWidget }>(),
  );

  public static clearAllCustomDrilldownEvents = createAction(`${DashboardActions.CATEGORY} CLEAR_ALL_CUSTOM_DRILLDOWN_EVENTS`);

  public static clearDrilldownEvents = createAction(`${DashboardActions.CATEGORY} CLEAR_DRILLDOWN_EVENTS`, props<{ widgetId: string }>());

  public static clearCustomDrilldownEvents = createAction(
    `${DashboardActions.CATEGORY} CLEAR_CUSTOM_DRILLDOWN_EVENTS`,
    props<{ widget: AggregationWidget }>(),
  );

  public static popDrilldownEvent = createAction(`${DashboardActions.CATEGORY} POP_DRILLDOWN_EVENT`, props<{ widgetId: string }>());

  public static popDrilldownEventWithoutLoad = createAction(
    `${DashboardActions.CATEGORY} POP_DRILLDOWN_EVENT_WITHOUT_LOAD`,
    props<{ widgetId: string }>(),
  );

  public static popCustomDrilldownEvent = createAction(
    `${DashboardActions.CATEGORY} POP_CUSTOM_DRILLDOWN_EVENT`,
    props<{ widget: AggregationWidget }>(),
  );

  public static goToWidgetIdDetailPage = createAction(
    `${DashboardActions.CATEGORY} GO_TO_WIDGET_ID_DETAIL_PAGE`,
    props<{ widgetDetailPage: WidgetDetailPage }>(),
  );

  public static goToCustomWidgetDetailPage = createAction(
    `${DashboardActions.CATEGORY} GO_TO_CUSTOM_WIDGET_DETAIL_PAGE`,
    props<{ widgetDetailPage: WidgetDetailPage }>(),
  );

  public static patchLocalDataGridSettings = createAction(
    `${DashboardActions.CATEGORY} PATCH_LOCAL_DATA_GRID_SETTINGS`,
    props<{ settingId: string; localDataGridSettingsPatch: Partial<LocalDataGridSettings> }>(),
  );

  public static getDashboardRecipients = createAction(
    `${DashboardActions.CATEGORY} GET_DASHBOARD_RECIPIENTS`,
    props<{ dashboardId: string }>(),
  );

  public static getDashboardRecipientsSuccess = createAction(`${DashboardActions.CATEGORY} GET_DASHBOARD_RECIPIENTS_SUCCESS`);

  public static getDashboardRecipientsFailure = createAction(`${DashboardActions.CATEGORY} GET_DASHBOARD_RECIPIENTS_FAILURE`);

  public static addDashboardRecipients = createAction(
    `${DashboardActions.CATEGORY} ADD_DASHBOARD_RECIPIENTS`,
    props<{ userAdminAccounts: UserAdminAccount[] }>(),
  );

  public static shareDashboard = createAction(`${DashboardActions.CATEGORY} SHARE_DASHBOARD`);

  public static setTableTrendDefinitionsById = createAction(
    `${DashboardActions.CATEGORY} SET_TABLE_TREND_DEFINITIONS_BY_ID`,
    props<{ trendDefinitionsById: Record<string, TrendDefinition> }>(),
  );

  public static setTableTrendsById = createAction(
    `${DashboardActions.CATEGORY} SET_TABLE_TRENDS_BY_ID`,
    props<{ tableTrendsById: Record<string, Trend> }>(),
  );

  public static goToDeviceDetailPage = createAction(
    `${DashboardActions.CATEGORY} GO_TO_DEVICE_DETAIL_PAGE`,
    props<{
      device: Device;
      userDetailPageType?: UserDetailPageType;
      subDashboardId?: string;
      platform?: string;
      additionalParams?: Params;
      openNewTab?: boolean;
    }>(),
  );

  public static goToAppInsightWidgetDetail = createAction(
    `${DashboardActions.CATEGORY} GO_TO_APP_INSIGHT_WIDGET_DETAIL`,
    props<AppInsightWidgetDetail>(),
  );

  public static clearTrendDefinitionByStandardWidgetSubtype = createAction(
    `${DashboardActions.CATEGORY} CLEAR_TREND_DEFINITION_BY_STANDARD_WIDGET_SUBTYPE`,
  );

  public static getDefaultWidgetColumns = createAction(
    `${DashboardActions.CATEGORY} GET_DEFAULT_WIDGET_COLUMNS`,
    props<{
      categoryId: string;
      isCrossCategory?: boolean;
    }>(),
  );

  public static getDefaultWidgetColumnsSuccess = createAction(
    `${DashboardActions.CATEGORY} GET_DEFAULT_WIDGET_COLUMNS_SUCCESS`,
    props<{
      columns: string[];
      isCrossCategory: boolean;
    }>(),
  );

  public static getDefaultWidgetColumnsFailure = createAction(`${DashboardActions.CATEGORY} GET_DEFAULT_WIDGET_COLUMNS_FAILURE`);

  public static initializeIssueDetail = createAction(
    `${DashboardActions.CATEGORY} INITIALIZE_ISSUE_DETAIL`,
    props<{
      issueDetailPage: WidgetDetailPage;
      sortOns: SortOn[];
    }>(),
  );

  public static loadCompositeIssueDetail = createAction(`${DashboardActions.CATEGORY} LOAD_COMPOSITE_ISSUE_DETAIL`);

  public static loadCompositeIssueDetailSuccess = createAction(
    `${DashboardActions.CATEGORY} LOAD_COMPOSITE_ISSUE_DETAIL_SUCCESS`,
    props<{ compositeTrendData: Map<string, Trend> }>(),
  );

  public static loadCompositeIssueDetailFailure = createAction(
    `${DashboardActions.CATEGORY} LOAD_COMPOSITE_ISSUE_DETAIL_FAILURE`,
    props<{ webError: WebError }>(),
  );

  public static getIssueWidgetColumnsSuccess = createAction(
    `${DashboardActions.CATEGORY} GET_ISSUE_WIDGET_COLUMNS_SUCCESS`,
    props<{
      columns: string[];
      categoryId: string;
      isCrossCategory: boolean;
    }>(),
  );

  public static clearDefaultColumns = createAction(`${DashboardActions.CATEGORY} CLEAR_DEFAULT_COLUMNS`);

  public static setSelectedViewLayout = createAction(
    `${DashboardActions.CATEGORY} SET_SELECTED_VIEW_LAYOUT`,
    props<{ selectedViewLayout: LayoutType }>(),
  );

  public static setSearchDashboardType = createAction(
    `${DashboardActions.CATEGORY} SET_SEARCH_DASHBOARD_TYPE`,
    props<{ searchType: string }>(),
  );

  public static importDashboardTemplates = createAction(
    `${DashboardActions.CATEGORY} IMPORT_DASHBOARD_TEMPLATES`,
    props<{ templateIds: string[] }>(),
  );
  public static importDashboardTemplatesSuccess = createAction(`${DashboardActions.CATEGORY} IMPORT_DASHBOARD_TEMPLATES_SUCCESS`);
  public static importDashboardTemplatesFailure = createAction(`${DashboardActions.CATEGORY} IMPORT_DASHBOARD_TEMPLATES_FAILURE`);

  public static createWidgetFromDataExplorer = createAction(
    `${DashboardActions.CATEGORY} CREATE_WIDGET_FROM_DATA_EXPLORER`,
    props<{ dashboardId: string }>(),
  );
  public static createWidgetFromDataExplorerSuccess = createAction(`${DashboardActions.CATEGORY} CREATE_WIDGET_FROM_DATA_EXPLORER_SUCCESS`);
  public static createWidgetFromDataExplorerFailure = createAction(`${DashboardActions.CATEGORY} CREATE_WIDGET_FROM_DATA_EXPLORER_FAILURE`);

  public static addWidgetInNewDashboard = createAction(
    `${DashboardActions.CATEGORY} ADD_WIDGET_IN_NEW_DASHBOARD`,
    props<{ name: string; description: string }>(),
  );

  public static setDashboardCrossCategory = createAction(
    `${DashboardActions.CATEGORY} SET_DASHBOARD_CROSS_CATEGORY`,
    props<{ isCrossCategory: boolean }>(),
  );

  public static loadIncrementalWidgetDataByWidgetId = createAction(
    `${DashboardActions.CATEGORY} LOAD_INCREMENTAL_WIDGET_DATA_BY_WIDGET_ID`,
    props<{
      trackingId: string;
      details?: IncrementalLoadingWidgetDetails;
    }>(),
  );

  public static loadIncrementalWidgetDataByTrendDefinition = createAction(
    `${DashboardActions.CATEGORY} LOAD_INCREMENTAL_WIDGET_DATA_BY_TREND_DEFINITION`,
    props<{
      trackingId: string;
      details?: IncrementalLoadingWidgetDetails;
    }>(),
  );

  public static loadIncrementalWidgetData = createAction(
    `${DashboardActions.CATEGORY} LOAD_INCREMENTAL_WIDGET_DATA`,
    props<{ trackingIdDetailsMap: Map<string, IncrementalLoadingWidgetDetails>; isWidgetIdsMap?: boolean }>(),
  );

  public static initPollingWidgetDataByQueryId = createAction(
    `${DashboardActions.CATEGORY} INIT_POLLING_WIDGET_DATA_BY_QUERY_ID`,
    props<{ backendQueryId: string; uiQueryId: string }>(),
  );

  public static loadWidgetDataByQueryId = createAction(
    `${DashboardActions.CATEGORY} LOAD_WIDGET_DATA_BY_QUERY_ID`,
    props<{ backendQueryId: string; uiQueryId: string }>(),
  );

  public static loadWidgetDataByQueryIdSuccess = createAction(
    `${DashboardActions.CATEGORY} LOAD_WIDGET_DATA_BY_QUERY_ID_SUCCESS`,
    props<{ trendPreviewResponse: IncrementalLoadingTrendPreviewResponse }>(),
  );

  public static updateTrackingIdDetailsMap = createAction(
    `${DashboardActions.CATEGORY} UPDATE_TRACKING_ID_DETAILS_MAP`,
    props<{ trackingIdDetailsMap: Map<string, IncrementalLoadingWidgetDetails> }>(),
  );

  public static cancelPollingByQueryId = createAction(
    `${DashboardActions.CATEGORY} CANCEL_POLLING_BY_QUERY_ID`,
    props<{ uiQueryIds: string[] }>(),
  );

  public static deleteQueryId = createAction(`${DashboardActions.CATEGORY} DELETE_QUERY_ID`, props<{ uiQueryIds: string[] }>());

  public static getQueryIdForTrendDefinitions = createAction(
    `${DashboardActions.CATEGORY} GET_QUERY_ID_FOR_TREND_DEFINITIONS`,
    props<{ trackingIdDetailsMap: Map<string, IncrementalLoadingWidgetDetails>; isWidgetIdsMap?: boolean; uiQueryId: string }>(),
  );

  public static setTrackingIdsStatusAsInProgress = createAction(
    `${DashboardActions.CATEGORY} SET_TRACKING_IDS_STATUS_AS_IN_PROGRESS`,
    props<{ trackingIds: string[] }>(),
  );

  public static noop = createAction(`${DashboardActions.CATEGORY} NOOP`);
}
