/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { FuzzySubgroupConfig, GenericObject, WebError } from '@dpa/ui-common';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { get, keyBy } from 'lodash-es';

import { CoreAppState } from '@ws1c/intelligence-core/store/core-app-state';
import { getSubgroupConfig, groupCategoriesByIntegration, IntegrationMetaSelectors } from '@ws1c/intelligence-core/store/integration-meta';
import { FeatureSelectors } from '@ws1c/intelligence-core/store/rbac';
import { UserPreferenceFeatureControlsSelectors } from '@ws1c/intelligence-core/store/user-preference/user-preference-feature-controls.selectors';
import {
  AggregationWidgetChartType,
  Automation,
  AutomationAction,
  AutomationDialogMode,
  AutomationQuota,
  AutomationQuotaType,
  AutomationSearchRequest,
  AutomationSearchResponse,
  AutomationSourceObject,
  AutomationTestConnectionResult,
  AutomationType,
  AutomationWizardMode,
  Category,
  Column,
  ColumnIndex,
  ConnectionTestMode,
  Connector,
  ConnectorAction,
  CronExpressionData,
  CustomReportPreviewSearchResponse,
  DataGridColumn,
  EVALUATION_TYPE_CONFIG,
  EvaluationType,
  IntegrationCategories,
  LabelValueDescription,
  MetaForm,
  MetaFormField,
  NameValue,
  SearchTagFilterType,
  ServiceLimits,
  ServiceLimitsName,
} from '@ws1c/intelligence-models';
import {
  areAutomationConnectorActionsValid,
  convertColumnsToDatagridColumns,
  convertMetaFormFieldsForDisplay,
  cronExpressionDataFromScheduleOrDefault,
  getAnythingByKey,
  getAutomationCategory,
  getAutomationValid,
  getAvailableAutomationEntities,
  getDynamicValuesColumnsByNameHelper,
  getDynamicValuesColumnsHelper,
  getFilteredTestAttributes,
  getIrrecoverableConnectorActionsFromAutomation,
  isAutomationCategoryOnlySupportsIncomingData,
  isAutomationDialogMode,
} from './automation-common-selector.helpers';
import { AutomationCommonState } from './automation-common.store';

/**
 * AutomationCommonSelectors
 *
 * @export
 * @class AutomationCommonSelectors
 */
export class AutomationCommonSelectors {
  public static selectAutomationCommonState = (state: CoreAppState) => state.automationCommonState;

  /**
   * getAutomationCategoriesState
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, Category[]>}
   * @memberOf AutomationCommonSelectors
   */
  public static getAutomationCategoriesState: MemoizedSelector<CoreAppState, Category[]> = createSelector(
    IntegrationMetaSelectors.getAvailableCategoriesState,
    getAvailableAutomationEntities,
  );

  /**
   * getAutomationCategoriesByIntegration
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, IntegrationCategories[]>}
   * @memberOf AutomationCommonSelectors
   */
  public static getAutomationCategoriesByIntegration: MemoizedSelector<CoreAppState, IntegrationCategories[]> = createSelector(
    AutomationCommonSelectors.getAutomationCategoriesState,
    groupCategoriesByIntegration,
  );

  /**
   * getAutomationSubgroupConfig
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, FuzzySubgroupConfig>}
   * @memberOf AutomationCommonSelectors
   */
  public static getAutomationSubgroupConfig: MemoizedSelector<CoreAppState, FuzzySubgroupConfig> = createSelector(
    AutomationCommonSelectors.getAutomationCategoriesByIntegration,
    getSubgroupConfig,
  );

  /**
   * getAutomation
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, Automation>}
   * @memberOf AutomationCommonSelectors
   */
  public static getAutomation: MemoizedSelector<CoreAppState, Automation> = createSelector(
    AutomationCommonSelectors.selectAutomationCommonState,
    (state: AutomationCommonState) => state.automation,
  );

  /**
   * getAutmationQuotas
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, AutomationQuota[]>}
   * @memberof AutomationCommonSelectors
   */
  public static getAutomationQuotas: MemoizedSelector<CoreAppState, AutomationQuota[]> = createSelector(
    AutomationCommonSelectors.selectAutomationCommonState,
    (state: AutomationCommonState) => state.automationQuotas,
  );

  /**
   * getAutomationDailyEmailCapacity
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, AutomationQuota>}
   * @memberof AutomationCommonSelectors
   */
  public static getAutomationDailyEmailCapacity: MemoizedSelector<CoreAppState, AutomationQuota> = createSelector(
    AutomationCommonSelectors.getAutomationQuotas,
    (automationQuotas: AutomationQuota[]) => {
      return automationQuotas.filter((element: AutomationQuota) => element.type === AutomationQuotaType.EMAIL_EXECUTIONS)[0];
    },
  );

  /**
   * getAutomationWizardMode
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, AutomationWizardMode>}
   * @memberof AutomationCommonSelectors
   */
  public static getAutomationWizardMode: MemoizedSelector<CoreAppState, AutomationWizardMode> = createSelector(
    AutomationCommonSelectors.selectAutomationCommonState,
    (state: AutomationCommonState) => state.automationWizardMode,
  );

  /**
   * getAutomationStateParams
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, GenericObject>}
   * @memberof AutomationCommonSelectors
   */
  public static getAutomationStateParams: MemoizedSelector<CoreAppState, GenericObject> = createSelector(
    AutomationCommonSelectors.selectAutomationCommonState,
    (state: AutomationCommonState) => state.automationStateParams,
  );

  /**
   * isWizardAutomatedFromOtherSource
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof AutomationCommonSelectors
   */
  public static isWizardAutomatedFromOtherSource: MemoizedSelector<CoreAppState, boolean> = createSelector(
    AutomationCommonSelectors.getAutomationWizardMode,
    (wizardMode: AutomationWizardMode) => wizardMode === AutomationWizardMode.CREATE_FROM_OTHER_SOURCE,
  );

  /**
   * isAutomationWizardCreateMode
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof AutomationCommonSelectors
   */
  public static isAutomationWizardCreateMode: MemoizedSelector<CoreAppState, boolean> = createSelector(
    AutomationCommonSelectors.getAutomationWizardMode,
    AutomationCommonSelectors.isWizardAutomatedFromOtherSource,
    (wizardMode: AutomationWizardMode, isWizardAutomatedFromOtherSource: boolean) => {
      if (isWizardAutomatedFromOtherSource) {
        return true;
      }
      return wizardMode === AutomationWizardMode.CREATE;
    },
  );

  /**
   * isAutomationWizardEditMode
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof AutomationCommonSelectors
   */
  public static isAutomationWizardEditMode: MemoizedSelector<CoreAppState, boolean> = createSelector(
    AutomationCommonSelectors.getAutomationWizardMode,
    (wizardMode: AutomationWizardMode) =>
      [AutomationWizardMode.EDIT, AutomationWizardMode.EDIT_FROM_AUTOMATION_DETAILS, AutomationWizardMode.EDIT_FROM_OTHER_SOURCE].includes(
        wizardMode,
      ),
  );

  /**
   * getAutomationPreviewData
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, CustomReportPreviewSearchResponse>}
   * @returns {CustomReportPreviewSearchResponse}
   * @memberof AutomationCommonSelectors
   */
  public static getAutomationPreviewData: MemoizedSelector<CoreAppState, CustomReportPreviewSearchResponse> = createSelector(
    AutomationCommonSelectors.selectAutomationCommonState,
    (state: AutomationCommonState) => state.previewData,
  );

  /**
   * isAutomationSubPage
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @returns {boolean}
   * @memberof AutomationCommonSelectors
   */
  public static isAutomationSubPage: MemoizedSelector<CoreAppState, boolean> = createSelector(
    AutomationCommonSelectors.selectAutomationCommonState,
    (state: AutomationCommonState) => state.isSubPage,
  );

  /**
   * getSourceObject
   * @static
   * @type {MemoizedSelector<CoreAppState, AutomationSourceObject>}
   * @returns {AutomationSourceObject}
   * @memberof AutomationCommonSelectors
   */
  public static getSourceObject: MemoizedSelector<CoreAppState, AutomationSourceObject> = createSelector(
    AutomationCommonSelectors.selectAutomationCommonState,
    (state: AutomationCommonState) => state.sourceObject,
  );

  /**
   * getDetailsAutomation
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, Automation>}
   * @returns {Automation}
   * @memberof AutomationCommonSelectors
   */
  public static getDetailsAutomation: MemoizedSelector<CoreAppState, Automation> = createSelector(
    AutomationCommonSelectors.selectAutomationCommonState,
    (state: AutomationCommonState) => state.detailsAutomation,
  );

  /**
   * isLoadingAutomationRequest
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @returns {boolean}
   * @memberOf AutomationCommonSelectors
   */
  public static isLoadingAutomationRequest: MemoizedSelector<CoreAppState, boolean> = createSelector(
    AutomationCommonSelectors.selectAutomationCommonState,
    (state: AutomationCommonState) => state.loading,
  );

  /**
   * isLoadingMoreAutomations
   *
   * @static
   * @type {MemoizedSelector<WorkflowState, boolean>}
   * @returns {boolean}
   * @memberof AutomationCommonSelectors
   */
  public static isLoadingMoreAutomations: MemoizedSelector<CoreAppState, boolean> = createSelector(
    AutomationCommonSelectors.selectAutomationCommonState,
    (state: AutomationCommonState) => state.loadingMore,
  );

  /**
   * getTestAttributes
   *
   * @static
   * @type {MemoizedSelector<WorkflowState, Set<string>>}
   * @returns {Set<string>}
   * @memberof AutomationCommonSelectors
   */
  public static getTestAttributes: MemoizedSelector<CoreAppState, Set<string>> = createSelector(
    AutomationCommonSelectors.selectAutomationCommonState,
    (state: AutomationCommonState) => state.testAttributes,
  );

  /**
   * getTestableMetaForm
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, MetaForm>}
   * @returns {MetaForm}
   * @memberof AutomationCommonSelectors
   */
  public static getTestableMetaForm: MemoizedSelector<CoreAppState, MetaForm> = createSelector(
    AutomationCommonSelectors.selectAutomationCommonState,
    (state: AutomationCommonState) => state.testableMetaForm,
  );

  /**
   * getTestableAutomationAction
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, AutomationAction>}
   * @returns {AutomationAction}
   * @memberof AutomationCommonSelectors
   */
  public static getTestableAutomationAction: MemoizedSelector<CoreAppState, AutomationAction> = createSelector(
    AutomationCommonSelectors.selectAutomationCommonState,
    (state: AutomationCommonState) => state.testableAutomationAction,
  );

  /**
   * getTestConnectionStatus
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, ConnectionTestMode>}
   * @returns {ConnectionTestMode}
   * @memberof AutomationCommonSelectors
   */
  public static getTestConnectionStatus: MemoizedSelector<CoreAppState, ConnectionTestMode> = createSelector(
    AutomationCommonSelectors.selectAutomationCommonState,
    (state: AutomationCommonState) => state.testConnectionStatus,
  );

  /**
   * getAutomationTestResult
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, AutomationTestConnectionResult>}
   * @returns {AutomationTestConnectionResult}
   * @memberof AutomationCommonSelectors
   */
  public static getAutomationTestResult: MemoizedSelector<CoreAppState, AutomationTestConnectionResult> = createSelector(
    AutomationCommonSelectors.selectAutomationCommonState,
    (state: AutomationCommonState) => state.testConnectionResult,
  );

  /**
   * isConnectionTestRunning
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @returns {boolean}
   * @memberof AutomationCommonSelectors
   */
  public static isConnectionTestRunning: MemoizedSelector<CoreAppState, boolean> = createSelector(
    AutomationCommonSelectors.getTestConnectionStatus,
    (testMode: ConnectionTestMode) => testMode === ConnectionTestMode.TESTING,
  );

  /**
   * getTestDynamicValues
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, CustomReportPreviewSearchResponse>}
   * @returns {CustomReportPreviewSearchResponse}
   * @memberof AutomationCommonSelectors
   */
  public static getTestDynamicValues: MemoizedSelector<CoreAppState, CustomReportPreviewSearchResponse> = createSelector(
    AutomationCommonSelectors.selectAutomationCommonState,
    (state: AutomationCommonState) => state.testDynamicValues,
  );

  /**
   * getTestDynamicValuesLoading
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @returns {boolean}
   * @memberof AutomationCommonSelectors
   */
  public static getTestDynamicValuesLoading: MemoizedSelector<CoreAppState, boolean> = createSelector(
    AutomationCommonSelectors.selectAutomationCommonState,
    (state: AutomationCommonState) => state.testDynamicValuesLoading,
  );

  /**
   * getDynamicValuesColumns
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, Column[]>}
   * @returns {Column[]}
   * @memberof AutomationCommonSelectors
   */
  public static getDynamicValuesColumns: MemoizedSelector<CoreAppState, Column[]> = createSelector(
    IntegrationMetaSelectors.getCrossCategoryColumnsByName,
    AutomationCommonSelectors.getTestAttributes,
    (availableColumns: ColumnIndex, attributes: Set<string>) => {
      return getDynamicValuesColumnsHelper(availableColumns, attributes);
    },
  );

  /**
   * getUnknownTestAttributes
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, Set<string>>}
   * @returns {Set<string>}
   * @memberof AutomationCommonSelectors
   */
  public static getUnknownTestAttributes: MemoizedSelector<CoreAppState, Set<string>> = createSelector(
    IntegrationMetaSelectors.getCrossCategoryColumnsByName,
    AutomationCommonSelectors.getTestAttributes,
    (allColumns: ColumnIndex, testAttributes: Set<string>) => {
      return getFilteredTestAttributes(allColumns, testAttributes, false);
    },
  );

  /**
   * getKnownTestAttributes
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, Set<string>>}
   * @returns {Set<string>}
   * @memberof AutomationCommonSelectors
   */
  public static getKnownTestAttributes: MemoizedSelector<CoreAppState, Set<string>> = createSelector(
    IntegrationMetaSelectors.getCrossCategoryColumnsByName,
    AutomationCommonSelectors.getTestAttributes,
    (allColumns: ColumnIndex, testAttributes: Set<string>) => {
      return getFilteredTestAttributes(allColumns, testAttributes);
    },
  );

  /**
   * getDynamicValuesDataGridColumns
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, DataGridColumn[]>}
   * @returns {DataGridColumn[]}
   * @memberof AutomationCommonSelectors
   */
  public static getDynamicValuesDataGridColumns: MemoizedSelector<CoreAppState, DataGridColumn[]> = createSelector(
    AutomationCommonSelectors.getDynamicValuesColumns,
    convertColumnsToDatagridColumns,
  );

  /**
   * getDynamicValuesColumnsByName
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, ColumnIndex>}
   * @returns {ColumnIndex}
   * @memberof AutomationCommonSelectors
   */
  public static getDynamicValuesColumnsByName: MemoizedSelector<CoreAppState, ColumnIndex> = createSelector(
    IntegrationMetaSelectors.getCrossCategoryColumnsByName,
    AutomationCommonSelectors.getTestAttributes,
    (availableColumns: ColumnIndex, attributes: Set<string>) => {
      return getDynamicValuesColumnsByNameHelper(availableColumns, attributes);
    },
  );

  /**
   * fromDetailView
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @returns {boolean}
   * @memberof AutomationCommonSelectors
   */
  public static fromDetailView: MemoizedSelector<CoreAppState, boolean> = createSelector(
    AutomationCommonSelectors.selectAutomationCommonState,
    (state: AutomationCommonState) => state.fromDetailView,
  );

  /**
   * getAutomationSearchResponse
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, AutomationSearchResponse>}
   * @returns {AutomationSearchResponse}
   * @memberOf AutomationCommonSelectors
   */
  public static getAutomationSearchResponse: MemoizedSelector<CoreAppState, AutomationSearchResponse> = createSelector(
    AutomationCommonSelectors.selectAutomationCommonState,
    (state: AutomationCommonState) => state.response,
  );

  /**
   * getAutomationSearchRequest
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, AutomationSearchRequest>}
   * @returns {AutomationSearchRequest}
   * @memberOf AutomationCommonSelectors
   */
  public static getAutomationSearchRequest: MemoizedSelector<CoreAppState, AutomationSearchRequest> = createSelector(
    AutomationCommonSelectors.selectAutomationCommonState,
    (state: AutomationCommonState) => state.request,
  );

  /**
   * showAutomationFilter
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @returns {boolean}
   * @memberOf AutomationCommonSelectors
   */
  public static showAutomationFilter: MemoizedSelector<CoreAppState, boolean> = createSelector(
    AutomationCommonSelectors.getAutomationSearchResponse,
    AutomationCommonSelectors.getAutomationSearchRequest,
    (response: AutomationSearchResponse, request: AutomationSearchRequest) =>
      !!(response?.results?.length || get(request, 'searchTerm.value')),
  );

  /**
   * isLoadingOrHasAutomations
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @returns {boolean}
   * @memberOf AutomationCommonSelectors
   */
  public static isLoadingOrHasAutomations: MemoizedSelector<CoreAppState, boolean> = createSelector(
    AutomationCommonSelectors.isLoadingAutomationRequest,
    AutomationCommonSelectors.showAutomationFilter,
    (isLoading: boolean, showFilter: boolean) => isLoading || showFilter,
  );

  /**
   * getAutomationDialogMode
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, AutomationDialogMode>}
   * @returns {AutomationDialogMode}
   * @memberOf AutomationCommonSelectors
   */
  public static getAutomationDialogMode: MemoizedSelector<CoreAppState, AutomationDialogMode> = createSelector(
    AutomationCommonSelectors.selectAutomationCommonState,
    (state: AutomationCommonState) => state.automationDialogMode,
  );

  /**
   * getAutomationDialogModel
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, Automation>}
   * @returns {Automation}
   * @memberOf AutomationCommonSelectors
   */
  public static getAutomationDialogModel: MemoizedSelector<CoreAppState, Automation> = createSelector(
    AutomationCommonSelectors.selectAutomationCommonState,
    (state: AutomationCommonState) => state.automationDialogModel,
  );

  /**
   * isAutomationDialogMode
   *
   * @static
   * @type {() => {}}
   * @returns {(...args: AutomationDialogMode[]) => MemoizedSelector<CoreAppState, boolean>}
   * @memberOf AutomationCommonSelectors
   */
  public static isAutomationDialogMode = (...args: AutomationDialogMode[]) => {
    const myFun = isAutomationDialogMode(...args);
    return createSelector(AutomationCommonSelectors.getAutomationDialogMode, myFun);
  };

  /**
   * getCancelWizardReturnPath
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, string>}
   * @returns {string}
   * @memberof AutomationCommonSelectors
   */
  public static getCancelWizardReturnPath: MemoizedSelector<CoreAppState, string> = createSelector(
    AutomationCommonSelectors.selectAutomationCommonState,
    (state: AutomationCommonState) => state.cancelWizardReturnPath,
  );

  /**
   * getSuccessWizardReturnPath
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, string>}
   * @returns {string}
   * @memberof AutomationCommonSelectors
   */
  public static getSuccessWizardReturnPath: MemoizedSelector<CoreAppState, string> = createSelector(
    AutomationCommonSelectors.selectAutomationCommonState,
    (state: AutomationCommonState) => state.successWizardReturnPath,
  );

  /**
   * automationDialogShouldLoadPreviewData
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @returns {boolean}
   * @memberOf AutomationCommonSelectors
   */
  public static automationDialogShouldLoadPreviewData: MemoizedSelector<CoreAppState, boolean> = createSelector(
    AutomationCommonSelectors.getAutomationDialogMode,
    AutomationCommonSelectors.getAutomationDialogModel,
    (dialogMode: AutomationDialogMode, automation: Automation) => {
      if (!automation) {
        return false;
      }
      if (isAutomationDialogMode(AutomationDialogMode.TOGGLE, AutomationDialogMode.SAVE_AND_ENABLE)(dialogMode)) {
        return !automation.active;
      }
      return isAutomationDialogMode(AutomationDialogMode.RUN)(dialogMode);
    },
  );

  /**
   * isAutomationCategoryOnlySupportsHistoricalData
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @returns {boolean}
   * @memberOf AutomationCommonSelectors
   */
  public static isAutomationCategoryOnlySupportsHistoricalData: MemoizedSelector<CoreAppState, boolean> = createSelector(
    IntegrationMetaSelectors.getActiveCategory,
    isAutomationCategoryOnlySupportsIncomingData,
  );

  /**
   * getInitialSelectedChartType
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, AggregationWidgetChartType>}
   * @returns {AggregationWidgetChartType}
   * @memberOf AutomationCommonSelectors
   */
  public static getInitialSelectedChartType: MemoizedSelector<CoreAppState, AggregationWidgetChartType> = createSelector(
    AutomationCommonSelectors.isAutomationCategoryOnlySupportsHistoricalData,
    (isHistorical: boolean) => (isHistorical ? AggregationWidgetChartType.LINE : AggregationWidgetChartType.VERTICAL),
  );

  /**
   * isAutomationReadyForBatchMode
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @returns {boolean}
   * @memberOf AutomationCommonSelectors
   */
  public static isAutomationReadyForBatchMode: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceFeatureControlsSelectors.isAutomationBatchModeEnabled,
    AutomationCommonSelectors.isAutomationCategoryOnlySupportsHistoricalData,
    (isBatchModeEnabled: boolean, isOnlyIncomingData: boolean) => isBatchModeEnabled && !isOnlyIncomingData,
  );

  /**
   * isInactiveAutomationInBatchMode
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @returns {boolean}
   * @memberOf AutomationCommonSelectors
   */
  public static isInactiveAutomationInBatchMode: MemoizedSelector<CoreAppState, boolean> = createSelector(
    AutomationCommonSelectors.getAutomationDialogModel,
    UserPreferenceFeatureControlsSelectors.isAutomationBatchModeEnabled,
    (automationDialogModel: Automation, isBatchModeEnabled: boolean) => {
      if (automationDialogModel?.active) {
        return false;
      }
      return isBatchModeEnabled;
    },
  );

  /**
   * getQuickViewCategory
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, Category>}
   * @returns {Category}
   * @memberOf AutomationCommonSelectors
   */
  public static getQuickViewCategory: MemoizedSelector<CoreAppState, Category> = createSelector(
    AutomationCommonSelectors.getAutomation,
    AutomationCommonSelectors.getAutomationCategoriesState,
    getAutomationCategory,
  );

  /**
   * isQuickViewCategoryBatchModeEnabled
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @returns {boolean}
   * @memberOf AutomationCommonSelectors
   */
  public static isQuickViewCategoryBatchModeEnabled: MemoizedSelector<CoreAppState, boolean> = createSelector(
    UserPreferenceFeatureControlsSelectors.isAutomationBatchModeEnabled,
    AutomationCommonSelectors.getQuickViewCategory,
    (isBatchModeEnabled: boolean, automationCategory: Category) => {
      if (!isBatchModeEnabled) {
        return false;
      }
      return !isAutomationCategoryOnlySupportsIncomingData(automationCategory);
    },
  );

  /**
   * getAutomationPreviewModel
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, Automation>}
   * @returns {Automation}
   * @memberOf AutomationCommonSelectors
   */
  public static getAutomationPreviewModel: MemoizedSelector<CoreAppState, Automation> = createSelector(
    AutomationCommonSelectors.selectAutomationCommonState,
    (state: AutomationCommonState) => state.automationPreviewModel,
  );

  /**
   * getAutomationWizardModel
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, Automation>}
   * @returns {Automation}
   * @memberOf AutomationCommonSelectors
   */
  public static getAutomationWizardModel: MemoizedSelector<CoreAppState, Automation> = createSelector(
    AutomationCommonSelectors.selectAutomationCommonState,
    (state: AutomationCommonState) => state.automationWizardModel,
  );

  /**
   * getSelectedAutomationEvaluationType
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, LabelValueDescription>}
   * @returns {LabelValueDescription}
   * @memberof AutomationCommonSelectors
   */
  public static getSelectedAutomationEvaluationType: MemoizedSelector<CoreAppState, LabelValueDescription> = createSelector(
    AutomationCommonSelectors.selectAutomationCommonState,
    (state: AutomationCommonState) => {
      if (!state.automationWizardModel) {
        return;
      }
      return EVALUATION_TYPE_CONFIG[state.automationWizardModel.evaluationType];
    },
  );

  /**
   * isSelectedAutomationEvaluationType
   *
   * @static
   * @param {EvaluationType} evaluationType
   * @type {(EvaluationType) => MemoizedSelector<CoreAppState, boolean>}
   * @memberof ConnectionCommonSelectors
   */
  public static isSelectedAutomationEvaluationType: (EvaluationType) => MemoizedSelector<CoreAppState, boolean> = (
    evaluationType: EvaluationType,
  ) =>
    createSelector(
      AutomationCommonSelectors.getSelectedAutomationEvaluationType,
      (labelValueDescription: LabelValueDescription) => labelValueDescription?.value === evaluationType,
    );

  /**
   * getDataListForDisplay
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, MetaFormField[]>}
   * @returns {MetaFormField[]}
   * @memberof AutomationCommonSelectors
   */
  public static getDataListForDisplay: MemoizedSelector<CoreAppState, MetaFormField[]> = createSelector(
    AutomationCommonSelectors.getTestableAutomationAction,
    AutomationCommonSelectors.getTestableMetaForm,
    convertMetaFormFieldsForDisplay,
  );

  /**
   * getSelectedCronExpressionData
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, CronExpressionData>}
   * @memberOf AutomationCommonSelectors
   */
  public static getSelectedCronExpressionData: MemoizedSelector<CoreAppState, CronExpressionData> = createSelector(
    AutomationCommonSelectors.getAutomationWizardModel,
    cronExpressionDataFromScheduleOrDefault,
  );

  /**
   * loadingActions
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof AutomationCommonSelectors
   */
  public static loadingActions: MemoizedSelector<CoreAppState, boolean> = createSelector(
    AutomationCommonSelectors.selectAutomationCommonState,
    (state: AutomationCommonState) => state.loadingActions,
  );

  /**
   * getFilteredActions
   * @static
   * @type {MemoizedSelector<CoreAppState, ConnectorAction[]>}
   * @memberof AutomationCommonSelectors
   */
  public static getFilteredActions: MemoizedSelector<CoreAppState, ConnectorAction[]> = createSelector(
    AutomationCommonSelectors.selectAutomationCommonState,
    (state: AutomationCommonState) =>
      state.connectorsActionsResponse?.data.filter((action: ConnectorAction) => !action.metadata.isUiHidden),
  );

  /**
   * getAllActions
   * @static
   * @type {MemoizedSelector<CoreAppState, ConnectorAction[]>}
   * @memberof AutomationCommonSelectors
   */
  public static getAllActions: MemoizedSelector<CoreAppState, ConnectorAction[]> = createSelector(
    AutomationCommonSelectors.selectAutomationCommonState,
    (state: AutomationCommonState) => state.connectorsActionsResponse?.data,
  );

  /**
   * currentActionConnectorId
   * @static
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberof AutomationCommonSelectors
   */
  public static currentActionConnectorId: MemoizedSelector<CoreAppState, string> = createSelector(
    AutomationCommonSelectors.selectAutomationCommonState,
    (state: AutomationCommonState) => state.currentActionConnectorId,
  );

  /**
   * currentConnectorActions
   * @static
   * @type {MemoizedSelector<CoreAppState, ConnectorAction[]>}
   * @memberof AutomationCommonSelectors
   */
  public static currentConnectorActions: MemoizedSelector<CoreAppState, ConnectorAction[]> = createSelector(
    AutomationCommonSelectors.currentActionConnectorId,
    AutomationCommonSelectors.getAllActions,
    (connectorId: string, actions: ConnectorAction[]) => actions?.filter((action: ConnectorAction) => action.connectorId === connectorId),
  );

  /**
   * getAllActionsByKey
   * @static
   * @type {MemoizedSelector<CoreAppState, Record<string, ConnectorAction>>}
   * @memberof AutomationCommonSelectors
   */
  public static getAllActionsByKey: MemoizedSelector<CoreAppState, Record<string, ConnectorAction>> = createSelector(
    AutomationCommonSelectors.currentConnectorActions,
    getAnythingByKey,
  );

  /**
   * getAllActionsById
   * @static
   * @type {MemoizedSelector<CoreAppState, Record<string, ConnectorAction>>}
   * @memberof AutomationCommonSelectors
   */
  public static getAllActionsById: MemoizedSelector<CoreAppState, Record<string, ConnectorAction>> = createSelector(
    AutomationCommonSelectors.getAllActions,
    (connectorActions: ConnectorAction[]) => keyBy(connectorActions, 'id'),
  );

  /**
   * getAutomationIrrecoverableConnectorActions
   * @static
   * @type {MemoizedSelector<CoreAppState, ConnectorAction[]>}
   * @memberof AutomationCommonSelectors
   */
  public static getAutomationIrrecoverableConnectorActions: MemoizedSelector<CoreAppState, ConnectorAction[]> = createSelector(
    AutomationCommonSelectors.getAutomationWizardModel,
    AutomationCommonSelectors.getAllActionsById,
    AutomationCommonSelectors.getAutomationDialogModel,
    getIrrecoverableConnectorActionsFromAutomation,
  );

  /**
   * getAutomationConnectorActionsValidity
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberOf AutomationCommonSelectors
   */
  public static getAutomationConnectorActionsValidity: MemoizedSelector<CoreAppState, boolean> = createSelector(
    AutomationCommonSelectors.getAutomationWizardModel,
    AutomationCommonSelectors.getAllActions,
    areAutomationConnectorActionsValid,
  );

  /**
   * getTestableConnectorAction
   * @static
   * @type {MemoizedSelector<CoreAppState, ConnectorAction>}
   * @memberof AutomationCommonSelectors
   */
  public static getTestableConnectorAction: MemoizedSelector<CoreAppState, ConnectorAction> = createSelector(
    AutomationCommonSelectors.selectAutomationCommonState,
    (state: AutomationCommonState) => state.testableConnectorAction,
  );

  /**
   * getTestableConnector
   * @static
   * @type {MemoizedSelector<CoreAppState, ConnectorAction>}
   * @memberof AutomationCommonSelectors
   */
  public static getTestableConnector: MemoizedSelector<CoreAppState, Connector> = createSelector(
    AutomationCommonSelectors.selectAutomationCommonState,
    (state: AutomationCommonState) => state.testableConnector,
  );

  /**
   * isGOAutomationValid
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @returns {boolean}
   * @memberOf AutomationCommonSelectors
   */
  public static isGOAutomationValid: MemoizedSelector<CoreAppState, boolean> = createSelector(
    AutomationCommonSelectors.getAutomationWizardModel,
    (automation: Automation) => {
      return automation?.allActions?.length !== 0;
    },
  );

  /**
   * getAutomationValidity
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @returns {boolean}
   * @memberOf AutomationCommonSelectors
   */
  public static getAutomationValidity: MemoizedSelector<CoreAppState, boolean> = createSelector(
    AutomationCommonSelectors.getAutomationConnectorActionsValidity,
    AutomationCommonSelectors.getAutomationWizardModel,
    AutomationCommonSelectors.isGOAutomationValid,
    (automationConnectorActionsValidity: boolean, wizardModel: Automation, isGOAutomationValid: boolean) => {
      return getAutomationValid(automationConnectorActionsValidity, wizardModel, isGOAutomationValid);
    },
  );

  /**
   * isDisabledAutomationWizard
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @returns {boolean}
   * @memberOf AutomationCommonSelectors
   */
  public static isDisabledAutomationWizard: MemoizedSelector<CoreAppState, boolean> = createSelector(
    AutomationCommonSelectors.getAutomationValidity,
    AutomationCommonSelectors.isLoadingAutomationRequest,
    (automationValidity: boolean, isLoadingAutomationRequest: boolean) => !automationValidity || isLoadingAutomationRequest,
  );

  /**
   * getAutomationPreviewDataLoading
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @returns {boolean}
   * @memberof AutomationCommonSelectors
   */
  public static getAutomationPreviewDataLoading: MemoizedSelector<CoreAppState, boolean> = createSelector(
    AutomationCommonSelectors.selectAutomationCommonState,
    (state: AutomationCommonState) => state.previewDataLoading,
  );

  /**
   * getAutomationPreviewDataLastRefreshedAt
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, number>}
   * @returns {number}
   * @memberof AutomationCommonSelectors
   */
  public static getAutomationPreviewDataLastRefreshedAt: MemoizedSelector<CoreAppState, number> = createSelector(
    AutomationCommonSelectors.selectAutomationCommonState,
    (state: AutomationCommonState) => state.previewDataLastRefreshedAt,
  );

  /**
   * isPreviewDataNotPresentOrNotLoading
   *
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @returns {boolean}
   * @memberof AutomationCommonSelectors
   */
  public static isPreviewDataNotPresentOrNotLoading: MemoizedSelector<CoreAppState, boolean> = createSelector(
    AutomationCommonSelectors.getAutomationPreviewData,
    AutomationCommonSelectors.getAutomationPreviewDataLoading,
    (previewData: CustomReportPreviewSearchResponse, isPreviewDataLoading: boolean) => {
      return !previewData?.total && !isPreviewDataLoading;
    },
  );

  /**
   * getAvailableQuickFilterLabels
   * @static
   * @type {MemoizedSelector<CoreAppState, Record<string, string>>}
   * @memberof AutomationCommonSelectors
   */
  public static getAvailableQuickFilterLabels: MemoizedSelector<CoreAppState, Record<string, string>> = createSelector(
    UserPreferenceFeatureControlsSelectors.isSolutionsDeemIncidentEnabled,
    UserPreferenceFeatureControlsSelectors.isSolutionsDeemSurveysEnabled,
    (isIncidentEnabled: boolean, isSurveysEnabled: boolean) => ({
      [SearchTagFilterType.WORKFLOWS]: 'COMMON_ACTIONS.WORKFLOWS',
      ...(isIncidentEnabled && { [SearchTagFilterType.INCIDENTS]: 'COMMON_ACTIONS.INCIDENTS' }),
      ...(isSurveysEnabled && { [SearchTagFilterType.SURVEYS]: 'COMMON_ACTIONS.SURVEYS' }),
    }),
  );

  /**
   * lookupMap
   * @static
   * @type {MemoizedSelector<CoreAppState, Record<string, NameValue[]>>}
   * @memberof AutomationCommonSelectors
   */
  public static lookupMap: MemoizedSelector<CoreAppState, Record<string, NameValue[]>> = createSelector(
    AutomationCommonSelectors.selectAutomationCommonState,
    (state: AutomationCommonState) => state.lookupMap,
  );

  /**
   * isLookupMapLoading
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof AutomationCommonSelectors
   */
  public static isLookupMapLoading: MemoizedSelector<CoreAppState, boolean> = createSelector(
    AutomationCommonSelectors.selectAutomationCommonState,
    (state: AutomationCommonState) => state.lookupMapLoading,
  );

  /**
   * getLookupMapError
   * @static
   * @type {MemoizedSelector<CoreAppState, WebError>}
   * @memberof AutomationCommonSelectors
   */
  public static getLookupMapError: MemoizedSelector<CoreAppState, WebError> = createSelector(
    AutomationCommonSelectors.selectAutomationCommonState,
    (state: AutomationCommonState) => state.lookupMapError,
  );

  /**
   * hasRunHistoryDashboardPerm
   *
   * @static
   * @type {MemoizedSelector<WorkflowState, boolean>}
   * @returns {boolean}
   * @memberof AutomationCommonSelectors
   */
  public static hasRunHistoryDashboardPerm: MemoizedSelector<CoreAppState, boolean> = createSelector(
    FeatureSelectors.hasDashboardReadOnlyPerm,
    UserPreferenceFeatureControlsSelectors.isWorkflowRunHistoryEnabled,
    (hasDashboardReadOnlyPerm: boolean, isWorkflowRunHistoryEnabled: boolean) => hasDashboardReadOnlyPerm && isWorkflowRunHistoryEnabled,
  );

  /**
   * getActiveAutomationType
   * @static
   * @type {MemoizedSelector<CoreAppState, AutomationType>}
   * @memberof AutomationCommonSelectors
   */
  public static getActiveAutomationType: MemoizedSelector<CoreAppState, AutomationType> = createSelector(
    AutomationCommonSelectors.selectAutomationCommonState,
    (state: AutomationCommonState) => state.activeAutomationType,
  );

  /**
   * getMaxPotentialImpactTargets
   * @static
   * @type {MemoizedSelector<CoreAppState, number>}
   * @memberof AutomationCommonSelectors
   */
  public static getMaxPotentialImpactTargetsServiceLimit: MemoizedSelector<CoreAppState, number> = createSelector(
    AutomationCommonSelectors.selectAutomationCommonState,
    (state: AutomationCommonState) => {
      const serviceLimit = state.serviceLimits?.find(
        (limit: ServiceLimits): boolean => limit.name === ServiceLimitsName.AUTOMATION_POTENTIAL_IMPACT_TARGETS,
      );
      return serviceLimit?.value ?? Infinity;
    },
  );

  public static arePotentialImpactTargetsAboveLimit: MemoizedSelector<CoreAppState, boolean> = createSelector(
    AutomationCommonSelectors.getAutomationPreviewData,
    AutomationCommonSelectors.getMaxPotentialImpactTargetsServiceLimit,
    (previewData: CustomReportPreviewSearchResponse, maxPotentialTargets: number) => {
      const potentialTargets = previewData?.total ?? 0;
      return potentialTargets >= maxPotentialTargets;
    },
  );

  /**
   * getShowTriggerRules
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof AutomationCommonSelectors
   */
  public static getShowTriggerRules: MemoizedSelector<CoreAppState, boolean> = createSelector(
    AutomationCommonSelectors.selectAutomationCommonState,
    (state: AutomationCommonState) => state.showTriggerRules,
  );
}
